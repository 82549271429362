import { merge } from "lodash";

export default {
  setIsFetchingData(state, data) {
    state.isFetchingData = data;
  },
  setProgressMessage(state, data) {
    state.progressMessage = data;
  },
  setSuccessMessage(state, data) {
    state.successMessage = data;
  },
  setFetchError(state, data) {
    state.fetchError = data;
  },
  setIsSubmitting(state, data) {
    state.isSubmitting = data;
  },

  setIsLoading(state, data) {
    state.isLoading = data;
  },
  setIsFormValid(state, data) {
    state.isFormValid = data;
  },
  setError(state, error) {
    if (error?.response?.data?.title) {
      state.error = error.response.data.title;
    } else {
      state.error = error;
    }
  },
  setSubmitError(state, error) {
    if (Object.keys(error).length === 0) {
      state.submitError = error;
      return;
    }

    const title = error.title ?? error?.response?.data?.title;
    const status = error.status ?? error?.response?.data?.status;
    const detail = error.detail ?? error?.response?.data?.detail;

    state.submitError = {
      title: title ?? "",
      status: status ?? "",
      detail: detail ?? "",
    };
  },
  setFormData(state, data) {
    merge(state.formData, data);
  },
  clearFormData(state) {
    state.isFormValid = false;
    state.emailSentTo = "";
    state.w4PdfId = "";
    state.w4PdfTitle = "";
    state.formData = {
      claimingStatus: "", // S M H E
      additionalAmount: 0.0,
      exemptFlag: false,
      otherIncome: 0.0,
      deductions: 0.0,
      qualifyingChildrenAmount: 0.0,
      otherDependentAmount: 0.0,
      otherCreditAmount: 0.0,
      creditsSum: 0.0,
      newFlag: "0",
      multiJobsFlag: false,
      headHouseholdFlag: "0",
      employeeSignature: "",
      dateSigned: new Date(),
    };
    state.formSections.forEach((section) => {
      section.valid = true;
      section.enabled = true;
    });
  },
  setPersonalInformation(state, data) {
    state.personalInformation = {
      employeeFirstName: data.firstName,
      employeeLastName: data.lastName,
      ssn: data.ssn,
      street: data.streetAddress,
      apartment: data.streetAddress2 === "" ? " " : data.streetAddress2,
      city: data.city,
      state: data.state,
      zip: data.zip,
    };
  },
  setCurrentW4Information(state, data) {
    let claimingStatus = "";
    const isNew = data.isNewW4Form ? "Yes" : "No";

    if (data.isExempt) {
      claimingStatus = "Exempt";
    } else if (data.isSingleOrMarriedFilingSeparate) {
      claimingStatus = "Single or Married Filing Separately";
    } else if (data.isMarriedFilingJointly) {
      claimingStatus = "Married Filing Jointly or Qualifying Widow(er)";
    } else if (data.isFilingHeadOfHousehold) {
      claimingStatus = "Head of Household";
    }

    state.currentW4Information = {
      claimingStatus,
      isNew,
      isExempt: data.isExempt,
      isFilingStatusLocked: data.isFilingStatusLocked,
      dependentsSum: data.taxCredits,
      otherIncome: data.otherIncome,
      deductions: data.deductions,
      additionalAmount: data.employeeDeductionAmount,
    };
  },
  setFormSections(state, data) {
    state.formSections = data;
  },
  setDocumentArchive(state, data) {
    state.documentArchive = data;
  },
  setTotalDocumentsInArchive(state, data) {
    state.totalDocumentsInArchive = data;
  },
  setEmailSentTo(state, data) {
    state.emailSentTo = data;
  },
  setW4PdfId(state, data) {
    state.w4PdfId = data;
  },
  setW4PdfTitle(state, data) {
    state.w4PdfTitle = data;
  },
};
