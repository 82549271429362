<template>
  <router-view />
</template>

<script>
import { Loader } from "google-maps";
import { getEnv } from "@/utils/util";
import Vue from "vue";

export default {
  async beforeCreate() {
    if (Vue.$google === undefined) {
      const loader = new Loader(getEnv("VUE_APP_GOOGLE_MAPS_KEY"), {
        libraries: ["places"],
      });
      const google = await loader.load();

      Vue.$google = google;
      Vue.prototype.$google = google;
    }

    await this.$store.dispatch("settings/fetchStates");
  },
};
</script>
