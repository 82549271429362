import stateTax from "../../api/stateTax";
import { getApiError } from "../../../../utils/errorUtils";

export default {
  async submitTaxForm({ commit, getters }) {
    commit("setIsSubmitting", true);
    try {
      await stateTax.putStateTaxes(getters.payload);
      commit("setIsSubmitting", false);
    } catch (error) {
      commit("setSubmitError", getApiError(error));
      commit("setIsSubmitting", false);
    }
  },
  async getStateTaxes({ commit }) {
    commit("setIsLoading", true);
    commit("setError", null);
    try {
      const { data } = await stateTax.getStateTaxes();
      commit("setCurrentTaxInfo", data);
      commit("setElectWithhold", data?.percentageElection ?? "");
      commit("setPercentageElection", data?.percentageElection ?? "");
      commit("setElectAmount", data?.additionalAmount ?? "");
      commit("setAdditionalAmount", data?.additionalAmount ?? "");
      commit("setIsLoading", false);
      commit("setHasQueued", data?.queued);
      commit("setQueued", data?.queued ?? {});
    } catch (error) {
      console.warn(error);
      commit("setError", getApiError(error));
      commit("setIsLoading", false);
    }
  },
};
