export default {
  setIsFetchingData(state, data) {
    state.isFetchingData = data;
  },
  setProgressMessage(state, data) {
    state.progressMessage = data;
  },
  setSuccessMessage(state, data) {
    state.successMessage = data;
  },
  setFetchError(state, data) {
    state.fetchError = data;
  },
  setIsSubmitting(state, data) {
    state.isSubmitting = data;
  },
  setSubmitError(state, data) {
    state.submitError = data;
  },
  // Admin mutations
  setSearchCriteria(state, data) {
    state.searchCriteria = data;
  },
  setAdminUsers(state, data) {
    state.adminUsers = data;

    state.adminUsers.forEach((user) => {
      if (user.proxyEmployerId !== undefined) {
        user[
          "id"
        ] = `${user.employerId}/${user.employeeId}-${user.proxyEmployerId}/${user.proxyEmployeeId}`;
      } else {
        user["id"] = user.employeeId;
      }
    });
  },
  setTotalAdminUsers(state, data) {
    state.totalAdminUsers = data;
  },
  setListViewOptions(state, data) {
    state.listViewOptions = data;
  },
  setNewAdminUser(state, data) {
    state.adminUsers.push(data);
  },
  setScheduleChanges(state, data) {
    data.forEach((change) => {
      change.employeeFirstName = change.employeeFirstName ?? "";
      change.employeeLastName = change.employeeLastName ?? "";
      change.managerFirstName = change.managerFirstName ?? "";
      change.managerLastName = change.managerLastName ?? "";
      change.changedByFirstName = change.changedByFirstName ?? "";
      change.changedByLastName = change.changedByLastName ?? "";
      change.managerEmail = change.managerEmail ?? "";
      change.previousSchedule = JSON.parse(change.hoursBefore);
      change.currentSchedule = JSON.parse(change.hoursAfter);

      for (let day in change.previousSchedule) {
        change.previousSchedule[day] = parseFloat(
          change.previousSchedule[day]
        ).toFixed(2);
      }

      for (let day in change.currentSchedule) {
        change.currentSchedule[day] = parseFloat(
          change.currentSchedule[day]
        ).toFixed(2);
      }
    });

    state.scheduleChanges = data;
  },
  setTotalScheduleChanges(state, data) {
    state.totalScheduleChanges = data;
  },
};
