<template>
  <v-speed-dial
    bottom
    right
    fixed
    :open-on-hover="hover"
    direction="top"
    transition="fade-transition"
  >
    <template #activator>
      <v-btn dark fab :color="color">
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <v-tooltip
      disabled
      :value="true"
      left
      v-for="action in actions"
      :key="action.name"
    >
      <template #activator="{ on }">
        <v-btn
          small
          dark
          fab
          :color="action.color"
          v-on="on"
          @click="$emit(action.event)"
        >
          <v-icon>{{ action.icon }}</v-icon>
        </v-btn>
      </template>
      {{ action.name }}
    </v-tooltip>
  </v-speed-dial>
</template>

<script>
export default {
  props: {
    color: { type: String, default: "accent" },
    icon: { type: String, default: "mdi-wrench-outline" },
    hover: { type: Boolean, default: false },
    /*
     * Actions
     * Array of objects, each object represents a button to render in the speed dial component
     * Properties:
     *    - Name: This is the tooltip text
     *          the name is used as the primary key
     *    - Event: This is the event fired when the button is clicked
     *    - Icon: This is the icon to show
     *    - Color: This is the color the button should be
     */
    actions: { type: Array, required: true },
  },
};
</script>
