<template>
  <pageManager
    :isLoading="isLoading"
    :error="fetchError"
    :isEmpty="isEmpty"
    :dialogError="dialogError"
    @dialogClosed="onErrorDialogClosed"
  >
    <v-container>
      <v-alert dismissible v-if="showSuccessAlert" dense text type="success">
        {{ successMessage }}
      </v-alert>

      <v-card elevation="2" :loading="isLoading ? 'grey lighten-2' : null">
        <v-card-title class="headline white--text primary font-weight-light">
          {{ title }}
        </v-card-title>

        <v-card-text>
          <v-sheet class="mt-5">
            <div v-if="displayType === config('adminListDisplayTypes').proxies">
              <v-row>
                <v-col cols="6" class="d-flex">
                  <v-text-field
                    class="mr-3"
                    readonly
                    outlined
                    clearable
                    placeholder="Select Manager/Supervisor"
                    v-model="selectedSupervisorName"
                    :disabled="selectedSupervisor === null"
                    @click:clear="clearEmployee('supervisor')"
                  ></v-text-field>
                  <v-btn
                    color="primary"
                    class="employeeSelectorButtons"
                    @click="onAddUser('supervisor')"
                  >
                    Select
                  </v-btn>
                </v-col>
                <v-col cols="6" class="d-flex">
                  <v-text-field
                    class="mr-3"
                    readonly
                    outlined
                    clearable
                    placeholder="Select Proxy Employee"
                    v-model="selectedProxyName"
                    :disabled="selectedProxy === null"
                    @click:clear="clearEmployee('proxy')"
                  ></v-text-field>
                  <v-btn
                    color="primary"
                    class="employeeSelectorButtons"
                    @click="onAddUser('proxy')"
                  >
                    Select
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <div v-if="displayType === config('adminListDisplayTypes').proxies">
              <v-btn
                color="primary"
                :disabled="
                  selectedSupervisor === null || selectedProxy === null
                "
                @click="addSupervisorProxy"
              >
                <v-icon class="mr-3">{{ addUserIcon }}</v-icon>
                {{ addUserButtonLabel }}
              </v-btn>
              <v-btn
                class="ml-3"
                color="primary"
                :disabled="
                  selectedSupervisor === null && selectedProxy === null
                "
                @click="clearEmployeeSelectors"
              >
                Clear
              </v-btn>
            </div>
            <v-btn v-else color="primary" @click="onAddUser(null)">
              <v-icon class="mr-3">{{ addUserIcon }}</v-icon>
              {{ addUserButtonLabel }}
            </v-btn>
          </v-sheet>
          <v-sheet>
            <search-component
              ref="searchComponent"
              @onSearchChange="searchChanged"
            ></search-component>
            <admin-list-view
              ref="listView"
              @userRemoved="onUserRemoved"
              :userType="displayType.id"
              :tableStyle="displayType.tableStyle"
              :search-criteria="userSearchCriteria"
            ></admin-list-view>
          </v-sheet>
        </v-card-text>
      </v-card>

      <employeeDataTableSelectDialog
        :title="employeeSelectDialog.title"
        :show="employeeSelectDialog.show"
        searchType="infinium"
        :returnObject="true"
        @close="closeEmployeeSelect"
        @employeeSelected="employeeSelected"
      />

      <progressDialog
        :showDialog="isSubmitting"
        :message="progressMessage"
      ></progressDialog>
    </v-container>
  </pageManager>
</template>
<script>
import { Component, Mixins, Watch } from "vue-property-decorator";
import modulePageMixin from "@/utils/mixins";
import pageManager from "@/components/pageManager";
import SearchComponent from "./components/search";
import AdminListView from "./components/adminListView";
import EmployeeDataTableSelectDialog from "../components/employeeDataTableSelectDialog";
import { namespace } from "vuex-class";
import progressDialog from "@/components/progressDialog";
import pageManagerMixin from "@/utils/pageManagerMixin";

const timeOffStore = namespace("timeOff");
const adminStore = namespace("timeOff/admin");

@Component({
  name: "ManageAdmins",
  components: {
    progressDialog,
    EmployeeDataTableSelectDialog,
    AdminListView,
    pageManager,
    SearchComponent,
  },
})
export default class AdminUsersManager extends Mixins(
  modulePageMixin,
  pageManagerMixin
) {
  @timeOffStore.Getter("config") config;
  @adminStore.Action("addAdminUser") addAdminUser;
  @adminStore.Getter("submitError") submitError;
  @adminStore.Getter("isSubmitting") isSubmitting;
  @adminStore.Getter("progressMessage") progressMessage;
  @adminStore.Getter("successMessage") successMessage;
  @adminStore.Mutation("setSubmitError") setSubmitError;
  @adminStore.Mutation("setProgressMessage") setProgressMessage;
  @adminStore.Mutation("setSuccessMessage") setSuccessMessage;

  data() {
    return {
      isLoading: false,
      savingUserDialog: false,
      userSearchCriteria: "",
      displayType: {},
      selectedEmployee: null,
      selectedSupervisor: null,
      selectedSupervisorName: "",
      selectedProxy: null,
      selectedProxyName: "",
      context: null,
      employeeSelectDialog: {
        title: "Select Employee",
        show: false,
      },
    };
  }

  isEmpty = false;
  fetchError = "";

  get title() {
    return this.$route.meta?.title ?? "Manage Admin Users";
  }

  get addUserIcon() {
    return this.$route.meta?.icon;
  }

  get showSuccessAlert() {
    return (
      Object.keys(this.submitError).length === 0 && this.successMessage !== ""
    );
  }

  get isSubmitError() {
    return Object.keys(this.submitError).length !== 0;
  }

  get addUserButtonLabel() {
    return `Add ${this.displayType.title}` ?? "User";
  }

  get savingText() {
    if (this.displayType === this.config("adminListDisplayTypes").proxies) {
      return `Adding ${this.selectedProxy.firstName} ${this.selectedProxy.lastName}
      (${this.selectedProxy.employerId}-${this.selectedProxy.employeeId})
      as a proxy for ${this.selectedSupervisor.firstName} ${this.selectedSupervisor.lastName}
      (${this.selectedSupervisor.employerId}-${this.selectedSupervisor.employeeId})`;
    }

    if (this.selectedEmployee === null) return "";

    return `Adding (${this.selectedEmployee.employerId}-${this.selectedEmployee.employeeId})
    ${this.selectedEmployee.firstName} ${this.selectedEmployee.lastName}`;
  }

  onAddUser(val) {
    this.context = val;
    this.employeeSelectDialog.show = true;
  }

  onErrorDialogClosed() {
    this.setSubmitError({});
  }

  clearDialogs() {
    this.setSuccessMessage("");
  }

  clearEmployeeSelectors() {
    this.clearEmployee("proxy");
    this.clearEmployee("supervisor");
  }

  clearEmployee(val) {
    switch (val) {
      case "supervisor":
        this.selectedSupervisor = null;
        this.selectedSupervisorName = "";
        break;
      case "proxy":
        this.selectedProxy = null;
        this.selectedProxyName = "";
        break;
    }
  }

  async employeeSelected(selectedEmployee) {
    this.clearDialogs();

    this.employeeSelectDialog.show = false;

    if (this.displayType === this.config("adminListDisplayTypes").proxies) {
      if (this.context === "supervisor") {
        this.selectedSupervisor = selectedEmployee;
        this.selectedSupervisorName = `${selectedEmployee.lastName.trim()}, ${selectedEmployee.firstName.trim()} (${
          selectedEmployee.employerId
        } - ${selectedEmployee.employeeId.trim()})`;

        return;
      }
      if (this.context === "proxy") {
        this.selectedProxy = selectedEmployee;
        this.selectedProxyName = `${selectedEmployee.lastName.trim()}, ${selectedEmployee.firstName.trim()} (${
          selectedEmployee.employerId
        } - ${selectedEmployee.employeeId.trim()})`;

        return;
      }
    }

    this.selectedEmployee = selectedEmployee;
    this.savingUserDialog = true;
    this.setProgressMessage(this.savingText);

    await this.addAdminUser({
      userType: this.displayType.id,
      user: {
        employerId: selectedEmployee.employerId,
        employeeId: selectedEmployee.employeeId,
        isEnabled: true,
      },
    });

    if (!this.isSubmitError) {
      this.$refs.listView.refresh();

      this.showSuccessMessage(`Employee
      ${selectedEmployee.firstName} ${selectedEmployee.lastName} added successfully`);
    }

    this.savingUserDialog = false;
  }

  async addSupervisorProxy() {
    this.savingUserDialog = true;
    this.setProgressMessage(this.savingText);

    await this.addAdminUser({
      userType: this.displayType.id,
      user: {
        employerId: this.selectedSupervisor.employerId,
        employeeId: this.selectedSupervisor.employeeId,
        proxyEmployerId: this.selectedProxy.employerId,
        proxyEmployeeId: this.selectedProxy.employeeId,
        isEnabled: true,
      },
    });

    if (!this.isSubmitError) {
      this.$refs.listView.refresh();

      this.showSuccessMessage(`Employee
        ${this.selectedProxy.firstName} ${this.selectedProxy.lastName} added successfully as a proxy for
        ${this.selectedSupervisor.firstName} ${this.selectedSupervisor.lastName}`);
    }

    this.clearEmployeeSelectors();

    this.savingUserDialog = false;
  }

  closeEmployeeSelect() {
    this.employeeSelectDialog.show = false;
  }

  searchChanged(newValue) {
    this.userSearchCriteria = newValue;
  }

  onUserRemoved(user) {
    if (this.displayType === this.config("adminListDisplayTypes").proxies) {
      this.showSuccessMessage(`User
      ${user.proxyFirstName} ${user.proxyLastName} successfully removed as a proxy for user ${user.employeeFirstName} ${user.employeeLastName}`);
    } else {
      this.showSuccessMessage(`User
      ${user.employeeFirstName} ${user.employeeLastName} successfully removed`);
    }
  }

  showSuccessMessage(message) {
    this.setSuccessMessage(message);

    const timer = setTimeout(() => {
      this.clearDialogs();
      clearTimeout(timer);
    }, 5000);
  }

  @Watch("$route.path")
  onPathChange() {
    this.$refs.searchComponent.clear();
    this.setDisplayType();
  }

  setDisplayType() {
    const type = this.getUrlSegment(5) ?? "admins";
    this.displayType = this.config("adminListDisplayTypes")[type.toLowerCase()];
  }

  mounted() {
    this.setDisplayType();
  }
}
</script>

<style scoped>
.employeeSelectorButtons {
  margin-top: 10px;
  margin-right: 10vw;
}
</style>
