import forEach from "lodash/forEach";

function getErrors(data) {
  function getErrorsRecursive(errorData, curr) {
    if (typeof errorData === "object" && errorData !== null) {
      forEach(errorData, (d) => getErrorsRecursive(d, curr));
    } else {
      curr.push(errorData);
    }
  }
  const result = [];
  if (typeof data === "object" && data !== null) {
    getErrorsRecursive(data, result);
  }
  return result;
}

export default {
  setIsLoading(state, data) {
    state.isLoading = data;
  },
  setIsSubmitting(state, data) {
    state.isSubmitting = data;
  },
  setError(state, error) {
    if (error?.response?.data?.title) {
      state.error = error.response.data.title;
    } else {
      state.error = error;
    }
  },
  setMaritalCodes(state, codes) {
    state.maritalCodes = codes.map((c) => ({
      ...c,
      code: c.code.trim(),
      description: c.description.trim(),
    }));
  },
  setSubmitError(state, error) {
    const title = error?.response?.data?.title;
    const status = error?.response?.data?.status;
    const errors = error?.response?.data?.errors;

    if (status === 423 && title === "Locked") {
      state.savingInfo = {
        message: "Your changes have been received and will update shortly.",
      };
    } else if (title && errors) {
      state.submitError = {
        title,
        errors: getErrors(errors),
      };
    } else if (title) {
      state.submitError = title;
    } else {
      state.submitError = error;
    }
  },
  setSavingInfo(state, info) {
    state.savingInfo = info;
  },
  setPersonalInformation(state, data) {
    state.personalInfo = data;
    state.wantsMailingAddress = data?.mailingStreetAddress !== "";
    state.sameAddress = state.wantsMailingAddress
      && data?.residentStreetAddress?.toUpperCase() === data?.mailingStreetAddress?.toUpperCase()
      && data?.residentCity?.toUpperCase() === data?.mailingCity.toUpperCase()
      && data?.residentState?.toUpperCase() === data?.mailingState.toUpperCase()
      && data?.residentZipCode?.toUpperCase() === data?.mailingZipCode.toUpperCase();
  },
  setResidentPhoneNumber(state, data) {
    if (typeof data === "string") {
      state.personalInfo.phoneNumber = data.trim();
    } else {
      state.personalInfo.phoneNumber = data;
    }
  },
  setMaritalStatus(state, data) {
    if (typeof data === "string") {
      state.personalInfo.maritalStatus = data.trim();
    } else {
      state.personalInfo.maritalStatus = data;
    }
  },
  setResidentStreetAddress(state, data) {
    state.personalInfo.residentStreetAddress = data ? data.trim() : "";
  },
  setResidentSuite(state, data) {
    state.personalInfo.residentSuite = data.trim();
  },
  setResidentCity(state, data) {
    state.personalInfo.residentCity = data.trim();
  },
  setResidentState(state, data) {
    state.personalInfo.residentState = data.code.trim();
  },
  setResidentZipCode(state, data) {
    state.personalInfo.residentZipCode = data.trim();
  },
  setMailingStreetAddress(state, data) {
    state.personalInfo.mailingStreetAddress = data ? data.trim() : "";
  },
  setMailingSuite(state, data) {
    state.personalInfo.mailingSuite = data.trim();
  },
  setMailingCity(state, data) {
    state.personalInfo.mailingCity = data.trim();
  },
  setMailingState(state, data) {
    state.personalInfo.mailingState = data.code.trim();
  },
  setMailingZipCode(state, data) {
    state.personalInfo.mailingZipCode = data.trim();
  },
  setSameAddress(state, data) {
    state.sameAddress = data;
  },
  setWantsMailingAddress(state, data) {
    state.wantsMailingAddress = data;
  },
  setWorkEmailAddress(state, data) {
    state.personalInfo.workEmailAddress = data?.trim() || "";
  },
  setPersonalEmailAddress(state, data) {
    state.personalInfo.personalEmailAddress = data?.trim() || "";
  },
  setCellPhoneNumber(state, data) {
    if (typeof data === "string") {
      state.personalInfo.cellNumber = data.trim();
    } else {
      state.personalInfo.cellNumber = data || "";
    }
  },
};
