import Vue from "vue";

export default {
  async fetchReleaseVersion({ commit }) {
    try {
      const { data } = await Vue.$apis.core.getBackendReleaseVersion();
      commit("setReleaseVersion", Object.keys(data)[0]);
    } catch (error) {
      commit("setError", error);
    }
  },
};
