export default {
  setApplicationConfig(state, data) {
    state.application = data;
  },
  setModulesConfig(state, data) {
    state.modules = data;
  },
  setModuleConfig(state, data) {
    state.modules[data.key] = data.payload;
  },
  setReleaseVersion(state, data) {
    state.release = data;
  },
  setModuleRoute(state, data) {
    state.routes.push(data);
  },
  setError(state, data) {
    state.error = data;
  },
};
