<template>
  <div class="myInformation">
    <pageManager :isLoading="isLoading" :isEmpty="false" :error="error">
      <v-form v-model="valid" ref="form">
        <v-card class="mx-auto my-4">
          <v-toolbar class="toolBar" color="primary" dark>
            <v-toolbar-title>Pay Statements Email Preferences</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-expand-transition>
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="12" class="fullName">
                  {{ fullName }}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" class="text-justify">
                  <p>Select the email you wish to receive your pay statement at:</p>
                  <v-radio-group v-model="emailOption">
                    <v-radio
                      v-for="option in preferenceOptions"
                      :key="option.key"
                      :label="optionLabel(option)"
                      :value="option.key"
                      :disabled="disableOption(option)"
                    />
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row v-show="showEditButton">
                <v-col cols="12" md="12" class="text-justify">
                  <p>There is no personal email on file, to add it, edit your personal information.</p>
                  <v-btn @click="$router.push('/personal-info/my-information')">Edit Personal Information</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-expand-transition>
        </v-card>
      </v-form>
      <submitDialog :propIsSubmitting="isSubmitting" :propSubmitError="submitError" @clearErrors="clearErrors" />
      <infoDialog v-if="showInfoDialog" :propInfo="savingInfo" @clearInfo="clearInfo" />
      <div class="text-right">
        <v-btn
          class="px-8 submit-button"
          color="accent"
          @click="submit"
          tile
          :loading="isSubmitting"
          :disabled="disableSubmit"
        >
          <v-icon class="mr-2">mdi-send</v-icon>Submit
        </v-btn>
      </div>
    </pageManager>
  </div>
</template>

<script>
import pageManager from "@/components/pageManager.vue";
import submitDialog from "@/components/submitDialog.vue";
import infoDialog from "@/components/info-dialog.vue";
import { mapGetters, mapMutations, mapState } from "vuex";
import _ from "lodash";

export default {
  components: {
    submitDialog,
    infoDialog,
    pageManager,
  },
  page: {
    title: "Email Preferences",
    meta: [
      {
        name: "description",
        content: "Edit pay statements email preferences.",
      },
    ],
  },
  data() {
    return {
      valid: false,
      showInfoDialog: false,
      emailOption: null,
      showButton: false,
    };
  },
  computed: {
    ...mapState("auth", ["userInfo"]),
    ...mapState("payroll/emailPreferences", [
      "isLoading",
      "isSubmitting",
      "error",
      "submitError",
      "savingInfo",
      "emailPreferences",
    ]),
    ...mapGetters("payroll/emailPreferences", ["selectedEmailPreference"]),
    disableSubmit() {
      return (
        this.isSubmitting
        || this.emailOption === ""
        || this.emailOption === "NONE"
      );
    },
    showEditButton() {
      if (this.emailPreferences.options === undefined) return false;

      const option = this?.emailPreferences?.options.find(
        ({ key }) => key === "EMAIL2",
      );

      return option.value === "";
    },
    preferenceOptions() {
      if (this.emailPreferences.options === undefined) return [];

      return this.emailPreferences.options.filter(
        (element) => element.key !== "NONE",
      );
    },
    fullName() {
      return this.userInfo?.checkName ?? "";
    },
    personalEmailAddress() {
      return this.userInfo?.emailAddress2?.trim() ?? "";
    },
    companyEmailAddress() {
      return this.userInfo?.email?.trim() ?? "";
    },
    selectedPreference() {
      return { payStatementEmailPreference: this.emailOption };
    },
  },
  methods: {
    ...mapMutations("payroll/emailPreferences", ["setSubmitError"]),
    disableOption(option) {
      return option.value === "";
    },
    optionLabel(option) {
      return option.key !== "NONE"
        ? `${option.label}: ${option.value === "" ? "No email on file." : option.value}`
        : option.key;
    },
    async fetchData() {
      await this.$store.dispatch(
        "payroll/emailPreferences/fetchEmailPreferences",
      );
    },
    clearErrors() {
      this.setSubmitError({});
    },
    clearInfo() {
      this.showInfoDialog = false;
      // return to home
      this.$router.push("/");
    },
    validate() {
      this.$refs.form.validate();
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        this.$toasted.error("Please fix errors prior to submitting.");
      } else {
        await this.$store.dispatch(
          "payroll/emailPreferences/submitEmailPreferences",
          this.selectedPreference,
        );

        if (!_.isEmpty(this.savingInfo)) {
          this.showInfoDialog = true;
        }

        if (
          Object.keys(this.submitError).length === 0
          && _.isEmpty(this.savingInfo)
        ) {
          this.$toasted.success("Form was Successfully Saved!", {
            duration: 2000,
          });
        }
      }
    },
  },
  async mounted() {
    await this.fetchData();
    this.emailOption = this.selectedEmailPreference;
  },
};
</script>

<style lang="scss" scoped>
.submit-button {
  margin-right: 0;
  margin-top: 10px;
}

.fullName {
  font-size: 25px;
  color: grey;
}
</style>
