const email = (val) => !!val.match(/^.{1,64}@.+\..{2,}$/);

const required = (val) => {
  if (Array.isArray(val) && val.length === 0) {
    return false;
  }
  if (typeof val === "number" && !Number.isNaN(val)) {
    return true;
  }
  return !!val;
};

const federalReserveValidation = (reserveNumber) => {
  const firstTwo = parseInt(reserveNumber.slice(0, 2), 10);

  if (
    (firstTwo >= 0 && firstTwo <= 12)
    || (firstTwo >= 21 && firstTwo <= 32)
    || (firstTwo >= 61 && firstTwo <= 72)
    || firstTwo === 80
  ) {
    return true;
  }

  return false;
};

/**
 * Validates the first for digits of ABA routing numbers
 * The first two digits of the nine digit RTN must be in the ranges 00 through 12, 21 through 32, 61 through 72, or 80.
 * @param {number} reserveNumber federal reserve identifier, first four digits of ABA
 * @returns {boolean} true if valid, false if invalid
 */
/**
 * Validates the routing number using the 9th digit
 * formula: 3(d1+d4+d7)+7(d2+d5+d8)+(d3+d6+d9) mod 10 = 0
 * @param {string} routingNumber 9 digit ABA routing number
 * @returns {boolean} true if valid, false if invalid
 */
const checksumValidation = (routingNumber) => {
  let firstDigits = 0;
  let secondDigits = 0;
  let thirdDigits = 0;

  routingNumber.split("").forEach((digit, index) => {
    const place = parseInt(index + 1, 10) % 3;

    switch (place) {
      case 1:
        firstDigits += parseInt(digit, 10);
        break;
      case 2:
        secondDigits += parseInt(digit, 10);
        break;
      case 0:
        thirdDigits += parseInt(digit, 10);
        break;
      default:
        throw new Error(`Unknown place "${place}"`);
    }
  });

  return (3 * firstDigits + 7 * secondDigits + thirdDigits) % 10 === 0;
};

/**
 * See ABA routing validation page on wikipedia for details:
 * https://en.wikipedia.org/wiki/ABA_routing_transit_number#cite_note-bur05-16
 */
const routingNumber = (val) => {
  // prevent nullish / falsy values
  if (!val) return false;
  // allow numbers
  if (typeof val === "number") val = val.toString();
  // validate length
  if (val.length !== 9) return false;

  if (!federalReserveValidation(val.slice(0, 4))) return false;

  if (!checksumValidation(val)) return false;

  return true;
};

const money = (value) => (
  String(value).match(/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/) !== null
  || "Invalid format for currency"
);

const maxAmount = (value, maxValue) => {
  const number = Number(String(value).replace(/[^0-9.]+/g, ""));
  return number <= maxValue || `Amount should lower than ${maxValue}`;
};

export default {
  email,
  required,
  routingNumber,
  money,
  maxAmount,
};
