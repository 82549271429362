<template>
  <v-card-actions class="justify-left">
    <v-btn
      @click="downloadSpreadsheet"
      outlined
      :loading="isButtonLoading"
      params
    >
      <v-icon left> mdi-microsoft-excel </v-icon>
      Download Report In Excel
    </v-btn>
  </v-card-actions>
</template>

<script>
import { namespace } from "vuex-class";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";

const requestsSearchStore = namespace("timeOff/requestsSearch");

@Component({
  name: "downloadSpreadSheet",
})
export default class downloadSpreadSheet extends Vue {
  isButtonLoading = false;
  @requestsSearchStore.Action("fetchTimeOffRequestsDownload")
  fetchTimeOffRequestsDownload;

  @Prop({
    type: Object,
    default: false,
  })
  params;

  async downloadSpreadsheet() {
    this.isButtonLoading = true;

    let timeOffRequestsDownloadData = await this.fetchTimeOffRequestsDownload(
      this.params
    );

    let timeOffRequestsDownload = timeOffRequestsDownloadData["data"];

    let fileName = timeOffRequestsDownloadData["headers"]["content-disposition"]
      .split("filename=")[1]
      .replace(/["]+/g, "");

    const url = window.URL.createObjectURL(timeOffRequestsDownload);
    const link = document.createElement("a");
    link.href = url;

    link.setAttribute("download", fileName);

    link.click();

    this.isButtonLoading = false;
  }
}
</script>
