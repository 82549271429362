export default [
  {
    moduleId: "account",
    moduleName: "Account",
    metaInfo: {
      title: "User Account",
      meta: [
        {
          name: "User Account Management",
          content: "Manage User Account Settings",
        },
      ],
    },
    forms: [
      {
        formId: "",
      },
    ],
    workflow: [],
  },
  {
    moduleId: "account:profile",
    parent: "account",
    moduleName: "Profile",
    metaInfo: {
      title: "User Profile",
      meta: [
        {
          name: "User Profile",
          content: "User Profile Management",
        },
      ],
    },
    forms: [
      {
        formId: "",
      },
    ],
    workflow: [],
  },
];
