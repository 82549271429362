import cloneDeep from "lodash/cloneDeep";
import store from "@/store";
import forEach from "lodash/forEach";

function getErrors(data) {
  function getErrorsRecursive(errorData, curr) {
    if (typeof errorData === "object" && errorData !== null) {
      forEach(errorData, (d) => getErrorsRecursive(d, curr));
    } else {
      curr.push(errorData);
    }
  }
  const result = [];
  if (typeof data === "object" && data !== null) {
    getErrorsRecursive(data, result);
  }
  return result;
}

function findMatchingDeposit(deposits, feId) {
  return deposits.find((deposit) => deposit.feId === feId);
}

function setMatchingDepositValue(deposits, feId, key, val) {
  findMatchingDeposit(deposits, feId)[key] = val;
}

function getReasonableDefaultAmount(depositType) {
  let amount;

  switch (depositType) {
    case "NET":
      amount = 999; // The amount in here is irrelevant the back end will always return 999
      break;
    case "P":
      amount = "";
      break;
    case "F":
      amount = "";
      break;
    default:
      amount = 0;
      break;
  }

  return amount;
}

export default {
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setIsSaving(state, isSaving) {
    state.isSaving = isSaving;
  },
  setError(state, error) {
    if (error?.response?.data?.title) {
      state.error = error.response.data.title;
    } else {
      state.error = error;
    }
  },
  setInitialDeposits(state, deposits) {
    if (deposits.length > 0) {
      let color;
      state.isFirstMovement = false;
      state.initialDeposits = deposits.map((deposit) => {
        switch (deposit.depositType) {
          case "F":
            color = "red lighten-5";
            if (!Number.isNaN(deposit.amount) && deposit.amount % 1 === 0) {
              deposit.amount += ".00";
            }
            break;
          case "P":
            color = "blue lighten-5";
            break;
          default:
            color = "white";
        }
        if (deposit.accountType === "F") {
          // Fuel Card
          color = "green lighten-5";
          deposit.order = 3;
        } else {
          switch (deposit.depositType) {
            case "F":
              deposit.order = 1; // Dollar Amount
              break;
            case "P":
              deposit.order = 2; // Percentage Amount
              break;
            case "NET":
              deposit.order = 4; // Net Amount
              break;
            default:
              throw new Error(`Unknown depositType '${deposit.depositType}'`);
          }
        }

        deposit.accountNumber = deposit.accountNumber === "0" ? "" : deposit.accountNumber;
        deposit.routingNumber = deposit.routingNumber === "0" ? "" : deposit.routingNumber;
        deposit.backgroundColor = color;
        deposit.feId = Math.random();

        return deposit;
      });
      state.initialDeposits.sort((a, b) => (a.order > b.order ? 1 : -1));
    } else {
      state.isFirstMovement = true;
      state.initialDeposits = [
        {
          accountNumber: "",
          accountType: "C",
          amount: 999, // The amount in here is irrelevant the back end will always return 999
          bankName: "",
          depositType: "NET",
          employeeId: store.getters["auth/employeeId"],
          feId: Math.random(),
          routingNumber: "",
          backgroundColor: "white",
        },
      ];
    }
  },
  setDeposits(state) {
    state.deposits = cloneDeep(state.initialDeposits);
  },
  setDepositType(state, { type, feId }) {
    setMatchingDepositValue(state.deposits, feId, "depositType", type);
    const amount = getReasonableDefaultAmount(type);
    setMatchingDepositValue(state.deposits, feId, "amount", amount);
  },
  setDepositAccountNumber(state, { number, feId }) {
    setMatchingDepositValue(state.deposits, feId, "accountNumber", number);
  },
  setDepositRoutingNumber(state, { number, feId }) {
    setMatchingDepositValue(state.deposits, feId, "routingNumber", number);
  },
  setDepositAmount(state, { amount, feId }) {
    setMatchingDepositValue(state.deposits, feId, "amount", amount);
  },
  setDepositBankName(state, { name, feId }) {
    setMatchingDepositValue(state.deposits, feId, "bankName", name);
  },
  deleteDeposit(state, { feId }) {
    state.deposits = state.deposits.filter((deposit) => deposit.feId !== feId);
  },
  deleteAllDeposits(state) {
    state.deposits = [];
  },
  addDeposit(state, { depositType, employeeId, accountType }) {
    depositType = depositType || "NET";
    const amount = getReasonableDefaultAmount(depositType);
    let color;

    if (accountType === "F") {
      // Fuel Card
      color = "green lighten-5";
    } else if (depositType === "F") {
      color = "red lighten-5";
    } else if (depositType === "P") {
      color = "blue lighten-5";
    } else color = "white";

    state.deposits.unshift({
      accountNumber: "",
      accountType,
      amount,
      bankName: "",
      depositType,
      employeeId,
      routingNumber: "",
      backgroundColor: color,
      feId: Math.random(),
    });
  },
  setSubmitError(state, error) {
    if (error?.response?.data?.title && error?.response?.data?.errors) {
      state.submitError = {
        title: error.response.data.title,
        errors: getErrors(error.response.data.errors),
      };
    } else if (error?.response?.data?.detail || error?.response?.data?.title) {
      state.submitError = error.response.data.detail || error.response.data.title;
    } else {
      state.submitError = error;
    }
  },
};
