export default [
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "login" */ "@/views/login.vue"),
    meta: {
      title: "Login",
      hideFromNav: true,
      icon: "mdi-account-key",
    },
    props: (route) => ({ ssoToken: route.query.token }),
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import(/* webpackChunkName: "login" */ "@/views/login.vue"),
    meta: {
      title: "Logout",
      hideFromNav: true,
      icon: "mdi-account-key",
    },
  },
];
