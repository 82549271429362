<template>
  <v-form ref="otherAdjustmentsForm" v-model="valid">
    <v-card class="my-4">
      <v-toolbar class="toolBar" color="primary" dark>
        <v-toolbar-title><i class="mdi mdi-cash" /> Step 4 - Other Adjustments (Optional)</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-2">
        <v-row class="adjustmentsRow">
          <v-col cols="12" sm="9">
            <p class="ma-0">
              Other income (not from jobs). If you want tax withheld for other income you expect this year that won’t
              have withholding, enter the amount of other income here. This may include interest, dividends, and
              retirement income
            </p>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              dense
              prefix="$"
              v-model="otherIncome"
              :rules="[money, maxAmount]"
              @blur="checkValue($event, 'otherIncome')"
            />
          </v-col>
        </v-row>
        <v-row class="adjustmentsRow">
          <v-col cols="12" sm="9">
            <p>
              Deductions. If you expect to claim deductions other than the standard deduction and want to reduce your
              withholding, use the Deductions Worksheet on
              <a @keydown.enter.stop="$emit('openPDF', 'page3')" @click.stop="$emit('openPDF', 'page3')">page 3</a>
              and enter the result here
            </p>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              dense
              prefix="$"
              v-model="deductions"
              :rules="[money, maxAmount]"
              v-on:blur="checkValue($event, 'deductions')"
            />
          </v-col>
        </v-row>
        <v-row class="adjustmentsRow">
          <v-col cols="12" sm="9">
            <p>Extra withholding. Enter any additional tax you want withheld each pay period</p>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              dense
              prefix="$"
              v-model="additionalAmount"
              :rules="[money, maxAmount]"
              v-on:blur="checkValue($event, 'additionalAmount')"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
/* eslint-disable class-methods-use-this */
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import validators from "@/utils/validators";

const w4store = namespace("payroll/w4");

@Component({
  name: "OtherAdjustments",
  components: {},
})
export default class OtherAdjustments extends Vue {
  @w4store.State("formData") formData;

  @w4store.Mutation("setFormData") setFormData;

  @w4store.State("formSections") formSections;

  @w4store.Mutation("setFormSections") setFormSections;

  @Watch("formData.exemptFlag", { deep: true })
  updateValuesIfExempt(isExempt) {
    if (isExempt) {
      this.setFormData({
        otherIncome: 0,
        deductions: 0,
        additionalAmount: 0,
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  data() {
    return {
      valid: true,
    };
  }

  validate() {
    const isValid = this.$refs.otherAdjustmentsForm.validate();
    this.sections.forEach((section) => {
      if (section.component === "otherAdjustments") {
        section.valid = isValid;
      }
    });
    this.setFormSections(this.sections);
    return isValid;
  }

  money(value) {
    return validators.money(value) || "Invalid format for currency";
  }

  maxAmount(value) {
    return (
      validators.maxAmount(value, 99999) || "Amount should lower than $99,999"
    );
  }

  async checkValue(event, field) {
    if (event.target.value === "") {
      this[field] = "0";
    }
    await this.$nextTick();
    this.validate();
  }

  get sections() {
    return this.formSections;
  }

  get additionalAmount() {
    return this.formData.additionalAmount;
  }

  set additionalAmount(value) {
    this.setFormData({ additionalAmount: value, employeeSignature: "" });
    this.$emit("validate");
  }

  get deductions() {
    return this.formData.deductions;
  }

  set deductions(value) {
    this.setFormData({ deductions: value, employeeSignature: "" });
    this.$emit("validate");
  }

  get otherIncome() {
    return this.formData.otherIncome;
  }

  set otherIncome(value) {
    this.setFormData({ otherIncome: value, employeeSignature: "" });
    this.$emit("validate");
  }
}
</script>

<style scoped>
a {
  text-decoration: underline;
}
.adjustmentsRow {
  text-align: right;
}
@media only screen and (max-width: 599px) {
  .adjustmentsRow {
    text-align: left;
  }
}
</style>
