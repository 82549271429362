import directDepositAPI from "@/modules/payroll/api/directDeposit";

export default {
  async fetchDeposits({ commit }, employeeId) {
    commit("setIsLoading", true);
    commit("setError", null);
    try {
      const { data } = await directDepositAPI.getDirectDeposits(employeeId);
      commit("setInitialDeposits", data?._embedded?.direct_deposits ?? []);
      commit("setDeposits");
      commit("setIsLoading", false);
    } catch (e) {
      commit("setError", e);
      commit("setIsLoading", false);
    }
  },
  async saveDeposits({ commit, state }) {
    commit("setIsSaving", true);
    commit("setSubmitError", {});
    try {
      const { data } = await directDepositAPI.saveDirectDeposits(state.deposits);
      commit("setInitialDeposits", data?._embedded?.direct_deposits ?? []);
      commit("setDeposits");
      commit("setIsSaving", false);
    } catch (e) {
      commit("setSubmitError", e);
      commit("setIsSaving", false);
    }
  },
};
