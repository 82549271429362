export default function () {
  return {
    isFetchingData: false,
    fetchError: null,
    submitError: {},
    isSubmitting: false,
    sendCalendarInvitesForMyReports: false,
    sendCalendarInvitesToMe: false,
    timeZoneAbbreviation: "CDT",
    showCalendarLegend: false,
    timeZonesList: [],
    userSelectedTimeZone: {},
    employeeProxies: [],
    totalEmployeeProxies: 0,
  };
}
