<template>
  <div class="request-form-container clear-right-nav">
    <v-card elevation="2" :loading="isLoading ? 'grey lighten-2' : null">
      <v-card-title class="headline white--text primary font-weight-light">
        {{ title }}
      </v-card-title>

      <errorAlert
        :error="requestFetchError"
        v-show="requestFetchError"
      ></errorAlert>

      <v-card class="inner-card" v-show="show">
        <v-card-title>Requested for </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <label-wrapper label="Name">
                {{ employeeData.employeeDescriptionAlt }} -
                {{ employeeData.positionTitle }}
              </label-wrapper>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <label-wrapper label="Email Address">
                {{ employeeData.emailAddress }}
              </label-wrapper>
            </v-col>
            <v-col cols="12" sm="12" md="2">
              <label-wrapper label="Hire Date">
                {{ employeeData.employeeHireDate }}
              </label-wrapper>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="12">
              <label-wrapper label="Supervisor">
                {{ employeeData.managerDescriptionAlt }} -
                {{ employeeData.managerPositionTitle }}
              </label-wrapper>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="8">
              <label-wrapper label="Schedule"></label-wrapper>
              <v-simple-table>
                <template>
                  <thead>
                    <tr>
                      <th>Sun</th>
                      <th>Mon</th>
                      <th>Tue</th>
                      <th>Wed</th>
                      <th>Thu</th>
                      <th>Fri</th>
                      <th>Sat</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ employeeData.scheduleSun }}</td>
                      <td>{{ employeeData.scheduleMon }}</td>
                      <td>{{ employeeData.scheduleTue }}</td>
                      <td>{{ employeeData.scheduleWed }}</td>
                      <td>{{ employeeData.scheduleThu }}</td>
                      <td>{{ employeeData.scheduleFri }}</td>
                      <td>{{ employeeData.scheduleSat }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="inner-card" v-show="show">
        <v-card-title>Requested By </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <label-wrapper label="Name">
                {{ request.createdByDescriptionAlt }} -
                {{ request.createdByPositionTitle }}
              </label-wrapper>
            </v-col>
            <v-col cols="12" md="6">
              <label-wrapper label="Email Address">
                {{ request.createdByEmail }}
              </label-wrapper>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="inner-card" v-show="show">
        <v-card-title>Request Details </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <label-wrapper label="Status">
                {{ request.statusDescription }}
              </label-wrapper>
            </v-col>
            <v-col cols="12" md="4">
              <label-wrapper label="Hours Requested">
                {{ requestedHours }}
              </label-wrapper>
            </v-col>
            <v-col cols="12" md="4">
              <label-wrapper label="Reason">
                {{ request.reason }}
              </label-wrapper>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="8">
              <label-wrapper label="TimeOff Hours">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Grandfathered</th>
                        <th>PTO</th>
                        <th>Float</th>
                        <th>Sick</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Pending</th>
                        <td>{{ employeeData.gfPendingTotal }}</td>
                        <td>{{ employeeData.ptoPendingTotal }}</td>
                        <td>{{ employeeData.floatPendingTotal }}</td>
                        <td>{{ employeeData.sickPendingTotal }}</td>
                      </tr>
                      <tr>
                        <th>Remaining</th>
                        <td>{{ employeeData.gfPendingTotal }}</td>
                        <td>{{ employeeData.ptoRemaining }}</td>
                        <td>{{ employeeData.floatRemaining }}</td>
                        <td>{{ employeeData.sickRemaining }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </label-wrapper>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <RequestedHoursTable
                :entries="requestedEntries"
              ></RequestedHoursTable>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="requested-hours-table-controls text-right">
                <v-btn
                  color="accent"
                  outlined
                  @click="openRequestEdit"
                  v-show="canEdit"
                >
                  <v-icon left>mdi-pencil-outline</v-icon>
                  Edit Request
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <RequestHistory :history="requestHistory" v-show="show"></RequestHistory>
      <v-card class="inner-card" v-show="show">
        <v-card-title>Notes</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <comments
              :propModel="reviewComments"
              label=""
              name="reviewComments"
              :rules="[commentRequired]"
              @validationError="onError($event)"
              v-on:inputChange="onReviewCommentsChange($event)"
            ></comments>
          </v-form>
        </v-card-text>
      </v-card>
    </v-card>
    <RequestEditModal
      v-if="showRequestEditModal"
      @close="closeRequestEdit"
      @editDialogue="showSuccessDialog('edit')"
      :requestId="requestId"
      :timeOffRequest="getCurrentRequest"
      :employeeUserId="getCurrentRequest.employeeUserId"
      :otherEmployeeSelected="false"
      :isModal="true"
    />
    <successDialog
      :message="successDialog.message"
      :show="successDialog.show"
      @close="closeAlertDialog"
    />
  </div>
</template>

<script>
import Component from "vue-class-component";
import { Mixins, Prop } from "vue-property-decorator";
import modulePageMixin from "@/utils/mixins";
import LabelWrapper from "@/components/labelWrapper";
import successDialog from "@/components/successDialog";
import { namespace } from "vuex-class";
import RequestHistory from "@/modules/timeOff/requests/components/requestHistory";
import comments from "@/modules/timeOff/components/fields/comments";
import RequestedHoursTable from "@/modules/timeOff/requests/components/requestedHoursTable";
import RequestEditModal from "@/modules/timeOff/requests/components/requestEditModal";
import validatorMixin from "@/utils/validatorMixin";
import errorAlert from "@/components/errorAlert";
import dates from "@/utils/dates";

const requestFormStore = namespace("timeOff/requestForm");
const authStore = namespace("auth");

@Component({
  name: "ReviewRequestForm",
  components: {
    errorAlert,
    LabelWrapper,
    RequestHistory,
    RequestedHoursTable,
    comments,
    successDialog,
    RequestEditModal,
  },
})
export default class ReviewRequestForm extends Mixins(
  modulePageMixin,
  validatorMixin
) {
  isLoading = false;
  isLoaded = false;
  isSaving = false;
  valid = true;
  showRequestEditModal = false;
  successDialog = {
    message: "",
    show: false,
  };

  @Prop({
    type: String,
    required: true,
  })
  employeeUserId;

  @Prop({
    type: String,
    required: true,
  })
  requestId;

  @requestFormStore.Action("approveRequest") approveRequest;
  @requestFormStore.Action("denyRequest") denyRequest;
  @requestFormStore.Action("fetchEmployeeRequest") fetchEmployeeRequest;
  @requestFormStore.Getter("isCommentRequired") isCommentRequired;
  @requestFormStore.Getter("getCurrentRequest") getCurrentRequest;
  @requestFormStore.Getter("requestFetchError") requestFetchError;
  @requestFormStore.Getter("reviewComments") reviewComments;
  @requestFormStore.Getter("isSubmitting") isSubmitting;
  @requestFormStore.Getter("successMessage") successMessage;
  @requestFormStore.Mutation("setIsApprovalFormValid") setIsApprovalFormValid;
  @requestFormStore.Mutation("setIsCommentRequired") setIsCommentRequired;
  @requestFormStore.Mutation("setReviewComments") setReviewComments;
  @requestFormStore.Mutation("setCurrentRequest") setCurrentRequest;
  @authStore.Getter("employerId") employerId;
  @authStore.Getter("employeeId") employeeId;
  @authStore.Getter("hasAnyRole") hasAnyRole;

  commentRequired() {
    if (this.reviewComments === "" && this.isCommentRequired === true) {
      return "A comment is required when denying a request";
    }
    return true;
  }
  get title() {
    return `Details for Request ${this.requestId}`;
  }
  get request() {
    return this.getCurrentRequest ?? {};
  }
  get requestedHours() {
    let hours = 0;

    this.requestedEntries.forEach((value) => {
      hours += parseFloat(value.hours);
    });

    return hours;
  }
  get requestHistory() {
    return this.getCurrentRequest.logs ?? [];
  }
  get requestedEntries() {
    return this.getCurrentRequest.entries ?? [];
  }
  get employeeData() {
    return this.getCurrentRequest?.employeeData ?? {};
  }
  get show() {
    return this.isLoaded && this.isLoading === false;
  }

  get canEdit() {
    if (
      this.hasAnyRole(["isPayroll"]) &&
      this.getCurrentRequest?.status !== "D" &&
      this.getCurrentRequest?.status !== "S"
    ) {
      return !(
        this.getCurrentRequest?.status === "F" &&
        this.getCurrentRequest?.employeeId === this.employeeId &&
        this.getCurrentRequest?.employerId === this.employerId
      );
    }

    if (this.getCurrentRequest?.status === "P") {
      return (
        this.hasAnyRole(["isManager", "isSupervisor", "isProxyForManager"]) ||
        (this.getCurrentRequest?.employeeId === this.employeeId &&
          this.getCurrentRequest?.employerId === this.employerId)
      );
    }

    return false;
  }

  created() {
    this.loadRequest();
  }

  async loadRequest() {
    this.setIsApprovalFormValid(this.valid);

    this.isLoading = true;

    await this.fetchEmployeeRequest({
      employeeIdentifier: this.employeeUserId,
      requestId: this.requestId,
    });

    this.$emit("onLoadingComplete", true);
    this.isLoading = false;

    if (this.requestFetchError === null) {
      this.isLoaded = true;
    }
  }

  async validate() {
    // this method is called by parent component for field validation
    this.valid = this.$refs.form.validate();
    this.setIsApprovalFormValid(this.valid);

    if (!this.valid) {
      await this.$nextTick();
      this.goToErrorField({});
    }
  }

  onError(event) {
    this.valid = !event;
    this.setIsApprovalFormValid(this.valid);
  }

  async approveForm() {
    let entries = [];

    this.requestedEntries.forEach((entry) => {
      entries.push({
        date: dates.dateString(entry.date.date),
        code: entry.code,
        hours: entry.hours.toFixed(2).toString(),
      });
    });

    const requestData = {
      comments: this.reviewComments,
      entries: entries,
    };

    if (this.valid === true) {
      await this.approveRequest({
        employeeIdentifier: this.request.employeeUserId.trim(),
        requestId: this.request.id,
        requestData: requestData,
      });
      this.setReviewComments("");
    }
  }

  async denyForm() {
    const requestData = {
      comments: this.reviewComments,
    };

    if (this.valid === true) {
      await this.denyRequest({
        employeeIdentifier: this.request.employeeUserId.trim(),
        requestId: this.request.id,
        requestData: requestData,
      });
      this.setReviewComments("");
    }
  }

  onReviewCommentsChange(event) {
    this.setReviewComments(event);
  }

  closeRequestEdit() {
    this.showRequestEditModal = false;
  }

  openRequestEdit() {
    this.showRequestEditModal = true;
  }

  showSuccessDialog(action) {
    this.successDialog.message = this.successMessage(action);
    this.successDialog.show = true;
  }

  closeAlertDialog() {
    this.successDialog.show = false;
  }

  mounted() {
    this.setIsCommentRequired(false);
  }

  destroyed() {
    this.setReviewComments("");
    this.setCurrentRequest({});
  }
}
</script>
