<template>
  <v-card elevation="2" :loading="isLoading">
    <v-card-title>
      {{ title }}
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-img :src="logoAsset()" class="my-3" contain height="200" />
        </v-col>
        <v-col cols="12" md="6" class="text-right">
          <v-btn x-large color="error" dark outlined @click="goToMyInformation">
            My Information
          </v-btn>
        </v-col>
        <v-col cols="12" md="6">
          <v-btn x-large outlined color="error" dark @click="goToDirectDeposit">
            Direct Deposit
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import modulePageMixin from "@/utils/mixins";
import { Component, Mixins } from "vue-property-decorator";

@Component({})
export default class DashboardKnight extends Mixins(modulePageMixin) {
  isLoading = false;

  isEmpty = false;

  fetchError = "No Error";

  title = "Knight Dashboard";

  // eslint-disable-next-line class-methods-use-this
  data() {
    return {
      validation: true,
      testing: false,
    };
  }

  logoAsset() {
    return this.getAssetPath("logo.png");
  }

  goToMyInformation() {
    this.$router.push("/personal-info/my-information");
  }

  goToDirectDeposit() {
    this.$router.push("/payroll/directDeposit");
  }

  loadingDashBoards(data) {
    this.isLoading = !data;
  }
}
</script>
