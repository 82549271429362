import timeOffApi from "../../api/timeOff";
import { getApiError } from "@/utils/errorUtils";

export default {
  async fetchEmployeeListTimeOffRequests(
    { commit },
    { employeeIdentifier, params }
  ) {
    commit("setIsFetchingData", true);
    commit("setFetchError", null);

    try {
      const { data } = await timeOffApi.fetchTimeOffRequests(
        employeeIdentifier,
        params
      );

      commit("setTimeOffRequests", data._embedded?.requests ?? []);
      commit("setTotalTimeOffRequests", data._total_items);
    } catch (error) {
      commit("setFetchError", getApiError(error));
    }
    commit("setIsFetchingData", false);
  },
  resetListViewOptions({ commit, state }) {
    commit("setListViewOptions", state.defaultListViewOptions);
    commit("setRequestDateRangeCriteria", []);
  },
};
