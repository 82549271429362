import dates from "@/utils/dates";

export default {
  employeeSchedule(state) {
    return state.employeeSchedule;
  },
  scheduleForDate: (state) => (dateStr) => {
    let hours = 0;

    state.employeeScheduleChanges.every((schedChange) => {
      let schedule = JSON.parse(schedChange.hoursAfter);

      let day =
        "SCHEDULE_" +
        dates.dateToStringCustomFormat(dateStr, "ddd").toUpperCase();

      hours = schedule[day];

      return !dates.dateIsAfter(dateStr, schedChange.modifiedTimestamp.date);
    }, hours);

    return hours;
  },
  employeeScheduleUserId(state) {
    return state.employeeScheduleUserId;
  },
  employeeScheduleFirstName(state) {
    return state.employeeScheduleFirstName;
  },
  employeeScheduleLastName(state) {
    return state.employeeScheduleLastName;
  },
  employeeSchedulePayType(state) {
    return state.employeeSchedulePayType;
  },
  employeeScheduleChanges(state) {
    return state.employeeScheduleChanges;
  },
  employeeScheduleEmployerId(state) {
    return state.employeeScheduleEmployerId;
  },
  employeeScheduleEmployeeId(state) {
    return state.employeeScheduleEmployeeId;
  },
  employeeScheduleFetchError(state) {
    return state.employeeScheduleFetchError;
  },
  employeeScheduleSaveError(state) {
    return state.employeeScheduleSaveError;
  },
  employeeScheduledDays(state) {
    let totalDays = 0;
    Object.values(state.employeeSchedule).forEach((hours) => {
      if (hours > 0) {
        totalDays++;
      }
    });

    return totalDays;
  },
};
