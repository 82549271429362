<template>
  <div>
    <h2>Additional Knight Specific fields</h2>
    <p>
      Additional fields or components can be added here that apply only to
      Knight.
    </p>
    <p>
      Code can also be separated and base component methods and function
      overwritten here as well.
    </p>
  </div>
</template>

<script>
import { Component } from "vue-property-decorator";
import Requests from "./requests.vue";

@Component
export default class RequestsKnight extends Requests {
  showSwift = false;
  isLoading = false;
  isEmpty = false;
  fetchError = "Error!";
}
</script>
