// import router hook registrations
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import createVuetify from "./plugins/vuetify";
import meta from "./plugins/vue-meta";
import loadConfiguration from "./utils/loadConfiguration";

require("@/apis/index");
require("@/plugins/vue-toasted");
//

Vue.config.productionTip = false;

// Vue-Meta
meta(Vue);
loadConfiguration(store, router)
  .then(() => {
    const vuetify = createVuetify();
    // create Vue App
    new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  })
  .catch((e) => e);
