export default {
  getEmployeeIdentifier(state) {
    return state.employeeIdentifier;
  },

  listViewOptions(state) {
    return state.listViewOptions;
  },
  getRequestDateRangeCriteria(state) {
    return state.requestDateRangeCriteria;
  },

  isFetchingData(state) {
    return state.isFetchingData;
  },
  fetchError(state) {
    return state.fetchError;
  },

  getTimeOffRequests(state) {
    return state.timeOffRequests;
  },
  getTotalTimeOffRequests(state) {
    return state.totalTimeOffRequests;
  },
};
