<template>
  <v-card-actions class="justify-left">
    <v-btn @click="printCalendar" outlined>
      <v-icon left> mdi-printer-outline </v-icon>
      Print Calendar
    </v-btn>
  </v-card-actions>
</template>

<script>
import Component from "vue-class-component";
import Vue from "vue";

@Component({
  name: "printCalendar",
})
export default class printCalendar extends Vue {
  printCalendar() {
    window.print();
  }
}
</script>
