import timeOffApi from "../../api/timeOff";
import { getApiError } from "@/utils/errorUtils";

export default {
  async fetchRequestQueue({ commit }, params) {
    commit("setRequestQueueFetchError", null);
    try {
      const { data } = await timeOffApi.fetchRequestQueue(params);

      commit("setRequestQueue", data._embedded.queue);
      commit("setRequests", data._embedded.requests);
      commit("setTotalRequests", data._total_items);
    } catch (error) {
      commit("setRequestQueueFetchError", getApiError(error));
    }
  },

  async reprocessAS400Upload({ commit }) {
    commit("setReprocessAS400UploadError", null);
    try {
      const { data } = await timeOffApi.patchTimeOffRequestReattemptUpload();
      commit("setReprocessAS400UploadRequests", data);
    } catch (error) {
      commit("setReprocessAS400UploadError", getApiError(error));
    }
  },
};
