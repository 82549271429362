import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const timeOffStore = namespace("timeOff");
const appConfigStore = namespace("appConfig");

@Component
export default class timeOffMixin extends Vue {
  @timeOffStore.Getter("config") config;
  @appConfigStore.Getter("getRouteByName") getRouteByName;

  timeOffConfig(key) {
    return this.config(key) ?? {};
  }
  timeOffCategoryBgColor(timeOffCategory) {
    return `var(--pto-cat-bg-color-${timeOffCategory})`;
  }
  timeOffCategoryBgColorSecondary(timeOffCategory) {
    return `var(--pto-cat-bg-color-secondary-${timeOffCategory})`;
  }
  timeOffRequestReviewPath(userId, requestId) {
    const route = this.getRouteByName("timeOff:reviewRequest");
    if (route) {
      return `/timeoff/${
        route.path
      }?user=${userId.trim()}&request=${requestId}`;
    }
    return null;
  }
  timeOffRequestViewPath(userId, requestId) {
    const route = this.getRouteByName("timeOff:viewRequest");

    if (route) {
      return `/timeoff/${
        route.path
      }?user=${userId.trim()}&request=${requestId}`;
    }
    return null;
  }
  gotoReview(userId, requestId) {
    const destination = this.timeOffRequestReviewPath(userId, requestId);

    if (destination) {
      this.$router.push(destination);
    }
  }
  gotoView(userId, requestId) {
    const destination = this.timeOffRequestViewPath(userId, requestId);

    if (destination) {
      this.$router.push(destination);
    }
  }
  filterDeletedEntries(requests) {
    requests.forEach((request) => {
      if (Object.prototype.hasOwnProperty.call(request, "entries")) {
        request.entries = request.entries.filter((entry) => !entry.isDeleted);
      }
    });

    return requests;
  }
}
