<template>
  <div class="Instructions">
    <v-card class="mx-auto my-4">
      <v-toolbar class="toolBar" color="primary" dark @click="shouldDisplay = !shouldDisplay">
        <v-toolbar-title>Arizona A4 Withholding Election</v-toolbar-title>
        <v-spacer />
        <v-btn icon>
          <v-icon v-if="shouldDisplay">mdi-arrow-down-circle-outline</v-icon>
          <v-icon v-else>mdi-arrow-right-circle-outline</v-icon>
        </v-btn>
      </v-toolbar>
      <v-expand-transition>
        <v-container fluid v-show="shouldDisplay" class="instructions">
          <v-row>
            <v-col cols="12" md="6" class="text-justify">
              <h2>Instructions</h2>
              <p>
                Arizona law requires your employer to withhold Arizona income tax from your wages for work done in
                Arizona. The amount withheld is applied to your Arizona income tax due when you file your tax return.
                The amount withheld is a percentage of your gross taxable wages from every paycheck. You may also have
                your employer withhold an extra amount from each paycheck. Complete this form to select a percentage and
                any extra amount to be withheld from each paycheck.
              </p>
              <h3>What are my “Gross Taxable Wages”?</h3>
              <p>
                For withholding purposes, your “gross taxable wages” are the wages that will generally be in box 1 of
                your federal Form W-2. It is your gross wages less any pretax deductions, such as your share of health
                insurance premiums.
              </p>
              <h3>New Employees</h3>
              <p>
                Complete this form within the first five days of your employment to select an Arizona withholding
                percentage. You may also have your employer withhold an extra amount from each paycheck. If you do not
                give this form to your employer the department requires your employer to withhold 2.0% of your gross
                taxable wages.
              </p>
              <h3>Current Employees</h3>
              <p>
                If you want to change your current amount withheld, you must file this form to change the Arizona
                withholding percentage or to change the extra amount withheld
              </p>
            </v-col>
            <v-col cols="12" md="6" class="text-justify">
              <h3>What Should I do With Form A-4?</h3>
              <p>Give your completed Form A-4 to your employer.</p>
              <h3>Electing a Withholding Percentage of Zero</h3>
              <p>
                You may elect an Arizona withholding percentage of zero if you expect to have no Arizona income tax
                liability for the current year. Arizona tax liability is gross tax liability less any tax credits, such
                as the family tax credit, school tax credits, or credits for taxes paid to other states. If you make
                this election, your employer will not withhold Arizona income tax from your wages for payroll periods
                beginning after the date you file the form. Zero withholding does not relieve you from paying Arizona
                income taxes that might be due at the time you file your Arizona income tax return. If you have an
                Arizona tax liability when you file your return or if at any time during the current year conditions
                change so that you expect to have a tax liability, you should promptly file a new Form A-4 and choose a
                withholding percentage that applies to you.
              </p>
              <h3>Voluntary Withholding Election by Certain Nonresident Employees</h3>
              <p>
                Compensation earned by nonresidents while physically working in Arizona for temporary periods is subject
                to Arizona income tax. However, under Arizona law, compensation paid to certain nonresident employees is
                not subject to Arizona income tax withholding. These nonresident employees need to review their
                situations and determine if they should elect to have Arizona income taxes withheld from their Arizona
                source compensation. Nonresident employees may request that their employer withhold Arizona income taxes
                by completing this form to elect Arizona income tax withholding.
              </p>
              <p>You can find the current
                <a v-bind:href="formURL" target="_blank" rel="noopener noreferrer">Arizona A4 here.</a>
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import { getEnv } from "@/utils/util";

export default {
  data() {
    return {
      shouldDisplay: true,
      formURL: getEnv("VUE_APP_ARIZONA_FORMS"),
    };
  },
};
</script>

<style lang="scss" scoped>
.instructions {
  font-size: 14px;
}
</style>
