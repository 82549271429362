export default {
  payload(state) {
    const myInfo = {
      maritalStatus: state.personalInfo.maritalStatus,
      phoneNumber: state.personalInfo.phoneNumber,
      cellNumber: state.personalInfo.cellNumber,
      residentStreetAddress: state.personalInfo.residentStreetAddress,
      residentSuite: state.personalInfo.residentSuite,
      residentCity: state.personalInfo.residentCity,
      residentState: state.personalInfo.residentState,
      residentZipCode: state.personalInfo.residentZipCode,
      personalEmailAddress: state.personalInfo.personalEmailAddress,
    };

    let returnValue = {};

    if (state.wantsMailingAddress) {
      const mailingAddress = {
        mailingStreetAddress: state.sameAddress
          ? state.personalInfo.residentStreetAddress
          : state.personalInfo.mailingStreetAddress,

        mailingSuite: state.sameAddress
          ? state.personalInfo.residentSuite
          : state.personalInfo.mailingSuite,

        mailingCity: state.sameAddress
          ? state.personalInfo.residentCity
          : state.personalInfo.mailingCity,

        mailingState: state.sameAddress
          ? state.personalInfo.residentState
          : state.personalInfo.mailingState,

        mailingZipCode: state.sameAddress
          ? state.personalInfo.residentZipCode
          : state.personalInfo.mailingZipCode,
        mailingCountyCode: "",
      };

      returnValue = {
        ...myInfo,
        ...mailingAddress,
      };
    } else {
      const mailingAddressEmpty = {
        mailingStreetAddress: "",
        mailingSuite: "",
        mailingCity: "",
        mailingState: "",
        mailingZipCode: "",
        mailingCountyCode: "",
      };

      returnValue = {
        ...myInfo,
        ...mailingAddressEmpty,
      };
    }

    return returnValue;
  },
};
