export default {
  getCalendarEntries(state) {
    return state.calendarEntries;
  },
  calendarSelectedMonth(state) {
    return state.calendarSelectedMonth;
  },
  isFetchingData(state) {
    return state.isFetchingData;
  },
  requestFetchError(state) {
    return state.requestFetchError;
  },
};
