<template>
  <div>
    <v-row>
      <v-col class="d-flex justify-center flex-wrap">
        <div v-show="noUserData">{{ noDataMessage }}</div>
        <div
          class="pto-dash-item"
          v-for="employeeCount in employeeCounts"
          v-bind:key="employeeCount.id"
        >
          <hours-balance :employeeCount="employeeCount" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import modulePageMixin from "@/utils/mixins";
import { Component, Mixins } from "vue-property-decorator";
import HoursBalance from "@/modules/timeOff/components/fields/hoursBalance";
import { namespace } from "vuex-class";

const employeeHoursStore = namespace("timeOff/employeeHours");

@Component({
  components: { HoursBalance },
})
export default class TimeOffHoursBalance extends Mixins(modulePageMixin) {
  isLoading = false;
  isEmpty = false;
  fetchError = "";
  noDataMessage = "No data returned for logged-in user ID";

  @employeeHoursStore.Getter("employeeHours") employeeHours;
  @employeeHoursStore.Action("fetchEmployeeHours") fetchEmployeeHours;

  get employeeCounts() {
    return this.employeeHours;
  }

  get noUserData() {
    return !this.isLoading && this.employeeHours.length === 0;
  }

  created() {
    this.isLoading = true;
    this.fetchEmployeeHours(this.currentUserId).then(() => {
      this.$emit("onLoadingComplete", true);
      this.isLoading = false;
    });
  }
}
</script>
