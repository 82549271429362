export default [
  {
    path: "/account",
    name: "account",
    component: "modules/account",
    meta: {
      title: "Account",
      hideFromNav: false,
      icon: "mdi-information",
    },
    children: [
      {
        name: "account:profile",
        path: "profile",
        component: "modules/account/profile",
        meta: {
          title: "Profile",
          hideFromNav: false,
          icon: "mdi-information",
        },
      },
    ],
  },
];
