import _ from "lodash";

export default {
  computed: {
    errorTitle() {
      if (this?.error?.status !== "" && this?.error?.status !== undefined) {
        return `${this.error?.status ?? ""} -  ${this.error?.title ?? ""}`;
      }
      return `${this.error?.title ?? ""}`;
    },
    errorDetails() {
      return this?.error?.detail ?? "";
    },
    errors() {
      return this.getErrorsList();
    },
    hasErrors() {
      return this.error ? Object.keys(this.error).length > 0 : false;
    },
  },
  methods: {
    closeDialog() {
      this.$emit("dialogClosed");
    },
    getErrorsList() {
      const result = [];

      if (this.error === null || this.error === undefined) return result;

      const getError = (value) => {
        const errorMessages = [];

        if (typeof value === "string") {
          return value;
        }

        if (Object.keys(value).length > 0) {
          _.forEach(value, (childValue) => {
            if (typeof childValue === "string") {
              errorMessages.push(childValue);
            } else if (typeof childValue === "object") {
              errorMessages.push(getError(childValue));
            }
          });
        }

        return _.flatMapDeep(errorMessages);
      };

      _.forEach(this.error?.errors, (errorItem) => {
        result.push(getError(errorItem));
      });
      return _.flatMapDeep(result);
    },
  },
};
