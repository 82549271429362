<template>
  <div>
    <span class="font-weight-bold d-block">
      {{ label }}
    </span>
    <slot />
  </div>
</template>

<script>
export default {
  name: "LabelWrapper",
  props: {
    label: {
      type: String,
      required: true,
    },
  },
};
</script>
