import forEach from "lodash/forEach";

function getErrors(data) {
  function getErrorsRecursive(errorData, curr) {
    if (typeof errorData === "object" && errorData !== null) {
      forEach(errorData, (d) => getErrorsRecursive(d, curr));
    } else {
      curr.push(errorData);
    }
  }
  const result = [];
  if (typeof data === "object" && data !== null) {
    getErrorsRecursive(data, result);
  }
  return result;
}

export default {
  setIsLoading(state, data) {
    state.isLoading = data;
  },
  setIsSubmitting(state, data) {
    state.isSubmitting = data;
  },
  setError(state, error) {
    if (error?.response?.data?.title) {
      state.error = error.response.data.title;
    } else {
      state.error = error;
    }
  },
  setEmailPreferences(state, data) {
    state.emailPreferences = data;
  },
  setSubmitError(state, error) {
    const title = error?.response?.data?.title;
    const status = error?.response?.data?.status;
    const errors = error?.response?.data?.errors;

    if (status === 423 && title === "Locked") {
      state.savingInfo = {
        message: "Your changes have been received and will update shortly.",
      };
    } else if (title && errors) {
      state.submitError = {
        title,
        errors: getErrors(errors),
      };
    } else if (title) {
      state.submitError = title;
    } else {
      state.submitError = error;
    }
  },
  setSavingInfo(state, info) {
    state.savingInfo = info;
  },
};
