export default [
  {
    moduleId: "payroll",
    moduleName: "payroll",
    metaInfo: {
      title: "Payroll",
      meta: [
        {
          name: "ESS Payroll",
          content: "Payroll",
        },
      ],
    },
    forms: [
      {
        formId: "",
      },
    ],
    workflow: [],
    api: [{}],
  },
  {
    moduleId: "payroll:directDeposit",
    parent: "payroll",
    moduleName: "directDeposit",
    metaInfo: {
      title: "Direct Deposit",
      meta: [
        {
          name: "directDeposit",
          content: "Direct Deposit",
        },
      ],
    },
    forms: [
      {
        formId: "",
      },
    ],
    workflow: [],
  },
  {
    moduleId: "payroll:taxForm",
    parent: "payroll",
    moduleName: "taxForm",
    metaInfo: {
      title: "State Tax",
      meta: [
        {
          name: "taxForm",
          content: "State Tax",
        },
      ],
    },
    forms: [
      {
        formId: "",
      },
    ],
    workflow: [],
  },
  {
    moduleId: "payroll:emailPreferences",
    parent: "payroll",
    moduleName: "emailPreferences",
    metaInfo: {
      title: "Email Preferences",
      meta: [
        {
          name: "emailPreferences",
          content: "Email Preferences",
        },
      ],
    },
    forms: [
      {
        formId: "",
      },
    ],
    workflow: [],
  },
];
