<template>
  <v-card-actions class="justify-left">
    <v-btn
      @click="reprocessAS400upload"
      outlined
      :loading="isButtonLoading"
      :disabled="requests.length === 0"
    >
      <v-icon left> mdi-upload-multiple </v-icon>
      Reprocess Incomplete AS400 Uploads
    </v-btn>
  </v-card-actions>
</template>

<script>
import Component from "vue-class-component";
import Vue from "vue";
import { namespace } from "vuex-class";
import { Inject } from "vue-property-decorator";

const requestQueuesStore = namespace("timeOff/requestQueues");

@Component({
  name: "reprocessUploads",
})
export default class reprocessUploads extends Vue {
  @requestQueuesStore.Action("reprocessAS400Upload") reprocessAS400Upload;
  @requestQueuesStore.Getter("reprocessAS400UploadRequests")
  reprocessAS400UploadRequests;
  @requestQueuesStore.Getter("reprocessAS400UploadError")
  reprocessAS400UploadError;
  @requestQueuesStore.Getter("requests")
  requests;
  @requestQueuesStore.Mutation("setRequests")
  setRequests;
  @requestQueuesStore.Mutation("setTotalRequests")
  setTotalRequests;

  @Inject("showDialog")
  showDialog;

  isButtonLoading = false;

  async reprocessAS400upload() {
    this.isButtonLoading = true;

    await this.reprocessAS400Upload();

    let requests = this.requests;

    this.reprocessAS400UploadRequests.forEach((reprocessedRequest) => {
      if (
        reprocessedRequest.papaa === "complete" &&
        reprocessedRequest.pendingAs400 === "complete"
      ) {
        requests = requests.filter(
          (request) => request.id !== reprocessedRequest.timeOffRequestId
        );
      }
    });

    this.setRequests(requests);
    this.setTotalRequests(requests.length);

    let message = "All requests have been successfully reprocessed.";
    let title = null;

    if (requests.length > 0) {
      title = "Failure Processing Uploads";
      message = "Some requests have not been successfully reprocessed.";
    }

    this.showDialog(message, title);
    this.isButtonLoading = false;
  }
}
</script>
