export default [
  {
    path: "/payroll",
    name: "payroll",
    component: "modules/payroll",
    meta: {
      title: "Payroll",
      hideFromNav: false,
      icon: "mdi-cash-multiple",
    },
    children: [
      {
        name: "payroll:directDeposit",
        path: "directDeposit",
        component: "modules/payroll/directDeposit",
        meta: {
          title: "Direct Deposit",
          hideFromNav: false,
          icon: "mdi-cash-plus",
        },
      },
      {
        name: "payroll:updateW4",
        path: "updateW4",
        component: "modules/payroll/updateW4",
        meta: {
          title: "Update W4",
          hideFromNav: false,
          icon: "mdi-file-edit",
        },
        excludeUsers: ["isKEX"],
      },
      {
        name: "payroll:W4Archive",
        path: "w4-archive",
        component: "modules/payroll/updateW4/w4Archive",
        meta: {
          title: "Federal Withholding Form (W4)",
          hideFromNav: false,
          icon: "mdi-file-document-multiple",
        },
        excludeUsers: ["isKEX"],
      },
      {
        path: "arizona-a4",
        name: "payroll:arizona-a4",
        component: "modules/payroll/taxForm",
        meta: {
          title: "State Tax",
          hideFromNav: false,
          icon: "mdi-cash-minus",
        },
      },
      {
        name: "payroll:emailPreferences",
        path: "emailPreferences",
        component: "modules/payroll/emailPreferences",
        meta: {
          title: "Email Preferences",
          hideFromNav: false,
          icon: "mdi-email",
        },
        excludeUsers: ["isDriver", "isKEX"],
      },
    ],
  },
];
