export default [
  {
    moduleId: "timeOff",
    moduleName: "Time Off",
    metaInfo: {
      title: "Time Off Management",
      meta: [
        {
          name: "Time Off Management Application",
          content: "Time Off management",
        },
      ],
    },
    forms: [
      {
        formId: "",
      },
    ],
    workflow: [],
    api: [
      {
        userHours: "/users/%username%/hours",
      },
    ],
  },
  {
    moduleId: "timeOff:requests",
    parent: "timeOff",
    moduleName: "My Requests",
    metaInfo: {
      title: "My Time Off Requests List",
      meta: [
        {
          name: "My Time Off Requests",
          content: "List of existing Time Off requests",
        },
      ],
    },
    forms: [
      {
        formId: "",
      },
    ],
    workflow: [],
  },
  {
    moduleId: "timeOff:newRequest",
    parent: "timeOff",
    moduleName: "New Request",
    metaInfo: {
      title: "New Time Off Request",
      meta: [
        {
          name: "New Time Off Request",
          content: "Create a new request for time off",
        },
      ],
    },
    forms: [
      {
        formId: "",
      },
    ],
    workflow: [],
  },
  {
    moduleId: "timeOff:myEmployeeRequests",
    parent: "timeOff",
    moduleName: "My Employee Requests",
    metaInfo: {
      title: "Manager Time Off Requests",
      meta: [
        {
          name: "Manager Time Off Requests",
          content: "List of time requests for direct and indirect reports",
        },
      ],
    },
    forms: [
      {
        formId: "",
      },
    ],
    workflow: [],
  },
  {
    moduleId: "timeOff:schedule",
    parent: "timeOff",
    moduleName: "Work Schedule",
    metaInfo: {
      title: "Employee Schedule",
      meta: [
        {
          name: "Employee Schedule",
          content: "Manage Employee's Schedule",
        },
      ],
    },
    forms: [
      {
        formId: "",
      },
    ],
    workflow: [],
  },
  {
    moduleId: "timeOff:scheduleEditModal",
  },
  {
    moduleId: "timeOff:requestQueues",
    parent: "timeOff",
    moduleName: "Request Queues",
    metaInfo: {
      title: "Request Queues",
      meta: [
        {
          name: "Request Queues",
          content: "View TimeOff Request Queues",
        },
      ],
    },
    forms: [
      {
        formId: "",
      },
    ],
    workflow: [],
  },
];
