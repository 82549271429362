import store from "@/store";

export default (to, _, next) => {
  if (
    to.name === "logout"
    || to.name === "login"
    || store.getters["auth/isFullyLoggedIn"]
  ) {
    next();
  } else if (store.getters["auth/hasAuthInSessionStorage"]) {
    // if there is a token in session storage then try to log in with it
    store
      .dispatch("auth/authenticateWithJwt", {
        jwt: sessionStorage.getItem("jwt"),
      })
      .then(() => {
        if (store.state.auth.fetchUserInfoError) {
          // if there is a problem with the token, navigate to login page
          next("/login");
        } else {
          store.commit(
            "auth/setIsMultiFactorValidated",
            sessionStorage.getItem("isMultiFactorValidated"),
          );
          next(to.fullPath);
        }
      });
  } else {
    if (to.path !== "/login") {
      store.commit("auth/setLoginRedirect", to.fullPath);
    }
    next("/login");
  }
};
