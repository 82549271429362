import Vue from "vue";
import stateTax from "./stateTax";
import emailPreferencesAPI from "./emailPreferences";
import directDepositAPI from "./directDeposit";
import w4 from "./w4";

export default {
  getApi() {
    return { emailPreferencesAPI, stateTax, directDepositAPI };
  },
  registerApi() {
    Vue.$apis.addApi(stateTax);
    Vue.$apis.addApi(emailPreferencesAPI);
    Vue.$apis.addApi(directDepositAPI);
    Vue.$apis.addApi(w4);
  },
};
