import timeOffApi from "../../api/timeOff";
import { filter as _filter, cloneDeep as _cloneDeep } from "lodash";
import { hoursFormat } from "@/utils/util";
import dates from "@/utils/dates";
import { getApiError } from "@/utils/errorUtils";

const sortObjectByKeys = function (obj) {
  return Object.keys(obj)
    .sort()
    .reduce((accumulator, key) => {
      accumulator[key] = obj[key];

      return accumulator;
    }, {});
};

export default {
  async postRequest({ commit }, { employeeIdentifier, requestData }) {
    commit("setIsSubmitting", true);
    commit("setRequestSubmitError", {});

    try {
      await timeOffApi.postTimeOffRequest(employeeIdentifier, requestData);
      commit("setIsSubmitting", false);
    } catch (error) {
      commit("setRequestSubmitError", getApiError(error));
    } finally {
      commit("setIsSubmitting", false);
    }
  },

  async approveRequest(
    { commit },
    { employeeIdentifier, requestId, requestData }
  ) {
    commit("setIsSubmitting", true);
    commit("setRequestSubmitError", {});

    try {
      await timeOffApi.patchTimeOffRequestApprove(
        employeeIdentifier,
        requestId,
        requestData
      );
      commit("setIsSubmitting", false);
    } catch (error) {
      commit("setRequestSubmitError", getApiError(error));
    } finally {
      commit("setIsSubmitting", false);
    }
  },

  async saveRequest(
    { commit },
    { employeeIdentifier, requestId, requestData }
  ) {
    commit("setIsSubmitting", true);
    commit("setRequestSubmitError", {});

    try {
      const { data } = await timeOffApi.patchTimeOffRequestEdit(
        employeeIdentifier,
        requestId,
        requestData
      );
      commit("setIsSubmitting", false);
      commit("setCurrentRequest", data);
    } catch (error) {
      commit("setRequestSubmitError", getApiError(error));
    } finally {
      commit("setIsSubmitting", false);
    }
  },

  async denyRequest(
    { commit },
    { employeeIdentifier, requestId, requestData }
  ) {
    commit("setIsSubmitting", true);
    commit("setRequestSubmitError", {});

    try {
      await timeOffApi.patchTimeOffRequestDeny(
        employeeIdentifier,
        requestId,
        requestData
      );
      commit("setIsSubmitting", false);
    } catch (error) {
      commit("setRequestSubmitError", getApiError(error));
    } finally {
      commit("setIsSubmitting", false);
    }
  },

  /**
   * requestEntries actions
   */
  toggleRequestEntry({ dispatch, getters }, { dateEntry, selectedDates }) {
    const dateCodeRequestEntry = getters.getRequestEntryDateCode(
      dateEntry.dateStr,
      dateEntry.code
    );

    if (dateCodeRequestEntry) {
      dispatch("removeDateSelected", dateEntry);
      return;
    }

    dispatch("setDateSelected", { dateEntry, selectedDates });
  },
  setDateSelected(
    { commit, getters, rootGetters },
    { dateEntry, selectedDates }
  ) {
    // create a clone - will reset requestEntries with a new object to trigger change
    const requestEntries = _cloneDeep(getters.requestEntries);

    let max_splits = rootGetters["timeOff/config"]("request_day_max_splits");
    max_splits = parseInt(max_splits) > 0 ? parseInt(max_splits) : 1;

    if (!requestEntries[dateEntry.dateStr]) {
      requestEntries[dateEntry.dateStr] = [];
    }

    // fetch the existing code array for date
    let dateEntries = requestEntries[dateEntry.dateStr];

    // ignore duplicate codes
    const existingDateEntries = _filter(
      dateEntries,
      (d) => d.code === dateEntry.code
    );

    // if code already exists, nothing to do
    if (existingDateEntries.length >= 1) {
      return;
    }

    const dateEntriesPrevRequest = _filter(
      selectedDates,
      (entry) =>
        dateEntry.dateStr in entry &&
        entry[dateEntry.dateStr].requestId !== undefined &&
        entry[dateEntry.dateStr].requestId !== getters.getCurrentRequest.id
    );

    if (dateEntries.length + dateEntriesPrevRequest.length >= max_splits) {
      throw `Cannot have more than ${max_splits} entries per day`;
    }

    let prevEntry =
      dateEntriesPrevRequest.length > 0
        ? dateEntriesPrevRequest[0][dateEntry.dateStr]
        : null;

    requestEntries[dateEntry.dateStr] = getters.updateSelectedHours(
      dateEntries,
      dateEntry,
      prevEntry
    );

    commit("setRequestEntries", sortObjectByKeys(requestEntries));
  },
  removeDateSelected({ commit, getters, rootGetters }, dateEntry) {
    // create a clone - will reset requestEntries with a new object to trigger change
    const requestEntries = _cloneDeep(getters.requestEntries);
    // if date is not set in requestEntries, jump out
    if (!requestEntries[dateEntry.dateStr]) {
      return;
    }

    const dateEntries = requestEntries[dateEntry.dateStr];

    const existingDateEntries = _filter(
      dateEntries,
      (d) => d.code === dateEntry.code
    );

    // code is not set. nothing to do
    if (existingDateEntries.length === 0) {
      return;
    }

    // filter out the code
    let newDateEntries = _filter(dateEntries, (d) => d.code !== dateEntry.code);

    const scheduledHours = parseFloat(
      rootGetters["timeOff/employeeSchedule/scheduleForDate"](dateEntry.dateStr)
    );
    if (newDateEntries.length > 0) {
      // date still has other code. recalculate perc and hours to full schedule hours
      newDateEntries.forEach((s) => {
        const newDayHours = getters.getDayHours(dateEntry.dateStr, s.code);
        const newHours = newDayHours / newDateEntries.length;
        if (newDayHours !== 0 && newDayHours > s.hours) {
          s.perc = newHours / scheduledHours;
          s.hours = hoursFormat(newHours);
          s.isSplit = s.perc !== 1;
        }
      });
      requestEntries[dateEntry.dateStr] = newDateEntries;
    } else {
      // remove the dateStr key
      delete requestEntries[dateEntry.dateStr];
    }

    commit("setRequestEntries", sortObjectByKeys(requestEntries));
  },
  updateRequestEntryHours({ commit, getters, rootGetters }, dateEntry) {
    // create a clone - will reset requestEntries with a new object to trigger change
    const requestEntries = _cloneDeep(getters.requestEntries);
    // if date is not set in requestEntries, jump out
    if (!requestEntries[dateEntry.dateStr]) {
      return;
    }

    const dateEntries = requestEntries[dateEntry.dateStr];

    const existingCodeEntries = _filter(
      dateEntries,
      (d) => d.code === dateEntry.code
    );

    // code is not set. nothing to do
    if (existingCodeEntries.length === 0) {
      return;
    }

    const dateCodeRequestEntry = existingCodeEntries[0];

    // day's hours from employee schedule
    const dayHours = getters.getDayHours(dateEntry.dateStr, dateEntry.code);

    const scheduledHours = parseFloat(
      rootGetters["timeOff/employeeSchedule/scheduleForDate"](dateEntry.dateStr)
    );

    const perc = dateEntry.hours / dayHours;

    dateCodeRequestEntry.hours = hoursFormat(dateEntry.hours);
    dateCodeRequestEntry.perc = perc;

    // if only 2 entries and new total is greater than scheduled hours, adjust the other one
    if (dateEntries.length === 2) {
      // if only 2 entries and new total is greater than scheduled hours, adjust the other one
      const newHoursTotal = dateEntries.reduce((accumulator, entry) => {
        accumulator += parseFloat(entry.hours);
        return accumulator;
      }, 0);

      if (newHoursTotal > scheduledHours) {
        const hoursDiff = newHoursTotal - scheduledHours;
        const otherEntry = _filter(
          dateEntries,
          (d) => d.code !== dateEntry.code
        )[0];
        const otherHours = parseInt(otherEntry.hours) - hoursDiff;

        if (otherHours > 0) {
          otherEntry.hours = hoursFormat(
            parseInt(otherEntry.hours) - hoursDiff
          );
        }
      }
    }
    // recalc percentages
    dateEntries.forEach((s) => {
      s.perc = s.hours / dayHours;
    });

    commit("setRequestEntries", sortObjectByKeys(requestEntries));
  },
  formatRequestData({ commit, getters, rootGetters }) {
    let timeOffRequest = getters.getCurrentRequest;
    let formattedEntries = {};

    timeOffRequest.entries.forEach((entry) => {
      let dateStr = dates.dateString(dates.dateFromMezzioDateJson(entry.date));
      let fullDayHours =
        rootGetters["timeOff/employeeSchedule/scheduleForDate"](dateStr);
      let percent = parseFloat(entry.hours) / parseFloat(fullDayHours);

      let formattedEntry = [
        {
          id: entry.id,
          code: entry.code,
          error: "",
          hours: entry.hours.toFixed(2),
          perc: percent,
          isSplit: percent < 1,
        },
      ];

      if (dateStr in formattedEntries) {
        formattedEntries[dateStr] =
          formattedEntries[dateStr].concat(formattedEntry);
      } else {
        formattedEntries[dateStr] = formattedEntry;
      }
    });

    commit("setRequestEntries", formattedEntries);
    commit("setReasonForRequest", timeOffRequest.reason);
  },
  async fetchEmployeeRequest({ commit }, { employeeIdentifier, requestId }) {
    try {
      const { data } = await timeOffApi.fetchTimeOffRequest(
        employeeIdentifier.trim(),
        requestId
      );
      commit("setCurrentRequest", data);
    } catch (error) {
      commit("setRequestFetchError", getApiError(error));
    }
  },
};
