<template>
  <pageManager
    :isLoading="isLoading"
    :isEmpty="isEmpty"
    :error="fetchError"
    :dialog-error="dialogError"
    @dialogClosed="onErrorDialogClosed"
  >
    <v-card flat>
      <v-card-title>Allow Others to Request on My Behalf</v-card-title>
      <p class="infoText">
        Here, you may select proxies, or other individuals that can submit Time
        Off requests on your behalf.
      </p>
      <v-alert dismissible v-if="showSuccess" dense text type="success">
        {{ successMessage }}
      </v-alert>
      <v-btn class="addButton" color="primary" @click="openEmployeeSelect">
        <v-icon class="mr-3">mdi-account-switch</v-icon>
        Add Proxy
      </v-btn>
      <v-data-table
        class="elevation-1"
        :loading="isFetchingData"
        loading-text="Loading Proxies..."
        no-data-text="No Proxies Found"
        :headers="tableHeaders"
        :footer-props="tableFooter"
        :items="employeeProxies"
        :server-items-length="totalEmployeeProxies"
        :options.sync="options"
        item-key="id"
      >
        <template v-slot:header>
          <tr class="grey lighten-3 table-filter-row">
            <th v-for="header in tableHeaders" :key="header.text"></th>
          </tr>
        </template>
        <template v-slot:[`item.proxyEmployeeId`]="{ item }">
          {{ formatProxyName(item) }}
        </template>
        <template v-slot:[`item.isEnabled`]="{ item }">
          <div class="d-flex justify-center">
            <v-switch
              v-model="item.isEnabled"
              inset
              @change="updateActiveStatus(item)"
            ></v-switch>
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn text @click="confirmDelete(item)">
            <v-icon class="pl-2 pa-2">mdi-account-remove</v-icon>
            Remove
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <employeeDataTableSelectDialog
      :title="employeeSelectDialog.title"
      :show="employeeSelectDialog.show"
      searchType="infinium"
      :returnObject="true"
      @close="closeEmployeeSelect"
      @employeeSelected="employeeSelected"
    />
    <deleteDialog
      persistent
      :showDialog="deleteConfirmation"
      :message="deleteMessage"
      @deleteConfirmed="deleteEmployeeProxy"
      @closeDialog="deleteConfirmation = false"
    ></deleteDialog>
    <progressDialog
      :showDialog="isSubmitting"
      :message="progressMessage"
    ></progressDialog>
  </pageManager>
</template>

<script>
import { Component, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { isEmpty } from "lodash";
import ErrorAlert from "@/components/errorAlert.vue";
import deleteDialog from "@/components/deleteDialog.vue";
import progressDialog from "@/components/progressDialog.vue";
import pageManager from "@/components/pageManager.vue";
import EmployeeDataTableSelectDialog from "@/modules/timeOff/components/employeeDataTableSelectDialog.vue";

const authStore = namespace("auth");
const settingsStore = namespace("timeOff/settings");

@Component({
  name: "employeeProxies",
  components: {
    EmployeeDataTableSelectDialog,
    pageManager,
    progressDialog,
    deleteDialog,
    ErrorAlert,
  },
})
export default class EmployeeProxies {
  @settingsStore.Getter("isFetchingData") isFetchingData;
  @settingsStore.Getter("fetchError") fetchError;
  @settingsStore.Getter("isSubmitting") isSubmitting;
  @settingsStore.Getter("submitError") submitError;
  @settingsStore.Getter("employeeProxies") employeeProxies;
  @settingsStore.Getter("totalEmployeeProxies") totalEmployeeProxies;
  @settingsStore.Action("fetchEmployeeProxies") fetchEmployeeProxies;
  @settingsStore.Action("removeEmployeeProxy") removeEmployeeProxy;
  @settingsStore.Action("updateEmployeeProxy") updateEmployeeProxy;
  @settingsStore.Action("addEmployeeProxy") addEmployeeProxy;
  @settingsStore.Mutation("setEmployeeProxies") setEmployeeProxies;
  @settingsStore.Mutation("setSubmitError") setSubmitError;

  @authStore.Getter("employerId") employerId;
  @authStore.Getter("employeeId") employeeId;

  tableHeaders = [
    {
      text: "Employee",
      align: "center",
      value: "proxyEmployeeId",
      sortable: true,
      width: "33%",
    },
    {
      text: "Active",
      align: "center",
      value: "isEnabled",
      sortable: true,
      width: "33%",
    },
    {
      text: "Action",
      align: "center",
      value: "actions",
      sortable: false,
    },
  ];
  tableFooter = {
    "items-per-page-options": [10, 25],
    "show-current-page": true,
    "show-first-last-page": true,
  };
  options = {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
  };
  isEmpty = false;
  isLoading = false;
  sort = "";
  deleteConfirmation = false;
  deleteMessage = "";
  progressMessage = "";
  proxyToDelete = null;
  showSuccess = false;
  successMessage = "";
  employeeSelectDialog = {
    title: "Select Employee",
    show: false,
  };

  get dialogError() {
    if (!isEmpty(this.submitError)) {
      return {
        error: this.submitError,
        model: true,
      };
    }
    return {};
  }

  @Watch("options", { deep: true })
  onOptionsChange() {
    if (Object.keys(this.options).length !== 0) {
      if (this.options.sortBy[0]) {
        this.sort = this.options.sortBy[0];
        if (this.sort === "proxyEmployeeId") {
          this.sort = "proxyLastName";
        }
        if (this.options.sortDesc[0]) {
          this.sort = this.sort + ":desc";
        }
      } else {
        this.sort = "proxyLastName";
      }
    }
    this.refresh();
  }

  refresh() {
    this.clearProxies();
    clearTimeout(this._searchTimerId);
    this._searchTimerId = setTimeout(() => {
      this.loadEmployeeProxies();
    }, 500);
  }

  clearProxies() {
    this.setEmployeeProxies([]);
  }

  async loadEmployeeProxies() {
    let params = {
      limit: this.options.itemsPerPage,
      page: this.options.page,
      sort: this.sort,
    };

    this.fetchEmployeeProxies({ params: params });
  }

  formatProxyName(proxy) {
    const firstName = proxy.proxyFirstName.trim();
    const lastName = proxy.proxyLastName.trim();
    const employerId = proxy.proxyEmployerId.trim();
    const employeeId = proxy.proxyEmployeeId.trim();

    return `${lastName}, ${firstName} (${employerId} - ${employeeId})`;
  }

  confirmDelete(proxy) {
    const proxyName = this.formatProxyName(proxy);
    this.deleteMessage = `Are you sure you want to remove ${proxyName} as a proxy?`;
    this.progressMessage = `Removing ${proxyName}`;
    this.proxyToDelete = proxy;
    this.deleteConfirmation = true;
  }

  async deleteEmployeeProxy() {
    this.deleteConfirmation = false;

    await this.removeEmployeeProxy({
      payload: {
        employerId: this.employerId,
        employeeId: this.employeeId,
        proxyEmployerId: this.proxyToDelete.proxyEmployerId,
        proxyEmployeeId: this.proxyToDelete.proxyEmployeeId,
      },
    });

    if (Object.keys(this.submitError).length === 0) {
      const proxyName = this.formatProxyName(this.proxyToDelete);

      this.successMessage = `Successfully removed ${proxyName} as a proxy`;
      this.showSuccess = true;

      const timer = setTimeout(() => {
        this.showSuccess = false;
        clearTimeout(timer);
      }, 5000);

      this.options.page = 1;
      this.refresh();
    }
  }

  async updateActiveStatus(proxy) {
    await this.updateEmployeeProxy({
      payload: {
        isEnabled: proxy.isEnabled,
        employerId: proxy.employerId,
        employeeId: proxy.employeeId,
        proxyEmployerId: proxy.proxyEmployerId,
        proxyEmployeeId: proxy.proxyEmployeeId,
      },
    });

    this.refresh();
  }

  onErrorDialogClosed() {
    this.setSubmitError({});
  }

  openEmployeeSelect() {
    this.employeeSelectDialog.show = true;
  }

  closeEmployeeSelect() {
    this.employeeSelectDialog.show = false;
  }

  async employeeSelected(proxy) {
    this.closeEmployeeSelect();

    const proxyName = `${proxy.lastName.trim()}, ${proxy.firstName.trim()} (${proxy.employerId.trim()} - ${proxy.employeeId.trim()})`;

    this.progressMessage = `Adding ${proxyName}`;

    await this.addEmployeeProxy({
      payload: {
        employerId: this.employerId,
        employeeId: this.employeeId,
        proxyEmployerId: proxy.employerId,
        proxyEmployeeId: proxy.employeeId,
        isEnabled: true,
      },
    });

    if (Object.keys(this.submitError).length === 0) {
      this.successMessage = `Successfully added ${proxyName} as a proxy`;
      this.showSuccess = true;

      const timer = setTimeout(() => {
        this.showSuccess = false;
        clearTimeout(timer);
      }, 5000);

      this.options.page = 1;
      this.refresh();
    }
  }
}
</script>

<style scoped>
.infoText {
  margin-left: 16px;
}
.addButton {
  margin-left: 16px;
  margin-bottom: 16px;
}
</style>
