import cloneDeep from "lodash/cloneDeep";
import store from "@/store";

function findMatchingContact(contacts, sequenceNumber, feId) {
  if (sequenceNumber !== 0) {
    return contacts.find(
      (contact) => contact.sequenceNumber === sequenceNumber,
    );
  }
  return contacts.find((contact) => contact.feId === feId);
}

function setMatchingContactValue(contacts, sequenceNumber, feId, key, val) {
  findMatchingContact(contacts, sequenceNumber, feId)[key] = val;
}

function formatPhoneNumber(num) {
  const cleaned = (`${num}`).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
}

export default {
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setIsSaving(state, isSaving) {
    state.isSaving = isSaving;
  },
  setError(state, error) {
    if (error?.response?.data?.errors) {
      state.error = error.response.data.errors;
    } else if (error?.response?.data?.title) {
      state.error = error.response.data.title;
    } else {
      state.error = error;
    }
  },
  setSavingError(state, error) {
    // if error is status 423, just show info dialog
    if (
      error
      && error?.response?.data?.status
      && error.response.data.status === 423
    ) {
      this.commit("emergencyContacts/updateInitialContacts");
      state.savingInfo = {
        message: "Your changes have been received and will update shortly.",
      };

      return;
    }

    if (error?.response?.data?.errors) {
      state.savingError = {
        errors: [],
      };

      Object.keys(error.response.data.errors).forEach((contactKey) => {
        Object.values(error.response.data.errors[contactKey]).forEach((fieldErrors) => {
          Object.values(fieldErrors).forEach((fieldError) => {
            state.savingError.errors.push(`${fieldError} for contact ${contactKey}`);
          });
        });
      });
    } else if (error?.response?.data?.title) {
      state.savingError = error.response.data.title;
    } else {
      state.savingError = error;
    }
  },
  setSavingInfo(state, info) {
    state.savingInfo = info;
  },
  setInitialContacts(state, contacts) {
    if (contacts.length > 0) {
      state.initialContacts = contacts.map((val) => ({
        ...val,
        deleteCode: false,
        emergencyRelationshipCode: val.emergencyRelationshipCode.trim(),
        emergencyAddressZipCode: val.emergencyAddressZipCode.trim(),
        emergencyPhoneNumber: formatPhoneNumber(val.emergencyPhoneNumber),
        feId: Math.random(),
      }));
    } else {
      state.initialContacts = [
        {
          deleteCode: false,
          emergencyAddressCity: "",
          emergencyAddressCityState: "",
          emergencyAddressLine1: "",
          emergencyAddressStateCode: "",
          emergencyAddressZipCode: "",
          emergencyContactName: "",
          emergencyPhoneNumber: "",
          emergencyRelationshipCode: "",
          employeeId: store.state.auth.userInfo.employeeId,
          employerId: store.state.auth.userInfo.employerId,
          primaryContact: 1,
          sequenceNumber: 0,
          feId: Math.random(),
        },
      ];
    }
  },
  addContact(state, { employeeId, employerId }) {
    state.workingContacts.push({
      emergencyContactName: "",
      emergencyRelationshipCode: "",
      emergencyPhoneNumber: "",
      emergencyAddressLine1: "",
      emergencyAddressCityState: "",
      emergencyAddressCity: "",
      emergencyAddressStateCode: "",
      emergencyAddressZipCode: "",
      primaryContact: state.workingContacts.length ? 0 : 1,
      sequenceNumber: 0, // seq # 0 indicates a new contact
      employerId,
      employeeId,
      deleteCode: false,
      feId: Math.random(),
    });
  },
  deleteContact(state, contact) {
    state.workingContacts = state.workingContacts.filter((c) => c !== contact);
    if (state.workingContacts.length === 1) {
      state.workingContacts.__ob__.value[0].primaryContact = 1;
    }
  },
  createWorkingContacts(state) {
    state.workingContacts = cloneDeep(state.initialContacts).map((contact) => contact);
  },
  updateInitialContacts(state) {
    state.initialContacts = cloneDeep(state.workingContacts).map((contact) => contact);
  },
  setWorkingContactName(state, { feId, sequenceNumber, val }) {
    setMatchingContactValue(
      state.workingContacts,
      sequenceNumber,
      feId,
      "emergencyContactName",
      val,
    );
  },
  setWorkingContactRelationship(state, { feId, sequenceNumber, val }) {
    setMatchingContactValue(
      state.workingContacts,
      sequenceNumber,
      feId,
      "emergencyRelationshipCode",
      val,
    );
  },
  setWorkingContactPhone(state, { feId, sequenceNumber, val }) {
    setMatchingContactValue(
      state.workingContacts,
      sequenceNumber,
      feId,
      "emergencyPhoneNumber",
      val,
    );
  },
  setWorkingContactStreet(state, { feId, sequenceNumber, val }) {
    setMatchingContactValue(
      state.workingContacts,
      sequenceNumber,
      feId,
      "emergencyAddressLine1",
      val,
    );
  },
  setWorkingContactCity(state, { feId, sequenceNumber, val }) {
    setMatchingContactValue(
      state.workingContacts,
      sequenceNumber,
      feId,
      "emergencyAddressCity",
      val,
    );
  },
  setWorkingContactState(state, { feId, sequenceNumber, val }) {
    setMatchingContactValue(
      state.workingContacts,
      sequenceNumber,
      feId,
      "emergencyAddressStateCode",
      val,
    );
  },
  setWorkingContactZip(state, { feId, sequenceNumber, val }) {
    setMatchingContactValue(
      state.workingContacts,
      sequenceNumber,
      feId,
      "emergencyAddressZipCode",
      val,
    );
  },
  setPrimaryWorkingContact(state, [val, emergencyContact]) {
    state.workingContacts = state.workingContacts.map((contact) => {
      if (contact.feId === emergencyContact.feId) {
        contact.primaryContact = val === true ? 1 : 0;
      } else {
        contact.primaryContact = 0;
      }
      return contact;
    });
  },
  setRelationships(state, relationships) {
    state.relationships = relationships.map((val) => ({
      ...val,
      code: val.code.trim(),
      description: val.description.trim(),
    }));
  },
};
