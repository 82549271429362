import timeOffApi from "../../api/timeOff";
import { getApiError } from "@/utils/errorUtils";

export default {
  async fetchEmployeeHours({ commit }, employeeIdentifier) {
    commit("resetEmployeeHours");
    commit("setEmployeeHoursUserId", employeeIdentifier);
    try {
      const { data } = await timeOffApi.fetchEmployeeHours(employeeIdentifier);
      commit("setEmployeeHours", data._embedded?.hours);
    } catch (error) {
      commit("setEmployeeHoursFetchError", getApiError(error));
    }
  },
};
