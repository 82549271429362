import state from "./state";
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

import admin from "./admin";
import calendar from "./calendar";
import employeeHours from "./employeeHours";
import employeeSchedule from "./employeeSchedule";
import requestForm from "./requestForm";
import requestQueues from "./requestQueues";
import requestsSearch from "./requestsSearch";
import requestsEmployee from "./requestsEmployee";
import settings from "./settings";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules: {
    admin,
    calendar,
    employeeHours,
    employeeSchedule,
    requestForm,
    requestQueues,
    requestsSearch,
    requestsEmployee,
    settings,
  },
};
