export default {
  setRequestQueue(state, data) {
    state.requestQueue = data;
  },
  setRequests(state, data) {
    state.requests = data;
  },
  setRequestQueueFetchError(state, data) {
    state.requestQueueFetchError = data;
  },
  setTotalRequests(state, data) {
    state.totalRequests = data;
  },
  setReprocessAS400UploadError(state, data) {
    state.reprocessAS400UploadError = data;
  },
  setReprocessAS400UploadRequests(state, data) {
    state.reprocessAS400UploadRequests = data;
  },
};
