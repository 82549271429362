<template>
  <div class="employeeScheduleEditModal">
    <v-overlay opacity="0.75">
      <v-fade-transition>
        <v-sheet light class="scheduleModal" style="background: transparent">
          <v-card elevation="2" :loading="isLoading || isSaving">
            <v-card-title :class="cardHeaderClass">
              {{ title }}
              <v-col class="text-right">
                <v-btn icon color="white" @click="$emit('close')">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col class="d-flex justify-center flex-wrap">
                  <div v-show="noScheduleData">{{ noScheduleMessage }}</div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex" cols="12">
                  <employeeScheduleTable
                    v-if="isLoaded"
                    :schedule="employeeSchedule ? employeeSchedule : undefined"
                    :payType="employeeSchedulePayType"
                    :edit="true"
                    @cancel="$emit('close')"
                    @submit="saveScheduleModal"
                    ref="employeeSchedule"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-sheet
                  light
                  v-if="employeeScheduleSaveError"
                  class="submit-modal pa-md-5"
                >
                  <error-dialog
                    :error="employeeScheduleSaveError"
                    :model=false
                    @dialogClosed="$emit('clearErrors')"
                  ></error-dialog>
                </v-sheet>
              </v-row>
              <v-row>
                <v-alert
                  width="100%"
                  dense
                  outlined
                  type="success"
                  v-if="isSaved"
                >
                  {{ savedMessage }}
                </v-alert>
              </v-row>
            </v-card-text>
          </v-card>
        </v-sheet>
      </v-fade-transition>
    </v-overlay>
  </div>
</template>
<script>
import employeeScheduleCard from "./employeeScheduleCard.vue";
import {Component, Mixins} from "vue-property-decorator";
import errorDialog from "@/components/errorDialog";

@Component({
  components: { errorDialog },
})

// Extends EmployeeScheduleCard
export default class EmployeeScheduleEditModal extends Mixins(
  employeeScheduleCard
) {}
</script>

<style lang="scss" scoped>
.scheduleModal {
  min-width: 600px;
  min-height: 200px;
}
</style>
