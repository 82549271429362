<template>
  <div class="calendar-legend">
    <v-row>
      <v-col cols="auto">
        <span class="color-box" style="background: #a9c680"></span
        ><span class="category"> PTO </span>
        <v-spacer></v-spacer>
        <span class="color-box" style="background: #558eb4"></span
        ><span class="category"> Sick </span>
      </v-col>
      <v-col cols="auto">
        <span class="color-box" style="background: #0074d9"></span
        ><span class="category"> Float </span>
        <v-spacer></v-spacer>
        <span class="color-box" style="background: #44679c"></span
        ><span class="category"> No Pay </span>
      </v-col>
      <v-col cols="auto">
        <span class="color-box" style="background: #d9b06d"></span
        ><span class="category"> Jury Duty </span>
        <v-spacer></v-spacer>
        <span class="color-box" style="background: #9b9ece"></span
        ><span class="category"> Unexcused </span>
      </v-col>
      <v-col cols="auto">
        <span class="color-box" style="background: #704c5e"></span
        ><span class="category"> Bereavement </span>
        <v-spacer></v-spacer>
        <span class="color-box" style="background: #5b376e"></span
        ><span class="category"> Grandfathered </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Component from "vue-class-component";
import Vue from "vue";

@Component({
  name: "calendarLegend",
})
export default class calendarLegend extends Vue {}
</script>

<style scoped>
.calendar-legend {
  display: none;
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.color-box {
  width: 10px;
  height: 10px;
  display: inline-block;
}

.category {
  font-size: 12px;
}
</style>
