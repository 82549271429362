export default {
  styles: {
    timeOffCategories: {
      P: {
        backgroundColor: "#A9C680",
        color: "",
      },
      K: {
        backgroundColor: "#0074D9",
        color: "",
      },
      S: {
        backgroundColor: "#558EB4",
        color: "",
      },
      X: {
        backgroundColor: "#9B9ECE",
        color: "",
      },
      B: {
        backgroundColor: "#704C5E",
        color: "",
      },
      J: {
        backgroundColor: "#D9B06D",
        color: "",
      },
      A: {
        backgroundColor: "#44679C",
        color: "",
      },
      R: {
        backgroundColor: "#5B376E",
        color: "",
      },
    },
  },
};
