<template>
  <v-container class="request-queue">
    <component :is="managerEmployeeRequests" />
  </v-container>
</template>

<script>
import Component from "vue-class-component";
import { Mixins } from "vue-property-decorator";
import modulePageMixin from "@/utils/mixins";

@Component({
  name: "myEmployeeRequests",
  components: {},
})
export default class MyEmployeeRequests extends Mixins(modulePageMixin) {
  isLoading = false;

  get managerEmployeeRequests() {
    return this.loadComponent("managerEmployeeRequests", "timeOff/requests");
  }
}
</script>

<style scoped></style>
