<template>
  <div>
    <div v-if="isOver" class="over textCounter" key="character-counter">
      {{ charactersOver }} characters over.
    </div>
    <div v-else class="textCounter" key="character-counter">
      {{ charactersRemaining }} characters remaining.
    </div>
    <v-textarea
      :name="name"
      :label="label"
      v-model="commentModel"
      no-resize
      auto-grow
      filled
      clearable
      clear-icon="mdi-close"
      outlined
      :rules="getRules"
      v-on:input="(event) => this.$emit('inputChange', event)"
      @update:error="onError($event)"
    ></v-textarea>
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
/**
 *  Usage:
 *  <Comments
 *    :max-characters="250"
 *    label="Reason for Request"
 *    name="requestComment"
 *    :propModel="comments"
 *    v-on:inputChange="setComment"
 *  ></Comments>
 *
 *  In the script:
 *  comments = "";
 *
 *  setComment(event) {
 *    this.comments = event ? event : "";
 *  }
 */

@Component({})
export default class Comments extends Vue {
  commentModel = "";

  @Prop({
    type: Number,
    default: 500,
  })
  maxCharacters;

  @Prop({
    type: String,
    default: "Enter your comments.",
  })
  label;

  @Prop({
    type: String,
    default: "comments",
  })
  name;

  @Prop({
    type: String,
    required: true,
    default: "comments",
  })
  propModel;

  @Prop({
    type: Array,
    required: false,
    default: function () {
      return [];
    },
  })
  rules;

  get charactersRemaining() {
    return this.maxCharacters - (this.commentModel || "").length;
  }

  get charactersOver() {
    return this.isOver ? this.commentModel.length - this.maxCharacters : 0;
  }

  get isOver() {
    return this.charactersRemaining < 0;
  }

  get getRules() {
    return [...this.rules, this.maxCharactersAllowed];
  }

  get maxCharactersAllowed() {
    const maxChars = this.maxCharacters;
    return (val) =>
      val === null || val.length <= maxChars || `Max ${maxChars} characters`;
  }

  created() {
    this.commentModel = this.propModel;
  }

  onError(event) {
    this.$emit("validationError", event);
  }
}
</script>
