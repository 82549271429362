import { sanitizeStrings } from "@/utils/util";
import personalInfoApi from "../../api/personalInfo";

export default {
  async fetchContacts({ commit, dispatch }, employeeId) {
    commit("setIsLoading", true);
    commit("setError", null);
    try {
      const relCodesRequest = dispatch(
        "personalInfo/emergencyContacts/getRelationships",
        null,
        {
          root: true,
        },
      );
      const contactsRequest = personalInfoApi.getEmergencyContacts(employeeId);

      await relCodesRequest;
      const { data } = await contactsRequest;

      sanitizeStrings(data._embedded.emergency_contacts);

      commit("setInitialContacts", data._embedded.emergency_contacts);
      commit("createWorkingContacts");
      commit("setIsLoading", false);
    } catch (e) {
      console.warn(e);
      commit("setError", e);
      commit("setIsLoading", false);
    }
  },
  async saveContacts({ commit, getters }) {
    commit("setIsSaving", true);
    commit("setSavingError", null);
    commit("setError", false);
    commit("setSavingInfo", {});
    try {
      const { data } = await personalInfoApi.updateEmergencyContacts(
        getters.payload,
      );
      sanitizeStrings(data._embedded.emergency_contacts);
      commit("setInitialContacts", data._embedded.emergency_contacts);
      commit("createWorkingContacts");
      commit("setIsSaving", false);
    } catch (e) {
      commit("setIsSaving", false);
      commit("setSavingError", e);
    }
  },
  discardChanges({ commit }) {
    commit("createWorkingContacts");
  },
  setWorkingContactAddress(
    { commit },
    {
      sequenceNumber, feId, street, city, state, zip,
    },
  ) {
    commit("setWorkingContactStreet", { sequenceNumber, feId, val: street });
    commit("setWorkingContactCity", { sequenceNumber, feId, val: city });
    commit("setWorkingContactState", { sequenceNumber, feId, val: state });
    commit("setWorkingContactZip", { sequenceNumber, feId, val: zip });
  },
  async getRelationships({ commit }) {
    try {
      const { data } = await personalInfoApi.getInfiniumRelationships();
      commit("setRelationships", data._embedded.relationships);
    } catch (e) {
      console.warn(e);
    }
  },
};
