export default function () {
  return {
    /**
     * Employee states:
     */
    employeeIdentifier: null,

    /**
     * List v-data-table states:
     */
    listViewOptions: {
      page: 1,
      itemsPerPage: 25,
      sortBy: ["id"],
      sortDesc: [true],
    },
    defaultListViewOptions: {
      page: 1,
      itemsPerPage: 25,
      sortBy: ["id"],
      sortDesc: [true],
    },
    requestDateRangeCriteria: [],

    /**
     * Component states:
     */
    isFetchingData: false,
    fetchError: null,

    /**
     * Fetched data:
     */
    timeOffRequests: [],
    totalTimeOffRequests: 0,
  };
}
