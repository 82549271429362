import { getEnv } from "@/utils/util";

class mock {
  constructor(response, empty = null, error = null, time = 0) {
    this.response = response;
    this.empty = empty;
    this.error = error;
    this.time = time;
  }

  getResponse(responseType) {
    const { response, empty, error } = this;
    let resp = null;

    responseType = responseType || getEnv("VUE_APP_STUB_TYPE") || "valid";

    switch (responseType) {
      case "valid":
        return new Promise((resolve) => {
          window.setTimeout(() => resolve(response), this.time);
        });
      case "empty":
        resp = empty ? empty : response;
        return new Promise((resolve) => {
          window.setTimeout(() => resolve(resp), this.time);
        });
      case "error":
        resp = error ? error : response;

        return error
          ? new Promise((_, reject) => {
            window.setTimeout(() => reject(resp), this.time);
          })
          : new Promise((resolve) => {
            window.setTimeout(() => resolve(response), this.time);
          });
      default:
        throw new Error(`Unknown responseType '${responseType}'`);
    }
  }
}

export default mock;
