export default {
  isSubmitting(state) {
    return state.isSubmitting;
  },
  timeZonesList(state) {
    return state?.timeZonesList ?? [];
  },
  timeZoneAbbreviation(state) {
    return state.timeZoneAbbreviation;
  },
  sendCalendarInvitesForMyReports(state) {
    return state?.sendCalendarInvitesForMyReports ?? false;
  },
  sendCalendarInvitesToMe(state) {
    return state?.sendCalendarInvitesToMe ?? false;
  },
  userSettings(state) {
    return {
      timezoneAbbr: state.timeZoneAbbreviation,
      sendCalendarInvitesForMyReports: state.sendCalendarInvitesForMyReports,
      sendCalendarInvitesToMe: state.sendCalendarInvitesToMe,
    };
  },
  isFetchingData(state) {
    return state.isFetchingData;
  },
  employeeProxies(state) {
    return state.employeeProxies;
  },
  totalEmployeeProxies(state) {
    return state.totalEmployeeProxies;
  },
  fetchError(state) {
    return state.fetchError;
  },
  submitError(state) {
    return state.submitError;
  },
};
