import axios from "axios";
import { getEnv } from "@/utils/util";
import getStateTaxes from "./stubs/stateTax";

const api = axios.create({
  baseURL: getEnv("VUE_APP_ESS_API_URL"),
});

api.defaults.headers.common.Accept = "application/json";

export default {
  api,
  name() {
    return "taxForm";
  },
  putStateTaxes(payload) {
    return api.put("/payroll/state-taxes/AZ", payload);
  },
  getStateTaxes() {
    return getEnv("VUE_APP_STUB_APIS").toLowerCase() === "true"
      ? getStateTaxes.getResponse()
      : api.get("/payroll/state-taxes/AZ");
  },
};
