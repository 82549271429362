<template>
  <div class="submitDialog">
    <v-overlay :value="propIsSubmitting || isSubmitError" opacity="0">
      <v-fade-transition>
        <v-sheet light v-if="isSubmitError" class="submit-modal pa-md-5">
          <error-dialog
            :error="propSubmitError"
            :model="isSubmitError"
            @dialogClosed="$emit('clearErrors')"
          />
        </v-sheet>
        <v-sheet v-else light class="submit-modal">
          <progressDialog
            :showDialog="propIsSubmitting"
            :message="propProgressTitle"
          />
        </v-sheet>
      </v-fade-transition>
    </v-overlay>
  </div>
</template>
<script>
import progressDialog from "@/components/progressDialog.vue";
import errorDialog from "@/components/errorDialog.vue";

export default {
  components: {
    progressDialog,
    errorDialog,
  },
  props: {
    propIsSubmitting: { type: Boolean, required: true },
    propSubmitError: { required: true },
    propProgressTitle: { type: String, default: "Submitting..." },
  },
  computed: {
    isSubmitError() {
      return Object.keys(this.propSubmitError).length > 0;
    },
  },
};
</script>
