<template>
  <fieldset>
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          ref="street"
          label="Street"
          autocomplete="address-line1"
          clearable
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Apartment"
          autocomplete="address-line2"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="City"
          autocomplete="address-level2"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-autocomplete
          label="State"
          item-value="code"
          item-text="description"
          return-object
          autocomplete="address-level1"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field label="Zip Code"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Phone Number"
          ref="phone"
          autocomplete="new-phoneNumber"
        />
      </v-col>
    </v-row>
  </fieldset>
</template>

<script>
import modulePageMixin from "@/utils/mixins";
import { Component, Mixins } from "vue-property-decorator";

@Component
export default class Profile extends Mixins(modulePageMixin) {
  autocomplete = null;
}
</script>
