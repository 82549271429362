export default function () {
  return {
    defaultHoursRequired: [
      {
        code: "P",
        description: "PTO",
        earned: "0.00",
        taken: "0.00",
        unapproved: "0",
        pending: "0",
        pending_tmp: "0",
        pendingTotal: 0,
        remaining: 0,
      },
      {
        code: "A",
        description: "Time Off Without Pay",
        earned: "0.00",
        taken: "0.00",
        unapproved: "0",
        pending: "0",
        pending_tmp: "0",
        pendingTotal: 0,
        remaining: 0,
      },
    ],
    employeeHoursUserId: null,
    employeeHours: [],
    employeeHoursFetchError: null,
  };
}
