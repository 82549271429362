<template>
  <v-card elevation="0">
    <v-card-subtitle class="grey lighten-2"> Hours Requested </v-card-subtitle>
    <v-simple-table class="request-entries-table">
      <thead>
        <tr>
          <th class="date-column text-left">Date</th>
          <th class="category-column text-left">Category</th>
          <th class="hour-column text-center">Hours</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="dateEntry in entries"
          :key="`${dateEntry.date.date}-${dateEntry.code}`"
        >
          <td class="text-left">
            {{ dateFormat(dateEntry.date.date) }}
          </td>
          <td class="text-left">
            <span
              v-bind:style="{
                color: `var(--pto-cat-bg-color-${dateEntry.code})`,
              }"
            >
              {{ dateEntry.codeDescription }}
            </span>
          </td>
          <td class="text-center">
            {{ dateEntry.hours }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
import { Component, Prop } from "vue-property-decorator";
import dates from "@/utils/dates";
import Vue from "vue";
import { namespace } from "vuex-class";

const employeeHoursStore = namespace("timeOff/employeeHours");

@Component({
  name: "RequestedHoursTable",
})
export default class RequestedHoursTable extends Vue {
  name = "requestedHoursTable.vue";

  @employeeHoursStore.Getter("categoryFormat") categoryFormat;

  @Prop({
    type: Array,
    required: true,
    default: [],
  })
  entries;

  dateFormat(value) {
    return dates.dateToString(value);
  }
}
</script>

<style scoped></style>
