import timeOffApi from "../../api/timeOff";
import { getApiError } from "@/utils/errorUtils";

export default {
  async fetchManagerListTimeOffRequests({ commit }, params) {
    commit("setIsFetchingData", true);

    try {
      const { data } = await timeOffApi.fetchManagerListTimeOffRequests(params);

      commit("setTimeOffRequests", data._embedded?.requests ?? []);
      commit("setTotalTimeOffRequests", data._total_items);
    } catch (error) {
      commit("setRequestFetchError", getApiError(error));
    }
    commit("setIsFetchingData", false);
  },
  async fetchTimeOffRequestsDownload({ commit }, params) {
    try {
      const { data, headers } = await timeOffApi.fetchTimeOffRequestsDownload(
        params
      );

      return { data, headers };
    } catch (error) {
      commit("setRequestFetchError", getApiError(error));
    }
  },
  resetListViewOptions({ commit, state }) {
    commit("setListViewOptions", state.defaultListViewOptions);
    commit("setRequestDateRangeCriteria", []);
    commit("setStatusCriteria", null);
  },
};
