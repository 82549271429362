<template>
  <v-alert prominent type="error" v-show="hasErrors" class="mt-3">
    {{ errorTitle }}
    <div>
      {{ errorDetails }}
      <div class="errors" v-if="errors.length > 0">
        <ul>
          <li v-for="(error, i) in errors" :key="i">
            {{ error }}
          </li>
        </ul>
      </div>
      <slot name="button" />
    </div>
  </v-alert>
</template>

<script>
import errorsMixin from "@/utils/errorsMixin";

export default {
  name: "errorAlert",
  mixins: [errorsMixin],
  props: {
    error: { required: false, default: "" },
  },
};
</script>

<style scoped></style>
