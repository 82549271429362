export default {
  categories_rules: {
    B: {
      rules: {
        maxDays: "@bereavement_max_days",
      },
      messages: {
        maxDays:
          "You can only request up to %s days per time off request for selected category.",
      },
    },
    S: {
      rules: {
        negativeBalance: false,
      },
      messages: {
        negativeBalance: "not implemented yet",
      },
    },
    J: {
      rules: {
        excludeWeekdays: ["Sunday", "Saturday"],
        maxCivicDutyDays: "$employeeScheduledDays",
      },
      messages: {
        excludeWeekdays: "%s is not available for a Civic Duty request.",
        maxCivicDutyDays: "You can only request up to %s days for Civic Duty.",
      },
    },
  },
  default_datatable_items_per_page: 25,
  highlight_queue_dates_older_than: 3,
  calendar_max_past_months: 6,
  calendar_max_future_months: 12,
  dashboard_calendar_max_past_months: 1,
  dashboard_calendar_max_future_months: 12,
  calendar_skip_interval: 3,
  adminListUserTypes: {
    payrollAdmins: "default",
    payrollAssistants: "default",
    supervisorProxies: "proxies",
    employeeProxies: "proxies",
  },
  adminListDisplayTypes: {
    admins: {
      title: "Payroll Admin",
      id: "payrollAdmins",
      tableStyle: "default",
    },
    assistants: {
      title: "Payroll Assistant",
      id: "payrollAssistants",
      tableStyle: "default",
    },
    proxies: {
      title: "Supervisor Proxy",
      id: "employeeProxies",
      tableStyle: "proxies",
    },
  },
};
