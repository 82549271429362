<template>
  <pageManager :isLoading="isLoading" :error="error" :isEmpty="isEmpty">
    <v-container>
      <v-alert dismissible v-if="showSuccessAlert" dense text type="success">
        {{ successMessage }}
      </v-alert>
      <v-card elevation="2" :loading="isLoading ? 'grey lighten-2' : null">
        <v-card-title class="headline white--text primary font-weight-light">
          Manage Company Holidays
        </v-card-title>
        <v-card-text>
          <v-sheet class="mt-5">
            <div class="add-company-holiday">
              <v-row>
                <v-col cols="3">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-bottom="48"
                    transition="scale-transition"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formattedDate"
                        solo
                        readonly
                        clearable
                        outlined
                        label="Select Date"
                        append-icon="mdi-calendar-month-outline"
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="resetDate"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      :color="datePickerColor"
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="3">
                  <v-btn
                    color="primary"
                    :disabled="date === null"
                    @click="addHoliday(date)"
                  >
                    Add Company Holiday
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <v-data-table
              class="elevation-1"
              :loading="isLoadingHolidays"
              loading-text="Loading Holidays..."
              no-data-text="No Company Holidays"
              :headers="tableHeaders"
              :footer-props="tableFooter"
              :items="holidays"
              :custom-sort="sortDates"
              :sort-desc="true"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn color="primary" @click="deleteHoliday(item.date)">
                  Delete
                </v-btn>
              </template>
            </v-data-table>
          </v-sheet>
        </v-card-text>
      </v-card>

      <submitDialog
        :propIsSubmitting="isSubmitting"
        :propSubmitError="submitError"
        :propProgressTitle="progressTitle"
        @clearErrors="onErrorDialogClosed"
      />

      <deleteDialog
        persistent
        :showDialog="deleteConfirmation"
        :message="deleteMessage"
        @deleteConfirmed="deleteHolidayConfirmed"
        @closeDialog="deleteConfirmation = false"
      ></deleteDialog>
    </v-container>
  </pageManager>
</template>

<script>
import { Component, Mixins, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { DatePicker } from "v-calendar";
import themes from "@/utils/themes";
import dates from "@/utils/dates";
import timeOffMixin from "@/modules/timeOff/timeOffMixin";
import pageManager from "@/components/pageManager";
import deleteDialog from "@/components/deleteDialog";
import Vue from "vue";
import pageManagerMixin from "@/utils/pageManagerMixin";
import SubmitDialog from "@/components/submitDialog";

const timeOffStore = namespace("timeOff");
const adminStore = namespace("timeOff/admin");

@Component({
  name: "ManageCompanyHolidays",
  components: {
    SubmitDialog,
    pageManager,
    DatePicker,
    deleteDialog,
  },
})
export default class CompanyHolidaysManager extends Mixins(
  timeOffMixin,
  pageManagerMixin
) {
  @timeOffStore.Getter("companyHolidays") companyHolidays;
  @timeOffStore.Mutation("setConfig") setConfig;

  @adminStore.Getter("submitError") submitError;
  @adminStore.Mutation("setSubmitError") setSubmitError;
  @adminStore.Action("addCompanyHoliday") addCompanyHoliday;
  @adminStore.Action("deleteCompanyHoliday") deleteCompanyHoliday;

  isLoading = false;
  isEmpty = false;
  error = "";
  menu = false;
  date = null;
  formattedDate = "";
  datePickerColor = themes.Swift.primary;
  holidays = [];
  showSuccessAlert = false;
  isLoadingHolidays = false;
  isSubmitting = false;
  successMessage = "";
  deleteConfirmation = false;
  deleteMessage = "";
  dateToDelete = "";
  progressTitle = "Submitting...";
  tableHeaders = [
    {
      text: "Date",
      align: "start",
      value: "formattedDate",
      sortable: true,
    },
    {
      text: "Actions",
      align: "center",
      value: "actions",
      width: "125px",
      sortable: false,
    },
  ];
  tableFooter = {
    "items-per-page-options": [10, 25],
    "show-current-page": true,
    "show-first-last-page": true,
  };

  get isSubmitError() {
    return Object.keys(this.submitError).length !== 0;
  }

  @Watch("date")
  onDateChange() {
    if (this.date === null) {
      this.formattedDate = "";
      return;
    }
    this.formattedDate = dates.dateToString(this.date);
  }

  resetDate() {
    this.date = null;
  }

  sortDates(items, index, isDesc) {
    items.sort((a, b) => {
      let aDate = new Date(a.date);
      let bDate = new Date(b.date);
      if (isDesc[0] === false) {
        return aDate.getTime() - bDate.getTime();
      }
      return bDate.getTime() - aDate.getTime();
    });
    return items;
  }

  onErrorDialogClosed() {
    this.clearDialogs();
  }

  clearDialogs() {
    this.showSuccessAlert = false;
    this.successMessage = "";
    this.setSubmitError({});
    this.deleteConfirmation = false;
    this.deleteMessage = "";
    this.dateToDelete = "";
  }

  async addHoliday(date) {
    this.isSubmitting = true;
    this.progressTitle = `Adding ${date} to Company Holidays...`;

    let result = await this.addCompanyHoliday({ date: date });

    if (!this.isSubmitError) {
      this.setConfig({
        moduleConfig: result.data,
        companyName: Vue.$appInfo.company.toLowerCase(),
      });
      await this.$nextTick();
      this.updateHolidays();
      this.showSuccessAlert = true;
      this.successMessage = `Company Holiday ${dates.dateToString(
        date
      )} successfully added`;
    }
    const timer = setTimeout(() => {
      this.clearDialogs();
      clearTimeout(timer);
    }, 5000);
    this.resetDate();
    this.isSubmitting = false;
  }

  deleteHoliday(date) {
    this.dateToDelete = date;
    this.deleteMessage = `Are you sure you want to delete ${dates.dateToString(
      date
    )}?`;
    this.deleteConfirmation = true;
  }

  async deleteHolidayConfirmed() {
    this.deleteConfirmation = false;
    this.progressTitle = `Deleting ${this.dateToDelete} from Company Holidays...`;
    this.isSubmitting = true;

    await this.deleteCompanyHoliday({ date: this.dateToDelete });

    if (!this.isSubmitError) {
      let updatedHolidays = [];

      this.companyHolidays.forEach((holiday) => {
        if (dates.dateString(holiday) !== this.dateToDelete) {
          updatedHolidays.push(holiday);
        }
      });

      this.setConfig({
        moduleConfig: { companyHolidays: updatedHolidays },
        companyName: Vue.$appInfo.company.toLowerCase(),
      });

      await this.$nextTick();

      this.updateHolidays();
      this.showSuccessAlert = true;
      this.successMessage = `Company Holiday ${dates.dateToString(
        this.dateToDelete
      )} successfully deleted`;
      this.dateToDelete = "";

      const timer = setTimeout(() => {
        this.clearDialogs();
        clearTimeout(timer);
      }, 5000);
    }

    this.isSubmitting = false;
  }

  updateHolidays() {
    this.isLoadingHolidays = true;
    this.holidays = [];

    this.companyHolidays.forEach((holiday) => {
      this.holidays.push({
        formattedDate: holiday,
        date: dates.dateString(holiday),
      });
    });

    this.isLoadingHolidays = false;
  }

  mounted() {
    this.updateHolidays();
  }
}
</script>

<style scoped>
.add-company-holiday {
  padding-top: 10px;
}
</style>
