<template>
  <div class="taxForm">
    <pageManager :isLoading="isLoading" :isEmpty="false" :error="error">
      <div v-if="isArizonaUser">
        <Instructions />
        <StatusPersonalInformation />
        <WithholdingElection ref="WithholdingElection" />
        <div class="text-right">
          <v-btn
            class="px-8 submit-button"
            color="accent"
            @click="conformSubmit"
            tile
            :loading="isSubmitting"
            :disabled="isSubmitting"
          >
            <v-icon class="mr-2">mdi-send</v-icon>Submit
          </v-btn>
          <submitDialog
            :propIsSubmitting="propIsSubmitting"
            :propSubmitError="propSubmitError"
            @clearErrors="clearErrors"
          />
          <v-dialog v-model="summaryDialog" persistent max-width="600">
            <v-card>
              <v-card-title>You have requested the following changes:</v-card-title>
              <v-card-text class="summary">
                <ul>
                  <li v-if="electWithhold">
                    I elect an Arizona withholding percentage of zero, and I certify that I expect to have no Arizona
                    tax liability for the current taxable year.
                  </li>
                  <li v-if="percentageElection">
                    Withhold from gross taxable wages at the percentage of:<b>{{ percentageElection }}%</b>
                  </li>
                  <li v-if="additionalAmount">
                    The following extra amount will be withheld from each paycheck:<b>${{ additionalAmount }}</b>
                  </li>
                </ul>
                <div>
                  <v-checkbox
                    class="acknowledgement"
                    :value="electronicConsent"
                    label="I certify that I have made the elections marked above."
                    @change="setElectronicConsent"
                    labelPosition="Before"
                  />
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="red darken-1" text @click="disagreed">Disagree</v-btn>
                <v-btn color="red darken-1" text @click="submit" :disabled="!electronicConsent">Agree</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="successDialog" width="400">
            <v-card>
              <v-card-title class="headline grey lighten-2">Your selection was saved</v-card-title>
              <v-card-text>
                <div class="text-md-justify mt-5">
                  Your changes have been submitted to Payroll and will be updated in the next cycle.
                </div>
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-spacer />
                <v-btn color="red" text @click="closeSuccessDialog"> Ok </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <div v-else>
        <notArizonaInstructions />
      </div>
    </pageManager>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import pageManager from "@/components/pageManager.vue";
import Instructions from "./components/instructions.vue";
import StatusPersonalInformation from "./components/statusPersonalInformation.vue";
import WithholdingElection from "./components/withholdingElection.vue";
import submitDialog from "../../../components/submitDialog.vue";
import notArizonaInstructions from "./components/notArizonaInstructions.vue";

export default {
  data() {
    return {
      summaryDialog: false,
      targetStore: "taxForm",
      successDialog: false,
    };
  },
  components: {
    Instructions,
    StatusPersonalInformation,
    WithholdingElection,
    submitDialog,
    notArizonaInstructions,
    pageManager,
  },
  computed: {
    ...mapState("auth", ["userInfo"]),
    ...mapState("personalInfo/myInformation", ["personalInfo"]),
    ...mapState("payroll/taxForm", [
      "isSubmitting",
      "submitError",
      "electronicConsent",
      "electionOption",
      "percentageElection",
      "additionalAmount",
      "isLoading",
      "error",
      "electWithhold",
    ]),
    propIsSubmitting() {
      return this.isSubmitting;
    },
    propSubmitError() {
      return this.submitError;
    },
    isArizonaUser() {
      if (this.personalInfo !== null) {
        return this.personalInfo?.residentState === "AZ";
      }
      return this.userInfo.state.trim() === "AZ";
    },
  },
  page: {
    title: "State Tax",
    meta: [{ name: "description", content: "The state tax page." }],
  },
  methods: {
    ...mapMutations("payroll/taxForm", [
      "setSubmitError",
      "setElectronicConsent",
    ]),
    async conformSubmit() {
      this.$refs.WithholdingElection.validate();
      // Validate does not excetute inmediatly so we need to start a timeout.
      // The validate function is not synchronous so we need to use a set time out.
      setTimeout(() => {
        if (!this.$refs.WithholdingElection.value) {
          this.$toasted.error("Please fix errors prior to submitting.");
        } else {
          this.summaryDialog = true;
        }
      });
    },
    async submit() {
      this.summaryDialog = false;
      if (this.electronicConsent) {
        await this.$store.dispatch("payroll/taxForm/submitTaxForm");
        if (Object.keys(this.submitError).length === 0) {
          this.successDialog = true;
        }
      }

      this.setElectronicConsent(false);
    },
    closeSuccessDialog() {
      this.successDialog = false;
      this.$router.push("/");
    },
    disagreed() {
      this.summaryDialog = false;
      this.setElectronicConsent(false);
    },
    async getCurrentTaxInfo() {
      await this.$store.dispatch("payroll/taxForm/getStateTaxes");
    },
    clearErrors() {
      this.setSubmitError({});
    },
  },
  mounted() {
    this.getCurrentTaxInfo();
  },
};
</script>

<style lang="scss" scoped>
.summary {
  font-size: 16px;
  margin-bottom: -30px;
}
.acknowledgement {
  font-size: 10px;
}
.v-checkbox .v-label {
  font-size: 8px;
}
.submit-button {
  margin-right: 0;
  margin-top: 10px;
}
</style>
