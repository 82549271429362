<template>
  <v-card :disabled="isSaving">
    <v-fade-transition>
      <DeleteOverlay
        v-if="deleting"
        @cancel="deleting = false"
        @delete="confirmDelete(true)"
      />
    </v-fade-transition>
    <v-toolbar dark flat color="primary">
      <v-toolbar-title> Education </v-toolbar-title>
      <v-spacer />
      <v-btn @click="confirmDelete(false)" text>
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-toolbar>
    <div class="education-content">
      <v-form ref="form" v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" md="6" class="form-column">
              <v-text-field
                label="School/College"
                :value="education.school"
                @input="
                  (val) =>
                    setWorkingSchool({
                      feId: education.feId,
                      sequenceNumber: education.sequenceNumber,
                      val,
                    })
                "
                :rules="[requiredSchool, maxLengthSchoolRule]"
              />
            </v-col>
            <v-col cols="12" md="6" class="form-column">
              <v-select
                :value="education.degree"
                :items="degrees"
                item-value="code"
                item-text="description"
                label="Degree"
                @change="changeDegree"
                :rules="[requiredDegree, degreeRule]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="form-column">
              <v-text-field
                label="Subject"
                :value="education.subject"
                @input="
                  (val) =>
                    setWorkingSubject({
                      feId: education.feId,
                      sequenceNumber: education.sequenceNumber,
                      val,
                    })
                "
                :rules="[maxLengthSubjectRule]"
              />
            </v-col>
            <v-col cols="12" md="6" class="form-column">
              <v-text-field
                label="GPA"
                :value="education.gpa"
                @input="
                  (val) =>
                    setWorkingGpa({
                      feId: education.feId,
                      sequenceNumber: education.sequenceNumber,
                      val,
                    })
                "
                v-mask="'#.#'"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="form-column">
              <v-text-field
                label="Major"
                :value="education.major"
                @input="
                  (val) =>
                    setWorkingMajor({
                      feId: education.feId,
                      sequenceNumber: education.sequenceNumber,
                      val,
                    })
                "
                :rules="[requiredMajor, maxLengthMajorRule]"
              />
            </v-col>
            <v-col cols="12" md="6" class="form-column">
              <v-text-field
                label="Minor"
                :value="education.minor"
                @input="
                  (val) =>
                    setWorkingMinor({
                      feId: education.feId,
                      sequenceNumber: education.sequenceNumber,
                      val,
                    })
                "
                :rules="[requiredMinor, maxLengthMinorRule]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="form-column">
              <v-text-field
                label="Start Year"
                :value="education.startYear"
                v-mask="'####'"
                @input="
                  (val) =>
                    setWorkingStartYear({
                      feId: education.feId,
                      sequenceNumber: education.sequenceNumber,
                      val,
                    })
                "
                :rules="
                  rules.startYear
                    .concat(rules.startYearRequired)
                    .concat(rules.earlyStart)
                "
                validate-on-blur
              />
            </v-col>
            <v-col cols="12" md="6" class="form-column">
              <v-text-field
                label="Year of Completion"
                :value="education.graduationYear"
                @input="
                  (val) =>
                    setWorkingGraduationYear({
                      feId: education.feId,
                      sequenceNumber: education.sequenceNumber,
                      val,
                    })
                "
                :rules="
                  rules.graduationYear
                    .concat(rules.graduationYearRequired)
                    .concat(rules.graduationPrior)
                    .concat(rules.earlyGraduation)
                "
                validate-on-blur
                v-mask="'####'"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </div>
  </v-card>
</template>

<script>
import DeleteOverlay from "@/components/delete-overlay.vue";
import { mapState, mapMutations } from "vuex";
import { mask } from "vue-the-mask";

export default {
  directives: {
    mask,
  },
  components: {
    DeleteOverlay,
  },
  data() {
    return {
      deleting: false,
      isValid: false,
      requiredSchool: (v) => !!v || "This field is required",
      requiredDegree: (v) => v.trim() !== ""
        || (this.education.school.trim() === ""
          && this.education.major.trim() === ""
          && this.education.minor.trim() === "")
        || "This field is required",
      requiredMajor: (v) => v.trim() !== ""
        || (this.education.school.trim() === ""
          && (this.education.degree === undefined
            || this.education.degree.trim() === "")
          && this.education.minor.trim() === "")
        || "This field is required",
      requiredMinor: (v) => v.trim() !== ""
        || (this.education.school.trim() === ""
          && (this.education.degree === undefined
            || this.education.degree.trim() === "")
          && this.education.major.trim() === "")
        || "This field is required",
      degreeRule: (v) => (v.code !== "" && v.description !== "") || "This field is required",
      maxLengthMajorRule: (v) => v.length <= 60 || "Max 60 characters",
      maxLengthMinorRule: (v) => v.length <= 60 || "Max 60 characters",
      maxLengthSchoolRule: (v) => v.length <= 60 || "Max 60 characters",
      maxLengthSubjectRule: (v) => v.length <= 60 || "Max 60 characters",
      shouldDisplay: true,
      value: false,
      valid: false,
    };
  },
  props: {
    education: { type: Object, required: true },
  },
  computed: {
    ...mapState("auth", ["userInfo"]),
    ...mapState("personalInfo/education", ["isSaving", "degrees", "isLoading"]),
    rules() {
      const validStart = !(
        this.education.startYear.trim() === ""
        && this.education.graduationYear.trim() !== ""
      );

      const validGraduation = !(
        this.education.startYear.trim() !== ""
        && this.education.graduationYear.trim() === ""
      );

      const graduationYearRequired = this.education.graduationYear.trim() !== "";

      const graduationPrior = this.education.graduationYear.trim() === ""
        || this.education.graduationYear >= this.education.startYear;

      const startYearRequired = this.education.startYear.trim() !== "";

      const earlyStart = this.education.startYear.trim() === ""
        || parseInt(this.education.startYear.trim(), 10) >= 1901;

      const earlyGraduation = this.education.graduationYear.trim() === ""
        || parseInt(this.education.graduationYear.trim(), 10) >= 1901;

      return {
        startYearRequired: [() => startYearRequired || "Start Year is required."],
        startYear: [() => validStart || "The Start Year must be defined if the Year of Completion is present."],
        graduationYear: [
          () => validGraduation || "The Year of Completion must be defined if the Start Year is present.",
        ],
        graduationPrior: [() => graduationPrior || "Year of Completion cannot be prior to Start Year."],
        graduationYearRequired: [() => graduationYearRequired || "Year of Completion is required."],
        earlyStart: [() => earlyStart || "Start Year cannot be prior to 1901."],
        earlyGraduation: [() => earlyGraduation || "Year of Completion cannot be prior to 1901."],
      };
    },
    dates() {
      return [this.education.startYear, this.education.graduationYear];
    },
    values() {
      return [
        this.education.school,
        this.education.degree,
        this.education.minor,
        this.education.major,
      ];
    },
  },
  methods: {
    ...mapMutations("personalInfo/education", [
      "setWorkingDegree",
      "setWorkingGpa",
      "setWorkingGraduationYear",
      "setWorkingMajor",
      "setWorkingMinor",
      "setWorkingSchool",
      "setWorkingStartYear",
      "setWorkingSubject",
      "deleteEducation",
    ]),
    confirmDelete(confirmed = false) {
      if (this.education.sequenceNumber === 0 || confirmed) {
        this.deleteEducation(this.education);
      } else {
        this.deleting = true;
      }
    },
    changeDegree(val) {
      if (val !== undefined) {
        val = val.trim().padEnd(3, " ");
        this.setWorkingDegree({
          feId: this.education.feId,
          sequenceNumber: this.education.sequenceNumber,
          val,
        });
      }
    },
    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>

<style lang="scss">
.form-column {
  padding: 5px !important;
}
.primary-contact > header.v-toolbar {
  border-color: black !important;
  border-width: 2px !important;
  border-style: solid !important;
}
</style>
