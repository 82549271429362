<template>
  <v-sheet class="card-top-margin">
    <v-row>
      <v-col>
        <v-text-field
          style="padding-top: 5px"
          v-model="searchCriteria"
          outlined
          label="Search by First Name, Last Name, or Employee Id"
          append-icon="mdi-magnify"
          autofocus
          clearable
        ></v-text-field>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { Mixins, Prop, Watch } from "vue-property-decorator";
import Component from "vue-class-component";
import modulePageMixin from "@/utils/mixins";

@Component({
  name: "searchComponent",
  components: {},
})
export default class searchComponent extends Mixins(modulePageMixin) {
  data() {
    return {
      searchCriteria: "",
      isLoading: false,
    };
  }

  @Prop({
    type: Function,
    required: false,
    default: () => {},
  })
  searchAction;

  @Watch("searchCriteria")
  onSearchChange() {
    if (
      this.searchCriteria !== null &&
      this.searchCriteria.length !== 0 &&
      this.searchCriteria.length < 3
    ) {
      return;
    }

    clearTimeout(this._searchTimerId);
    this._searchTimerId = setTimeout(() => {
      this.$emit("onSearchChange", this.searchCriteria);
    }, 500);
  }

  clear() {
    this.searchCriteria = "";
  }
}
</script>

<style scoped>
.card-top-margin {
  margin-top: 20px;
}
</style>
