import Mock from "@/apis/stubs/mock";

export const directDeposits = new Mock(
  {
    data: {
      _links: {
        self: {
          href: "http://swift.swift.com:10090"
            + "/EmployeeSelfServiceBackend/development/knight/public/payroll/direct-deposits?page=1",
        },
        first: {
          href: "http://swift.swift.com:10090"
            + "/EmployeeSelfServiceBackend/development/knight/public/payroll/direct-deposits",
        },
        last: {
          href: "http://swift.swift.com:10090"
            + "/EmployeeSelfServiceBackend/development/knight/public/payroll/direct-deposits?page=1",
        },
      },
      _embedded: {
        direct_deposits: [
          {
            employeeId: "7652",
            bankName: "BANK OF TIM",
            routingNumber: "122187238",
            accountNumber: "1071864080",
            accountType: "C",
            amount: 1,
            depositType: "P",
          },
          {
            employeeId: "7652",
            bankName: "BANK OF ZORK",
            routingNumber: "122883719",
            accountNumber: "1071864081",
            accountType: "S",
            amount: 999,
            depositType: "NET",
          },
        ],
      },
      page_count: 1,
      page_size: 25,
      total_items: 2,
      page: 1,
    },
  },
  {
    _links: {
      self: {
        href: "http://swift.swift.com:10090"
          + "/EmployeeSelfServiceBackend/development/knight/public/payroll/direct-deposits?page=1",
      },
      first: {
        href:
          "http://swift.swift.com:10090/EmployeeSelfServiceBackend/development/knight/public/payroll/direct-deposits",
      },
      last: {
        href: "http://swift.swift.com:10090"
          + "/EmployeeSelfServiceBackend/development/knight/public/payroll/direct-deposits?page=1",
      },
    },
    _embedded: {
      direct_deposits: [],
    },
    page_count: 1,
    page_size: 25,
    total_items: 0,
    page: 1,
  },
  {
    data: {
      type: "http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html",
      title: "Bad Request",
      status: 400,
      detail: "Authorization Parameter Missing",
    },
  },
);

export const saveDirectDeposits = new Mock(
  {
    data: {
      _links: {
        self: {
          href: "http://swift.swift.com:10090"
            + "/EmployeeSelfServiceBackend/pre-uat/knight/public/payroll/direct-deposits?page=1",
        },
        first: {
          href: "http://swift.swift.com:10090/EmployeeSelfServiceBackend/pre-uat/knight/public/payroll/direct-deposits",
        },
        last: {
          href: "http://swift.swift.com:10090"
            + "/EmployeeSelfServiceBackend/pre-uat/knight/public/payroll/direct-deposits?page=1",
        },
      },
      _embedded: {
        direct_deposits: [
          {
            employeeId: "440861",
            bankName: "TEST2",
            routingNumber: "122187238",
            accountNumber: "1071864083",
            accountType: "S",
            amount: 15,
            depositType: "P",
          },
          {
            employeeId: "440861",
            bankName: "TEST2",
            routingNumber: "122187238",
            accountNumber: "1071864082",
            accountType: "C",
            amount: 10.22,
            depositType: "F",
          },
          {
            employeeId: "440861",
            bankName: "TEST1",
            routingNumber: "122187238",
            accountNumber: "1071864081",
            accountType: "C",
            amount: 999,
            depositType: "NET",
          },
        ],
      },
      page_count: 1,
      page_size: 25,
      total_items: 3,
      page: 1,
    },
  },
  {
    data: {
      _links: {},
      _embedded: {
        direct_deposits: [],
      },
      page_count: 1,
      page_size: 25,
      total_items: 0,
      page: 1,
    },
  },
  {
    data: {
      type: "http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html",
      title: "Bad Request",
      status: 400,
      detail: "Authorization Parameter Missing",
    },
  },
);
