import axios from "axios";
import { getEnv } from "@/utils/util";

import {
  getEmployeeInfo,
  getRelease,
  ssoToken,
  login,
  getRequestMultiFactorVerificationCode,
  getSubmitMultiFactorVerificationCode,
} from "@/apis/stubs/core";
import { merge as _merge } from "lodash";

const api = axios.create({
  baseURL: getEnv("VUE_APP_CORE_API_URL"),
});

api.defaults.headers.common.Accept = "application/json";

const useStub = getEnv("VUE_APP_STUB_APIS").toLowerCase() === "true";

export default {
  api,
  _fetchEmployeesCancel: null,
  login(username, password) {
    return useStub
      ? login.getResponse()
      : api.post("/activeDirectoryLogin", {
        username,
        password,
        realm: "ESS",
      });
  },
  logout(token) {
    return api.post("/logout", {
      authToken: token,
    });
  },
  getUserInfo(username) {
    return useStub
      ? getEmployeeInfo.getResponse()
      : api.get(`/infinium/employees/${username}`);
  },
  getUserEmployeeFields(username, fields) {
    return useStub
      ? getEmployeeInfo.getResponse()
      : api.get(`/infinium/employees/${username}`, {
        params: { fields },
      });
  },
  getStates() {
    return api.get("/infinium/state-codes", { params: { limit: 100 } });
  },
  getBackendReleaseVersion() {
    return useStub ? getRelease.getResponse() : api.get("releases/current");
  },
  submitSsoToken(token) {
    return useStub
      ? ssoToken.getResponse()
      : api.get(
        `/activeDirectorySSO?token=${token}&from=TRANSMISSION&realm=ESS`,
      );
  },
  requestMultiFactorVerificationCode(requestParams) {
    return useStub
      ? getRequestMultiFactorVerificationCode.getResponse()
      : api.get("/api/multi-factor", { params: requestParams });
  },
  submitMultiFactorVerificationCode(code) {
    return useStub
      ? getSubmitMultiFactorVerificationCode.getResponse()
      : api.post("/api/multi-factor", { code });
  },
  fetchEmployees(params) {
    // allow api call to be cancelled
    if (this._fetchEmployeesCancel?.cancel) {
      this._fetchEmployeesCancel?.cancel("Cancelled");
    }

    this._fetchEmployeesCancel = axios.CancelToken.source();

    return api.get("/infinium/employees", {
      cancelToken: this._fetchEmployeesCancel.token,
      params: _merge(
        {
          sort: "completeName",
          fields: "employeeId,lastName,firstName,level2,positionTitle",
        },
        params,
      ),
    });
  },
};
