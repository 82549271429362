<template>
  <div class="notArizonaInstructions">
    <v-card class="mx-auto my-4">
      <v-toolbar class="toolBar" color="primary" dark @click="shouldDisplay = !shouldDisplay">
        <v-toolbar-title>Instructions</v-toolbar-title>
        <v-spacer />
        <v-btn icon>
          <v-icon v-if="shouldDisplay">mdi-arrow-down-circle-outline</v-icon>
          <v-icon v-else>mdi-arrow-right-circle-outline</v-icon>
        </v-btn>
      </v-toolbar>
      <v-expand-transition>
        <v-container fluid v-show="shouldDisplay" class="instructions">
          <v-row>
            <v-col cols="12" md="6" class="text-justify">
              <p>
                You can find the current tax forms for your state in
                <a v-bind:href="formURL" target="_blank" rel="noopener noreferrer">this site</a>.
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import { getEnv } from "@/utils/util";

export default {
  data() {
    return {
      shouldDisplay: true,
      formURL: getEnv("VUE_APP_NOT_ARIZONA_FORMS"),
    };
  },
};
</script>

<style lang="scss" scoped>
.instructions {
  font-size: 14px;
}
</style>
