<template>
  <div class="deposit-instructions">
    <v-card class="mx-auto my-4">
      <v-toolbar class="toolBar" color="primary" dark @click="toggleDisplay">
        <v-toolbar-title>Direct Deposit</v-toolbar-title>
        <v-spacer />
        <v-btn icon>
          <v-icon v-if="shouldDisplay">mdi-arrow-down-circle-outline</v-icon>
          <v-icon v-else>mdi-arrow-right-circle-outline</v-icon>
        </v-btn>
      </v-toolbar>
      <v-expand-transition>
        <v-container fluid v-show="shouldDisplay">
          <v-row>
            <v-col cols="12" md="6" class="text-justify">
              <p>
                Your current Direct Deposit selections are shown below. Make any changes desired. You may select up to
                four percentage type deductions and up to four flat amount deductions as well as a ‘net’ deposit.
              </p>
              <p>
                Drivers may also select deductions deposited to their fuel card card. You are required to enter the bank
                name and transit number and your personal bank account number. For a sample of where to find this
                information on your bank check see the sample check below.
              </p>
              <p>
                For savings accounts please consult your bank statement or phone your bank. To remove an account, blank
                out all account information, including bank name, transit number, account, type and percentage/dollar
                amount or check box.
              </p>
            </v-col>
            <v-col cols="12" md="6">
              <v-img :src="checkImage" width="70%" class="mx-auto" />
            </v-col>
          </v-row>
        </v-container>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import checkImage from "@/assets/example_check.png";

export default {
  data() {
    return {
      checkImage,
      shouldDisplay: true,
    };
  },
  methods: {
    toggleDisplay() {
      this.shouldDisplay = !this.shouldDisplay;
    },
  },
};
</script>

<style lang="scss" scoped>
.toolBar {
  cursor: pointer;
  user-select: none;
}
</style>
