export default {
  setUserSettings(state, data) {
    state.timeZoneAbbreviation = data?.timezoneAbbr ?? "CDT";
    state.showCalendarLegend = data?.showCalendarLegend ?? false;
    state.sendCalendarInvitesToMe = data?.sendCalendarInvitesToMe ?? false;
    state.sendCalendarInvitesForMyReports =
      data?.sendCalendarInvitesForMyReports ?? false;
  },
  setTimeZones(state, data) {
    state.timeZonesList = data;
  },
  setUserSelectedTimeZone(state, data) {
    state.userSelectedTimeZone = data;
    state.timeZoneAbbreviation = data.timezoneAbbr;
  },
  setIsFetchingData(state, data) {
    state.isFetchingData = data;
  },
  setFetchError(state, data) {
    state.fetchError = data;
  },
  setSendCalendarInvitesToMe(state, data) {
    state.sendCalendarInvitesToMe = data;
  },
  setSendCalendarInvitesForMyReports(state, data) {
    state.sendCalendarInvitesForMyReports = data;
  },
  setIsSubmitting(state, data) {
    state.isSubmitting = data;
  },
  setSubmitError(state, error) {
    state.submitError = error;
  },
  setEmployeeProxies(state, data) {
    state.employeeProxies = data;
  },
  setTotalEmployeeProxies(state, data) {
    state.totalEmployeeProxies = data;
  },
};
