import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const auth = namespace("auth");

@Component
export class authorizationMixin extends Vue {
  @auth.Getter("hasPermission") hasPermission;
  @auth.Getter("hasAnyRole") hasAnyRole;
  @auth.Getter("userId") userId;
  @auth.Getter("firstName") firstName;
  @auth.Getter("lastName") lastName;
  @auth.Getter("isPayroll") isPayroll;
  @auth.Getter("managerProxyAssignments") managerProxyAssignments;

  get canEditOtherEmployeesSchedules() {
    return this.canChangeEmployees();
  }

  get canSubmitRequestForOtherEmployee() {
    return this.canChangeEmployees() || this.isProxy;
  }

  get employeeSearchType() {
    return this.isPayroll ? "infinium" : "timeOff";
  }

  get isProxy() {
    return this.hasAnyRole(["isProxy", "isProxyForManager"]);
  }

  get isProxyForManager() {
    return this.hasAnyRole(["isProxyForManager"]);
  }

  get isManager() {
    return this.hasAnyRole(["isManager", "isSupervisor"]);
  }

  canChangeEmployees() {
    return this.hasPermission(["canChangeEmployees"]);
  }

  hasDirectReports() {
    return this.hasAnyRole(["isManager", "isSupervisor"]);
  }

  canApproveRequests() {
    return this.hasAnyRole([
      "isManager",
      "isSupervisor",
      "isPayroll",
      "isProxyForManager",
    ]);
  }

  canDenyRequests() {
    return this.hasAnyRole([
      "isManager",
      "isSupervisor",
      "isPayroll",
      "isProxyForManager",
    ]);
  }
}
