<template>
  <v-card elevation="2" :loading="isLoading || isSaving">
    <v-card-title :class="cardHeaderClass">
      {{ title }}
    </v-card-title>

    <v-card-text>
      <errorAlert
        :error="employeeScheduleFetchError"
        v-show="employeeScheduleFetchError"
      ></errorAlert>
      <v-row>
        <v-col class="d-flex justify-center flex-wrap">
          <div v-show="noScheduleData">{{ noScheduleMessage }}</div>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="d-flex" cols="12" sm="6">
          <employeeScheduleTable
            v-if="isLoaded"
            transition="fade-transition"
            :schedule="employeeSchedule ? employeeSchedule : undefined"
            :payType="employeeSchedulePayType"
            :editable="true"
            :edit="!employeeSchedule"
            :isSaving="isSaving"
            @submit="saveSchedule"
            ref="employeeSchedule"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-alert width="100%" dense outlined type="success" v-if="isSaved">
          {{ savedMessage }}
        </v-alert>
      </v-row>
    </v-card-text>

    <submitDialog
      :propIsSubmitting="isSaving"
      :propSubmitError="employeeScheduleSaveError"
      @clearErrors="clearErrors"
    />
  </v-card>
</template>

<script>
import { Component, Mixins, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import EmployeeScheduleTable from "@/modules/timeOff/employeeSchedule/components/employeeScheduleTable";
import { replace } from "lodash";
import stylesMixIn from "@/utils/stylesMixin";
import ErrorAlert from "@/components/errorAlert";
import SubmitDialog from "@/components/submitDialog";

const employeeScheduleStore = namespace("timeOff/employeeSchedule");

@Component({
  components: { SubmitDialog, ErrorAlert, EmployeeScheduleTable },
})
export default class EmployeeScheduleCard extends Mixins(stylesMixIn) {
  isLoading = false;
  isLoaded = false;
  isSaving = false;
  isSaved = false;
  noScheduleMessage = "No work schedule is set. Please enter one below.";
  savedMessage = "Schedule successfully saved.";

  @Prop({
    type: String,
    required: true,
  })
  employeeUserId;

  @Prop({
    type: Boolean,
    required: true,
  })
  otherEmployeeSelected;

  @employeeScheduleStore.Getter("employeeSchedule") employeeSchedule;
  @employeeScheduleStore.Getter("employeeScheduleFirstName")
  employeeScheduleFirstName;
  @employeeScheduleStore.Getter("employeeScheduleLastName")
  employeeScheduleLastName;
  @employeeScheduleStore.Getter("employeeSchedulePayType")
  employeeSchedulePayType;
  @employeeScheduleStore.Getter("employeeScheduleFetchError")
  employeeScheduleFetchError;
  @employeeScheduleStore.Getter("employeeScheduleSaveError")
  employeeScheduleSaveError;
  @employeeScheduleStore.Action("fetchEmployeeSchedule") fetchEmployeeSchedule;
  @employeeScheduleStore.Action("patchEmployeeSchedule") patchEmployeeSchedule;
  @employeeScheduleStore.Mutation("setEmployeeScheduleSaveError")
  setEmployeeScheduleSaveError;

  get title() {
    if (this.isLoading) {
      return "Loading schedule for " + this.employeeUserId + ".....";
    }
    if (this.isSaving) {
      return "Saving Schedule";
    }
    if (!this.employeeScheduleFirstName && !this.employeeScheduleLastName) {
      return "Employee Schedule";
    }

    return (
      "Schedule for " +
      this.employeeScheduleFirstName +
      " " +
      this.employeeScheduleLastName
    );
  }

  get noScheduleData() {
    return this.isLoaded && this.employeeSchedule === null;
  }

  clearErrors() {
    this.setEmployeeScheduleSaveError({});
  }

  async created() {
    await this.load();
  }

  async load() {
    this.isLoaded = false;
    this.isLoading = true;

    await this.fetchEmployeeSchedule(this.employeeUserId);

    this.$emit("onLoadingComplete", true);
    this.isLoading = false;
    if (!this.employeeScheduleFetchError) {
      this.isLoaded = true;
    }
  }

  async saveSchedule(schedule) {
    this.isSaved = false;
    this.isSaving = true;

    await this.patchEmployeeSchedule({
      employeeIdentifier: this.employeeUserId,
      schedule: schedule,
    });

    this.isSaving = false;
    if (Object.keys(this.employeeScheduleSaveError).length === 0) {
      this.isSaved = true;
      // reload
      this.isLoaded = false;

      // reset url
      const currentPath = this.$router.currentRoute.path;
      if (currentPath.includes("my-schedule")) {
        const path = replace(currentPath, /\/edit$/, "");
        await this.$router.push(path);
      }

      await this.$nextTick();
      this.isLoaded = true;

      window.setTimeout(() => {
        this.$emit("scheduleSaved");
      }, 500);

      window.setTimeout(() => {
        this.isSaved = false;
      }, 3000);
    }
  }

  async saveScheduleModal(schedule) {
    await this.saveSchedule(schedule);
    this.$refs.employeeSchedule.isEditing = false;
    if (!this.employeeScheduleSaveError) {
      setTimeout(() => this.$emit("close"), 1500);
    }
  }
}
</script>
