export default {
  payload(state) {
    return {
      electionOption: state.electWithhold ? "opt3" : "opt1",
      percentageElection:
        state.percentageElection === ""
          ? "0"
          : state.percentageElection.replace("%", ""),
      additionalAmount: state.additionalAmount,
      electronicConsent: state.electronicConsent ? "Y" : "N",
    };
  },
};
