import state from "./state";
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";
import myInformation from "./myInformation";
import emergencyContacts from "./emergencyContacts";
import education from "./education";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules: {
    myInformation,
    emergencyContacts,
    education,
  },
};
