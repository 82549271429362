<template>
  <div>
    <div class="fieldLabel">{{ label }}</div>
    <p>
      {{ value }}
    </p>
  </div>
</template>

<script>
export default {
  name: "displayField",
  props: {
    label: { type: String, required: true },
    value: { required: true },
  },
};
</script>

<style scoped>
.fieldLabel {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.38);
}
</style>
