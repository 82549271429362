<template>
  <v-card elevation="2" :loading="isLoading ? 'grey lighten-2' : null">
    <v-card-title class="headline white--text primary font-weight-light">
      {{ title }}
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <error-alert
            :error="requestQueueFetchError"
            v-show="requestQueueFetchError"
          />

          <requestQueueTable :statusCode="statusCode" v-show="!isLoading" />
        </v-col>
      </v-row>
    </v-card-text>
    <successDialog
      v-if="messageDialog.title === null"
      :message="messageDialog.detail"
      :show="messageDialog.show"
      title="Success"
      @close="closeDialog"
    />
    <ErrorDialog
      v-if="messageDialog.title !== null"
      :error="messageDialog"
      :model="messageDialog.show"
      @dialogClosed="closeDialog"
    ></ErrorDialog>
  </v-card>
</template>

<script>
import { Component, Mixins, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import requestQueueTable from "@/modules/timeOff/requestQueues/components/requestQueueTable";
import modulePageMixin from "@/utils/mixins";
import ErrorAlert from "@/components/errorAlert";
import successDialog from "@/components/successDialog.vue";
import ErrorDialog from "@/components/errorDialog.vue";

const requestQueuesStore = namespace("timeOff/requestQueues");

@Component({
  components: {
    ErrorDialog,
    successDialog,
    ErrorAlert,
    requestQueueTable,
  },
  provide() {
    return { showDialog: this.showDialog };
  },
})
export default class requestQueueCard extends Mixins(modulePageMixin) {
  isLoading = false;
  messageDialog = {
    show: false,
    title: "",
  };

  @Prop({
    type: String,
    required: true,
  })
  statusCode;

  @requestQueuesStore.Getter("requestQueue") requestQueue;
  @requestQueuesStore.Getter("requestQueueFetchError") requestQueueFetchError;
  @requestQueuesStore.Action("fetchRequestQueue") fetchRequestQueue;
  @requestQueuesStore.Mutation("setRequestQueueFetchError")
  setRequestQueueFetchError;

  get title() {
    if (this.isLoading) {
      return "Loading Queue.....";
    }

    if (this.requestQueueFetchError) {
      return "Failed Loading Queue";
    }

    return this.$route.meta?.title ?? "Request Queue";
  }

  showDialog(message, title) {
    this.messageDialog = {
      show: true,
      detail: message,
      title: title,
    };
  }

  closeDialog() {
    this.messageDialog = {
      show: false,
      detail: "",
      title: null,
    };
  }

  beforeDestroy() {
    this.setRequestQueueFetchError(null);
  }
}
</script>

<style scoped></style>
