<template>
  <div class="list-table-div">
    <errorAlert :error="fetchError" v-show="fetchError"></errorAlert>
    <v-data-table
      class="elevation-1"
      :loading="isFetchingData"
      loading-text="Loading Users..."
      no-data-text="No Users"
      :headers="tableHeaders"
      :footer-props="tableFooter"
      :items="adminUsers"
      :server-items-length="totalAdminUsers"
      :options.sync="options"
      item-key="id"
    >
      <template v-slot:header>
        <tr class="grey lighten-3 table-filter-row">
          <th v-for="header in tableHeaders" :key="header.text"></th>
        </tr>
      </template>

      <template v-slot:[`item.employeeId`]="{ item }">
        {{ item.employerId }} - {{ item.employeeId }}
      </template>
      <template v-slot:[`item.employeeLastName`]="{ item }">
        {{ formatName(item) }}
      </template>
      <template v-slot:[`item.supervisorName`]="{ item }">
        {{ formatName(item) }}
      </template>
      <template v-slot:[`item.proxyName`]="{ item }">
        {{ formatProxyName(item) }}
      </template>
      <template v-slot:[`item.isEnabled`]="{ item }">
        <div class="d-flex justify-center">
          <v-switch
            v-model="item.isEnabled"
            inset
            @change="updateActiveStatus(item)"
          ></v-switch>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn text @click="removeUser(item)">
          <v-icon class="pl-2 pa-2">mdi-account-remove</v-icon>
          Remove
        </v-btn>
      </template>
    </v-data-table>

    <deleteDialog
      persistent
      :showDialog="deleteConfirmation"
      :message="deleteMessage"
      @deleteConfirmed="removeUserConfirmed"
      @closeDialog="deleteConfirmation = false"
    ></deleteDialog>
  </div>
</template>

<script>
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import rulesMixin from "@/modules/timeOff/rulesMixin";
import timeOffMixin from "@/modules/timeOff/timeOffMixin";
import { namespace } from "vuex-class";
import deleteDialog from "@/components/deleteDialog";
import errorAlert from "@/components/errorAlert";

const timeOffStore = namespace("timeOff");
const adminStore = namespace("timeOff/admin");

@Component({
  name: "AdminListView",
  components: { deleteDialog, errorAlert },
  watch: {
    searchCriteria() {
      this.options.page = 1;
      this.refresh();
    },
  },
})
export default class AdminListView extends Mixins(rulesMixin, timeOffMixin) {
  // State
  @timeOffStore.Getter("config") config;
  @adminStore.Action("fetchAdminUsers") fetchAdminUsers;
  @adminStore.Action("removeAdminUser") removeAdminUser;
  @adminStore.Action("updateAdminUser") updateAdminUser;
  @adminStore.Action("removeSupervisorProxy") removeSupervisorProxy;
  @adminStore.Action("updateSupervisorProxy") updateSupervisorProxy;
  @adminStore.Action("resetListViewOptions") resetListViewOptions;
  @adminStore.Getter("adminUsers") adminUsers;
  @adminStore.Getter("isFetchingData") isFetchingData;
  @adminStore.Getter("fetchError") fetchError;
  @adminStore.Getter("submitError") submitError;
  @adminStore.Getter("listViewOptions") listViewOptions;
  @adminStore.Getter("progressMessage") progressMessage;
  @adminStore.Getter("successMessage") successMessage;
  @adminStore.Getter("totalAdminUsers") totalAdminUsers;
  @adminStore.Mutation("setListViewOptions") setListViewOptions;
  @adminStore.Mutation("setTotalAdminUsers") setTotalAdminUsers;
  @adminStore.Mutation("setAdminUsers") setAdminUsers;
  @adminStore.Mutation("setProgressMessage") setProgressMessage;
  @adminStore.Mutation("setSuccessMessage") setSuccessMessage;

  data() {
    return {
      isLoading: false,
      actions: ["remove"],
      tableFooter: {
        "items-per-page-options": [10, 25],
        "show-current-page": true,
        "show-first-last-page": true,
      },
      search: "",
      sort: "",
      statusCriteriaString: "",
      deleteConfirmation: false,
      userToDelete: null,
      deleteMessage: "",
      sortTranslations: {
        employeeName: "employeeLastName",
      },
    };
  }

  viewModes = {
    default: {
      tableHeaders: [
        {
          text: "Employee Id",
          align: "center",
          value: "employeeId",
          sortable: true,
          width: "10%",
        },
        {
          text: "Employee",
          align: "center",
          value: "employeeLastName",
          sortable: true,
        },
        {
          text: "Active",
          align: "center",
          value: "isEnabled",
          sortable: true,
          width: "20%",
        },
        {
          text: "Action",
          align: "center",
          value: "actions",
          width: "10%",
          sortable: false,
        },
      ],
    },
    proxies: {
      tableHeaders: [
        {
          text: "Supervisor",
          align: "center",
          value: "supervisorName",
          sortable: true,
        },
        {
          text: "Proxy",
          align: "center",
          value: "proxyName",
          sortable: true,
        },
        {
          text: "Active",
          align: "center",
          value: "isEnabled",
          sortable: true,
          width: "20%",
        },
        {
          text: "Action",
          align: "center",
          value: "actions",
          width: "10%",
          sortable: false,
        },
      ],
    },
  };

  @Prop({
    type: String,
    default: "",
  })
  searchCriteria;

  @Prop({
    type: String,
    default: "default",
  })
  tableStyle;

  @Prop({
    required: true,
    type: String,
    default: "payrollAdmins",
  })
  userType;

  @Watch("options", { deep: true })
  onOptionsChange() {
    if (Object.keys(this.options).length !== 0) {
      //set the format for the endpoint like: lastName:desc
      if (this.options.sortBy[0]) {
        this.sort = this.options.sortBy[0];
        if (this.sort === "supervisorName") {
          this.sort = "employeeLastName";
        }
        if (this.sort === "proxyName") {
          this.sort = "proxyLastName";
        }
        if (this.options.sortDesc[0]) this.sort = this.sort + ":desc";
      } else {
        this.sort = "createTimestamp:desc";
      }
    }

    this.refresh();
  }

  get tableHeaders() {
    return this.viewModes[this.tableStyle].tableHeaders;
  }

  get options() {
    return this.listViewOptions;
  }
  set options(data) {
    this.setListViewOptions(data);
  }

  formatName(user) {
    if (
      this.tableStyle === this.config("adminListUserTypes").supervisorProxies
    ) {
      return `${user.employeeLastName.trim()}, ${user.employeeFirstName.trim()} (${
        user.employerId
      } - ${user.employeeId.trim()})`;
    }

    return `${user.employeeLastName.trim()}, ${user.employeeFirstName.trim()}`;
  }

  formatProxyName(user) {
    return `${user.proxyLastName.trim()}, ${user.proxyFirstName.trim()} (${
      user.proxyEmployerId
    } - ${user.proxyEmployeeId.trim()})`;
  }

  removeUser(user) {
    let message;
    if (
      this.tableStyle === this.config("adminListUserTypes").supervisorProxies
    ) {
      message = `${user.proxyFirstName} ${user.proxyLastName} (${user.proxyEmployerId}-${user.proxyEmployeeId})
      as a proxy for ${user.employeeFirstName} ${user.employeeLastName} (${user.employerId}-${user.employeeId})`;
    } else {
      message = `(${user.employerId}-${user.employeeId})
      ${user.employeeFirstName} ${user.employeeLastName}`;
    }

    this.deleteMessage = `Are you sure you want to delete ${message}?`;
    this.setProgressMessage(`Deleting ${message}`);
    this.userToDelete = user;
    this.deleteConfirmation = true;
  }

  async removeUserConfirmed() {
    this.deleteConfirmation = false;

    if (
      this.tableStyle === this.config("adminListUserTypes").supervisorProxies
    ) {
      await this.removeSupervisorProxy({
        payload: {
          employerId: this.userToDelete.employerId,
          employeeId: this.userToDelete.employeeId,
          proxyEmployerId: this.userToDelete.proxyEmployerId,
          proxyEmployeeId: this.userToDelete.proxyEmployeeId,
        },
      });
    } else {
      await this.removeAdminUser({
        userType: this.userType,
        userId: this.userToDelete.employeeUserId,
      });
    }

    if (Object.keys(this.submitError).length === 0) {
      this.options.page = 1;
      this.refresh();

      this.$emit("userRemoved", this.userToDelete);
    }
  }

  async updateActiveStatus(user) {
    if (
      this.tableStyle === this.config("adminListUserTypes").supervisorProxies
    ) {
      await this.updateSupervisorProxy({
        payload: {
          isEnabled: user.isEnabled,
          employerId: user.employerId,
          employeeId: user.employeeId,
          proxyEmployerId: user.proxyEmployerId,
          proxyEmployeeId: user.proxyEmployeeId,
        },
      });

      this.refresh();

      return;
    }

    await this.updateAdminUser({
      userType: this.userType,
      userId: user.employeeUserId.trim(),
      payload: {
        isEnabled: user.isEnabled,
      },
    });

    this.refresh();
  }

  refresh() {
    this.clearRequests();
    clearTimeout(this._searchTimerId);
    this._searchTimerId = setTimeout(() => {
      this.loadAdminUsers();
    }, 500);
  }

  clearRequests() {
    this.setAdminUsers([]);
    this.setTotalAdminUsers(0);
  }

  @Watch("userType")
  userTypeChanged() {
    this.resetListViewOptions();
    this.clearRequests();
    if (this.searchCriteria === "") {
      this.refresh();
    }
  }

  async loadAdminUsers() {
    this.isLoading = true;
    if (this.config("adminListUserTypes")[this.userType] === undefined) {
      alert(`Invalid user type: ${this.userType}`);
    }

    let params = {
      limit: this.options.itemsPerPage,
      page: this.options.page,
      sort: this.sort,
    };

    if (this.searchCriteria !== "") {
      if (
        this.tableStyle === this.config("adminListUserTypes").supervisorProxies
      ) {
        params[
          "employeeId||employeeLastName||employeeFirstName||proxyEmployeeId||proxyLastName||proxyFirstName"
        ] = this.searchCriteria;
      } else {
        params["employeeId||employeeLastName||employeeFirstName"] =
          this.searchCriteria;
      }
    }

    let userType = this.userType;

    if (
      this.tableStyle === this.config("adminListUserTypes").supervisorProxies
    ) {
      userType = "supervisorProxies";
    }

    await this.fetchAdminUsers({
      userType: userType,
      params: params,
    });

    this.isLoading = false;
  }

  destroyed() {
    this.resetListViewOptions();
  }
}
</script>
