<template>
  <v-container class="new-request">
    <navBar @submit="submitRequest" :show-submit="true" />

    <v-row
      v-if="canSubmitRequestForOtherEmployee"
      class="schedule-controls-row"
    >
      <v-col>
        <v-btn @click="changeEmployee" text color="primary">
          <v-icon left> mdi-account </v-icon>
          Change Employee
        </v-btn>
      </v-col>
      <v-col class="text-right">
        <v-btn
          @click="changeToMyRequest"
          text
          color="primary"
          v-if="otherEmployeeSelected"
        >
          <v-icon left> mdi-keyboard-return </v-icon>
          Return to my request
        </v-btn>
      </v-col>
    </v-row>

    <requestForm
      :key="employeeUserId"
      :employeeUserId="employeeUserId"
      :otherEmployeeSelected="otherEmployeeSelected"
      ref="RequestForm"
    />

    <submitDialog
      :propIsSubmitting="isSubmitting"
      :propSubmitError="submitError"
      @clearErrors="clearErrors"
    />

    <successDialog
      :message="successDialog.message"
      :show="successDialog.show"
      @close="closeSuccessDialog"
    />

    <employeeDataTableSelectDialog
      v-if="canSubmitRequestForOtherEmployee"
      :searchType="employeeSearchType"
      :title="employeeSelect.title"
      :show="employeeSelect.show"
      :currentSelectedEmployee="currentSelectedEmployee"
      @close="closeEmployeeSelect"
      @employeeSelected="employeeSelected"
      :hasReports="isManager"
      :hasProxyAssignments="isProxy"
      :hasManagerProxyReports="isProxyForManager"
      :managerProxyAssignments="managerProxyAssignments"
    />
  </v-container>
</template>

<script>
import _ from "lodash";
import modulePageMixin from "@/utils/mixins";
import navBar from "./components/requestFormNavBar";
import requestForm from "./components/requestForm";
import { Component, Mixins } from "vue-property-decorator";
import submitDialog from "@/components/submitDialog";
import successDialog from "@/components/successDialog";
import { namespace } from "vuex-class";
import { authorizationMixin } from "@/modules/timeOff/authorizationMixin";
import employeeDataTableSelectDialog from "@/modules/timeOff/components/employeeDataTableSelectDialog";

const requestFormStore = namespace("timeOff/requestForm");

@Component({
  components: {
    successDialog,
    submitDialog,
    requestForm,
    navBar,
    employeeDataTableSelectDialog,
  },
})
export default class NewRequest extends Mixins(
  modulePageMixin,
  authorizationMixin
) {
  successDialog = {
    message: "",
    show: false,
  };

  // current employee user - can be logged in user, or chosen from dropdown
  employeeUserId = null;
  employeeSelect = {
    title: "Select Employee",
    show: false,
  };

  @requestFormStore.Getter("requestSubmitError") requestSubmitError;
  @requestFormStore.Getter("isSubmitting") isSubmitting;
  @requestFormStore.Getter("successMessage") successMessage;
  @requestFormStore.Mutation("setIsSubmitting") setIsSubmitting;
  @requestFormStore.Mutation("setRequestSubmitError") setRequestSubmitError;
  @requestFormStore.Mutation("resetRequest") resetRequest;

  get submitError() {
    return this.requestSubmitError;
  }

  get isSubmitError() {
    return Object.keys(this.requestSubmitError).length !== 0;
  }

  get otherEmployeeSelected() {
    return this.currentUserId !== this.employeeUserId.trim();
  }

  get currentSelectedEmployee() {
    if (this.otherEmployeeSelected) {
      return this.employeeUserId.trim();
    }
    return "";
  }

  changeEmployee() {
    this.employeeSelect.show = true;
  }

  closeEmployeeSelect() {
    this.employeeSelect.show = false;
  }

  employeeSelected(employeeUserId) {
    this.updateUrlQuery({ user: employeeUserId.trim() });
    this.employeeUserId = employeeUserId.trim();
    this.closeEmployeeSelect();
  }

  changeToMyRequest() {
    this.employeeUserId = this.currentUserId;
    this.closeEmployeeSelect();
  }

  clearErrors() {
    this.setRequestSubmitError({});
  }
  showSuccessDialog(action) {
    if (_.isEmpty(this.submitError) === false) return;

    this.successDialog.message = this.successMessage(action);
    this.successDialog.show = true;
  }

  closeSuccessDialog() {
    this.successDialog.show = false;
    if (!this.otherEmployeeSelected) {
      return this.$router.push("/timeoff/my-requests");
    }
    this.resetRequestForm();
  }

  async resetRequestForm() {
    this.resetRequest();
    this.employeeUserId = this.currentUserId;
  }

  async submitRequest() {
    await this.$refs.RequestForm.validate();

    if (this.$refs.RequestForm.valid) {
      await this.$refs.RequestForm.submitForm();
      this.showSuccessDialog("submit");
    }
  }

  created() {
    this.employeeUserId = this.$route.query?.user ?? this.currentUserId;
  }
}
</script>
