<template>
  <div class="myInformation">
    <pageManager :isLoading="isLoading" :isEmpty="false" :error="error">
      <template #loading>
        <div class="loader">
          <v-progress-circular indeterminate color="accent" />
        </div>
      </template>
      <v-form v-model="valid" ref="form">
        <v-card class="mx-auto my-4">
          <v-toolbar class="toolBar" color="primary" dark>
            <v-toolbar-title>My Information</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-expand-transition>
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="12" class="fullName">
                  {{ fullName }}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" class="text-justify">
                  <address-fieldset
                    :header="{ text: 'Resident Address' }"
                    :street="street"
                    :street2="street2"
                    :city="city"
                    :state="state"
                    :zip="zip"
                    :phoneNumber="phone"
                    @change="residenceAddressChanged"
                    :cannotEditSection="false"
                    autocomplete="street-address"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4" class="text-justify">
                  <v-autocomplete
                    class="ml-1"
                    :value="maritalStatus"
                    :items="maritalCodes"
                    item-value="code"
                    item-text="description"
                    label="Marital Status"
                    clearable
                    :disabled="isSubmitting"
                    :loading="isLoading"
                    @change="setMaritalStatus"
                    :rules="[required]"
                  />
                </v-col>
                <v-col cols="12" md="4" class="text-justify">
                  <v-text-field
                    ref="companyEmailAddress"
                    label="Work Email"
                    disabled
                    :value="companyEmailAddress"
                  />
                </v-col>
                <v-col cols="12" md="4" class="text-justify">
                  <v-text-field
                    ref="personalEmailAddress"
                    label="Personal Email"
                    :rules="[validEmail]"
                    :value="personalEmailAddress"
                    @change="(val) => setPersonalEmail(val)"
                    clearable
                  />
                </v-col>
                <v-col cols="12" md="4" class="text-justify">
                  <v-text-field
                    ref="cellPhone"
                    label="Cell Phone"
                    v-mask="'(###) ###-####'"
                    :value="cellNumber"
                    :rules="[isPhoneNumber]"
                    @change="(val) => setCellNumber(val)"
                    validate-on-blur
                    clearable
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-expand-transition>
        </v-card>
        <v-card class="mx-auto my-4">
          <v-toolbar class="toolBar" color="primary" dark>
            <v-toolbar-title>
              Mailing Address
              <span class="grey--text"> (Optional) </span>
            </v-toolbar-title>
            <v-spacer />
            <v-switch
              class="sameSwitch"
              color="red"
              :input-value="wantsMailingAddress"
              label="Add Mailing Address"
              @change="toggleMailingAddress"
            />
          </v-toolbar>
          <v-expand-transition>
            <v-container fluid v-show="wantsMailingAddress">
              <div>
                <v-switch
                  class="sameSwitch"
                  color="red"
                  :input-value="sameAddress"
                  label="Same as Resident Address"
                  @change="toggleSameAddress"
                />
                <v-expand-transition>
                  <address-fieldset
                    v-show="!sameAddress && wantsMailingAddress"
                    :street="mailingStreet"
                    :street2="mailingStreet2"
                    :city="mailingCity"
                    :state="mailingState"
                    :zip="mailingZip"
                    :validateFieldSet="wantsMailingAddress"
                    @change="setMailingAddress"
                    :cannotEditSection="false"
                    autocomplete="street-address"
                  />
                </v-expand-transition>
              </div>
            </v-container>
          </v-expand-transition>
        </v-card>
      </v-form>
      <submitDialog
        :propIsSubmitting="isSubmitting"
        :propSubmitError="submitError"
        @clearErrors="clearErrors"
      />
      <infoDialog
        v-if="showInfoDialog"
        :propInfo="savingInfo"
        @clearInfo="clearInfo"
      />
      <div class="text-right">
        <v-btn
          class="px-8 submit-button"
          color="accent"
          @click="submit"
          tile
          :loading="isSubmitting"
          :disabled="isSubmitting"
        >
          <v-icon class="mr-2">mdi-send</v-icon>
          Submit
        </v-btn>
      </div>
      <div>
        <v-dialog v-model="mailingAddressDialog" width="unset" persistent>
          <v-card>
            <v-card-title class="headline grey lighten-2">
              User Notice:
            </v-card-title>
            <v-card-text>
              <div class="text-md-justify mt-5">
                I acknowledge entering a mailing address will send pay
                statements to that address
              </div>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn color="red" text @click="removeMailingAddress">
                Cancel
              </v-btn>
              <v-btn color="red" text @click="addMailingAddress">
                Accept
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <div>
        <v-dialog v-model="changeMailingAddressDialog" width="unset" persistent>
          <v-card>
            <v-card-title class="headline grey lighten-2">
              Change Your Mailing Address?
            </v-card-title>
            <v-card-text>
              <div class="text-md-justify mt-5">
                You have selected to have a mailing address the same as your
                street address. Do you want to change your mailing address as
                well?
              </div>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-row>
                <v-col class="text-left" cols="12" md="6">
                  <v-btn
                    color="red"
                    text
                    @click="makeMailingAddressSameAsNewAddress"
                  >
                    Yes, set my mailing address the same
                  </v-btn>
                </v-col>
                <v-col class="text-right" cols="12" md="6">
                  <v-btn color="red" text @click="enterNewMailingAddress">
                    No, I will enter a different address
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </pageManager>
  </div>
</template>

<script>
import addressFieldset from "@/components/address-fieldset.vue";
import submitDialog from "@/components/submitDialog.vue";
import infoDialog from "@/components/info-dialog.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import _ from "lodash";
import { mask } from "vue-the-mask";
import pageManager from "@/components/pageManager.vue";
import validators from "@/utils/validators";

export default {
  directives: {
    mask,
  },
  components: {
    addressFieldset,
    submitDialog,
    infoDialog,
    pageManager,
  },
  page: {
    title: "My Information",
    meta: [{ name: "description", content: "Edit personal information." }],
  },
  data() {
    return {
      valid: false,
      forceSameAddress: false,
      mailingAddressDialog: false,
      showInfoDialog: false,
      changeMailingAddressDialog: false,
    };
  },
  computed: {
    ...mapState("auth", ["userInfo"]),
    ...mapState("personalInfo/myInformation", [
      "isLoading",
      "isSubmitting",
      "error",
      "submitError",
      "savingInfo",
      "maritalCodes",
      "personalInfo",
      "wantsMailingAddress",
      "sameAddress",
    ]),
    fullName() {
      return this.userInfo?.checkName ?? "";
    },
    street() {
      return this.personalInfo?.residentStreetAddress?.trim() ?? "";
    },
    street2() {
      return this.personalInfo?.residentSuite?.trim() ?? "";
    },
    city() {
      return this.personalInfo?.residentCity?.trim() ?? "";
    },
    state() {
      return {
        code: this.personalInfo?.residentState?.trim() ?? "",
        description: this.personalInfo?.residentState?.trim() ?? "",
      };
    },
    zip() {
      return this.personalInfo?.residentZipCode?.trim() ?? "";
    },
    phone() {
      return this.personalInfo?.phoneNumber?.trim() ?? "";
    },
    companyEmailAddress() {
      return this.personalInfo?.companyEmailAddress?.trim() ?? "";
    },
    personalEmailAddress() {
      return this.personalInfo?.personalEmailAddress?.trim() ?? "";
    },
    cellNumber() {
      return this.personalInfo?.cellNumber?.trim() ?? "";
    },
    maritalStatus() {
      return this.personalInfo?.maritalStatus?.trim() ?? "";
    },
    mailingStreet() {
      return this.personalInfo?.mailingStreetAddress ?? "";
    },
    mailingStreet2() {
      return this.personalInfo?.mailingSuite?.trim() ?? "";
    },
    mailingCity() {
      return this.personalInfo?.mailingCity?.trim() ?? "";
    },
    mailingState() {
      return {
        code: this.personalInfo?.mailingState?.trim() ?? "",
        description: this.personalInfo?.mailingState?.trim() ?? "",
      };
    },
    mailingZip() {
      return this.personalInfo?.mailingZipCode?.trim() ?? "";
    },
  },
  methods: {
    ...mapActions("personalInfo/myInformation", [
      "setResidenceAddress",
      "setMailingAddress",
    ]),
    ...mapMutations("personalInfo/myInformation", [
      "setMaritalStatus",
      "setResidentPhoneNumber",
      "setSameAddress",
      "setSubmitError",
      "setWantsMailingAddress",
      "setPersonalEmailAddress",
      "setCellPhoneNumber",
    ]),
    async fetchData() {
      await this.$store.dispatch("personalInfo/myInformation/fetchData");
    },
    isPhoneNumber(val) {
      return val.trim().length === 0
        ? true
        : val.replace(/\D/g, "").length === 10
            || "Please provide a valid phone number";
    },
    setPersonalEmail(val) {
      this.setPersonalEmailAddress(val);
    },
    setCellNumber(val) {
      this.setCellPhoneNumber(val);
    },
    addMailingAddress() {
      this.setWantsMailingAddress(true);
      this.mailingAddressDialog = false;
    },
    removeMailingAddress() {
      this.setWantsMailingAddress(false);
      this.mailingAddressDialog = false;
    },
    toggleSameAddress(val) {
      this.forceSameAddress = val;

      if (val === true) {
        this.setMailingSameAsStreetAddress();
      }

      this.setSameAddress(val);
    },
    toggleMailingAddress(val) {
      this.setWantsMailingAddress(val);
      if (val) {
        this.mailingAddressDialog = true;
      }
    },
    required(val) {
      return validators.required(val) || "This field is required";
    },
    validEmail(val) {
      if (!val || val.trim().length === 0) {
        return true;
      }

      return validators.email(val) || "Email must be in a valid format";
    },
    clearErrors() {
      this.setSubmitError({});
    },
    clearInfo() {
      this.showInfoDialog = false;
      // return to home
      this.$router.push("/");
    },
    validate() {
      this.$refs.form.validate();
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        this.$toasted.error("Please fix errors prior to submitting.");
      } else {
        await this.$store.dispatch(
          "personalInfo/myInformation/submitPersonalInformation",
        );

        if (!_.isEmpty(this.savingInfo)) {
          this.showInfoDialog = true;
        }

        if (
          Object.keys(this.submitError).length === 0
          && _.isEmpty(this.savingInfo)
        ) {
          this.$toasted.success("Form was Successfully Saved!", {
            duration: 2000,
          });
          this.forceSameAddress = false;
        }
      }
    },
    residenceAddressChanged(address) {
      this.setResidenceAddress(address);

      if (
        this.wantsMailingAddress
        && this.sameAddress
        && !this.forceSameAddress
      ) {
        this.changeMailingAddressDialog = true;

        return;
      }

      if (this.wantsMailingAddress && this.forceSameAddress) {
        this.setMailingSameAsStreetAddress();
      }

      this.setSameAddress(this.mailingSameAsStreetAddress());
    },
    makeMailingAddressSameAsNewAddress() {
      this.changeMailingAddressDialog = false;

      // enforce toggle - sets up for duplicating street address
      this.toggleSameAddress(true);
    },
    enterNewMailingAddress() {
      this.changeMailingAddressDialog = false;

      this.toggleSameAddress(false);

      this.clearMailingAddress();
    },
    mailingSameAsStreetAddress() {
      return (
        this.street.toUpperCase() === this.mailingStreet.toUpperCase()
        && this.city.toUpperCase() === this.mailingCity.toUpperCase()
        && this.state.code.toUpperCase()
          === this.mailingState.code.toUpperCase()
        && this.zip.toUpperCase() === this.mailingZip.toUpperCase()
      );
    },
    clearMailingAddress() {
      this.setMailingAddress({
        zip: "",
        state: {
          code: "",
        },
        city: "",
        street2: "",
        street: "",
      });
    },
    setMailingSameAsStreetAddress() {
      this.setMailingAddress({
        zip: this.zip,
        state: this.state,
        city: this.city,
        street2: this.street2,
        street: this.street,
      });
    },
  },
  async mounted() {
    await this.fetchData();
  },
};
</script>

<style lang="scss" scoped>
.sameSwitch {
  margin-top: 17px;
}
.sameAddress {
  font-size: 15px;

  font-style: italic;
}
fieldset {
  padding: 12px;
  border: 1px dashed #dedede;
  transition: all 200ms;
  margin-bottom: 7px;

  legend {
    color: #bcbcbc;
  }

  &:focus-within {
    background-color: #dedede;
    border-color: black;

    legend {
      color: black;
    }
  }
}
.submit-button {
  margin-right: 0;
  margin-top: 10px;
}

.fullName {
  font-size: 25px;
  color: grey;
}
</style>
