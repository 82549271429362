<template>
  <v-container>
    <v-card elevation="2" :loading="isLoading ? 'grey lighten-2' : null">
      <v-card-title class="headline white--text primary font-weight-light">
        {{ title }}
      </v-card-title>

      <v-card-text>
        <v-sheet class="search">
          <requests-search @onReloadResults="onReloadResults"></requests-search>
        </v-sheet>

        <v-card flat>
          <v-tabs
            @change="onChangeTab"
            v-model="tab"
            centered
            color="black"
            background-color="grey lighten-2"
          >
            <v-tab v-for="tabItem in tabs" :key="tabItem.tab">
              {{ tabItem.tab }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <keep-alive>
                <component ref="ListComponent" :is="listTab"></component>
              </keep-alive>
            </v-tab-item>
            <v-tab-item>
              <keep-alive>
                <component
                  ref="CalendarComponent"
                  :is="calendarTab"
                  :searchCriteria="searchCriteria"
                  :reportsCriteria="reportsCriteria"
                  :proxyCriteria="proxyCriteria"
                ></component>
              </keep-alive>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Component from "vue-class-component";
import { Mixins } from "vue-property-decorator";
import modulePageMixin from "@/utils/mixins";
import timeOffMixin from "@/modules/timeOff/timeOffMixin";
import RequestsSearch from "@/modules/timeOff/requests/components/requestsSearch";
import RequestsCalendar from "@/modules/timeOff/requests/components/requestsCalendar";
import RequestsList from "@/modules/timeOff/requests/components/requestsList";
import { namespace } from "vuex-class";

const requestsSearchStore = namespace("timeOff/requestsSearch");

@Component({
  name: "ManagerEmployeeRequests",
  components: {
    RequestsSearch,
    RequestsCalendar,
    RequestsList,
  },
})
export default class ManagerEmployeeRequests extends Mixins(
  modulePageMixin,
  timeOffMixin
) {
  @requestsSearchStore.Getter("getSearchCriteria") searchCriteria;
  @requestsSearchStore.Mutation("setSearchCriteria") setSearchCriteria;
  @requestsSearchStore.Getter("getReportsCriteria") reportsCriteria;
  @requestsSearchStore.Mutation("setReportsCriteria") setReportsCriteria;
  @requestsSearchStore.Getter("getProxyCriteria") proxyCriteria;
  @requestsSearchStore.Mutation("setProxyCriteria") setProxyCriteria;

  title = "My Employee Requests";
  isLoading = false;
  tab = null;
  tabs = [
    { tab: "List", component: "requestsList" },
    { tab: "Calendar", component: "requestsCalendar" },
  ];

  onReloadResults() {
    this.tabs.forEach((tab) => {
      const refStr = tab.tab + "Component";
      if (this.$refs[refStr]?.reload) {
        this.$refs[refStr].reload();
      }
    });
  }

  onChangeTab() {
    this.updateUrlQuery({ tab: this.tab });
  }

  get listTab() {
    return this.loadComponent("requestsList", "timeOff/requests");
  }

  get calendarTab() {
    return this.loadComponent("requestsCalendar", "timeOff/requests");
  }

  created() {
    this.tab = parseInt(this.$route.query?.tab ?? 0);
    this.updateUrlQuery({ tab: this.tab });
  }
}
</script>

<style scoped>
.search {
  margin-bottom: 20px;
}
.v-tab--active {
  background-color: #c9c6c6;
}
</style>
