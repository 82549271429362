import timeOffApi from "../../api/timeOff";
import { getApiError } from "@/utils/errorUtils";

export default {
  async fetchEmployeeSchedule({ commit }, employeeIdentifier) {
    commit("resetEmployeeSchedule");
    commit("setEmployeeScheduleUserId", employeeIdentifier);
    try {
      const { data } = await timeOffApi.fetchEmployeeSchedule(
        employeeIdentifier.trim()
      );
      commit("setEmployeeScheduleFromAPI", data);
    } catch (error) {
      commit("setEmployeeScheduleFetchError", getApiError(error));
    }
  },
  async patchEmployeeSchedule({ commit }, { employeeIdentifier, schedule }) {
    commit("setEmployeeScheduleSaveError", {});

    try {
      await timeOffApi.patchEmployeeSchedule(
        employeeIdentifier.trim(),
        schedule
      );
      // commit the schedule changes we have passed here
      commit("setEmployeeSchedule", schedule);
    } catch (error) {
      commit("setEmployeeScheduleSaveError", getApiError(error));
    }
  },
  async fetchEmployeeScheduleChanges({ commit }, { params }) {
    const { data } = await timeOffApi.fetchScheduleChanges(params);

    commit("setEmployeeScheduleChanges", data._embedded.scheduleChanges);
  },
};
