<template>
  <v-card class="calendar-view" :loading="isLoading" flat>
    <v-sheet class="calendar-controls">
      <v-toolbar flat centered>
        <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
          Today
        </v-btn>
        <v-btn
          :disabled="range.past"
          fab
          text
          small
          color="grey darken-2"
          @click="move('skipPrev')"
        >
          <v-icon small> mdi-chevron-double-left</v-icon>
        </v-btn>
        <v-btn
          :disabled="range.past"
          fab
          text
          small
          color="grey darken-2"
          @click="move('prev')"
        >
          <v-icon small> mdi-chevron-left </v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-btn
          :disabled="range.future"
          fab
          text
          small
          color="grey darken-2"
          @click="move('next')"
        >
          <v-icon small> mdi-chevron-right </v-icon>
        </v-btn>
        <v-btn
          :disabled="range.future"
          fab
          text
          small
          color="grey darken-2"
          @click="move('skipNext')"
        >
          <v-icon small> mdi-chevron-double-right </v-icon>
        </v-btn>
        <calendar-legend></calendar-legend>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-sheet>

    <v-sheet class="calendar-controls">
      <v-calendar
        ref="calendar"
        :event-more="false"
        v-model="focus"
        :type="calendarType"
        :events="events"
        color="blue lighten-4"
        @change="getEvents"
        @click:event="showEvent"
      ></v-calendar>
      <v-menu
        v-model="selectedOpen"
        :close-on-content-click="true"
        :activator="selectedElement"
        offset-x
      >
        <v-card
          class="calendar-event-popup"
          :color="timeOffCategoryBgColorSecondary(selectedEvent.code)"
          flat
        >
          <v-card-title>
            <h2>{{ selectedEvent.category }}</h2>
          </v-card-title>
          <v-card-text v-show="showForCode(selectedEvent.code)">
            <h3>{{ selectedEvent.employeeName }}</h3>
            <div v-if="isDashboard">
              <strong>Status:</strong> {{ selectedEvent.requestStatus }}
            </div>
            <div><strong>Hours:</strong> {{ selectedEvent.hours }}</div>
            <div class="event-reason">
              <strong>Reason:</strong> {{ selectedEvent.reason }}
            </div>

            <span></span>
          </v-card-text>
          <v-card-actions
            v-show="showForCode(selectedEvent.code)"
            class="justify-center"
          >
            <v-btn
              outlined
              text
              color="var(--pto-cat-color-primary)"
              @click="
                viewRequest(
                  selectedEvent.employeeUserId,
                  selectedEvent.requestId
                )
              "
            >
              <v-icon class="pl-2">mdi-calendar-import</v-icon>
              View Request
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-sheet>
    <div v-if="!isDashboard">
      <print-calendar></print-calendar>
    </div>
  </v-card>
</template>

<script>
import { Component, Mixins, Prop } from "vue-property-decorator";
import rulesMixin from "@/modules/timeOff/rulesMixin";
import { namespace } from "vuex-class";
import dates from "@/utils/dates";
import timeOffMixin from "@/modules/timeOff/timeOffMixin";
import { titleCapitalization } from "@/utils/util";
import PrintCalendar from "@/modules/timeOff/requests/components/printCalendar";
import CalendarLegend from "@/modules/timeOff/requests/components/calendarLegend";

const calendarStore = namespace("timeOff/calendar");
const authStore = namespace("auth");

@Component({
  name: "requestsCalendar",
  components: {
    CalendarLegend,
    PrintCalendar,
  },
  watch: {
    reportsCriteria() {
      this.refresh();
    },
    searchCriteria() {
      this.refresh();
    },
    proxyCriteria() {
      this.refresh();
    },
  },
})
export default class RequestsCalendar extends Mixins(rulesMixin, timeOffMixin) {
  @calendarStore.Action("fetchCalendarRequests") fetchCalendarRequests;
  @calendarStore.Action("fetchDashboardCalendarRequests")
  fetchDashboardCalendarRequests;
  @calendarStore.Getter("getCalendarEntries") calendarEntries;
  @calendarStore.Getter("calendarSelectedMonth") calendarSelectedMonth;
  @calendarStore.Mutation("setCalendarSelectedMonth") setCalendarSelectedMonth;
  @authStore.Getter("userId") userId;

  data() {
    return {
      range: {
        past: false,
        future: false,
        pastDouble: false,
        futureDouble: false,
      },
      monthTitle: null,
      type: "month",
      types: ["month"],
      mode: "stack",
      modes: ["stack", "column"],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [{ text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] }],
      value: "",
      events: [],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      isLoading: false,
      startDate: null,
      endDate: null,
      holidays: [],
      holidaysCategory: {
        code: "companyHolidays",
        description: "Company Holiday",
      },
      fields:
        "id,employeeId,employeeUserId,employeeFirstName,employeeLastName,reason,status,statusDescription",
    };
  }

  @Prop({
    type: Boolean,
    default: false,
  })
  isDashboard;

  @Prop({
    type: String,
    default: "month",
  })
  calendarType;

  @Prop({
    type: String,
    default: "",
  })
  searchCriteria;

  @Prop({
    type: String,
    default: "",
  })
  reportsCriteria;

  @Prop({
    type: Object,
    default: function () {
      return {};
    },
  })
  proxyCriteria;

  @Prop({
    type: Boolean,
    default: true,
  })
  showCompanyHolidays;

  get focus() {
    return this.calendarSelectedMonth;
  }
  set focus(date) {
    this.setCalendarSelectedMonth(date);
  }

  showForCode(code) {
    return code !== this.holidaysCategory.code;
  }

  refresh() {
    this.getCalendarRequests();
  }

  mounted() {
    this.holidays = this.config("companyHolidays") ?? [];
    this.$refs.calendar.checkChange();
  }

  viewRequest(userId, requestId) {
    this.gotoReview(userId, requestId);
  }

  move(to) {
    this.events = [];
    switch (to) {
      case "prev":
      case "next":
        this.$refs.calendar[to]();
        break;
      case "skipNext":
        if (this.isDashboard) {
          this.$refs.calendar.move(
            this.getDashboardNextMonthValue(this.startDate)
          );
          break;
        }
        this.$refs.calendar.move(this.getNextMonthValue(this.startDate));
        break;
      case "skipPrev":
        if (this.isDashboard) {
          this.$refs.calendar.move(
            -this.getDashboardPrevMonthValue(this.startDate)
          );
          break;
        }
        this.$refs.calendar.move(-this.getPrevMonthValue(this.startDate));
        break;
      default:
        break;
    }
  }

  setToday() {
    this.focus = "";
  }

  showEvent({ nativeEvent, event }) {
    const open = () => {
      this.selectedEvent = event;
      this.selectedElement = nativeEvent.target;
      requestAnimationFrame(() =>
        requestAnimationFrame(() => (this.selectedOpen = true))
      );
    };

    if (this.selectedOpen) {
      this.selectedOpen = false;
      requestAnimationFrame(() => requestAnimationFrame(() => open()));
    } else {
      open();
    }

    nativeEvent.stopPropagation();
  }

  async getCalendarRequests() {
    if (this.isLoading) return;

    this.isLoading = true;

    let params = {
      includeEntries: 1,
      fields: this.fields,
    };

    if (this.startDate) {
      params.startDate = this.startDate;
    }

    if (this.endDate) {
      params.endDate = this.endDate;
    }

    if (this.searchCriteria !== "") {
      params["employeeId||employeeLastName||employeeFirstName"] =
        this.searchCriteria;
    }

    if (this.reportsCriteria !== "")
      params.reports = this.reportsCriteria.toLowerCase();

    if (this.proxyCriteria !== null) {
      if (this.proxyCriteria.text === "All") {
        params.selectedManagerEmployerId = "";
        params.selectedManagerEmployeeId = "all";
      } else {
        params.selectedManagerEmployerId = this.proxyCriteria.employerId;
        params.selectedManagerEmployeeId = this.proxyCriteria.employeeId;
      }
    }

    if (this.isDashboard) {
      await this.fetchDashboardCalendarRequests({
        employeeIdentifier: this.userId.trim(),
        params: params,
      });
    } else {
      await this.fetchCalendarRequests(params);
    }

    this.createCalendarEvents();

    this.isLoading = false;
  }

  createCalendarEvents() {
    this.events = [];
    this.createCompanyHolidays();

    this.calendarEntries.forEach((entry) => {
      this.createEvent(entry);
    });
  }

  createEvent(entry) {
    if (!entry.isDeleted) {
      this.events.push({
        employeeId: entry.employeeId,
        employeeUserId: entry.employeeUserId,
        requestId: entry.id,
        name: `${titleCapitalization(entry.partialName)} ${entry.hours}h`,
        code: entry.code,
        category: entry.description,
        employeeName: entry.fullName,
        hours: entry.hours,
        reason: entry.reason,
        requestStatus: entry.requestStatus,
        color: this.timeOffCategoryBgColor(entry.code),
        start: dates.dateFromMezzioDateJson(entry.date),
        end: dates.dateFromMezzioDateJson(entry.date),
        timed: false,
      });
    }
  }

  createCompanyHolidays() {
    this.holidays.forEach((date) => {
      if (dates.dateIsBetween(date, this.startDate, this.endDate)) {
        this.holidaysCategory.description =
          dates.dateHolidayName(date) ?? "Company Holiday";

        this.events.push({
          name: this.holidaysCategory.description,
          code: this.holidaysCategory.code,
          category: this.holidaysCategory.description,
          color: this.timeOffCategoryBgColor(this.holidaysCategory.code),
          start: dates.dateString(date),
          end: dates.dateString(date),
        });
      }
    });
  }

  getEvents({ start, end }) {
    if (this.isDashboard) {
      this.range = this.dashboardCalendarRange(start, end);
    } else {
      this.range = this.calendarRange(start, end);
    }

    this.startDate = start.date;
    this.endDate = end.date;

    this.refresh();
  }
}
</script>

<style scoped>
.v-toolbar__title {
  min-width: 200px;
  text-align: center;
}
.calendar-view {
  margin: 10px;
}
.calendar-event-popup {
  min-width: auto;
}
.calendar-event-popup .event-reason {
  max-width: 200px;
  overflow: auto;
  max-height: 100px;
}
.calendar-event-popup h2 {
  font-size: 1em;
}
.calendar-event-popup,
.calendar-event-popup .v-card__text {
  color: var(--pto-cat-color-primary) !important;
}
.multi-line {
  white-space: pre;
}
.theme--light.v-btn:focus::before {
  opacity: 0;
}
</style>
