<template>
  <div class="employee-schedule-table-div">
    <div class="info-message">
      {{ scheduleMessage }}
    </div>
    <v-form v-model="valid" ref="form">
      <v-simple-table class="employee-schedule-table">
        <thead>
          <tr>
            <th class="day-column text-left">Day</th>
            <th class="hour-column text-center">Hours</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="day in days" :key="day">
            <td class="text-left">{{ day }}</td>
            <td class="text-center" v-if="isEditing">
              <v-text-field
                dense
                class="centered-input"
                v-model="scheduleMutable[day]"
                :rules="[validateSchedule]"
                :validate-on-blur="false"
                v-on:blur="onChange($event, day)"
                :v-mask="maskPattern"
              />
            </td>
            <td class="text-center" v-else>{{ schedule[day] }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
    <div class="employee-schedule-table-controls text-right">
      <v-btn outlined @click="toggleEdit" v-if="editable && !isEditing"
        >Edit Schedule</v-btn
      >
      <v-btn outlined @click="cancel" v-if="isEditing" :disabled="isSaving"
        >Cancel</v-btn
      >
      <v-btn
        color="green"
        outlined
        @click="submit"
        v-if="isEditing"
        :disabled="!valid || isSaving"
        >Save Schedule</v-btn
      >
    </div>
  </div>
</template>

<script>
import { Component, Prop, Watch, Mixins } from "vue-property-decorator";
import { endsWith, replace } from "lodash";
import { hoursFormat } from "@/utils/util";
import { mask } from "vue-the-mask";
import rulesMixin from "@/modules/timeOff/rulesMixin";
import validatorMixin from "@/utils/validatorMixin";

@Component({ directives: { mask } })
export default class EmployeeScheduleTable extends Mixins(
  rulesMixin,
  validatorMixin
) {
  isEditing = false;
  message = "";
  maskPattern = "['##.##']['#.##']";

  @Watch("isEditing")
  onEditingChange(val) {
    // strip any  /edit
    let path = replace(this.$router.currentRoute.path, /\/edit$/, "");

    if (val) {
      path = path + "/edit";
    }

    // toggle path
    if (
      path !== this.$router.currentRoute.path &&
      path.includes("my-schedule")
    ) {
      this.$router.push(path);
    }
  }

  @Watch("$route")
  onRouteChange(newRoute) {
    let routeOnEdit = endsWith(newRoute.path, "edit");
    if (routeOnEdit !== this.isEditing) {
      this.isEditing = !this.isEditing;
    }
  }

  @Prop({ default: false }) edit;

  @Prop({ default: false }) editable;

  @Prop({ default: false }) isSaving;

  @Prop({
    default: function () {
      return {
        Monday: "0.00",
        Tuesday: "0.00",
        Wednesday: "0.00",
        Thursday: "0.00",
        Friday: "0.00",
        Saturday: "0.00",
        Sunday: "0.00",
      };
    },
  })
  schedule;

  @Prop({ default: "" })
  payType;

  data() {
    return {
      defaultHours: 0,
      valid: false,
      days: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      scheduleMutable: {
        Monday: "0.00",
        Tuesday: "0.00",
        Wednesday: "0.00",
        Thursday: "0.00",
        Friday: "0.00",
        Saturday: "0.00",
        Sunday: "0.00",
      },
    };
  }

  get scheduleMessage() {
    const hoursLimits = this.hoursLimits(this.payType);
    switch (this.payType) {
      case this.config("hourly_pay_type"):
        return `As an hourly employee you have a 1 minute minimum and ${hoursLimits.max} hours maximum.`;
      case this.config("salary_pay_type"):
        return `As a salary employee you have an ${hoursLimits.min} hours minimum and ${hoursLimits.max} hours maximum.`;
      default:
        return `Unknown pay type "${this.payType}"`;
    }
  }

  onChange(event, day) {
    if (isNaN(event.target.value)) {
      this.scheduleMutable[day] = hoursFormat(this.defaultHours);
    } else {
      this.scheduleMutable[day] = hoursFormat(event.target.value);
    }
  }

  mounted() {
    // create a copy of the schedule for editing
    this.scheduleMutable = JSON.parse(JSON.stringify(this.schedule));
    if (endsWith(this.$router.currentRoute.path, "edit")) {
      this.isEditing = true;
      return;
    }

    if (this.edit) {
      this.isEditing = true;
    }
  }

  toggleEdit() {
    this.isEditing = !this.isEditing;
  }

  cancel() {
    this.isEditing = false;
    this.$emit("cancel");
  }

  submit() {
    this.$refs.form.validate();
    if (this.valid) {
      this.$emit("submit", { ...this.scheduleMutable });
    }
  }

  validateSchedule(hours) {
    let valid = this.workHoursRule(hours);

    if (valid !== true) {
      const timer = setTimeout(() => {
        this.goToErrorField();
        clearTimeout(timer);
      }, 100);
    }

    return valid;
  }
}
</script>

<style scoped>
.employee-schedule-table-controls {
  margin: 10px;
}

.employee-schedule-table-div {
  width: 100%;
}

.employee-schedule-table {
  width: 100%;
}

.v-data-table.employee-schedule-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  font-size: 1.1rem;
  font-weight: bold;
}

.employee-schedule-table td,
.employee-schedule-table th {
  border: thin solid rgba(0, 0, 0, 0.12);
}

.employee-schedule-table .day-column {
  width: 60%;
}

.employee-schedule-table .hour-column {
  width: 40%;
}

.employee-schedule-table-controls button {
  margin-left: 10px;
}

.centered-input >>> input {
  text-align: center;
}
</style>
