<template>
  <v-card
    v-bind:max-width="width"
    v-bind:class="categoryClass"
    v-bind:style="{ backgroundColor: categoryColor }"
  >
    <v-card-title class="pto-hb-title">{{ categoryName }}</v-card-title>
    <div v-if="pendingHours != null" class="pto-hours-cont">
      <v-card-subtitle class="pto-hb-subtitle text-subtitle-2">
        Pending
      </v-card-subtitle>
      <v-card-text class="pto-hb-text pto-hb-pending" v-bind:class="largeText">
        <h1>{{ pendingHours }} <span>hours</span></h1>
      </v-card-text>
    </div>
    <div v-if="showRemaining">
      <v-card-subtitle class="pto-hb-subtitle"> Remaining </v-card-subtitle>
      <v-card-text class="pto-hb-text pto-hb-remaining">
        <h2>{{ remainingHours }} <span>hours</span></h2>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import { Component, Prop, Mixins } from "vue-property-decorator";
import timeOffMixin from "@/modules/timeOff/timeOffMixin";

@Component({})
export default class HoursBalance extends Mixins(timeOffMixin) {
  // Properties
  disabled = false;
  defaultState = false;

  @Prop({ default: 200 })
  maxWidth;

  @Prop({ default: {} })
  employeeCount;

  data() {
    return {
      ptoHours: 30.0,
    };
  }
  get width() {
    return this.maxWidth;
  }
  get largeText() {
    return this.showRemaining ? "pto-cat-hb-text" : "pto-cat-hb-text-large";
  }

  get categoryName() {
    return this.employeeCount.description;
  }

  get categoryClass() {
    return "pto-hours-card pto-cat-" + this.employeeCount.code;
  }

  get categoryColor() {
    return this.timeOffCategoryBgColor(this.employeeCount.code);
  }

  get remainingHours() {
    return this.employeeCount.remaining != null
      ? this.employeeCount.remaining.toFixed(2)
      : null;
  }

  get pendingHours() {
    return this.employeeCount.pendingTotal;
  }

  get showRemaining() {
    return this.remainingHours != null;
  }
}
</script>
