<template>
  <v-dialog v-model="showDialog" persistent width="50%">
    <v-sheet>
      <v-card color="primary" dark>
        <v-card-title v-if="title">{{ title }}</v-card-title>
        <v-card-text>
          <div class="mb-3">{{ message }}</div>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-sheet>
  </v-dialog>
</template>

<script>
export default {
  name: "progressDialog",
  props: {
    showDialog: { type: Boolean, required: true, default: false },
    title: {
      default: "",
      type: String,
      required: false,
    },
    message: {
      default: "",
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped></style>
