<template>
  <pageManager :isLoading="isLoading" :error="fetchError" :isEmpty="isEmpty">
    <v-container>
      <v-card elevation="2" :loading="isLoading ? 'grey lighten-2' : null">
        <v-card-title class="headline white--text primary font-weight-light">
          {{ title }}
        </v-card-title>

        <v-card-text>
          <v-card flat>
            <v-tabs
              id="settings-tabs"
              @change="onChangeTab"
              v-model="tab"
              centered
              color="black"
              background-color="grey lighten-2"
            >
              <v-tab v-for="tabItem in tabs" :key="tabItem.tab">
                {{ tabItem.tab }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item>
                <keep-alive>
                  <component
                    ref="employeeProxies"
                    :is="employeeProxiesTab"
                  ></component>
                </keep-alive>
              </v-tab-item>
              <v-tab-item>
                <keep-alive>
                  <component
                    ref="calendarInvites"
                    :is="calendarInvitesTab"
                  ></component>
                </keep-alive>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-card-text>
      </v-card>
    </v-container>
  </pageManager>
</template>

<script>
import pageManager from "@/components/pageManager";
import Component from "vue-class-component";
import { Mixins } from "vue-property-decorator";
import modulePageMixin from "@/utils/mixins";
import timeOffMixin from "@/modules/timeOff/timeOffMixin";

@Component({
  name: "TimeOffSettings",
  components: {
    pageManager,
  },
})
export default class TimeOffSettings extends Mixins(
  modulePageMixin,
  timeOffMixin
) {
  title = "Time Off Settings";
  isEmpty = false;
  fetchError = "";
  message = null;
  isLoading = false;
  tab = null;
  tabs = [
    { tab: "Employee Proxies", component: "employeeProxies" },
    { tab: "Calendar Invites", component: "calendarInvites" },
  ];

  onChangeTab() {
    this.updateUrlQuery({ tab: this.tab });
  }

  get employeeProxiesTab() {
    return this.loadComponent("employeeProxies", "timeOff/settings");
  }
  get calendarInvitesTab() {
    return this.loadComponent("calendarInvites", "timeOff/settings");
  }
}
</script>

<style scoped>
#settings-tabs {
  margin-top: 20px;
}
.v-tab--active {
  background-color: #c9c6c6;
}
</style>
