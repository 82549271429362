<template>
  <div class="request-list-table-div">
    <v-data-table
      class="elevation-1"
      :loading="isFetchingData"
      loading-text="Loading Requests..."
      no-data-text="No Requests"
      :headers="tableHeaders"
      :footer-props="tableFooter"
      :items="timeOffRequests"
      :server-items-length="totalTimeOffRequests"
      :search="search"
      :options.sync="options"
    >
      <template v-slot:header>
        <tr class="grey lighten-3 table-filter-row">
          <th v-for="header in tableHeaders" :key="header.text">
            <div
              v-if="header.value === 'statusDescription'"
              class="table-filter"
            >
              <v-autocomplete
                item-value="status"
                item-text="description"
                :items="timeOffStatuses"
                :value="statusCriteriaString"
                placeholder="Filter Status"
                v-on:input="statusFilterSelected"
                return-object
                dense
                flat
                clearable
                open-on-clear
              ></v-autocomplete>
            </div>
            <div v-if="header.value === 'minDate'" class="table-filter">
              <dateRangeSelector
                field="requestDateRange"
                placeholder="Filter Dates"
                :datesSelected="requestDateRangeCriteria"
                v-on:search="requestDateRangeSelected"
              ></dateRangeSelector>
            </div>
          </th>
        </tr>
      </template>
      <template v-slot:[`item.employeeLastName`]="{ item }">
        {{
          formatEmployeeName(
            item.employeeFirstName,
            item.employeeLastName,
            item.employerId,
            item.employeeId
          )
        }}
      </template>
      <template v-slot:[`item.managerLastName`]="{ item }">
        {{
          formatEmployeeName(
            item.managerFirstName,
            item.managerLastName,
            item.managerEmployerId,
            item.managerEmployeeId
          )
        }}
      </template>
      <template v-slot:[`item.statusDescription`]="{ item }">
        {{ item.statusDescription }}
      </template>
      <template v-slot:[`item.totalHours`]="{ item }">
        {{ item.totalHours !== null ? item.totalHours : "0.00" }}
      </template>
      <template v-slot:[`item.reason`]="{ item }">
        {{ item.reason }}
      </template>
      <template v-slot:[`item.minDate`]="{ item }">
        {{ item.minDate !== null ? reFormatISODate(item.minDate) : "-" }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn text @click="gotoReview(item.employeeUserId, item.id)">
          <v-icon class="pl-2">mdi-calendar-import</v-icon>
          View
        </v-btn>
      </template>
    </v-data-table>
    <downloadSpreadSheet
      :params="loadTimeOffRequestsParams()"
    ></downloadSpreadSheet>
  </div>
</template>

<script>
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import DownloadSpreadSheet from "@/modules/timeOff/requests/components/downloadSpreadSheet";
import rulesMixin from "@/modules/timeOff/rulesMixin";
import timeOffMixin from "@/modules/timeOff/timeOffMixin";
import { namespace } from "vuex-class";
import { reFormatISODate, formatEmployeeName } from "@/utils/util";
import dateRangeSelector from "@/modules/timeOff/components/fields/dateRangeSelector";

const requestsSearchStore = namespace("timeOff/requestsSearch");
const timeOffStore = namespace("timeOff");

@Component({
  name: "requestsList",
  components: {
    dateRangeSelector,
    DownloadSpreadSheet,
  },
})
export default class RequestsCalendar extends Mixins(rulesMixin, timeOffMixin) {
  @requestsSearchStore.Action("fetchManagerListTimeOffRequests")
  fetchManagerListTimeOffRequests;
  @requestsSearchStore.Action("resetListViewOptions") resetListViewOptions;
  @requestsSearchStore.Getter("getTimeOffRequests") timeOffRequests;
  @requestsSearchStore.Getter("getTotalTimeOffRequests") totalTimeOffRequests;
  @requestsSearchStore.Getter("getReportsCriteria") reportsCriteria;
  @requestsSearchStore.Getter("getSearchCriteria") searchCriteria;
  @requestsSearchStore.Getter("getStatusCriteria") statusCriteria;
  @requestsSearchStore.Getter("getProxyCriteria") proxyCriteria;
  @requestsSearchStore.Getter("getRequestDateRangeCriteria")
  requestDateRangeCriteria;
  @requestsSearchStore.Getter("isFetchingData") isFetchingData;
  @requestsSearchStore.Getter("listViewOptions") listViewOptions;
  @requestsSearchStore.Mutation("setTimeOffRequests") setTimeOffRequests;
  @requestsSearchStore.Mutation("setTotalTimeOffRequests")
  setTotalTimeOffRequests;
  @requestsSearchStore.Mutation("setStatusCriteria") setStatusCriteria;
  @requestsSearchStore.Mutation("setRequestDateRangeCriteria")
  setRequestDateRangeCriteria;
  @requestsSearchStore.Mutation("setListViewOptions") setListViewOptions;
  @timeOffStore.Getter("timeOffStatuses") timeOffStatuses;

  data() {
    return {
      reFormatISODate,
      formatEmployeeName,
      tableHeaders: [
        {
          text: "Employee",
          align: "center",
          value: "employeeLastName",
          sortable: true,
        },
        {
          text: "Approver Queue",
          align: "center",
          value: "managerLastName",
          sortable: true,
        },
        {
          text: "Status",
          align: "center",
          value: "statusDescription",
          sortable: true,
        },
        {
          text: "Hours",
          align: "center",
          value: "totalHours",
          sortable: true,
        },
        { text: "Reason", align: "center", value: "reason", sortable: false },
        {
          text: "First Day",
          align: "center",
          value: "minDate",
          sortable: true,
        },
        {
          text: "Actions",
          align: "center",
          value: "actions",
          width: "1px",
          sortable: false,
        },
      ],
      tableFooter: {
        "items-per-page-options": [10, 25],
        "show-current-page": true,
        "show-first-last-page": true,
      },
      search: "",
      sort: "",
      statusCriteriaString: null,
    };
  }

  @Prop({
    type: Boolean,
    default: false,
  })
  refresh;

  @Watch("options", { deep: true })
  onOptionsChange() {
    if (Object.keys(this.options).length !== 0) {
      //set the format for the endpoint like: lastName:desc
      if (this.options.sortBy[0]) {
        this.sort = this.options.sortBy[0];
        if (this.options.sortDesc[0]) this.sort = this.sort + ":desc";
      } else {
        this.sort = "id:desc";
      }
    }

    this.clearRequests();
    this.loadTimeOffRequests();
  }

  @Watch("statusCriteria")
  statusCriteriaChanged() {
    this.loadTimeOffRequests();
  }

  @Watch("requestDateRangeCriteria")
  requestDateRangeCriteriaChanged() {
    this.loadTimeOffRequests();
  }

  get options() {
    return this.listViewOptions;
  }
  set options(data) {
    this.setListViewOptions(data);
  }

  statusFilterSelected(status) {
    // reset page
    this.options.page = 1;
    this.setStatusCriteria(status);
  }

  requestDateRangeSelected(dateRangeObj) {
    // reset page
    this.options.page = 1;
    this.setRequestDateRangeCriteria(dateRangeObj.value.split("||"));
  }

  clearRequests() {
    this.setTimeOffRequests([]);
    this.setTotalTimeOffRequests(0);
  }

  reload() {
    this.options.page = 1;
    this.loadTimeOffRequests();
  }

  loadTimeOffRequestsParams() {
    const params = {
      sort: this.sort,
    };

    if (this.searchCriteria !== "") {
      params["employeeId||employeeLastName||employeeFirstName"] =
        this.searchCriteria;
    }

    if (this.statusCriteria !== null) {
      params.status = this.statusCriteria.status;
    }

    if (this.reportsCriteria !== "") {
      params.reports = this.reportsCriteria.toLowerCase();
    }

    if (this.proxyCriteria !== null) {
      if (this.proxyCriteria.text === "All") {
        params.selectedManagerEmployerId = "";
        params.selectedManagerEmployeeId = "all";
      } else {
        params.selectedManagerEmployerId = this.proxyCriteria.employerId;
        params.selectedManagerEmployeeId = this.proxyCriteria.employeeId;
      }
    }

    if (
      this.requestDateRangeCriteria?.length &&
      this.requestDateRangeCriteria.length > 0
    ) {
      params.startDate = this.requestDateRangeCriteria[0];
      params.endDate =
        this.requestDateRangeCriteria.length > 1
          ? this.requestDateRangeCriteria[1]
          : this.requestDateRangeCriteria[0];
    }

    return params;
  }
  async loadTimeOffRequests() {
    let params = this.loadTimeOffRequestsParams();
    params.limit = this.options.itemsPerPage;
    params.page = this.options.page;

    await this.fetchManagerListTimeOffRequests(params);
  }

  mounted() {
    if (this.statusCriteria !== null)
      this.statusCriteriaString = this.statusCriteria.status;
  }

  destroyed() {
    this.resetListViewOptions();
  }
}
</script>
