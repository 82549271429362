<template>
  <div v-if="show" class="pa-2">
    <v-btn
      :block="!isMini"
      :outlined="!isMini"
      :icon="isMini"
      :color="color"
      :disabled="disable"
      @click="$emit(action)"
      tile
    >
      <v-icon class="mr-2">{{ icon }}</v-icon>
      <slot v-if="!isMini" />
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "navButton",
  computed: {},
  props: {
    action: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    disable: {
      type: Boolean,
      defaults: false,
    },
    icon: {
      type: String,
      required: true,
    },
    isMini: {
      type: Boolean,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
