import { get } from "lodash";

export default {
  allConfigs(state) {
    return state.config;
  },
  config: (state) => (key, defaultValue) => {
    if (!(key in state.config)) {
      return defaultValue;
    }
    return state.config[key];
  },
  timeOffCategories(state, getters) {
    return getters.employeeHours.map((item) => ({
      id: item.code,
      text: item.description,
    }));
  },
  getRulesForCategoryCode: (state, getters) => (code) => {
    return get(getters.config("categories_rules"), code)?.rules;
  },
  timeOffStatuses(state) {
    return state.config.statuses.filter((status) => status.status !== "");
  },
  companyHolidays(state) {
    return state.config.companyHolidays;
  },
};
