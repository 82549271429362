<template>
  <v-card elevation="2" :loading="isLoading">
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-actions class="justify-center">
      <v-btn color="primary" @click="goToNewRequest" outlined>
        New Request
      </v-btn>
      <v-btn color="primary" @click="goToMyRequests" outlined>
        My Requests
      </v-btn>
    </v-card-actions>

    <v-card-text>
      <v-row> </v-row>
      <time-off-hours-balance @onLoadingComplete="loadingDashBoards" />
      <requests-calendar
        :is-dashboard="true"
        style="padding-top: 30px"
      ></requests-calendar>
    </v-card-text>
  </v-card>
</template>

<script>
import modulePageMixin from "@/utils/mixins";
import { Component, Mixins } from "vue-property-decorator";
import TimeOffHoursBalance from "@/modules/timeOff/components/timeOffHoursBalance";
import RequestsCalendar from "@/modules/timeOff/requests/components/requestsCalendar";

@Component({
  components: { TimeOffHoursBalance, RequestsCalendar },
})
export default class TimeOffDashboard extends Mixins(modulePageMixin) {
  isLoading = true;
  isEmpty = false;
  fetchError = "No Error";
  title = "Time Off Dashboard";

  data() {
    return {
      validation: true,
      testing: false,
    };
  }
  goToNewRequest() {
    this.$router.push("/timeoff/my-requests/new-request");
  }
  goToMyRequests() {
    this.$router.push("/timeoff/my-requests");
  }
  loadingDashBoards(data) {
    this.isLoading = !data;
  }
}
</script>
