<template>
  <div>
    <h3>
      This component is generic, if there is a company specific version it will
      be used instead.
    </h3>
    <p>
      This module is for demonstration of using Vue Class Component features,
      this allows us to have shared functionality and extend it when needed.
    </p>
    <p>{{ message }}</p>
  </div>
</template>

<script>
import modulePageMixin from "@/utils/mixins";
import { Component, Mixins } from "vue-property-decorator";

@Component
export default class Requests extends Mixins(modulePageMixin) {
  showSwift = true;
  isLoading = false;
  isEmpty = false;
  fetchError = "No Error";
  messageText = "This is a property - we are just testing now.";

  data() {
    return {
      validation: true,
      testing: false,
    };
  }
  set message(value) {
    this.messageText = value;
  }
  get message() {
    return this.messageText;
  }
}
</script>
