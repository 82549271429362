export default [
  {
    name: "personalInfo",
    path: "/personal-info",
    component: "modules/personalInfo",
    meta: {
      title: "Personal Info",
      hideFromNav: false,
      icon: "mdi-account-circle",
    },
    children: [
      {
        name: "personalInfo:myInformation",
        path: "/personal-info/my-information",
        component: "modules/personalInfo/myInformation",
        meta: {
          title: "My Information",
          hideFromNav: false,
          icon: "mdi-card-account-details",
        },
      },
      {
        name: "personalInfo:emergencyContact",
        path: "/personal-info/emergency-contact",
        component: "modules/personalInfo/emergencyContact",
        meta: {
          title: "Emergency Contact",
          hideFromNav: false,
          icon: "mdi-account-box-multiple-outline",
        },
      },
      {
        name: "personalInfo:education",
        path: "/personal-info/education",
        component: "modules/personalInfo/education",
        meta: {
          title: "Education",
          hideFromNav: false,
          icon: "mdi-account-school",
        },
      },
    ],
  },
];
