import Mock from "@/apis/stubs/mock";
/*
  There can be only one applicationConfig Object
  Swift does not need a mock for production.
  Knight will use the mock config on its initial release until a back-end is ready to
  serve the config
  If you need to use a mocked config for Swift development Remove the Swift Suffix
  and add the Knight suffix to the applicationConfig on line 63
*/

export const applicationConfig = new Mock({
  data: {
    _total_items: 1,
    _page: 1,
    _page_count: 1,
    _links: {
      self: {
        href: "https://ess-back-end-integration.apps.dev.openshift.swifttrans.com/applicationConfig",
      },
    },
    _embedded: {
      company: {
        companyName: "SWIFT",
        companyTitle: "Knight Transportation",
      },
      application: {
        title: "Employee Self Service",
        subTitle: "",
        loadDynamicConfig: true,
        requireMultiFactorLogin: false,
      },
      modules: [
        {
          name: "home",
          routes: [
            {
              name: "home",
            },
          ],
        },
        {
          name: "timeOff",
          api_url: "$VUE_APP_TO_API_URL",
        },
        {
          name: "payroll",
          config: {},
          permissions: {},
          routes: [
            {
              name: "payroll",
              children: [
                {
                  name: "payroll:updateW4",
                },
                {
                  name: "payroll:W4Archive",
                },
              ],
            },
          ],
        },
      ],
    },
  },
});
/*
  There can be only one applicationConfig Object
  Swift does not need a mock for production.
  Knight will use the mock config on its initial release until a back-end is ready to
  serve the config
  If you need to use a mocked config for Swift development Remove the Swift Suffix
  and add the Knight suffix to the applicationConfig on line 63
*/
export const applicationConfigKnightOld = new Mock({
  data: {
    _links: {
      self: { href: "" },
      first: { href: "" },
      last: { href: "" },
      next: { href: "" },
    },
    _embedded: {
      application: {
        company: "KNIGHT",
        companyTitle: "Knight Transportation",
        title: "Employee Self Service",
        subTitle: "",
        loadDynamicConfig: true,
        requireMultiFactorLogin: true,
        acceptSSOToken: false,
      },
      routes: [
        {
          name: "home",
        },
        {
          name: "personalInfo",
        },
        {
          name: "payroll",
        },
      ],
      modules: {},
      page_count: 1,
      page_size: 1,
      total_items: 1,
      page: 1,
    },
  },
});
