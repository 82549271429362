export default {
  selectedEmailPreference(state) {
    if (
      state.emailPreferences.payStatementEmailPreference === ""
      || state.emailPreferences.payStatementEmailPreference === "NONE"
    ) {
      const defaultPreference = state.emailPreferences.options.find(
        ({ value }) => value !== "",
      );

      return defaultPreference?.key ?? "";
    }

    return state.emailPreferences.payStatementEmailPreference;
  },
};
