<template>
  <fieldset>
    <legend>Current Information</legend>
    <v-row>
      <v-col cols="12" sm="6" md="4" class="personalInfoColumn">
        <display-field label="First Name" :value="model.employeeFirstName" />
      </v-col>
      <v-col cols="12" sm="6" md="4" class="personalInfoColumn">
        <display-field label="Last Name" :value="model.employeeLastName" />
      </v-col>
      <v-col cols="12" sm="4" class="personalInfoColumn">
        <display-field label="Social Security Number" :value="model.ssn" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="4" class="personalInfoColumn">
        <display-field label="Street" :value="model.street" />
      </v-col>
      <v-col cols="12" sm="6" md="4" class="personalInfoColumn">
        <display-field label="Apartment/Suite" :value="model.apartment" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4" class="personalInfoColumn">
        <display-field label="City" :value="model.city" />
      </v-col>
      <v-col cols="12" sm="4" class="personalInfoColumn">
        <display-field label="State" :value="model.state" />
      </v-col>
      <v-col cols="12" sm="4" class="personalInfoColumn">
        <display-field label="Zip Code" :value="model.zip" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="personalInfoColumn">
        <display-field label="Claiming Status" :value="w4model.claimingStatus" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="2" class="personalInfoColumn">
        <display-field label="New" :value="w4model.isNew" />
      </v-col>
      <v-col cols="12" sm="6" md="2" class="personalInfoColumn">
        <display-field label="Credits Sum" :value="w4model.dependentsSum" v-show="!w4model.isExempt" />
      </v-col>
      <v-col cols="12" sm="6" md="2" class="personalInfoColumn">
        <display-field label="Other Income" :value="w4model.otherIncome" v-show="!w4model.isExempt" />
      </v-col>
      <v-col cols="12" sm="6" md="2" class="personalInfoColumn">
        <display-field label="Deductions" :value="w4model.deductions" v-show="!w4model.isExempt" />
      </v-col>
      <v-col cols="12" sm="6" md="2" class="personalInfoColumn">
        <display-field label="Additional Amount" :value="w4model.additionalAmount" v-show="!w4model.isExempt" />
      </v-col>
    </v-row>
  </fieldset>
</template>

<script>
import DisplayField from "@/components/displayField.vue";

export default {
  name: "currentPersonalInformation",
  components: { DisplayField },
  props: {
    model: { type: Object, required: true },
    w4model: { type: Object, required: true },
  },
};
</script>

<style scoped>
.personalInfoColumn {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
