export default [
  {
    moduleId: "home",
    moduleName: "Employee Self Service",
    metaInfo: {
      title: "Employee Self Service",
      meta: [{ name: "Home Page Description", content: "Home Page Content" }],
    },
    forms: [
      {
        formId: "",
      },
    ],
    workflow: [],
  },
];
