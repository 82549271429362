export const errorBadRequest = {
  title: "Bad Request",
  status: "",
  detail: "Could not complete this request",
};

export const errorUnknown = {
  title: "Bad Request",
  status: "",
  detail: "Unknown error",
};

export const getApiError = (apiError) => {
  if (apiError.response) {
    return apiError.response.data;
    // If at any point we need to handle status codes
    // on a code by code basis, we can use a switch statement;
    //
    // switch (apiError.response.status) {
    //   default:
    //     return apiError.response.data;
    // }
  }

  if (apiError.request) {
    return errorBadRequest;
  }
  return errorUnknown;
};

export const makeError = (title, message, status = "") => ({
  status: status ?? "",
  title: title ?? "",
  detail: message ?? "",
});
