import w4Api from "../../api/w4";

function preparePayload(formData) {
  return {
    claimingStatus: formData.claimingStatus,
    additionalAmount: parseFloat(formData.additionalAmount),
    otherIncome: parseFloat(formData.otherIncome),
    deductions: parseFloat(formData.deductions),
    qualifyingChildrenAmount: formData.qualifyingChildrenAmount,
    otherDependentAmount: formData.otherDependentAmount,
    otherCreditAmount: formData.otherCreditAmount,
    creditsSum: formData.creditsSum,
    newFlag: formData.newFlag,
    multiJobsFlag: formData.multiJobsFlag,
    employeeSignature: formData.employeeSignature,
  };
}

export default {
  async submitW4Form({ commit, state }) {
    commit("setIsSubmitting", true);
    commit("setSubmitError", {});

    try {
      const payload = preparePayload(state.formData);
      const { data } = await w4Api.postW4Data(payload);

      commit("setEmailSentTo", data.emailSentTo);
      commit("setW4PdfId", data.w4PdfId);
      commit("setW4PdfTitle", data.w4PdfTitle);
    } catch (error) {
      if (error.response) {
        commit("setSubmitError", error.response.data);
      } else if (error.request) {
        commit("setSubmitError", {
          title: "Bad Request",
          status: "",
          detail: "Could not complete this request",
        });
      } else {
        commit("setSubmitError", "Unknown error");
      }
    } finally {
      commit("setIsSubmitting", false);
    }
  },
  async fetchW4Form({ commit }) {
    commit("setIsLoading", true);
    commit("setSubmitError", {});

    try {
      const { data } = await w4Api.fetchW4Data();
      commit("setPersonalInformation", data.employeeW4);
      commit("setCurrentW4Information", data.employeeW4);
    } catch (error) {
      commit("setSubmitError", error);
    } finally {
      commit("setIsLoading", false);
    }
  },
  async getDocumentsList({ commit }, params) {
    commit("setIsLoading", true);
    commit("setSubmitError", {});

    try {
      const { data } = await w4Api.fetchDocuments(params);
      commit("setDocumentArchive", data._embedded.employeeW4files);
      commit("setTotalDocumentsInArchive", data._total_items);
    } catch (error) {
      commit("setSubmitError", error.data);
    } finally {
      commit("setIsLoading", false);
    }
  },
  async openW4Pdf({ commit }, fileObject) {
    commit("setSubmitError", {});

    try {
      const { data } = await w4Api.fetchPDFDocument(fileObject.id);
      const fileName = `${fileObject?.fileTitle}.pdf`;

      const link = document.createElement("a");

      link.href = window.URL.createObjectURL(data);
      link.setAttribute("download", fileName);
      link.click();
    } catch (error) {
      const message = await error.response.data.text();
      commit("setSubmitError", JSON.parse(message));
    }
  },
};
