<template>
  <v-card
    id="manage-calendar-invites"
    class="calendar-invites"
    flat
    :loading="isLoading"
  >
    <v-card-title>Manage Calendar Invites</v-card-title>

    <error-alert :error="currentError" v-show="currentError" />

    <v-card-text>
      <v-row>
        <v-alert width="100%" dense outlined type="success" v-if="isSaved">
          {{ savedMessage }}
        </v-alert>
      </v-row>
      <v-row>
        <v-col cols="12" sm="10" md="4">
          <h3>My Time Zone</h3>
          <v-select
            v-model="selectedTimeZone"
            :items="timeZonesList"
            item-text="description"
            item-value="timeZoneAbbr"
            label="Select a time zone"
            @change="onTimezoneChange"
            return-object
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="8">
          <v-switch
            v-model="calendarInvitesToMe"
            inset
            :label="'Send me calendar invites for requests made for me'"
          ></v-switch>
          <v-switch
            v-if="hasDirectReports()"
            v-model="calendarInvitesForMyReports"
            inset
            :label="'Send me calendar invites for my reports (direct and indirect)'"
          ></v-switch>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="primary"
        outlined
        @click="saveSettings"
        :disabled="isLoading"
      >
        Save Settings
      </v-btn>
    </v-card-actions>

    <submitDialog
      :propIsSubmitting="isSubmitting"
      :propSubmitError="submitError"
      @clearErrors="clearErrors"
    />
  </v-card>
</template>

<script>
import { Component, Mixins } from "vue-property-decorator";
import { namespace } from "vuex-class";
import timeOffMixin from "@/modules/timeOff/timeOffMixin";
import { authorizationMixin } from "@/modules/timeOff/authorizationMixin";
import ErrorAlert from "@/components/errorAlert";
import SubmitDialog from "@/components/submitDialog";

const settingsStore = namespace("timeOff/settings");

@Component({
  name: "calendarInvites",
  components: { SubmitDialog, ErrorAlert },
})
export default class CalendarInvites extends Mixins(
  timeOffMixin,
  authorizationMixin
) {
  @settingsStore.State("fetchError")
  fetchError;

  @settingsStore.State("submitError")
  submitError;

  @settingsStore.Action("fetchTimeOffUserSettings")
  fetchTimeOffUserSettings;

  @settingsStore.Action("fetchTimeZones")
  fetchTimeZones;

  @settingsStore.Action("getTimeZoneFromList")
  getTimeZoneFromList;

  @settingsStore.Action("saveUserSettings")
  saveUserSettings;

  @settingsStore.Getter("timeZonesList")
  timeZonesList;

  @settingsStore.Getter("timeZoneAbbreviation")
  timeZoneAbbreviation;

  @settingsStore.Getter("sendCalendarInvitesToMe")
  sendCalendarInvitesToMe;

  @settingsStore.Getter("sendCalendarInvitesForMyReports")
  sendCalendarInvitesForMyReports;

  @settingsStore.Getter("isSubmitting")
  isSubmitting;

  @settingsStore.Mutation("setSendCalendarInvitesToMe")
  setSendCalendarInvitesToMe;

  @settingsStore.Mutation("setSendCalendarInvitesForMyReports")
  setSendCalendarInvitesForMyReports;

  @settingsStore.Mutation("setUserSelectedTimeZone")
  setUserSelectedTimeZone;

  @settingsStore.Mutation("setFetchError")
  setFetchError;

  @settingsStore.Mutation("setSubmitError")
  setSubmitError;

  isLoading = false;
  isSaved = false;
  selectedTimeZone = {
    timeZoneAbbr: "",
    description: "",
  };
  savedMessage = "Settings saved";

  onTimezoneChange(e) {
    this.setUserSelectedTimeZone(e);
  }

  get currentError() {
    return this.fetchError;
  }

  get calendarInvitesToMe() {
    return this.sendCalendarInvitesToMe;
  }
  set calendarInvitesToMe(value) {
    this.setSendCalendarInvitesToMe(value);
  }

  get calendarInvitesForMyReports() {
    return this.sendCalendarInvitesForMyReports;
  }
  set calendarInvitesForMyReports(value) {
    this.setSendCalendarInvitesForMyReports(value);
  }

  async saveSettings() {
    await this.saveUserSettings();

    if (Object.keys(this.submitError).length === 0) {
      this.isSaved = true;

      const timer = setTimeout(() => {
        this.isSaved = false;
        clearTimeout(timer);
      }, 1200);
    }
  }

  clearErrors() {
    this.setFetchError(null);
    this.setSubmitError({});
  }

  async mounted() {
    this.clearErrors();

    this.isLoading = true;

    await this.fetchTimeZones();
    await this.fetchTimeOffUserSettings();

    let preset = this.timeZonesList.filter(
      (e) => e.timezoneAbbr === this.timeZoneAbbreviation
    );

    this.selectedTimeZone = preset[0];
    this.isLoading = false;
  }
}
</script>

<style scoped></style>
