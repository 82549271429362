<template>
  <pageManager :isLoading="isLoading" :error="fetchError" :isEmpty="isEmpty">
    <v-container>
      <v-row class="text-center">
        <v-col class="mb-4">
          <h1 class="display-2 font-weight-bold mb-3">{{ moduleName }}</h1>
        </v-col>
      </v-row>
    </v-container>
    <component :is="loadClassComponent"
    />
  </pageManager>
</template>

<script>
import modulePageMixin from "@/utils/mixins";
import { loadDynamicComponent } from "@/utils/dynamicAssets";
import pageManager from "@/components/pageManager.vue";

export default {
  components: { pageManager },
  mixins: [modulePageMixin],
  data() {
    return {
      isLoading: false,
      isEmpty: false,
      fetchError: "",
      message: null,
    };
  },
  computed: {
    loadClassComponent() {
      return loadDynamicComponent("profile", "account");
    },
  },
};
</script>
