export default [
  {
    path: "/",
    name: "home",
    component: "modules/home",
    meta: {
      title: "Employee Self Service",
      hideFromNav: false,
      icon: "mdi-home",
    },
  },
];
