<template>
  <div>
    <Profile />
    <h4>Additional Info</h4>
    <p>
      This section is added specifically for Knight, it illustrates that we can
      have specific tags, properties and methods.
    </p>
  </div>
</template>
<script>
import { Component } from "vue-property-decorator";
import Profile from "./profile.vue";

@Component({
  components: { Profile },
})
export default class ProfileKnight extends Profile {
  autocomplete = null;
}
</script>
