export default () => ({
  isLoading: false,
  isSaving: false,
  error: null,
  initialContacts: [],
  workingContacts: [],
  savingError: null,
  savingInfo: {},
  relationships: [],
});
