<template>
  <v-container class="request-queue">
    <component
      :is="requestQueueCard"
      :statusCode="statusCode"
      :key="statusCode"
      ref="requestQueueCard"
    />
  </v-container>
</template>

<script>
import modulePageMixin from "@/utils/mixins";
import requestQueueCard from "./components/requestQueueCard.vue";

export default {
  mixins: [modulePageMixin],
  components: {
    requestQueueCard,
  },
  data() {
    return {
      statusCode: null,
    };
  },
  computed: {
    requestQueueCard() {
      return this.loadComponent("requestQueueCard", "timeOff/requestQueues");
    },
    path() {
      return this.$route.path;
    },
  },
  methods: {
    setStatusCode() {
      this.statusCode = this.$route.meta.statusCode;
    },
  },
  watch: {
    path() {
      this.setStatusCode();
    },
  },
  mounted() {
    this.setStatusCode();
  },
};
</script>
