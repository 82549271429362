<template>
  <v-container class="home">
    <v-row>
      <v-col class="text-center">
        <h1>{{ this.moduleName }}</h1>
      </v-col>
    </v-row>

    <component :is="loadDashboard" />
  </v-container>
</template>

<script>
import modulePageMixin from "@/utils/mixins";

export default {
  mixins: [modulePageMixin],
  computed: {
    logoAsset() {
      return this.getAssetPath("logo.png");
    },

    // A new generic dashboard component could be used here
    loadDashboard() {
      return this.loadComponent("dashboard", "home");
    },
  },
};
</script>
