<template>
  <div class="requestEditModal">
    <v-overlay opacity="0.75">
      <v-fade-transition>
        <v-sheet light class="requestModal" style="background: transparent">
          <v-card elevation="2" :loading="isLoading">
            <v-card-title
              class="headline white--text primary font-weight-light"
            >
              <v-btn icon @click="$emit('close')">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              {{ title }}
            </v-card-title>

            <v-card-text
              v-if="!isLoading"
              id="requestEditModal"
              class="requestModalScrollable"
            >
              <v-form ref="form" v-model="valid">
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" md="6">
                      <label class="text--primary font-weight-bold">
                        <span class="emph">1</span> Select Time Off Category
                      </label>
                      <dropdownBox
                        :items="employeeHours"
                        itemText="description"
                        :modelProperty="selectedCategory"
                        v-on:inputChange="onCategoryChange"
                        :rules="categoryRules"
                      ></dropdownBox>
                      <div class="pto-selected-hours" v-if="showHoursBalance">
                        <p class="text--primary font-weight-bold">
                          Current selected category hours summary
                        </p>
                        <hours-balance :employee-count="selectedCategory" />
                      </div>
                    </v-col>
                    <v-col cols="12" md="6">
                      <label class="text--primary font-weight-bold">
                        <span class="emph">2</span> Select Date(s)
                      </label>
                      <requestDatesPicker
                        v-if="!isLoading"
                        v-on:datesChanged="onDatesChange"
                        :is-modal="isModal"
                        :startDate="startDate"
                        :requestedDates="requestedDates"
                      ></requestDatesPicker>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <requestEntriesTable
                        v-on:hoursChanged="onHoursChange"
                        v-on:entryRemoved="onEntryRemoved"
                        :is-modal="isModal"
                      ></requestEntriesTable>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-alert
                        type="error"
                        outlined
                        v-show="entriesErrorMessage"
                        class="alert-box"
                        v-html="entriesErrorMessage"
                      ></v-alert>
                    </v-col>
                    <v-col cols="12">
                      <label class="text--primary font-weight-bold"
                        ><span class="emph">3</span> Reason for request</label
                      >
                      <comments
                        :max-characters="500"
                        label=""
                        name="requestComment"
                        :propModel="reasonForRequest"
                        v-on:inputChange="onReasonChange"
                      ></comments>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions v-if="!isLoading">
              <v-col class="text-right">
                <v-btn
                  outlined
                  style="margin-right: 10px"
                  @click="$emit('close')"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="green"
                  outlined
                  :disabled="!isFormValid"
                  @click="saveModalForm"
                >
                  Save Request
                </v-btn>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-sheet>
      </v-fade-transition>

      <submitDialog
        :propIsSubmitting="isSubmitting"
        :propSubmitError="submitError"
        @clearErrors="clearErrors"
      />
    </v-overlay>
  </div>
</template>
<script>
import requestForm from "./requestForm.vue";
import Component from "vue-class-component";
import { Mixins, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import submitDialog from "@/components/submitDialog";

const requestFormStore = namespace("timeOff/requestForm");
const employeeScheduleStore = namespace("timeOff/employeeSchedule");

@Component({
  name: "RequestEditModal",
  components: {
    requestForm,
    submitDialog,
  },
})
export default class RequestEditModal extends Mixins(requestForm) {
  @requestFormStore.Action("saveRequest") saveRequest;
  @requestFormStore.Action("formatRequestData") formatRequestData;
  @requestFormStore.Getter("requestSubmitError") requestSubmitError;
  @requestFormStore.Getter("isSubmitting") isSubmitting;
  @requestFormStore.Mutation("setRequestSubmitError") setRequestSubmitError;

  @employeeScheduleStore.State("employeeScheduleChanges")
  employeeScheduleChanges;

  @Prop({
    type: String,
    required: true,
  })
  requestId;

  @Prop({
    type: String,
    required: true,
  })
  employeeUserId;

  @Prop({
    type: Object,
    required: true,
  })
  timeOffRequest;

  get submitError() {
    return this.requestSubmitError;
  }

  get isSubmitError() {
    return Object.keys(this.requestSubmitError).length !== 0;
  }

  clearErrors() {
    this.setRequestSubmitError({});
  }

  get startDate() {
    let entries = this.requestEntries;
    let startDate = "";

    Object.keys(entries).forEach((dateStr) => {
      if (startDate === "" || dateStr < startDate) {
        startDate = dateStr;
      }
    });

    if (startDate === "") {
      return {};
    }

    let dateParts = startDate.split("-");
    return {
      month: parseInt(dateParts[1]),
      year: parseInt(dateParts[0]),
    };
  }

  get title() {
    if (this.isLoading) {
      return "Loading Request.....";
    }
    if (this.isSaving) {
      return "Saving Request.....";
    }
    return "Edit Request " + this.requestId;
  }

  mounted() {
    this.setIsFormValid(false);
  }

  @Watch("employeeScheduleChanges")
  formatData() {
    this.formatRequestData();
  }

  async saveModalForm() {
    await this.validate();

    if (!this.valid) {
      return;
    }

    const requestData = {
      reason: this.reasonForRequest,
      entries: this.transformedEntries,
    };

    await this.saveRequest({
      employeeIdentifier: this.employeeUserId.trim(),
      requestId: this.requestId,
      requestData: requestData,
    });

    if (this.requestSubmitError.status === undefined) {
      this.$emit("editDialogue");
      this.$emit("close");
    }
  }
}
</script>

<style lang="scss" scoped>
.requestModal {
  min-width: 50vw;
}

.requestModalScrollable {
  max-height: 65vh;
  overflow-y: auto;
}
</style>
