<template>
  <div class="withholdingElection">
    <v-form v-model="value" ref="form">
      <v-card class="mx-auto my-4">
        <v-toolbar class="toolBar" color="primary" dark @click="shouldDisplay = !shouldDisplay">
          <v-toolbar-title>Arizona Withhold Elections</v-toolbar-title>
          <v-spacer />
          <v-btn icon>
            <v-icon v-if="shouldDisplay">mdi-arrow-down-circle-outline</v-icon>
            <v-icon v-else>mdi-arrow-right-circle-outline</v-icon>
          </v-btn>
        </v-toolbar>
        <v-expand-transition>
          <v-container fluid v-show="shouldDisplay">
            <template v-if="hasQueued">
              <v-row class="text-center error--text" no-gutters>
                <v-col cols="12" md="12">
                  You have pending AZ A4 changes:<span class="pad-right-10">{{ queued.oldElections }}</span>
                </v-col>
              </v-row>
            </template>
            <v-row class="text-center" no-gutters>
              <v-col cols="12" md="12">
                <v-switch
                  :input-value="electWithhold"
                  :label="withholdingLabel"
                  @change="toggleElectWithhold"
                  :disabled="isSubmitting"
                />
              </v-col>
            </v-row>
            <v-expand-transition>
              <div v-show="!electWithhold">
                <v-row no-gutters>
                  <v-col cols="12" md="2">
                    <v-autocomplete
                      :value="percentageElection"
                      :items="withholdOptions"
                      label="Withhold from gross taxable wages"
                      required
                      clearable
                      placeholder="Choose an amount"
                      :rules="[conditionalWithholdRequired]"
                      :disabled="isSubmitting"
                      @change="setPercentageElection"
                      suffix="%"
                    />
                  </v-col>
                </v-row>
                <v-row class="text-center" no-gutters>
                  <v-col cols="12" md="12">
                    <v-switch
                      :input-value="electAmount"
                      @change="toggleeElectAmount"
                      label="I elect to enter an extra amount to be withheld from each paycheck"
                      :disabled="isSubmitting"
                    />
                  </v-col>
                </v-row>
                <v-expand-transition>
                  <v-row v-if="electAmount" no-gutters>
                    <v-col cols="12" md="3">
                      <v-text-field
                        prefix="$"
                        :value="additionalAmount"
                        label="Amount"
                        @change="changeValidAmount"
                        :rules="[conditionalAmountRequired, isValidAmount]"
                        :disabled="isSubmitting"
                      />
                    </v-col>
                  </v-row>
                </v-expand-transition>
              </div>
            </v-expand-transition>
          </v-container>
        </v-expand-transition>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import validators from "@/utils/validators";

export default {
  data() {
    return {
      shouldDisplay: true,
      value: false,
      amountValidation: /^[1-9]\d*(((\d{3}){1})?(\.\d{0,2})?)$/,
      twoDecimalPlaces: /\.\d{2}$/g,
      oneDecimalPlace: /\.\d{1}$/g,
      noDecimalPlacesWithDecimal: /\.\d{0}$/g,
    };
  },
  computed: {
    ...mapState("payroll/taxForm", [
      "withholdOptions",
      "isSubmitting",
      "percentageElection",
      "electWithhold",
      "electAmount",
      "additionalAmount",
      "hasQueued",
      "queued",
    ]),
    withholdingLabel() {
      return "I elect an Arizona withholding percentage of zero, and I certify that I expect to have no Arizona tax "
        + "liability for the current taxable year";
    },
  },
  methods: {
    ...mapMutations("payroll/taxForm", [
      "setPercentageElection",
      "setAdditionalAmount",
      "setElectAmount",
      "setElectionOption",
      "setElectronicConsent",
      "setElectWithhold",
    ]),
    isValidAmount(value) {
      return this.amountValidation.test(value) || "Invalid Amount";
    },
    changeValidAmount(value) {
      this.setAdditionalAmount(value);
      if (this.amountValidation.test(value)) {
        // Input is valid, check the number of decimal places
        if (value.match(this.twoDecimalPlaces)) {
          // all good
          return;
        }
        if (value.match(this.noDecimalPlacesWithDecimal)) {
          // add two decimal places
          this.setAdditionalAmount(`${value}00`);
          return;
        }
        if (value.match(this.oneDecimalPlace)) {
          // add one decimal place
          this.setAdditionalAmount(`${value}0`);
          return;
        }
        // else there is no decimal places and no decimal
        this.setAdditionalAmount(`${value}.00`);
      }
    },
    toggleElectWithhold(val) {
      this.setAdditionalAmount("");
      this.setPercentageElection(val ? "0.0" : "");
      this.setElectAmount(false);
      if (val) {
        this.setElectWithhold(val);
      } else {
        this.setElectWithhold(false);
      }
      this.setAdditionalAmount("");
    },
    toggleeElectAmount(val) {
      if (!val) {
        this.setAdditionalAmount("");
        this.setElectAmount(false);
      } else {
        this.setElectAmount(val);
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    conditionalAmountRequired(val) {
      if (this.electAmount) {
        return this.required(val);
      }
      return true;
    },
    conditionalWithholdRequired(val) {
      if (!this.electWithhold) {
        return this.required(val);
      }
      return true;
    },
    required(val) {
      return (
        (!Number.isNaN(val) && validators.required(val)) || "This field is required"
      );
    },
  },
  mounted() {
    if (this.additionalAmount && this.additionalAmount > 0) {
      this.changeValidAmount(this.additionalAmount.toString());
    }
  },
};
</script>
<style lang="scss" scoped>
.pad-right-10 {
  padding-left: 10px;
}
</style>
