<template>
  <v-dialog v-model="dialog" max-width="90%" persistent transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="secondary">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer />
        <v-btn dark @click="closeDialog"> Close </v-btn>
      </v-toolbar>
      <iframe :src="pdfURL" :title="title" />
    </v-card>
  </v-dialog>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "PdfView",
})
export default class PdfView extends Vue {
  @Prop({ type: Boolean, default: false }) dialog;

  @Prop({ type: String, default: "Title" }) title;

  @Prop({ type: String, default: "" }) pdfURL;

  closeDialog() {
    this.$emit("closeDialog");
  }
}
</script>

<style scoped>
iframe {
  height: 100vh;
  width: 100%;
}
</style>
