import personalInfoApi from "@/modules/personalInfo/api/personalInfo";
import { getEnv, getErrorMessage } from "@/utils/util";

export default {
  async fetchStates({ commit, dispatch }, tries = 1) {
    try {
      const { data } = await personalInfoApi.getStates();

      commit("setStates", data._embedded.states);
    } catch (error) {
      if (tries < getEnv("VUE_APP_SETTINGS_RETRY_COUNT")) {
        await dispatch("fetchStates", ++tries);
      } else {
        commit("addSettingsFetchError", {
          setting: "states",
          error: getErrorMessage(error),
        });
      }
    }
  },
};
