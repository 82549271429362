import jwtDecode from "jwt-decode";

export default {
  setIsLoggedIn(state, isLoggedIn) {
    state.isLoggedIn = isLoggedIn;
  },
  setLoginRedirect(state, loginRedirect) {
    state.loginRedirect = loginRedirect;
  },
  setJWT(state, jwt) {
    const decoded = jwtDecode(jwt);
    state.token = decoded.token;
    state.userId = decoded.userId;
    state.jwt = jwt;
    sessionStorage.setItem("jwt", jwt);
  },
  clearAuthentication() {
    this.commit("auth/setDefault");

    sessionStorage.removeItem("jwt");
    sessionStorage.removeItem("isMultiFactorValidated");
  },
  setError(state, error) {
    const responseData = error?.response?.data;
    if (responseData?.detail || responseData?.title) {
      if (responseData?.detail === "No email address found") {
        state.error = "No email address on file. Please contact your leader or HR.";
      } else {
        state.error = responseData?.detail || responseData?.title;
      }
    } else {
      state.error = error;
    }
  },
  setUserInfo(state, info) {
    state.userInfo = info;
  },
  setIsLoggingIn(state, data) {
    state.isLoggingIn = data;
  },
  setFetchUserInfoError(state, error) {
    state.fetchUserInfoError = error;
  },
  setIsLoggingOut(state, data) {
    state.isLoggingOut = data;
  },
  setDefault(state) {
    state.isLoggedIn = false;
    state.token = null;
    state.jwt = null;
    state.userInfo = null;
    state.error = null;
    state.isLoggingIn = false;
    state.fetchUserInfoError = null;
    state.isLoggingOut = false;
    state.isMultiFactorValidated = false;
    state.multiFactorApiResponse = {};
    state.isLoadingMultiFactor = false;
    state.multiFactorVerificationError = null;
  },
  /**
   *  multi-factor setters
   */
  setIsMultiFactorValidated(state, data) {
    const isValidated = (data === "true" || data === true);
    state.isMultiFactorValidated = isValidated;
    window.sessionStorage.setItem("isMultiFactorValidated", isValidated ? "true" : "false");
  },
  setMultiFactorApiResponse(state, data) {
    state.multiFactorApiResponse = data;
  },
  setIsLoadingMultiFactor(state, data) {
    state.isLoadingMultiFactor = data;
  },
  setMultiFactorVerificationError(state, error) {
    const responseData = error?.response?.data;
    if (responseData?.detail || responseData?.title) {
      state.multiFactorVerificationError = responseData?.detail || responseData?.title;
    } else {
      state.multiFactorVerificationError = error;
    }
  },
};
