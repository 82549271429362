import { getEnv, getErrorMessage } from "@/utils/util";
import Vue from "vue";

export default {
  async fetchSettings({ commit, dispatch }) {
    commit("setIsLoading", true);
    try {
      const stateCodes = dispatch("fetchStates");

      await stateCodes;

      commit("setIsLoading", false);
    } catch (error) {
      commit("addSettingsFetchError", {
        setting: "settings",
        error: getErrorMessage(error),
      });
      commit("setIsLoading", false);
    }
  },
  async fetchStates({ commit, dispatch }, tries = 1) {
    try {
      const { data } = await Vue.$apis.core.getStates();

      commit("setStates", data._embedded.states);
    } catch (error) {
      if (tries < getEnv("VUE_APP_SETTINGS_RETRY_COUNT")) {
        await dispatch("fetchStates", ++tries);
      } else {
        commit("addSettingsFetchError", {
          setting: "states",
          error: getErrorMessage(error),
        });
      }
    }
  },
};
