<template>
  <v-card class="my-4">
    <v-card-text class="mt-2">
      <v-alert icon="mdi-information-variant" prominent color="blue" outlined class="w4InfoAlert">
        <p class="w4InfoAlertText">
          <strong>Complete Steps 3-4(b) on Form W-4 for only ONE of these jobs.</strong> Leave those steps blank for the
          other jobs. (Your withholding will be most accurate if you complete Steps 3-4(b) on the Form W-4 for the
          highest paying job.)
        </p>
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "w4InfoCopy",
  data() {
    return {
      alignmentType: "center",
    };
  },
};
</script>

<style scoped>
.w4InfoAlert {
  border-color: transparent !important;
}
.w4InfoAlertText {
  color: rgba(0, 0, 0, 0.6);
  padding-top: 15px;
}
@media only screen and (max-width: 599px) {
  .w4InfoAlert::v-deep .v-alert__wrapper {
    display: block;
    text-align: center;
  }
  .w4InfoAlert::v-deep .v-alert__content {
    text-align: left;
  }
}
</style>
