import timeOffApi from "@/modules/timeOff/api/timeOff";
import { getApiError } from "@/utils/errorUtils";

export default {
  async updateAdminUser({ commit }, params) {
    commit("setIsFetchingData", true);

    try {
      await timeOffApi[`${params.userType}Update`](
        params.userId.trim(),
        params.payload
      );
    } catch (error) {
      commit("setSubmitError", getApiError(error));
    }
    commit("setIsFetchingData", false);
  },
  async removeAdminUser({ commit }, { userType, userId }) {
    commit("setIsSubmitting", true);
    try {
      await timeOffApi[`${userType}Delete`](userId.trim());
    } catch (error) {
      commit("setSubmitError", getApiError(error));
    }
    commit("setIsSubmitting", false);
  },
  async addAdminUser({ commit }, { userType, user }) {
    commit("setIsSubmitting", true);

    try {
      const { data } = await timeOffApi[`${userType}Create`](user);

      commit("setNewAdminUser", data ?? null);
    } catch (error) {
      commit("setSubmitError", getApiError(error));
    }
    commit("setIsSubmitting", false);
  },
  async fetchAdminUsers({ commit }, { userType, params }) {
    commit("setIsFetchingData", true);
    commit("setFetchError", null);

    try {
      const { data } = await timeOffApi[userType](params);

      commit("setAdminUsers", data._embedded[userType] ?? []);
      commit("setTotalAdminUsers", data._total_items);
    } catch (error) {
      commit("setFetchError", getApiError(error));
    }
    commit("setIsFetchingData", false);
  },
  async addCompanyHoliday({ commit }, { date }) {
    let payload = {
      date: date,
    };

    try {
      return await timeOffApi.addCompanyHoliday(payload);
    } catch (error) {
      commit("setSubmitError", getApiError(error));
    }
  },
  async deleteCompanyHoliday({ commit }, { date }) {
    try {
      await timeOffApi.deleteCompanyHoliday(date);
    } catch (error) {
      commit("setSubmitError", getApiError(error));
    }
  },
  async removeSupervisorProxy({ commit }, { payload }) {
    commit("setIsSubmitting", true);

    try {
      await timeOffApi.employeeProxiesDelete(payload);
    } catch (error) {
      commit("setSubmitError", getApiError(error));
    }
    commit("setIsSubmitting", false);
  },
  async updateSupervisorProxy({ commit }, { payload }) {
    commit("setIsFetchingData", true);

    try {
      await timeOffApi.employeeProxiesUpdate(payload);
    } catch (error) {
      commit("setSubmitError", getApiError(error));
    }
    commit("setIsFetchingData", false);
  },
  resetListViewOptions({ commit, state }) {
    commit("setListViewOptions", state.defaultListViewOptions);
  },
  async fetchScheduleChanges({ commit }, { params }) {
    commit("setFetchError", null);

    try {
      const { data } = await timeOffApi.fetchScheduleChanges(params);

      commit("setScheduleChanges", data._embedded.scheduleChanges ?? []);
      commit("setTotalScheduleChanges", data._total_items);
    } catch (error) {
      commit("setFetchError", getApiError(error));
    }
  },
  async fetchScheduleChangesDownload({ commit }, params) {
    try {
      const { data, headers } = await timeOffApi.fetchScheduleChangesDownload(
        params
      );

      return { data, headers };
    } catch (error) {
      commit("setFetchError", getApiError(error));
    }
  },
};
