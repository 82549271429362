import cloneDeep from "lodash/cloneDeep";
import forEach from "lodash/forEach";

function findMatchingEducation(educations, sequenceNumber, feId) {
  if (sequenceNumber !== 0) {
    return educations.find(
      (education) => education.sequenceNumber === sequenceNumber,
    );
  }
  return educations.find((education) => education.feId === feId);
}
function setMatchingEducationValue(educations, sequenceNumber, feId, key, val) {
  findMatchingEducation(educations, sequenceNumber, feId)[key] = val;
}

function getErrors(data) {
  function getErrorsRecursive(errorData, curr) {
    if (typeof errorData === "object" && errorData !== null) {
      forEach(errorData, (d) => getErrorsRecursive(d, curr));
    } else {
      curr.push(errorData);
    }
  }

  const result = [];
  if (typeof data === "object" && data !== null) {
    getErrorsRecursive(data, result);
  }
  return result;
}

export default {
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setIsSaving(state, isSaving) {
    state.isSaving = isSaving;
  },
  setError(state, error) {
    if (error?.response?.data?.errors) {
      state.error = error.response.data.errors;
    } else if (error?.response?.data?.title) {
      state.error = error.response.data.title;
    } else {
      state.error = error;
    }
  },
  setSavingError(state, error) {
    if (error?.response?.data?.title && error?.response?.data?.errors) {
      state.submitError = {
        title: error.response.data.title,
        errors: getErrors(error.response.data.errors),
      };
    } else if (error?.response?.data?.title) {
      state.submitError = error.response.data.title;
    } else {
      state.submitError = error;
    }
  },
  setInitialEducations(state, educations) {
    if (educations.length > 0) {
      state.initialEducations = educations.map((val) => ({
        ...val,
        deleteCode: false,
        degree: val.degree.trim().padEnd(3, " "),
        gpa: val.gpa.trim(),
        graduationYear: val.graduationYear.trim(),
        major: val.major.trim(),
        minor: val.minor.trim(),
        school: val.school.trim(),
        subject: val.subject.trim(),
        startYear: val.startYear.trim(),
        feId: Math.random(),
      }));
    } else {
      state.initialEducations = [
        {
          deleteCode: false,
          degree: "",
          gpa: "",
          graduationYear: "",
          major: "",
          minor: "",
          school: "",
          subject: "",
          sequenceNumber: 0,
          startYear: "",
          feId: Math.random(),
        },
      ];
    }
  },
  addEducation(state) {
    state.workingEducations.push({
      deleteCode: false,
      degree: "",
      gpa: "",
      graduationYear: "",
      major: "",
      minor: "",
      school: "",
      subject: "",
      sequenceNumber: 0,
      startYear: "",
      feId: Math.random(),
    });
  },
  deleteEducation(state, education) {
    state.workingEducations = state.workingEducations.filter(
      (e) => e !== education,
    );
  },
  createWorkingEducations(state) {
    state.workingEducations = cloneDeep(state.initialEducations).map(
      (education) => education,
    );
  },
  setWorkingDegree(state, { feId, sequenceNumber, val }) {
    setMatchingEducationValue(
      state.workingEducations,
      sequenceNumber,
      feId,
      "degree",
      val.trim().padEnd(3, " "),
    );
  },
  setWorkingGpa(state, { feId, sequenceNumber, val }) {
    setMatchingEducationValue(
      state.workingEducations,
      sequenceNumber,
      feId,
      "gpa",
      val,
    );
  },
  setWorkingGraduationYear(state, { feId, sequenceNumber, val }) {
    setMatchingEducationValue(
      state.workingEducations,
      sequenceNumber,
      feId,
      "graduationYear",
      val,
    );
  },
  setWorkingMajor(state, { feId, sequenceNumber, val }) {
    setMatchingEducationValue(
      state.workingEducations,
      sequenceNumber,
      feId,
      "major",
      val,
    );
  },
  setWorkingMinor(state, { feId, sequenceNumber, val }) {
    setMatchingEducationValue(
      state.workingEducations,
      sequenceNumber,
      feId,
      "minor",
      val,
    );
  },
  setWorkingSchool(state, { feId, sequenceNumber, val }) {
    setMatchingEducationValue(
      state.workingEducations,
      sequenceNumber,
      feId,
      "school",
      val,
    );
  },
  setWorkingSubject(state, { feId, sequenceNumber, val }) {
    setMatchingEducationValue(
      state.workingEducations,
      sequenceNumber,
      feId,
      "subject",
      val,
    );
  },
  setWorkingStartYear(state, { feId, sequenceNumber, val }) {
    setMatchingEducationValue(
      state.workingEducations,
      sequenceNumber,
      feId,
      "startYear",
      val,
    );
  },
  setDegreeCodes(state, data) {
    state.degrees = data._embedded.degrees.map((c) => ({
      code: c.code.trim().padEnd(3, " "),
      description: c.description.trim(),
    }));
  },
  mergeEducationCodes(state, data) {
    data._embedded.educations.forEach((value) => {
      const degreeExists = state.degrees.filter((elem) => (
        elem.code.trim().padEnd(3, " ") === value.code.trim().padEnd(3, " ")
      ));

      if (degreeExists.length === 0) {
        state.degrees.push({
          code: value.code.trim().padEnd(3, " "),
          description: value.description.trim(),
        });
      }

      state.degrees.sort((a, b) => (a.code > b.code ? 1 : -1));
    });
  },
};
