<template>
  <v-card elevation="2" :loading="isLoading ? 'grey lighten-2' : null">
    <v-card-title class="headline white--text primary font-weight-light">
      Audit Schedule Changes
    </v-card-title>

    <v-card-text>
      <v-sheet>
        <v-form ref="filterForm">
          <v-container class="filters">
            <v-row>
              <v-col cols="9" lg="6" class="pb-0">
                <v-text-field
                  readonly
                  outlined
                  clearable
                  label="Employee"
                  v-model="selectedEmployeeName"
                  @click:clear="clearSelectedEmployee('employee')"
                ></v-text-field>
              </v-col>
              <v-col cols="3" lg="1" class="pl-0">
                <v-btn
                  color="primary"
                  class="filterButtons"
                  @click="openEmployeeSelect('employee')"
                >
                  Select
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="9" lg="6" class="pb-0 pt-0">
                <v-text-field
                  readonly
                  outlined
                  clearable
                  label="Manager"
                  v-model="selectedManagerName"
                  @click:clear="clearSelectedEmployee('manager')"
                ></v-text-field>
              </v-col>
              <v-col cols="3" lg="1" class="pl-0 pt-0">
                <v-btn
                  color="primary"
                  class="filterButtons"
                  @click="openEmployeeSelect('manager')"
                >
                  Select
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" md="4" lg="3" class="pb-0 pt-0">
                <v-menu
                  v-model="startDateMenu"
                  :close-on-content-click="false"
                  :nudge-bottom="55"
                  transition="scale-transition"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formattedStartDate"
                      readonly
                      clearable
                      outlined
                      label="Start Date"
                      append-icon="mdi-calendar-month-outline"
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="resetDate('start')"
                      :rules="[startDateValid]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    :color="datePickerColor"
                    @input="startDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" md="4" lg="3" class="pb-0 pt-0">
                <v-menu
                  v-model="endDateMenu"
                  :close-on-content-click="false"
                  :nudge-bottom="55"
                  transition="scale-transition"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formattedEndDate"
                      readonly
                      clearable
                      outlined
                      label="End Date"
                      append-icon="mdi-calendar-month-outline"
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="resetDate('end')"
                      :rules="[endDateValid]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="endDate"
                    :color="datePickerColor"
                    @input="endDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="1" class="pt-0">
                <v-btn
                  color="primary"
                  @click="newSearch"
                  class="filterButtons"
                  :disabled="!isFormValid || hasNoFilter"
                >
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-sheet>
      <v-sheet>
        <v-data-table
          class="elevation-1"
          :loading="isLoading"
          loading-text="Loading Schedule Changes..."
          no-data-text="No Schedule Changes"
          :headers="tableHeaders"
          :footer-props="tableFooter"
          :items="scheduleChanges"
          :server-items-length="totalScheduleChanges"
          :options.sync="options"
          item-key="id"
          mobile-breakpoint="725"
        >
          <template v-slot:[`item.employeeId`]="{ item }">
            {{ formatName(item, "employee") }}
          </template>
          <template v-slot:[`item.previousSchedule`]="{ item }">
            <div
              class="scheduleTable"
              v-for="(hours, day) in item.previousSchedule"
              :key="day"
            >
              <strong>{{ day.substring(9) }}</strong
              ><br />{{ hours }}
            </div>
          </template>
          <template v-slot:[`item.currentSchedule`]="{ item }">
            <div
              class="scheduleTable"
              v-for="(hours, day) in item.currentSchedule"
              :key="day"
            >
              <strong>{{ day.substring(9) }}</strong
              ><br />
              <span
                :class="{
                  differentData:
                    item.currentSchedule[day] !== item.previousSchedule[day],
                }"
              >
                {{ hours }}
              </span>
            </div>
          </template>
          <template v-slot:[`item.managerEmployeeId`]="{ item }">
            <a :href="emailLink(item)">
              {{ formatName(item, "manager") }}
            </a>
          </template>
          <template v-slot:[`item.changedByEmployeeId`]="{ item }">
            {{ formatName(item, "changedBy") }}
          </template>
          <template v-slot:[`item.modifiedTimestamp`]="{ item }">
            {{ formatDate(item) }}
          </template>
        </v-data-table>
      </v-sheet>
    </v-card-text>
    <v-card-actions class="downloadButton">
      <v-btn
        outlined
        @click="downloadSpreadsheet"
        :loading="isButtonLoading"
        :disabled="hasNoRecords || hasTooManyRecords"
      >
        <v-icon left> mdi-microsoft-excel </v-icon>
        Download Report In Excel
      </v-btn>
    </v-card-actions>
    <p v-show="isButtonLoading" class="downloadMessage">
      * The download can take up to 2 minutes
    </p>
    <p v-show="hasTooManyRecords" class="downloadMessage">
      * There are too many records in the table for an excel download to
      successfully complete
    </p>
    <employeeDataTableSelectDialog
      :title="employeeSelectDialog.title"
      :show="employeeSelectDialog.show"
      searchType="infinium"
      :returnObject="true"
      @close="closeEmployeeSelect"
      @employeeSelected="setSelectedEmployee"
    />
  </v-card>
</template>

<script>
import { Component, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import EmployeeDataTableSelectDialog from "@/modules/timeOff/components/employeeDataTableSelectDialog.vue";
import dates from "@/utils/dates";
import themes from "@/utils/themes";

const adminStore = namespace("timeOff/admin");

@Component({
  name: "AuditScheduleChanges",
  components: { EmployeeDataTableSelectDialog },
})
export default class auditScheduleChanges {
  @adminStore.Action("fetchScheduleChanges") fetchScheduleChanges;
  @adminStore.Action("fetchScheduleChangesDownload")
  fetchScheduleChangesDownload;
  @adminStore.Getter("scheduleChanges") scheduleChanges;
  @adminStore.Getter("totalScheduleChanges") totalScheduleChanges;
  @adminStore.Mutation("setScheduleChanges") setScheduleChanges;
  @adminStore.Mutation("setTotalScheduleChanges") setTotalScheduleChanges;

  data() {
    return {
      isLoading: false,
      isButtonLoading: false,
      isFormValid: true,
      sort: "",
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      tableFooter: {
        "items-per-page-options": [10, 25],
        "show-current-page": true,
        "show-first-last-page": true,
      },
      tableHeaders: [
        {
          text: "Employee",
          align: "center",
          value: "employeeId",
          sortable: false,
          width: "15%",
        },
        {
          text: "Previous Schedule",
          align: "center",
          value: "previousSchedule",
          sortable: false,
          width: "20%",
        },
        {
          text: "Current Schedule",
          align: "center",
          value: "currentSchedule",
          sortable: false,
          width: "20%",
        },
        {
          text: "Supervisor Name",
          align: "center",
          value: "managerEmployeeId",
          sortable: false,
          width: "15%",
        },
        {
          text: "Changed",
          align: "center",
          value: "modifiedTimestamp",
          sortable: true,
          width: "15%",
        },
        {
          text: "Changed By",
          align: "center",
          value: "changedByEmployeeId",
          sortable: false,
          width: "15%",
        },
      ],
      daysOfWeek: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
      employeeSelectDialog: {
        title: "Select Employee",
        show: false,
      },
      datePickerColor: themes.Swift.primary,
      context: "",
      selectedEmployee: null,
      selectedEmployeeName: "",
      selectedEmployeeParam: null,
      selectedManager: null,
      selectedManagerName: "",
      selectedManagerParam: null,
      startDateMenu: false,
      startDate: null,
      formattedStartDate: "",
      startDateParam: null,
      endDateMenu: false,
      endDate: null,
      formattedEndDate: "",
      endDateParam: null,
    };
  }

  @Watch("options", { deep: true })
  onOptionsChange() {
    this.sort = "modifiedTimestamp";

    if (this.options.sortDesc[0]) {
      this.sort += ":desc";
    }

    this.refresh();
  }

  @Watch("startDate")
  onStartDateChange() {
    this.formattedStartDate =
      this.startDate === null ? "" : dates.dateToString(this.startDate);

    this.isFormValid = this.$refs.filterForm.validate();
  }

  @Watch("endDate")
  onEndDateChange() {
    this.formattedEndDate =
      this.endDate === null ? "" : dates.dateToString(this.endDate);

    this.isFormValid = this.$refs.filterForm.validate();
  }

  get hasNoFilter() {
    return (
      this.selectedEmployee === null &&
      this.selectedManager === null &&
      this.startDate === null &&
      this.endDate === null
    );
  }

  get hasNoRecords() {
    return this.totalScheduleChanges === 0;
  }

  get hasTooManyRecords() {
    /**
     * If you try to download too many records, the endpoint will time out
     * 6000 records is close to the limit, but should consistently return successfully
     */
    return this.totalScheduleChanges > 6000;
  }

  startDateValid() {
    return this.startDate !== null &&
      this.endDate !== null &&
      this.startDate > this.endDate
      ? "Start Date must be before End Date"
      : true;
  }

  endDateValid() {
    return this.startDate !== null &&
      this.endDate !== null &&
      this.startDate > this.endDate
      ? "End Date must be after Start Date"
      : true;
  }

  refresh() {
    this.setScheduleChanges([]);
    this.setTotalScheduleChanges(0);

    if (
      !(
        this.selectedEmployeeParam === null &&
        this.selectedManagerParam === null &&
        this.startDateParam === null &&
        this.endDateParam === null
      )
    ) {
      this.loadScheduleChanges();
    }
  }

  newSearch() {
    this.selectedEmployeeParam = this.selectedEmployee;
    this.selectedManagerParam = this.selectedManager;
    this.startDateParam = this.startDate;
    this.endDateParam = this.endDate;

    if (this.options.page === 1) {
      this.refresh();
    } else {
      this.options.page = 1;
    }
  }

  async loadScheduleChanges() {
    this.isLoading = true;

    await this.fetchScheduleChanges({
      params: this.setParams(),
    });

    this.isLoading = false;
  }

  async downloadSpreadsheet() {
    this.isButtonLoading = true;

    const scheduleChangesDownloadData = await this.fetchScheduleChangesDownload(
      this.setParams()
    );

    const scheduleChangesDownload = scheduleChangesDownloadData.data;

    const fileName = scheduleChangesDownloadData.headers["content-disposition"]
      .split("filename=")[1]
      .replace(/["]+/g, "");

    const url = window.URL.createObjectURL(scheduleChangesDownload);
    const link = document.createElement("a");
    link.href = url;

    link.setAttribute("download", fileName);

    link.click();

    this.isButtonLoading = false;
  }

  setParams() {
    let params = {
      limit: this.options.itemsPerPage,
      page: this.options.page,
      sort: this.sort,
    };

    if (this.selectedEmployeeParam !== null) {
      params.employerId = this.selectedEmployeeParam.employerId.trim();
      params.employeeId = this.selectedEmployeeParam.employeeId.trim();
    }

    if (this.selectedManagerParam !== null) {
      params.managerEmployerId = this.selectedManagerParam.employerId.trim();
      params.managerEmployeeId = this.selectedManagerParam.employeeId.trim();
    }

    if (this.startDateParam !== null) {
      params.startDate = this.startDateParam;
    }

    if (this.endDateParam !== null) {
      params.endDate = this.endDateParam;
    }

    return params;
  }

  emailLink(scheduleChange) {
    const employeeName = this.formatName(scheduleChange, "employee");
    const changedByName = this.formatName(scheduleChange, "changedBy");
    const changedBy = dates.dateToStringCustomFormat(
      scheduleChange.modifiedTimestamp.date,
      "MM/DD/YYYY hh:mm A"
    );

    const subject = `Employee schedule change notice for ${employeeName}`;

    const body =
      `The employee schedule for ${employeeName} was updated ${changedBy} by ${changedByName}:%0D%0A%0D%0A` +
      `PREVIOUS SCHEDULE:%0D%0A` +
      `SUN: ${scheduleChange.previousSchedule.SCHEDULE_SUN}%0D%0A` +
      `MON: ${scheduleChange.previousSchedule.SCHEDULE_MON}%0D%0A` +
      `TUE: ${scheduleChange.previousSchedule.SCHEDULE_TUE}%0D%0A` +
      `WED: ${scheduleChange.previousSchedule.SCHEDULE_WED}%0D%0A` +
      `THU: ${scheduleChange.previousSchedule.SCHEDULE_THU}%0D%0A` +
      `FRI: ${scheduleChange.previousSchedule.SCHEDULE_FRI}%0D%0A` +
      `SAT: ${scheduleChange.previousSchedule.SCHEDULE_SAT}%0D%0A%0D%0A` +
      `CURRENT SCHEDULE:%0D%0A` +
      `SUN: ${scheduleChange.currentSchedule.SCHEDULE_SUN}%0D%0A` +
      `MON: ${scheduleChange.currentSchedule.SCHEDULE_MON}%0D%0A` +
      `TUE: ${scheduleChange.currentSchedule.SCHEDULE_TUE}%0D%0A` +
      `WED: ${scheduleChange.currentSchedule.SCHEDULE_WED}%0D%0A` +
      `THU: ${scheduleChange.currentSchedule.SCHEDULE_THU}%0D%0A` +
      `FRI: ${scheduleChange.currentSchedule.SCHEDULE_FRI}%0D%0A` +
      `SAT: ${scheduleChange.currentSchedule.SCHEDULE_SAT}%0D%0A%0D%0A` +
      `========================================%0D%0A%0D%0A` +
      `THIS EMAIL WAS AUTOMATICALLY GENERATED. PLEASE DO NOT RESPOND TO THIS EMAIL.%0D%0A` +
      `IF YOU HAVE ANY QUESTIONS, PLEASE CONTACT YOUR DIRECT SUPERVISOR.%0D%0A` +
      `========================================`;

    return `mailto:${scheduleChange.managerEmail.trim()}?subject=${subject}&body=${body}`;
  }

  formatName(employee, context) {
    if (employee === null) {
      return "";
    }

    let firstName;
    let lastName;
    let employerId;
    let employeeId;
    let positionTitle;

    switch (context) {
      case "employee":
        firstName = employee.employeeFirstName.trim();
        lastName = employee.employeeLastName.trim();
        employerId = employee.employerId.trim();
        employeeId = employee.employeeId.trim();
        break;
      case "manager":
        firstName = employee.managerFirstName.trim();
        lastName = employee.managerLastName.trim();
        employerId = employee.managerEmployerId.trim();
        employeeId = employee.managerEmployeeId.trim();
        break;
      case "changedBy":
        firstName = employee.changedByFirstName.trim();
        lastName = employee.changedByLastName.trim();
        employerId = employee.changedByEmployerId.trim();
        employeeId = employee.changedByEmployeeId.trim();
        break;
      default:
        firstName = employee.firstName.trim();
        lastName = employee.lastName.trim();
        employerId = employee.employerId.trim();
        employeeId = employee.employeeId.trim();
        positionTitle = employee.positionTitle.trim();
        return `${lastName}, ${firstName} (${employerId} - ${employeeId}) - ${positionTitle}`;
    }

    return `${firstName} ${lastName} (${employerId} - ${employeeId})`;
  }

  formatDate(date) {
    return dates.dateToStringCustomFormat(
      date.modifiedTimestamp.date,
      "MM/DD/YYYY hh:mm A"
    );
  }

  setSelectedEmployee(employee) {
    if (this.context === "employee") {
      this.selectedEmployee = employee;
      this.selectedEmployeeName = this.formatName(employee);
    }
    if (this.context === "manager") {
      this.selectedManager = employee;
      this.selectedManagerName = this.formatName(employee);
    }

    this.closeEmployeeSelect();
  }

  clearSelectedEmployee(context) {
    this.context = context;
    this.setSelectedEmployee(null);
  }

  openEmployeeSelect(context) {
    this.context = context;
    this.employeeSelectDialog.show = true;
  }

  closeEmployeeSelect() {
    this.employeeSelectDialog.show = false;
  }

  resetDate(context) {
    if (context === "start") {
      this.startDate = null;
    }
    if (context === "end") {
      this.endDate = null;
    }
  }

  destroyed() {
    this.setScheduleChanges([]);
    this.setTotalScheduleChanges(0);
  }
}
</script>

<style scoped>
.scheduleTable {
  display: inline-block;
  width: 45px;
}
.differentData {
  color: red;
}
.filters {
  padding-left: 0;
}
.filterButtons {
  margin-top: 10px;
}
.downloadButton {
  padding-left: 16px;
}
.downloadMessage {
  padding-left: 16px;
  padding-bottom: 8px;
}
</style>
