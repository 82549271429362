<template>
  <v-form ref="personalInfo">
    <v-card class="my-4">
      <v-toolbar class="toolBar" color="primary" dark>
        <v-toolbar-title class="cardTitle"><i class="mdi mdi-account" /> Step 1 - Personal Information</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-2 personal-info-w4">
        <CurrentPersonalInformation :model="personalInformation" :w4model="currentW4Information" />
        <ClaimingStatus v-if="!isFilingStatusLocked" @validate="$emit('validate')" />
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import CurrentPersonalInformation from "@/modules/payroll/updateW4/components/currentPersonalInformation.vue";
import ClaimingStatus from "@/modules/payroll/updateW4/components/claimingStatus.vue";

const w4store = namespace("payroll/w4");

@Component({
  name: "W4PersonalInfo",
  components: { CurrentPersonalInformation, ClaimingStatus },
})
export default class W4PersonalInfo extends Vue {
  @w4store.Getter("getPersonalInformation") personalInformation;

  @w4store.Getter("getCurrentW4Information") currentW4Information;

  @w4store.Getter("getFormData") formData;

  @w4store.Getter("isFilingStatusLocked") isFilingStatusLocked;

  @w4store.State("formSections") formSections;

  @w4store.State("claimStatusOptions") claimStatusOptions;

  @w4store.Mutation("setFormData") setFormData;

  @w4store.Mutation("setFormSections") setFormSections;

  get sections() {
    return this.formSections;
  }

  validate() {
    const isValid = this.$refs.personalInfo.validate();
    this.sections.forEach((section) => {
      if (section.component === "personalInfo") {
        section.valid = isValid;
      }
    });

    this.setFormSections(this.sections);
    return isValid;
  }
}
</script>
