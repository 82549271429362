export default function () {
  return {
    employeeScheduleUserId: null,
    employeeScheduleFirstName: null,
    employeeScheduleLastName: null,
    employeeSchedulePayType: null,
    employeeSchedule: null,
    employeeScheduleFetchError: null,
    employeeScheduleSaveError: {},
    employeeScheduleChanges: [],
    employeeScheduleEmployerId: null,
    employeeScheduleEmployeeId: null,
  };
}
