<template>
  <div>
    <v-menu
      v-model="showDateMenu"
      :close-on-content-click="false"
      :return-values.sync="selectedDates"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      :min-width="140"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-textarea
          :value="dateRangeText"
          :placeholder="placeholder"
          :class="{
            multi: selectedDates.length > 1,
            empty: selectedDates.length <= 0,
          }"
          :append-icon="showDateMenu ? 'mdi-menu-up' : 'mdi-menu-down'"
          auto-grow
          no-resize
          readonly
          flat
          dense
          clearable
          :rows="rows"
          v-bind="attrs"
          v-on="on"
          :height="26 * rows"
          :row-height="26 / rows"
          @click:clear="clearDatePicker"
          @click:append="showDateMenu = !showDateMenu"
        >
        </v-textarea>
      </template>
      <v-date-picker
        v-model="datePicker"
        no-title
        range
        class="column-filter-date-picker"
      >
        <v-sheet class="d-flex flex-column">
          <v-card-text class="text-caption">
            Pick two dates for range, or one date, and click OK
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="error" @click="clearDatePicker"> Clear </v-btn>
            <v-btn text color="primary" @click="saveDatePicker"> OK </v-btn>
          </v-card-actions>
        </v-sheet>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import dates from "@/utils/dates";

export default {
  data() {
    return {
      selectedDates: [],
      datePicker: [],
      showDateMenu: false,
    };
  },
  props: {
    field: { type: String, required: true },
    placeholder: { type: String, required: true },
    datesSelected: { type: Array, default: () => [] },
  },
  methods: {
    clearDatePicker() {
      this.datePicker = this.selectedDates = [];
      this.$emit("search", this.searchValue());
      this.showDateMenu = false;
    },
    saveDatePicker() {
      this.updateDatesWithOrder();
      this.$emit("search", this.searchValue());
      this.showDateMenu = false;
    },
    searchValue() {
      const selectedDates = this.selectedDates;
      let value;

      if (selectedDates.length > 1) {
        const start = dates.dateString(selectedDates[0]);
        const finish = dates.dateString(selectedDates[1]);

        value = start + "||" + finish;
      } else if (selectedDates.length > 0) {
        value = dates.dateString(selectedDates[0]);
      } else {
        value = "";
      }

      return {
        field: this.field,
        value: value,
      };
    },
    updateDatesWithOrder() {
      const picker = this.datePicker;

      if (picker.length > 1) {
        const start = picker[0] > picker[1] ? picker[1] : picker[0];
        const finish = picker[0] > picker[1] ? picker[0] : picker[1];

        // handle the same date being selected twice
        if (start === finish) {
          this.selectedDates = [start];
        } else {
          this.selectedDates = [start, finish];
        }
      } else if (this.datePicker.length > 0) {
        this.selectedDates = [picker[0]];
      } else {
        this.selectedDates = [];
      }
    },
  },
  computed: {
    dateRangeText() {
      const selectedDates = this.selectedDates;

      if (selectedDates.length > 1) {
        const start = dates.dateToString(selectedDates[0]);
        const finish = dates.dateToString(selectedDates[1]);

        return start + " - " + finish;
      } else if (this.selectedDates.length > 0) {
        return dates.dateToString(selectedDates[0]);
      }

      return "";
    },
    rows() {
      return this.selectedDates.length > 1 ? 2 : 1;
    },
  },
  beforeMount() {
    if (this.datesSelected?.length) {
      this.selectedDates = this.datePicker = this.datesSelected;
    }
  },
};
</script>

<style scoped>
>>> .v-textarea textarea {
  font-size: 12px;
  font-weight: normal;
  line-height: 26px;
  width: 75px;
  text-align: center;
}

>>> .v-textarea.multi textarea {
  line-height: 15px;
  text-align: center;
}

>>> .v-textarea textarea::placeholder {
  text-align: center;
  white-space: pre-line;
  overflow: visible;
  overflow-x: visible;
  line-height: 26px;
}

>>> .v-input__icon {
  min-width: inherit;
  width: inherit;
}

>>> .v-icon {
  font-size: 16px;
  font-weight: normal;
}

>>> .v-picker__actions.v-card__actions.v-picker__actions--no-title {
  padding-top: 0;
}

.v-application .text-caption {
  font-size: 0.7rem !important;
  line-height: 1rem;
  padding: 0;
}
</style>
