export default {
  requestQueue(state) {
    return state.requestQueue;
  },
  requests(state) {
    return state.requests;
  },
  requestQueueFetchError(state) {
    return state.requestQueueFetchError;
  },
  reprocessAS400UploadError(state) {
    return state.reprocessAS400UploadError;
  },
  reprocessAS400UploadRequests(state) {
    return state.reprocessAS400UploadRequests;
  },
  totalRequests(state) {
    return state.totalRequests;
  },
};
