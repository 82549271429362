<template>
  <div class="requestFormNavBar">
    <v-navigation-drawer
      class="menuPadding"
      fixed
      clipped
      expand-on-hover
      :mini-variant.sync="isMini"
      permanent
      right
      id="navBar"
    >
      <template v-slot:append>
        <navButton
          action="submit"
          color="accent"
          icon="mdi-send"
          :isMini="isMini"
          :show="showSubmit"
          :disable="!isFormValid"
          v-on="$listeners"
        >
          Submit
        </navButton>
        <navButton
          action="approve"
          color="green"
          icon="mdi-check-outline"
          :isMini="isMini"
          :show="showApprove"
          v-on="$listeners"
        >
          Approve
        </navButton>
        <navButton
          action="deny"
          color="red"
          icon="mdi-cancel"
          :isMini="isMini"
          :show="showDeny"
          :disable="!isApprovalFormValid"
          v-on="$listeners"
        >
          Deny
        </navButton>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { Component, Mixins, Prop } from "vue-property-decorator";
import navButton from "@/modules/timeOff/components/fields/navButton";
import modulePageMixin from "@/utils/mixins";
import { namespace } from "vuex-class";

const requestFormStore = namespace("timeOff/requestForm");

@Component({
  components: { navButton },
})
export default class requestFormNavBar extends Mixins(modulePageMixin) {
  isLoading = false;
  isEmpty = false;
  isMini = true;

  @requestFormStore.Getter("isFormValid") isFormValid;
  @requestFormStore.Getter("isApprovalFormValid") isApprovalFormValid;

  @Prop({
    type: Boolean,
    default: false,
  })
  showApprove;

  @Prop({
    type: Boolean,
    default: false,
  })
  showDeny;

  @Prop({
    type: Boolean,
    default: false,
  })
  showSubmit;
}
</script>

<style scoped></style>
