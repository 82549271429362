import Mock from "@/apis/stubs/mock";

const getW4DocumentArchive = new Mock(
  {
    data: {
      _links: {
        self: {
          href: "http://swift.swift.com:10090"
            + "/EmployeeSelfServiceBackend/development/knight/public/payroll/direct-deposits?page=1",
        },
        first: {
          href:
            "http://swift.swift.com:10090/EmployeeSelfServiceBackend/development/knight/public/payroll/direct-deposits",
        },
        last: {
          href: "http://swift.swift.com:10090"
            + "/EmployeeSelfServiceBackend/development/knight/public/payroll/direct-deposits?page=1",
        },
      },
      _embedded: {
        w4_document_archive: [
          {
            fileId: 20030,
            dateSubmitted: {
              date: "2022-01-31 21:02:12.552667",
              timezone_type: 3,
              timezone: "America/Phoenix",
            },
            fileName: "W4 for Tax Year 2022",
          },
          {
            fileId: 20032,
            dateSubmitted: {
              date: "2022-01-31 21:02:12.552667",
              timezone_type: 3,
              timezone: "America/Phoenix",
            },
            fileName: "W4 for Tax Year 2022",
          },
        ],
      },
      page_count: 1,
      page_size: 25,
      total_items: 2,
      page: 1,
    },
  },
  {
    _links: {
      self: {
        href: "http://swift.swift.com:10090"
          + "/EmployeeSelfServiceBackend/development/knight/public/payroll/direct-deposits?page=1",
      },
      first: {
        href:
          "http://swift.swift.com:10090/EmployeeSelfServiceBackend/development/knight/public/payroll/direct-deposits",
      },
      last: {
        href: "http://swift.swift.com:10090"
          + "/EmployeeSelfServiceBackend/development/knight/public/payroll/direct-deposits?page=1",
      },
    },
  },
  {
    data: {
      type: "http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html",
      title: "Bad Request",
      status: 400,
      detail: "Authorization Parameter Missing",
    },
  },
);

export default getW4DocumentArchive;
