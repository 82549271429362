import staticRoutes from "@/router/routes";
import { find } from "lodash";

function findRoute(moduleRoutes, routeName) {
  if (!moduleRoutes) {
    return null;
  }

  if (moduleRoutes.name === routeName) {
    return moduleRoutes;
  }

  if (moduleRoutes.children) {
    for (let i = 0; i <= moduleRoutes.children.length; i++) {
      const foundRoute = findRoute(moduleRoutes.children[i], routeName); // note: recursive

      if (foundRoute) {
        return foundRoute;
      }
    }
  }

  return null;
}

export default {
  getDynamicRoutes(state) {
    return state.routes;
  },
  getAllRoutes(state) {
    return staticRoutes.concat(state.routes);
  },
  getRouteByName: (state) => (routeName) => {
    for (let i = 0; i <= state.routes.length; i++) {
      const moduleRoutes = state.routes[i];

      const foundRoute = findRoute(moduleRoutes, routeName);

      if (foundRoute) {
        return foundRoute;
      }
    }

    return null;
  },
  getModuleByName: (state) => (moduleName) => {
    const moduleId = moduleName.split(":");
    try {
      return find(state.modules[moduleId[0]], {
        moduleId: moduleName,
      });
    } catch (e) {
      return null;
    }
  },
  getReleaseVersion(state) {
    return state.release;
  },
  getApplicationConfigs(state) {
    return state.application;
  },
  getApplicationConfig: (state) => (key, defaultValue) => {
    if (!(key in state.application)) {
      return defaultValue;
    }
    return state.application[key];
  },
};
