export default {
  isFetchingData(state) {
    return state.isFetchingData;
  },
  isSubmitting(state) {
    return state.isSubmitting;
  },
  progressMessage(state) {
    return state.progressMessage;
  },
  successMessage(state) {
    return state.successMessage;
  },
  submitError(state) {
    return state.submitError;
  },
  fetchError(state) {
    return state.fetchError;
  },

  getPersonalInformation(state) {
    return state.personalInformation;
  },
  getCurrentW4Information(state) {
    return state.currentW4Information;
  },
  getFormData(state) {
    return state.formData;
  },
  isFilingStatusLocked(state) {
    return state.currentW4Information?.isFilingStatusLocked ?? false;
  },
  totalDocumentsInArchive(state) {
    return state.totalDocumentsInArchive;
  },
  getEmailSentTo(state) {
    return state.emailSentTo;
  },
};
