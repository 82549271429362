import timeOffApi from "../../api/timeOff";
import { formatScheduleHours } from "@/utils/util";

export default {
  resetEmployeeSchedule() {
    this.commit("timeOff/employeeSchedule/setEmployeeScheduleUserId", null);
    this.commit("timeOff/employeeSchedule/setEmployeeSchedule", null);
    this.commit("timeOff/employeeSchedule/setEmployeeScheduleFirstName", null);
    this.commit("timeOff/employeeSchedule/setEmployeeScheduleLastName", null);
    this.commit("timeOff/employeeSchedule/setEmployeeSchedulePayType", null);
    this.commit("timeOff/employeeSchedule/setEmployeeScheduleFetchError", null);
    this.commit("timeOff/employeeSchedule/setEmployeeScheduleSaveError", {});
  },
  setEmployeeScheduleFromAPI(state, data) {
    if (Object.prototype.hasOwnProperty.call(data, "mondaySchedule")) {
      this.commit(
        "timeOff/employeeSchedule/setEmployeeSchedule",
        timeOffApi.apiDataToSchedule(data)
      );
    }
    this.commit(
      "timeOff/employeeSchedule/setEmployeeScheduleFirstName",
      data?.employeeFirstName.trim() ?? ""
    );
    this.commit(
      "timeOff/employeeSchedule/setEmployeeScheduleLastName",
      data?.employeeLastName.trim() ?? ""
    );
    this.commit(
      "timeOff/employeeSchedule/setEmployeeSchedulePayType",
      data?.payType.trim() ?? ""
    );
    this.commit(
      "timeOff/employeeSchedule/setEmployeeScheduleEmployerId",
      data?.employerId.trim() ?? ""
    );
    this.commit(
      "timeOff/employeeSchedule/setEmployeeScheduleEmployeeId",
      data?.employeeId.trim() ?? ""
    );
    this.commit("timeOff/employeeSchedule/setEmployeeScheduleFetchError", null);
    this.commit("timeOff/employeeSchedule/setEmployeeScheduleSaveError", {});
  },
  setEmployeeScheduleUserId(state, data) {
    state.employeeScheduleUserId = data?.trim();
  },
  setEmployeeSchedule(state, data) {
    state.employeeSchedule = formatScheduleHours(data);
  },
  setEmployeeScheduleFirstName(state, data) {
    state.employeeScheduleFirstName = data;
  },
  setEmployeeScheduleLastName(state, data) {
    state.employeeScheduleLastName = data;
  },
  setEmployeeSchedulePayType(state, data) {
    state.employeeSchedulePayType = data;
  },
  setEmployeeScheduleFetchError(state, data) {
    state.employeeScheduleFetchError = data;
  },
  setEmployeeScheduleSaveError(state, data) {
    state.employeeScheduleSaveError = data;
  },
  setEmployeeScheduleChanges(state, data) {
    state.employeeScheduleChanges = data;
  },
  setEmployeeScheduleEmployerId(state, data) {
    state.employeeScheduleEmployerId = data;
  },
  setEmployeeScheduleEmployeeId(state, data) {
    state.employeeScheduleEmployeeId = data;
  },
};
