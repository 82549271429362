import timeOffApi from "@/modules/timeOff/api/timeOff";
import { getApiError } from "@/utils/errorUtils";

export default {
  async fetchTimeZones({ commit }) {
    commit("setIsFetchingData", true);
    commit("setFetchError", null);
    try {
      const { data } = await timeOffApi.getAllTimeZones();
      commit("setTimeZones", data._embedded?.timezones ?? []);
    } catch (e) {
      commit("setFetchError", getApiError(e));
    }
    commit("setIsFetchingData", false);
  },
  async fetchTimeOffUserSettings({ commit }) {
    commit("setIsFetchingData", true);
    commit("setFetchError", null);

    try {
      const { data } = await timeOffApi.fetchUserSettings();

      commit("setUserSettings", data._embedded?.settings ?? []);
    } catch (e) {
      commit("setFetchError", getApiError(e));
    }
    commit("setIsFetchingData", false);
  },
  async saveUserSettings({ commit, getters }) {
    commit("setIsSubmitting", true);
    commit("setSubmitError", {});

    const userSettings = getters.userSettings;
    try {
      await timeOffApi.patchUserSettings(userSettings);
      commit("setIsSubmitting", false);
    } catch (error) {
      commit("setSubmitError", getApiError(error));
    } finally {
      commit("setIsSubmitting", false);
    }
  },
  async fetchEmployeeProxies({ commit }, { params }) {
    commit("setIsFetchingData", true);
    commit("setFetchError", null);
    try {
      const { data } = await timeOffApi.employeeProxies(params);
      commit("setEmployeeProxies", data._embedded?.employeeProxies ?? []);
      commit("setTotalEmployeeProxies", data._total_items);
    } catch (e) {
      commit("setFetchError", getApiError(e));
    }
    commit("setIsFetchingData", false);
  },
  async removeEmployeeProxy({ commit }, { payload }) {
    commit("setIsSubmitting", true);
    commit("setSubmitError", {});
    try {
      await timeOffApi.employeeProxiesDelete(payload);
    } catch (error) {
      commit("setSubmitError", getApiError(error));
    }
    commit("setIsSubmitting", false);
  },
  async updateEmployeeProxy({ commit }, { payload }) {
    commit("setIsFetchingData", true);
    commit("setSubmitError", {});

    try {
      await timeOffApi.employeeProxiesUpdate(payload);
    } catch (error) {
      commit("setSubmitError", getApiError(error));
    }
    commit("setIsFetchingData", false);
  },
  async addEmployeeProxy({ commit }, { payload }) {
    commit("setIsSubmitting", true);
    commit("setSubmitError", {});
    try {
      await timeOffApi.employeeProxiesCreate(payload);
    } catch (error) {
      commit("setSubmitError", getApiError(error));
    }
    commit("setIsSubmitting", false);
  },
};
