<template>
  <v-list v-if="Array.isArray(routes)" dark>
    <MainNav
      v-for="route in routes"
      :routes="route"
      :key="route.name"
      :depth="depth + 1"
    />
  </v-list>
  <v-list-group
    v-else-if="routes.children && showInNav(routes)"
    class="nav-list"
    :prepend-icon="routes.meta.icon"
    :sub-group="depth > 1"
    dark
  >
    <template #activator>
      <v-list-item-content dark>
        <v-list-item-title dark>{{ routes.meta.title }}</v-list-item-title>
      </v-list-item-content>
    </template>
    <MainNav
      v-for="childRoute in routes.children"
      :routes="childRoute"
      :key="childRoute.name"
      :depth="depth + 1"
    />
  </v-list-group>
  <v-list-item
    :class="depth > 1 ? 'link v-list-sub-item' : 'top-link'"
    v-else-if="showInNav(routes)"
    :to="{ name: routes.name }"
    active-class="active"
    exact
    dark
  >
    <v-list-item-icon v-if="routes.meta.icon">
      <v-icon class="link-icon">
        {{ routes.meta.icon }}
      </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>{{ routes.meta.title }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { namespace } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";

const auth = namespace("auth");

@Component({
  name: "MainNav",
})
export default class MainNav extends Vue {
  name = "mainNav.vue";

  @auth.Getter("hasAnyRole") hasAnyRole;

  @auth.Getter("hasPermission") hasPermission;

  @auth.Getter("isDriver") isDriver;

  @auth.Getter("shouldExclude") shouldExclude;

  @Prop({
    type: [Array, Object],
    required: true,
  })
    routes;

  @Prop({
    type: Number,
    default: 0,
  })
    depth;

  showInNav(route) {
    if (!route.meta.hideFromNav) {
      if (route.excludeUsers !== undefined) {
        if (this.shouldExclude(route.excludeUsers)) {
          return false;
        }
      }

      if (route.roles !== undefined || route.permissions !== undefined) {
        return (
          this.hasAnyRole(route.roles) || this.hasPermission(route.permissions)
        );
      }
      return true;
    }
    return false;
  }
}
</script>
