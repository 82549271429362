<template>
  <v-form ref="digitalSignature">
    <v-card class="my-4">
      <v-toolbar class="toolBar" color="primary" dark>
        <v-toolbar-title><i class="mdi mdi-pencil" /> Step 5 - Digital Signature</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        Under penalties of perjury, I declare that this certificate, to the best of my knowledge and belief, is true,
        correct, and complete.
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="8">
            <v-text-field
              name="signature"
              label="Signature"
              @paste.prevent
              autocomplete="off"
              v-model="employeeSignature"
              :rules="[validateSignature]"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field label="Date" readonly v-model="dateSigned" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const w4store = namespace("payroll/w4");
const auth = namespace("auth");

@Component({
  name: "digitalSignature",
  components: {},
})
export default class DigitalSignature extends Vue {
  @w4store.Mutation("setFormData") setFormData;

  @w4store.Mutation("setFormSections") setFormSections;

  @w4store.State("formData") formData;

  @w4store.State("formSections") formSections;

  @auth.Getter("firstName") firstName;

  @auth.Getter("lastName") lastName;

  validateSignature() {
    if (
      this.formData.employeeSignature.toUpperCase()
      !== `${this.firstName.trim()} ${this.lastName.trim()}`
    ) {
      return "Signature must include first and last name as displayed in the personal information section";
    }
    return true;
  }

  validate() {
    const isValid = this.$refs.digitalSignature.validate();
    this.sections.forEach((section) => {
      if (section.component === "digitalSignature") {
        section.valid = isValid;
      }
    });
    this.setFormSections(this.sections);
    return isValid;
  }

  get employeeSignature() {
    return this.formData.employeeSignature;
  }

  set employeeSignature(value) {
    this.setFormData({ employeeSignature: value });
    this.$emit("validate");
  }

  get dateSigned() {
    return this.formData.dateSigned.toLocaleDateString();
  }

  get sections() {
    return this.formSections;
  }
}
</script>
