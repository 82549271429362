import Vue from "vue";

export const createDynamicAsset = (file) => {
  const path = `${Vue.$appInfo.company}/${file}`;
  // eslint-disable-next-line import/no-dynamic-require,global-require
  return require(`@/assets/${path}`);
};

export const createDynamicStyleAsset = (file, useCompany = true) => {
  const path = useCompany ? `${Vue.$appInfo.company}/${file}` : file;

  return `styles/${path}`;
};

export const loadDynamicComponent = (component, module) => {
  const company = `${Vue.$appInfo.company.toLowerCase()}`;
  let path = `${module}/components/${component}-${company}`;

  try {
    // if there is a component prefaced with company name, load the company specific component
    // eslint-disable-next-line global-require,import/no-dynamic-require
    require(`@/modules/${path}`);
  } catch (e) {
    // if the component is not prefaced with company name, load the shared component
    path = `${module}/components/${component}`;
  }

  return () => import(`@/modules/${path}`);
};

export const loadDynamicFieldComponent = (component, module) => {
  const company = `${Vue.$appInfo.company.toLowerCase()}`;
  let path = `${module}/components/fields/${component}`;

  try {
    // if the field component is not prefaced with company name, load the shared field component
    // eslint-disable-next-line global-require,import/no-dynamic-require
    require(`@/modules/${path}`);
  } catch (e) {
    // if there is no shared field component, load the company specific field component
    path = `${module}/components/fields/${component}-${company}`;
  }

  return () => import(`@/modules/${path}`);
};
