<template>
  <v-dialog v-model="show" max-width="50%" persistent>
    <v-card>
      <v-card-title class="text-h5">Delete Action</v-card-title>
      <v-card-text>{{ message }}</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="$emit('closeDialog')"
        >Cancel</v-btn
        >
        <v-btn
          color="blue darken-1"
          text
          @click="$emit('deleteConfirmed')"
        >OK</v-btn
        >
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "deleteDialog",
  props: {
    message: {
      type: String,
      required: false,
      default: "Are you sure you want to delete this item?",
    },
    showDialog: { type: Boolean, required: true, default: false },
  },
  computed: {
    show() {
      return this.showDialog;
    },
  },
};
</script>

<style scoped></style>
