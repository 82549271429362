<template>
  <v-container class="employee-schedule">
    <v-row v-if="canEditOtherEmployeesSchedules" class="schedule-controls-row">
      <v-col>
        <v-btn @click="changeEmployee" text color="primary">
          <v-icon left> mdi-account </v-icon>
          Change Employee
        </v-btn>
      </v-col>
      <v-col class="text-right">
        <v-btn
          @click="openMySchedule"
          text
          color="primary"
          v-if="otherEmployeeSelected"
        >
          <v-icon left> mdi-keyboard-return </v-icon>
          Return to my schedule
        </v-btn>
      </v-col>
    </v-row>
    <employeeScheduleCard
      :key="employeeUserId"
      :employeeUserId="employeeUserId"
      :otherEmployeeSelected="otherEmployeeSelected"
    />
    <employeeDataTableSelectDialog
      v-if="canEditOtherEmployeesSchedules"
      :title="employeeSelect.title"
      :show="employeeSelect.show"
      :searchType="employeeSearchType"
      @close="closeEmployeeSelect"
      @employeeSelected="employeeSelected"
    />
  </v-container>
</template>

<script>
import modulePageMixin from "@/utils/mixins";
import employeeDataTableSelectDialog from "@/modules/timeOff/components/employeeDataTableSelectDialog";
import { authorizationMixin } from "@/modules/timeOff/authorizationMixin";
import employeeScheduleCard from "./components/employeeScheduleCard.vue";

export default {
  mixins: [modulePageMixin, authorizationMixin],
  components: {
    employeeScheduleCard,
    employeeDataTableSelectDialog,
  },
  data() {
    return {
      // current employee user - can be logged in user, or chosen from dropdown
      employeeUserId: null,
      employeeSelect: {
        title: "Select Employee",
        show: false,
      },
    };
  },
  computed: {
    employeeScheduleCard() {
      return this.loadComponent(
        "employeeScheduleCard",
        "timeOff/employeeSchedule"
      );
    },
    otherEmployeeSelected() {
      return this.currentUserId !== this.employeeUserId.trim();
    },
  },
  methods: {
    changeEmployee() {
      this.employeeSelect.show = true;
    },

    closeEmployeeSelect() {
      this.employeeSelect.show = false;
    },

    employeeSelected(employeeId) {
      this.employeeUserId = employeeId.trim();
      this.closeEmployeeSelect();
    },

    openMySchedule() {
      this.employeeUserId = this.currentUserId;
      this.closeEmployeeSelect();
    },
  },

  created() {
    // By default, the employee schedule shown will be for the logged in user
    // If a userId is passed in the url, then the schedule will be for that user
    this.employeeUserId = this.$route.query?.user ?? this.currentUserId;
  },
};
</script>

<style>
.schedule-controls-row {
  margin: 0 0 0 10px;
}
</style>
