<template>
  <div class="W4Archive">
    <page-manager :isLoading="isLoading" :isEmpty="false" :error="error">
      <template #loading>
        <div class="loader">
          <v-progress-circular indeterminate color="accent" />
        </div>
      </template>
      <v-card>
        <v-toolbar class="toolBar" color="primary" dark>
          <v-toolbar-title><i class="mdi mdi-file-document-multiple" /> {{ moduleTitle }}</v-toolbar-title>
        </v-toolbar>
        <v-card-title>Previous W4's Available for Download</v-card-title>
        <v-sheet>
          <error-alert :error="submitError" />
          <v-data-table
            class="elevation-1"
            :loading="isFetchingData"
            loading-text="Loading Documents..."
            no-data-text="No available documents"
            :headers="tableHeaders"
            :footer-props="tableFooter"
            :items="documentArchive"
            :server-items-length="totalDocumentsInArchive"
            item-key="id"
            :options.sync="options"
          >
            <template v-slot:header>
              <tr class="grey lighten-3 table-filter-row">
                <th v-for="header in tableHeaders" :key="header.text" />
              </tr>
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">{{ formatDate(item.createdAt) }}</template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn small @click="onOpenDocument(item)">
                <v-icon>mdi-file-download-outline</v-icon> Download
              </v-btn>
            </template>
          </v-data-table>
        </v-sheet>
      </v-card>
    </page-manager>
  </div>
</template>

<script>
/* eslint-disable class-methods-use-this */
import { Component, Mixins, Watch } from "vue-property-decorator";

import { namespace } from "vuex-class";
import PageManager from "@/components/pageManager.vue";
import ErrorAlert from "@/components/errorAlert.vue";
import modulePageMixin from "@/utils/mixins";
import dates from "@/utils/dates";
import { isEqual } from "lodash";

const w4store = namespace("payroll/w4");

@Component({
  name: "W4Archive",
  components: { ErrorAlert, PageManager },
})
export default class W4Archive extends Mixins(modulePageMixin) {
  @w4store.State("error") error;

  @w4store.State("isSubmitting") isSubmitting;

  @w4store.State("documentArchive") documentArchive;

  @w4store.Action("getDocumentsList") getDocumentsList;

  @w4store.Action("openW4Pdf") openW4Pdf;

  @w4store.Getter("totalDocumentsInArchive") totalDocumentsInArchive;

  @w4store.Getter("submitError") submitError;

  @w4store.Getter("isFetchingData") isFetchingData;

  // eslint-disable-next-line class-methods-use-this
  data() {
    return {
      isLoading: false,
      moduleTitle: "Federal Withholding Form (W4)",
      tableHeaders: [
        {
          text: "Title",
          value: "fileTitle",
          sortable: false,
        },
        {
          text: "Date Submitted",
          align: "center",
          value: "createdAt",
          sortable: false,
        },
        {
          text: "PDF",
          align: "center",
          value: "actions",
          width: "10%",
          sortable: false,
        },
      ],
      tableFooter: {
        "items-per-page-options": [10, 25],
        "show-current-page": true,
        "show-first-last-page": true,
      },
      options: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ["createdAt"],
        sortDesc: [true],
      },
      sort: "",
    };
  }

  @Watch("options", { deep: true })
  async onOptionsChange(paramA, paramB) {
    if (!isEqual(paramA, paramB)) {
      if (Object.keys(this.options).length !== 0) {
        // set the format for the endpoint like: lastName:desc
        if (this.options.sortBy[0]) {
          [this.sort] = this.options.sortBy;
          if (this.options.sortDesc[0]) this.sort += ":desc";
        } else {
          this.sort = "createdAt:desc";
        }
      }
      await this.loadDocuments();
    }
  }

  refresh() {
    this.loadDocuments();
  }

  async loadDocuments() {
    this.isLoading = true;
    const params = {
      limit: this.options.itemsPerPage,
      page: this.options.page,
      sort: this.sort,
    };

    await this.getDocumentsList(params);

    this.isLoading = false;
  }

  formatDate(dateValue) {
    return dates.dateTimeString(dates.dateFromMezzioDateJson(dateValue));
  }

  async onOpenDocument(selected) {
    await this.openW4Pdf(selected);
  }
}
</script>

<style scoped></style>
