export default () => ({
  isLoggedIn: false,
  loginRedirect: null,
  jwt: null,
  token: null,
  userId: null,
  userInfo: null,
  error: null,
  isLoggingIn: false,
  fetchUserInfoError: null,
  isLoggingOut: false,
  // multi-factor states:
  isMultiFactorValidated: false,
  multiFactorApiResponse: {},
  isLoadingMultiFactor: false,
  multiFactorVerificationError: null,
});
