export default function () {
  return {
    /**
     * Search states:
     */
    searchCriteria: "",
    reportsCriteria: "Both",
    proxyCriteria: null,

    /**
     * List v-data-table states:
     */
    statusCriteria: null,
    listViewOptions: {
      page: 1,
      itemsPerPage: 25,
      sortBy: ["id"],
      sortDesc: [true],
    },
    defaultListViewOptions: {
      page: 1,
      itemsPerPage: 25,
      sortBy: ["id"],
      sortDesc: [true],
    },
    requestDateRangeCriteria: [],

    /**
     * Component states:
     */
    isFetchingData: false,

    /**
     * Fetched data:
     */
    timeOffRequests: [],
    totalTimeOffRequests: 0,
  };
}
