<template>
  <v-overlay absolute :color="color" :opacity="opacity">
    <v-row>
      <v-col>
        {{ message }}
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn text @click="$emit('cancel')"> Cancel </v-btn>
      </v-col>
      <v-col>
        <v-btn color="accent" @click="$emit('delete')"> Delete </v-btn>
      </v-col>
    </v-row>
  </v-overlay>
</template>

<script>
export default {
  props: {
    message: { type: String, default: "Are you sure you want to delete this?" },
    color: { type: String, default: "secondary" },
    opacity: { type: Number, default: 0.9 },
  },
};
</script>
