<template>
  <div class="StatusPersonalInformation">
    <v-card class="mx-auto my-4">
      <v-toolbar class="toolBar" color="primary" dark @click="shouldDisplay = !shouldDisplay">
        <v-toolbar-title>Current Federal Withholding Status</v-toolbar-title>
        <v-spacer />
        <v-btn icon>
          <v-icon v-if="shouldDisplay">mdi-arrow-down-circle-outline</v-icon>
          <v-icon v-else>mdi-arrow-right-circle-outline</v-icon>
        </v-btn>
      </v-toolbar>
      <v-expand-transition>
        <v-container fluid v-show="shouldDisplay">
          <v-row>
            <v-col cols="12" md="4">Name: {{ currentTaxInfo.fullName }}</v-col>
            <v-col cols="12" md="4">Current Number Of Allowances: {{ currentTaxInfo.allowances }}</v-col>
            <v-col cols="12" md="4">Current Federal Withholding: {{ currentTaxInfo.percentageElection || 0 }}%</v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              Address: {{ currentTaxInfo.address }}
              <router-link to="/personal-info/my-information" tag="button" class="link">
                <p title="Change address in My Information">( Change address )</p>
              </router-link>
              <br />
              <span class="hidden">Address: </span>
              {{ currentTaxInfo.city }}, {{ currentTaxInfo.state }} {{ currentTaxInfo.zipCode }}
            </v-col>
            <v-col cols="12" md="4">Filing Status: {{ filingStatus(currentTaxInfo.filingStatus) }}</v-col>
            <v-col cols="12" md="4">SSN: {{ maskedSsnNumber(currentTaxInfo.ssn) }}</v-col>
          </v-row>
        </v-container>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      shouldDisplay: true,
    };
  },
  computed: {
    ...mapState("payroll/taxForm", ["currentTaxInfo"]),
  },
  methods: {
    filingStatus(value) {
      const data = { M: "Married", S: "Single" };
      return data[value] ?? "";
    },
    maskedSsnNumber(value) {
      value = value || "";
      return value.replace(/^(\d{3})-(\d{2})-(\d{4})$/, "XXX-XX-$3");
    },
  },
};
</script>

<style lang="scss" scoped>
.hidden {
  visibility: hidden;
}
.link {
  text-decoration: underline;
  font-size: 80%;
}
</style>
