import Vue from "vue";
import Toasted from "vue-toasted";

Vue.use(Toasted, {
  position: "bottom-right",
  duration: 1000,
});

const options = {
  type: "error",
  duration: 5000,
};

Vue.toasted.register(
  "essError",
  (payload) => {
    if (!payload.message) {
      return "'Please fix errors.'";
    }
    return payload.message;
  },
  options,
);
