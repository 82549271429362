import { has } from "lodash";
import personalInfoApi from "../../api/personalInfo";

export default {
  async fetchMaritalCodes({ commit }) {
    commit("setError", null);
    try {
      // const { data } = await Vue.$apis.core.getMaritalCodes();
      const { data } = await personalInfoApi.getMaritalCodes();
      commit("setMaritalCodes", data._embedded.maritalStatus);
    } catch (e) {
      console.warn(e);
      commit("setError", e);
    }
  },
  async fetchData({ commit, dispatch }) {
    commit("setIsLoading", true);
    try {
      const maritalCodesRequest = dispatch("fetchMaritalCodes");
      const personalInfoRequest = dispatch("fetchPersonalInformation");

      await maritalCodesRequest;
      await personalInfoRequest;

      commit("setIsLoading", false);
    } catch (e) {
      commit("setError", e);
      commit("setIsLoading", false);
    }
  },
  async fetchPersonalInformation({ commit }) {
    commit("setIsLoading", true);
    commit("setError", null);
    try {
      const { data } = await personalInfoApi.fetchPersonalInformation();
      commit("setPersonalInformation", data?.personalInfo ?? {});
      commit("setIsLoading", false);
    } catch (e) {
      console.warn(e);
      commit("setError", e);
      commit("setIsLoading", false);
    }
  },
  async submitPersonalInformation({ commit, getters }) {
    commit("setIsSubmitting", true);
    commit("setSavingInfo", {});
    try {
      const { data } = await personalInfoApi.putPersonalInformation(
        getters.payload,
      );
      commit("setPersonalInformation", data);
      commit("setIsSubmitting", false);
    } catch (e) {
      commit("setSubmitError", e);
      commit("setIsSubmitting", false);
    }
  },
  setResidenceAddress({ commit }, address) {
    if (has(address, "phoneNumber")) {
      commit("setResidentPhoneNumber", address.phoneNumber);
    }
    commit("setResidentStreetAddress", address.street);
    commit("setResidentSuite", address.street2);
    commit("setResidentCity", address.city);
    commit("setResidentState", address.state);
    commit("setResidentZipCode", address.zip);
  },
  setMailingAddress({ commit }, address) {
    commit("setMailingZipCode", address.zip);
    commit("setMailingState", address.state);
    commit("setMailingCity", address.city);
    commit("setMailingSuite", address.street2);
    commit("setMailingStreetAddress", address.street);
  },
};
