import { merge } from "lodash";

export default {
  setCalendarEntries(state, data) {
    let entries = [];
    data.forEach((request) => {
      let newEvent = {
        id: request.id,
        employeeId: request.employeeId,
        employeeUserId: request.employeeUserId,
        employeeFirstName: request.employeeFirstName,
        employeeLastName: request.employeeLastName,
        fullName: request.employeeFirstName + " " + request.employeeLastName,
        partialName:
          request.employeeFirstName.charAt(0) + ". " + request.employeeLastName,
        requestId: request.id,
        reason: request.reason,
        requestStatus: request.statusDescription,
      };

      request.entries.forEach((entry) => {
        entries.push(merge(entry, newEvent));
      });
    });
    state.calendarEntries = entries;
  },
  setCalendarSelectedMonth(state, data) {
    state.calendarSelectedMonth = data;
  },
  setIsFetchingData(state, data) {
    state.isFetchingData = data;
  },
  setRequestFetchError(state, data) {
    state.requestFetchError = data;
  },
};
