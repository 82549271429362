<template>
  <v-list dense>
    <v-list-item active-class="active" exact dark>
      <v-list-item-content>
        <v-list-item-title
          class="appVersionDisplay"
        >
          FE: v{{ getAppVersion }} - BE: v{{ getBackendAppVersionNumber }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved
import changeLog from "raw-loader!../../CHANGELOG.md";
import { mapGetters } from "vuex";

export default {
  name: "AppVersion",
  computed: {
    ...mapGetters("appConfig", ["getReleaseVersion"]),
    getAppVersion() {
      const version = changeLog.match(/## \d+\.\d+\.\d+/).join();
      return version.substring(3);
    },
    getBackendAppVersionNumber() {
      return this.getReleaseVersion;
    },
  },
  async mounted() {
    await this.$store.dispatch("appConfig/fetchReleaseVersion");
  },
};
</script>
