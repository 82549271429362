<template>
  <v-container>
    <v-card elevation="2">
      <v-card-title class="headline white--text primary font-weight-light">
        {{ title }}
      </v-card-title>

      <v-card-text>
        <component :is="employeeRequestList"></component>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Component from "vue-class-component";
import { Mixins } from "vue-property-decorator";
import modulePageMixin from "@/utils/mixins";
import timeOffMixin from "@/modules/timeOff/timeOffMixin";
import { authorizationMixin } from "@/modules/timeOff/authorizationMixin";
import { namespace } from "vuex-class";

const requestsEmployeeStore = namespace("timeOff/requestsEmployee");

@Component({
  name: "EmployeeRequests",
  components: {},
})
export default class EmployeeRequests extends Mixins(
  modulePageMixin,
  timeOffMixin,
  authorizationMixin
) {
  @requestsEmployeeStore.Getter("getEmployeeIdentifier") getEmployeeIdentifier;
  @requestsEmployeeStore.Getter("isFetchingData") isFetchingData;

  get employeeRequestList() {
    return this.loadComponent("employeeRequestList", "timeOff/requests");
  }

  get title() {
    if (this.isFetchingData) {
      return "Loading requests for " + this.employeeIdentifier + ".....";
    }

    return "Time Off Requests for " + this.employeeName;
  }

  get employeeIdentifier() {
    return this.getEmployeeIdentifier;
  }

  get employeeName() {
    return this.firstName + " " + this.lastName;
  }
}
</script>

<style scoped></style>
