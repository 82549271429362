import { Component, Vue } from "vue-property-decorator";

@Component
export default class validatorMixin extends Vue {
  goToErrorField(otherScrollOptions) {
    let scrollOptions = {
      duration: 1000,
      offset: 50,
    };

    scrollOptions = {
      ...scrollOptions,
      ...otherScrollOptions,
    };

    Object.keys(this.$refs).every((key) => {
      if (!this.$refs[key].value) {
        Object.entries(this.$refs[key].errorBag).every((val) => {
          if (val[1]) {
            this.$vuetify.goTo(`#input-${val[0]}`, scrollOptions);
            return false;
          }
          return true;
        });
      }
      return true;
    });
  }
}
