import Vue from "vue";
import VueRouter from "vue-router";
import routes from "@/router/routes";
import requireAuth from "@/router/guards/requireAuth";
import { getEnv } from "@/utils/util";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: getEnv("BASE_URL"),
  routes,
});

router.beforeEach(requireAuth);

export default router;
