// Swift / Knight Utilities
import {
  isEmpty as _isEmpty, trim, mapValues,
} from "lodash";

export const sanitizeStrings = (listOfStrings) => {
  listOfStrings.forEach((myData) => {
    Object.keys(myData).forEach((key) => {
      if (typeof myData[key] === "string") {
        myData[key] = myData[key].trim();
      }
    });
  });
};

export const getErrorMessage = (error) => error?.data?.title ?? error;

export const stringFormat = (format, ...args) => {
  let i = 0;
  return format.replace(/%s/g, () => args[i++]);
};

export const isEmpty = (value) => _isEmpty(trim(value));

export const numberFormat = (value, options = {}) => (
  value != null ? Number(value).toLocaleString(undefined, options) : ""
);

export const hoursFormat = (value) => (
  numberFormat(value, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
);

export const formatScheduleHours = (schedule) => mapValues(schedule, (v) => hoursFormat(v));

export const getEnv = (name) => (window?.configs?.[name] || process.env[name]);

export const titleCase = (value) => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

export const titleCapitalization = (value) => value.split(" ").map((word) => titleCase(word)).join(" ");

export const underscoreToCamelCase = (value) => {
  const words = value.split("_");
  const camelize = words.map((item, index) => (
    index === 0 ? item.toLowerCase() : item.charAt(0).toUpperCase() + item.substring(1).toLowerCase()
  ));
  return camelize.join("");
};

export const formatEmployeeName = (
  firstName,
  lastName,
  employerId,
  employeeId,
) => `${firstName?.trim()} ${lastName?.trim()} (${employerId} - ${employeeId})`;

export const reFormatISODate = (date) => {
  const parts = date.split("-");
  return `${parts[1]}/${parts[2]}/${parts[0]}`;
};
