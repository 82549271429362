var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"2","loading":_vm.isLoading ? 'grey lighten-2' : null}},[_c('v-card-title',{staticClass:"headline white--text primary font-weight-light"},[_vm._v(" Audit Schedule Changes ")]),_c('v-card-text',[_c('v-sheet',[_c('v-form',{ref:"filterForm"},[_c('v-container',{staticClass:"filters"},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"9","lg":"6"}},[_c('v-text-field',{attrs:{"readonly":"","outlined":"","clearable":"","label":"Employee"},on:{"click:clear":function($event){return _vm.clearSelectedEmployee('employee')}},model:{value:(_vm.selectedEmployeeName),callback:function ($$v) {_vm.selectedEmployeeName=$$v},expression:"selectedEmployeeName"}})],1),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"3","lg":"1"}},[_c('v-btn',{staticClass:"filterButtons",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openEmployeeSelect('employee')}}},[_vm._v(" Select ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"9","lg":"6"}},[_c('v-text-field',{attrs:{"readonly":"","outlined":"","clearable":"","label":"Manager"},on:{"click:clear":function($event){return _vm.clearSelectedEmployee('manager')}},model:{value:(_vm.selectedManagerName),callback:function ($$v) {_vm.selectedManagerName=$$v},expression:"selectedManagerName"}})],1),_c('v-col',{staticClass:"pl-0 pt-0",attrs:{"cols":"3","lg":"1"}},[_c('v-btn',{staticClass:"filterButtons",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openEmployeeSelect('manager')}}},[_vm._v(" Select ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"6","md":"4","lg":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-bottom":55,"transition":"scale-transition","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","clearable":"","outlined":"","label":"Start Date","append-icon":"mdi-calendar-month-outline","rules":[_vm.startDateValid]},on:{"click:clear":function($event){return _vm.resetDate('start')}},model:{value:(_vm.formattedStartDate),callback:function ($$v) {_vm.formattedStartDate=$$v},expression:"formattedStartDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startDateMenu),callback:function ($$v) {_vm.startDateMenu=$$v},expression:"startDateMenu"}},[_c('v-date-picker',{attrs:{"color":_vm.datePickerColor},on:{"input":function($event){_vm.startDateMenu = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"6","md":"4","lg":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-bottom":55,"transition":"scale-transition","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","clearable":"","outlined":"","label":"End Date","append-icon":"mdi-calendar-month-outline","rules":[_vm.endDateValid]},on:{"click:clear":function($event){return _vm.resetDate('end')}},model:{value:(_vm.formattedEndDate),callback:function ($$v) {_vm.formattedEndDate=$$v},expression:"formattedEndDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endDateMenu),callback:function ($$v) {_vm.endDateMenu=$$v},expression:"endDateMenu"}},[_c('v-date-picker',{attrs:{"color":_vm.datePickerColor},on:{"input":function($event){_vm.endDateMenu = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"filterButtons",attrs:{"color":"primary","disabled":!_vm.isFormValid || _vm.hasNoFilter},on:{"click":_vm.newSearch}},[_vm._v(" Search ")])],1)],1)],1)],1)],1),_c('v-sheet',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.isLoading,"loading-text":"Loading Schedule Changes...","no-data-text":"No Schedule Changes","headers":_vm.tableHeaders,"footer-props":_vm.tableFooter,"items":_vm.scheduleChanges,"server-items-length":_vm.totalScheduleChanges,"options":_vm.options,"item-key":"id","mobile-breakpoint":"725"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.employeeId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatName(item, "employee"))+" ")]}},{key:"item.previousSchedule",fn:function(ref){
var item = ref.item;
return _vm._l((item.previousSchedule),function(hours,day){return _c('div',{key:day,staticClass:"scheduleTable"},[_c('strong',[_vm._v(_vm._s(day.substring(9)))]),_c('br'),_vm._v(_vm._s(hours)+" ")])})}},{key:"item.currentSchedule",fn:function(ref){
var item = ref.item;
return _vm._l((item.currentSchedule),function(hours,day){return _c('div',{key:day,staticClass:"scheduleTable"},[_c('strong',[_vm._v(_vm._s(day.substring(9)))]),_c('br'),_c('span',{class:{
                differentData:
                  item.currentSchedule[day] !== item.previousSchedule[day],
              }},[_vm._v(" "+_vm._s(hours)+" ")])])})}},{key:"item.managerEmployeeId",fn:function(ref){
              var item = ref.item;
return [_c('a',{attrs:{"href":_vm.emailLink(item)}},[_vm._v(" "+_vm._s(_vm.formatName(item, "manager"))+" ")])]}},{key:"item.changedByEmployeeId",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatName(item, "changedBy"))+" ")]}},{key:"item.modifiedTimestamp",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item))+" ")]}}],null,true)})],1)],1),_c('v-card-actions',{staticClass:"downloadButton"},[_c('v-btn',{attrs:{"outlined":"","loading":_vm.isButtonLoading,"disabled":_vm.hasNoRecords || _vm.hasTooManyRecords},on:{"click":_vm.downloadSpreadsheet}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-microsoft-excel ")]),_vm._v(" Download Report In Excel ")],1)],1),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.isButtonLoading),expression:"isButtonLoading"}],staticClass:"downloadMessage"},[_vm._v(" * The download can take up to 2 minutes ")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasTooManyRecords),expression:"hasTooManyRecords"}],staticClass:"downloadMessage"},[_vm._v(" * There are too many records in the table for an excel download to successfully complete ")]),_c('employeeDataTableSelectDialog',{attrs:{"title":_vm.employeeSelectDialog.title,"show":_vm.employeeSelectDialog.show,"searchType":"infinium","returnObject":true},on:{"close":_vm.closeEmployeeSelect,"employeeSelected":_vm.setSelectedEmployee}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }