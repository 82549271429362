<template>
  <v-app>
    <v-navigation-drawer
      v-if="isFullyLoggedIn"
      :value="$vuetify.breakpoint.mdAndUp ? true : nav"
      @input="(val) => (nav = val)"
      :mini-variant="$vuetify.breakpoint.mdAndUp"
      :expand-on-hover="$vuetify.breakpoint.mdAndUp"
      :clipped="true"
      app
      dark
      :bottom="showFromBottom"
      :mobile-breakpoint="Number($vuetify.breakpoint.thresholds.sm)"
      width="325"
    >
      <main-nav :routes="getRoutes" />
      <template v-slot:append>
        <app-version />
      </template>
    </v-navigation-drawer>
    <v-app-bar class="bar" app color="primary" clipped-left clipped-right dark>
      <v-icon
        class="mx-2 hidden-lg-and-up"
        @click="nav = !nav"
        v-if="isFullyLoggedIn"
      >
        mdi-menu
      </v-icon>
      <div class="d-flex align-center">
        <router-link style="color: white" to="/">
          <v-img
            class="shrink mr-2 nav-bar-logo"
            contain
            :src="assetLogo"
            transition="scale-transition"
          />
        </router-link>
      </div>
      <v-toolbar-title class="hidden-sm-and-down">
        <router-link to="/">
          {{ this.$appInfo.title }}
        </router-link>
      </v-toolbar-title>
      <span v-if="breadcrumbs.length" class="home-divider hidden-sm-and-down">
        /
      </span>
      <v-breadcrumbs
        class="breadcrumbs hidden-sm-and-down"
        :items="breadcrumbs"
        dark
      />

      <v-spacer />

      <v-item-group>
        <v-item v-if="isFullyLoggedIn">
          <div class="user-name">
            <v-icon class="user-name-icon">mdi-account</v-icon>
            {{ getLoggedInUser }}
          </div>
        </v-item>
      </v-item-group>

      <v-btn v-if="isFullyLoggedIn" @click="signOut" outlined>logout</v-btn>
    </v-app-bar>
    <v-container fill-height v-if="isLoggingIn && currentRouteName !== 'login'">
      <v-row justify="center" align="center">
        <v-progress-circular indeterminate size="120" width="8" color="red" />
      </v-row>
    </v-container>
    <v-main class="content">
      <v-container fluid>
        <!-- Refresh Settings Function -->
        <v-row
          v-if="isLoading && settingsRefreshed"
          transition="fade-transition"
        >
          <v-col cols="12" md="12">
            <v-alert class="fetch-error" type="error" prominent :loading="true">
              <v-row align="center">
                <v-col class="grow">Re-fetching Settings...</v-col>
                <v-col class="shrink">
                  <v-progress-circular indeterminate />
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
        </v-row>
        <v-row
          v-if="settingsRefreshed && !isLoading"
          transition="fade-transition"
        >
          <v-col cols="12" md="12">
            <v-alert class="fetch-error" type="info" prominent>
              You might have to refresh your page to see the latest changes.
            </v-alert>
          </v-col>
        </v-row>
        <!-- Display Pages -->
        <router-view />
      </v-container>
    </v-main>
    <v-dialog v-model="isTimeoutModalOpen" persistent width="400">
      <v-card>
        <v-card-title class="headline">
          <v-icon large>mdi-alert-octagon-outline</v-icon>
          <span>Inactivity Notice:</span>
        </v-card-title>
        <v-card-text>
          Please click continue to resume your session.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="isTimeoutModalOpen = false">
            Continue session
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import MainNav from "@/components/mainNav.vue";
import {
  mapState, mapActions, mapGetters, mapMutations,
} from "vuex";
import AppVersion from "@/components/app-version.vue";
import _ from "lodash";
import { getEnv } from "@/utils/util";
import {
  createDynamicAsset,
  createDynamicStyleAsset,
} from "@/utils/dynamicAssets";
import Vue from "vue";

export default {
  name: "App",
  components: {
    MainNav,
    AppVersion,
  },
  data() {
    return {
      nav: false,
      heartbeatIntervalId: null,
      userSessionId: Date.now(),
      isTimeoutModalOpen: false,
      lastActivity: 0,
      settingsRefreshed: false,
      loggedOut: false,
      bottomMenuLowerThreshold: 435,
      bottomMenuHigherThreshold: 935,
    };
  },
  computed: {
    ...mapGetters("auth", ["getLoggedInUser", "isFullyLoggedIn"]),
    ...mapState("auth", ["token", "isLoggingIn"]),
    ...mapGetters("appConfig", ["getDynamicRoutes", "getAllRoutes"]),
    ...mapState("settings", ["settingsFetchErrors", "isLoading"]),
    ...mapState("websocket", ["heartbeatSession"]),
    assetLogo() {
      return createDynamicAsset("logo.png");
    },
    getRoutes() {
      return this.getAllRoutes;
    },
    currentRouteName() {
      return this.$route.name;
    },
    breadcrumbs() {
      return this.$route.matched
        .filter((match) => match.name.toLowerCase() !== "home")
        .map((route) => ({
          text: route.meta.title,
          to: route.path,
        }));
    },
    showFromBottom() {
      return (
        (this.$vuetify.breakpoint.width < this.bottomMenuLowerThreshold
          && this.$vuetify.breakpoint.height < this.bottomMenuHigherThreshold)
        || (this.$vuetify.breakpoint.width < this.bottomMenuHigherThreshold
          && this.$vuetify.breakpoint.height < this.bottomMenuLowerThreshold)
      );
    },
  },
  watch: {
    isFullyLoggedIn(newVal) {
      this.manageSocketConnection(newVal);
    },
    isTimeoutModalOpen(newVal, oldVal) {
      if (oldVal && !newVal) {
        this.lastActivity = Date.now();
      }
    },
  },
  methods: {
    ...mapActions("settings", ["fetchSettings"]),
    ...mapMutations("websocket", ["setHeartbeat"]),
    signOut() {
      // close the socket connection
      if (this.heartbeatSession) {
        this.heartbeatSession.close();
      }

      this.$router.push("/logout");
    },
    manageSocketConnection(userIsValidated) {
      if (userIsValidated && !this.heartbeatIntervalId) {
        this.setHeartbeat(new WebSocket(getEnv("VUE_APP_SOCKET_SERVER")));

        // when the socket connection opens, register the id and token
        this.heartbeatSession.onopen = () => {
          this.heartbeatSession.send(
            `[{"type": "register", "id": ${this.userSessionId}, `
              + `"data": {"token": "${this.token}", "jwt": "${this.jwt}"}}]`,
          );
        };

        // refresh the token every 10 minutes
        this.heartbeatIntervalId = setInterval(() => {
          if (this.heartbeatSession.readyState === 1) {
            this.heartbeatSession.send(
              `[{"type": "ping", "id": ${this.userSessionId}, "data": []}]`,
            );
          }
        }, getEnv("VUE_APP_PING_INTERVAL"));

        // sign the user out when the message can't be parsed or when we get loggedOut message
        this.heartbeatSession.onmessage = (event) => {
          let data;
          try {
            data = JSON.parse(event.data);
          } catch (e) {
            this.signOut();
          }

          if (data.type === "loggedOut") {
            this.signOut();
          }
        };
      } else if (this.heartbeatIntervalId) {
        // if not logged in, clear the interval and stop pinging
        clearInterval(this.heartbeatIntervalId);
        this.heartbeatIntervalId = null;
      }
    },
    async startHeartBeat() {
      if (this.isFullyLoggedIn && this.heartbeatSession === null) {
        this.manageSocketConnection(true);
      }
      this.lastActivity = Date.now();

      setInterval(() => {
        const timeSinceLastActivity = Date.now() - this.lastActivity;
        if (
          getEnv("VUE_APP_TOKEN_TIMEOUT") - timeSinceLastActivity < 60000
          && !this.isTimeoutModalOpen
          && this.isFullyLoggedIn
        ) {
          this.isTimeoutModalOpen = true;
        }
        if (
          timeSinceLastActivity > getEnv("VUE_APP_TOKEN_TIMEOUT")
          && this.isFullyLoggedIn
        ) {
          sessionStorage.setItem("isTimedOut", "true");
          this.signOut();
        }
      }, 1000);

      // wait x time to count another action made by the user
      const logActivityTime = _.throttle(() => {
        if (!this.isTimeoutModalOpen) {
          this.lastActivity = Date.now();
        }
      }, 1000);

      window.addEventListener("mousemove", logActivityTime);
      window.addEventListener("mousedown", logActivityTime);
      window.addEventListener("keypress", logActivityTime);
      window.addEventListener("touchmove", logActivityTime);
      window.addEventListener("onscroll", logActivityTime);
    },
    initializeCompanyElements() {
      const { company } = Vue.$appInfo;
      document.body.classList.add(company);
      document.querySelectorAll("link[rel*=\"icon\"]").forEach((v) => {
        v.href = v.href.replace("Swift", company);
      });
    },
  },
  mounted() {
    this.startHeartBeat();
  },
  created() {
    this.initializeCompanyElements();
    // eslint-disable-next-line prefer-template
    import("@/" + createDynamicStyleAsset("theme.scss"));
  },
};
</script>
<style scoped>
.v-navigation-drawer--bottom.v-navigation-drawer--is-mobile {
  max-height: 70%;
}
</style>

<style scoped>
.v-navigation-drawer--bottom.v-navigation-drawer--is-mobile {
  max-height: 70%;
}
</style>
