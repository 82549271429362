import state from "./state";
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";
import taxForm from "./taxForm";
import emailPreferences from "./emailPreferences";
import directDeposit from "./directDeposit";
import w4 from "./w4";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules: {
    taxForm,
    emailPreferences,
    directDeposit,
    w4,
  },
};
