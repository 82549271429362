export default function () {
  return {
    requestQueue: null,
    requests: [],
    requestQueueFetchError: null,
    totalRequests: 0,
    reprocessAS400UploadError: null,
    reprocessAS400UploadRequests: [],
  };
}
