export default {
  Knight: {
    primary: "#1B1B1B",
    secondary: "#424242",
    accent: "#EA4949",
    error: "#B71C1C",
    warning: "#FDD835",
    info: "#90A4AE",
    success: "#7CB342",
  },
  Swift: {
    primary: "#18468D",
    secondary: "#424242",
    accent: "#E5B723",
    error: "#FF5252",
    warning: "#FFC107",
    info: "#2196F3",
    success: "#4CAF50",
  },
};
