export default {
  getSearchCriteria(state) {
    return state.searchCriteria;
  },
  getReportsCriteria(state) {
    return state.reportsCriteria;
  },
  getProxyCriteria(state) {
    return state.proxyCriteria;
  },
  getStatusCriteria(state) {
    return state.statusCriteria;
  },
  listViewOptions(state) {
    return state.listViewOptions;
  },
  getRequestDateRangeCriteria(state) {
    return state.requestDateRangeCriteria;
  },
  isFetchingData(state) {
    return state.isFetchingData;
  },
  getTimeOffRequests(state) {
    return state.timeOffRequests;
  },
  getTotalTimeOffRequests(state) {
    return state.totalTimeOffRequests;
  },
};
