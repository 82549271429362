<template>
  <v-alert prominent type="error" icon="mdi-lock">
    <v-row align="center">
      <v-col class="grow">
        <p>
          You are unable to update your W4 information through Employee Self Service at this time.<br />
          Please contact Payroll tax Department for further assistance.<br />
          Email payrolltax@swifttrans.com or call 800-771-6317, Option 5
        </p>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  name: "AccountLockedAlert",
};
</script>

<style scoped></style>
