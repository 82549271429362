<template>
  <div class="direct-deposit">
    <DepositInstructions />
    <v-expand-transition>
      <v-alert dense color="blue lighten-2" type="info" prominent v-show="hasUnsavedChanges">
        <v-row align="center">
          <v-col class="grow">You currently have unsaved changes</v-col>
          <v-col class="shrink">
            <v-btn color="blue darken-3" @click="save">Save Changes</v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-expand-transition>
    <pageManager :isLoading="isLoading" :error="error" :isEmpty="false">
      <template #loading>
        <div class="loader">
          <v-progress-circular indeterminate color="accent" />
        </div>
      </template>
      <v-card class="mx-auto my-4">
        <v-toolbar class="toolBar" color="primary" dark>
          <v-toolbar-title>Deposits</v-toolbar-title>
          <v-spacer />
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" @click="confirmAddDirectDeposit">
                <v-icon large>mdi-plus-thick</v-icon>
              </v-btn>
            </template>
            <span>Add deposit split</span>
          </v-tooltip>
        </v-toolbar>
        <v-fade-transition>
          <DeleteOverlay
            v-if="isDeleting"
            @cancel="isDeleting = false"
            @delete="deleteAll"
            message="Would like to delete All the Deposits?"
          />
        </v-fade-transition>
        <div v-for="(deposit, index) in deposits" :key="deposit.feId" :class="deposit.backgroundColor">
          <DirectDeposit
            :unformattedAmount="{ amount: deposit.amount }"
            ref="depositSplit"
            :deposit="deposit"
            @delete="deleteDeposit(deposit)"
          />
          <v-divider v-if="index + 1 < deposits.length" />
        </div>
      </v-card>
    </pageManager>
    <FloatingControls
      :actions="actions"
      @discard="discardChanges"
      @add="confirmAddDirectDeposit"
      @save="save"
      @confirmDeleteAll="confirmDeleteAll"
    />
    <v-overlay v-if="isSaving" color="secondary">
      <v-progress-circular indeterminate color="accent" />
    </v-overlay>
    <submitDialog :propIsSubmitting="isSaving" :propSubmitError="submitError" @clearErrors="clearErrors" />
    <unsavedChangesDialog
      :show="navigateDialog"
      @cancelNavigation="cancelNavigation"
      @continueNavigation="continueNavigation"
    />
    <newDepositDialog
      :show="newDepositDialog"
      @cancel="newDepositDialog = !newDepositDialog"
      @addDirectDeposit="addDirectDeposit"
    />
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
} from "vuex";
import FloatingControls from "@/components/floating-controls.vue";
import DeleteOverlay from "@/components/delete-overlay.vue";
import unsavedChangesDialog from "@/components/unsaved-changes-dialog.vue";
import pageManager from "@/components/pageManager.vue";
import DepositInstructions from "./components/deposit-instructions.vue";
import DirectDeposit from "./components/direct-deposit.vue";
import submitDialog from "../../../components/submitDialog.vue";
import newDepositDialog from "./components/new-deposit-dialog.vue";

export default {
  components: {
    DepositInstructions,
    DirectDeposit,
    FloatingControls,
    DeleteOverlay,
    submitDialog,
    unsavedChangesDialog,
    newDepositDialog,
    pageManager,
  },
  data() {
    return {
      navigateTo: null,
      navigateDialog: false,
      isDeleting: false,
      actions: [
        {
          event: "add",
          color: "success",
          icon: "mdi-plus",
          name: "Add Deposit Split",
        },
        {
          event: "save",
          color: "success",
          icon: "mdi-content-save-all",
          name: "Save Changes",
        },
        {
          event: "discard",
          color: "accent",
          icon: "mdi-close",
          name: "Discard Changes",
        },
        {
          event: "confirmDeleteAll",
          color: "accent",
          icon: "mdi-playlist-remove",
          name: "Delete All Deposits",
        },
      ],
      newDepositDialog: false,
      validFlatSplitCount: null,
      validPSplitCount: null,
    };
  },
  computed: {
    ...mapState("payroll/directDeposit", [
      "isLoading",
      "isSaving",
      "error",
      "deposits",
      "submitError",
    ]),
    ...mapGetters("auth", ["employeeId"]),
    ...mapGetters("payroll/directDeposit", [
      "flatSplitCount",
      "percentageSplitCount",
      "fuelCardSplits",
      "hasUnsavedChanges",
      "validFlatSplitLimit",
      "validPercentageSplitLimit",
    ]),
  },
  methods: {
    ...mapMutations("payroll/directDeposit", [
      "setDeposits",
      "deleteDeposit",
      "addDeposit",
      "setError",
      "deleteAllDeposits",
      "setSubmitError",
    ]),
    ...mapActions("payroll/directDeposit", ["fetchDeposits", "saveDeposits"]),
    clearErrors() {
      this.setSubmitError({});
    },
    discardChanges() {
      this.setDeposits();
      this.setError(null);
    },
    confirmAddDirectDeposit() {
      this.newDepositDialog = true;
    },
    addDirectDeposit(selectedOptions) {
      if (
        selectedOptions.accountType !== "F"
        && selectedOptions.depositType !== "NET"
        && !this.validFlatSplitLimit
        && !this.validPercentageSplitLimit
      ) {
        this.$toasted.global.essError({
          message: "You have the max number of Deposit splits.",
        });
        return;
      }
      this.addDeposit({
        depositType: selectedOptions.depositType,
        accountType: selectedOptions.accountType,
        employeeId: this.employeeId,
      });

      this.newDepositDialog = false;
    },
    async save() {
      // Trigger Child component validation
      this.$refs.depositSplit.forEach((split) => split.validate());

      // Check that all child components are valid
      let allValid = true;
      this.$refs.depositSplit.forEach((split) => {
        if (!split.valid) {
          allValid = false;
        }
      });
      if (!allValid) {
        this.$toasted.global.essError({
          message: "Please fix errors prior to saving.",
        });
      }

      // Confirm that there is exactly one Net deposit
      let validNetCount = true;
      if (this.deposits.filter((dep) => dep.depositType === "NET").length > 1) {
        validNetCount = false;
        this.$toasted.global.essError({
          message: "You must have no more than one Net deposit.",
        });
      } else if (
        this.deposits.filter((dep) => dep.depositType === "NET").length === 0
      ) {
        validNetCount = false;
        this.$toasted.global.essError({
          message: "You must have at least one Net deposit.",
        });
      }

      // No more than 99% in percentage splits
      let validTotalPercentage = true;
      const percentageTotal = this.deposits.reduce((sum, dep) => {
        if (dep.depositType === "P") (sum += parseInt(dep.amount, 10));
        return sum;
      }, 0);
      if (percentageTotal > 99) {
        validTotalPercentage = false;
        this.$toasted.global.essError({
          message: "You may only have up to 99% in percentage splits.",
        });
      }

      let validFuelCardSplitsCount = true;
      // only one fuel card split allowed
      if (this.fuelCardSplits.length > 1) {
        validFuelCardSplitsCount = false;
        this.$toasted.global.essError({
          message: "You may only have 1 fuel card split.",
        });
      }

      if (
        allValid
        && validNetCount
        && validTotalPercentage
        && validFuelCardSplitsCount
      ) {
        await this.saveDeposits();
        if (Object.keys(this.submitError).length === 0) {
          this.$toasted.success("Your changes have been successfully saved!");
        }
      }
    },
    confirmDeleteAll() {
      this.isDeleting = true;
    },
    deleteAll() {
      this.deleteAllDeposits();
      this.isDeleting = false;
    },
    continueNavigation() {
      this.navigateDialog = false;
      // returns to beforeRouteLeave and exceutes next function
      this.$router.push(this.navigateTo);
    },
    cancelNavigation() {
      this.navigateDialog = false;
      this.navigateTo = null;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      if (this.navigateTo) {
        next();
      } else {
        this.navigateTo = to;
        this.navigateDialog = true;
      }
    } else {
      next();
    }
  },
  async mounted() {
    await this.fetchDeposits(this.employeeId);
  },
  watch: {
    hasUnsavedChanges(val) {
      if (val) {
        window.onbeforeunload = () => true;
      } else {
        window.onbeforeunload = null;
      }
    },
  },
  page: {
    title: "Direct Deposit",
    meta: [{ name: "description", content: "The Direct Deposit page." }],
  },
};
</script>

<style lang="scss" scoped>
.direct-deposit {
  position: relative;
}
.toolBar {
  border-bottom: 5px solid #c72500 !important;
}
</style>
