export default {
  employeeHours(state) {
    return state.employeeHours;
  },
  employeeHoursUserId(state) {
    return state.employeeHoursUserId;
  },
  employeeHoursFetchError(state) {
    return state.employeeHoursFetchError;
  },
  getRemainingHoursForCategory: (state) => (categoryCode) => {
    const hours = state.employeeHours.find(({ code }) => code === categoryCode);

    return hours.remaining ?? 0;
  },
  categoryFormat: (state) => (value) => {
    return state.employeeHours.reduce(
      (currentValue, hours) =>
        hours.code === value ? hours.description : currentValue,
      ""
    );
  },
};
