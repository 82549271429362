<template>
  <v-container class="new-request">
    <navBar
      @approve="approveRequest"
      @deny="denyRequest"
      :showApprove="canApproveRequest"
      :showDeny="canDenyRequest"
    />

    <ReviewRequestForm
      :requestId="requestId"
      :employeeUserId="userId"
      ref="ReviewRequestForm"
    ></ReviewRequestForm>

    <submitDialog
      :propIsSubmitting="isSubmitting"
      :propSubmitError="submitError"
      @clearErrors="clearErrors"
    />

    <successDialog
      :message="successDialog.message"
      :show="successDialog.show"
      @close="closeAlertDialog"
    />
  </v-container>
</template>

<script>
import NewRequest from "./newRequest.vue";
import Component from "vue-class-component";
import ReviewRequestForm from "./components/reviewRequestForm";
import { namespace } from "vuex-class";

const requestFormStore = namespace("timeOff/requestForm");
const authStore = namespace("auth");

@Component({
  name: "ReviewRequest",
  components: { ReviewRequestForm },
})
export default class ReviewRequest extends NewRequest {
  @requestFormStore.Mutation("setIsCommentRequired") setIsCommentRequired;
  @requestFormStore.Getter("getCurrentRequest") getCurrentRequest;
  @authStore.Getter("employerId") employerId;
  @authStore.Getter("employeeId") employeeId;
  @authStore.Getter("hasAnyRole") hasAnyRole;

  get userId() {
    return this.$route.query?.user ?? "";
  }
  get requestId() {
    return this.$route.query?.request ?? "";
  }

  get canApproveRequest() {
    if (
      !this.canApproveRequests() ||
      parseInt(this.requestId) !== parseInt(this.getCurrentRequest?.id)
    ) {
      return false;
    }

    // cannot approve or deny employee's own request
    if (
      this.getCurrentRequest?.employeeId === this.employeeId &&
      this.getCurrentRequest?.employerId === this.employerId
    ) {
      return false;
    }

    if (
      this.hasAnyRole(["isPayroll"]) &&
      this.getCurrentRequest?.status === "Y"
    ) {
      return true;
    }

    if (this.getCurrentRequest?.status === "P") {
      return true;
    }

    return false;
  }

  get canDenyRequest() {
    return this.canDenyRequests() && this.canApproveRequest;
  }

  closeAlertDialog() {
    this.successDialog.show = false;
    this.$router.go(-1);
  }

  async approveRequest() {
    this.setIsCommentRequired(false);
    await this.$refs.ReviewRequestForm.validate();

    if (this.$refs.ReviewRequestForm.valid) {
      await this.$refs.ReviewRequestForm.approveForm();
      this.showSuccessDialog("approve");
    }
  }

  async denyRequest() {
    this.setIsCommentRequired(true);
    await this.$refs.ReviewRequestForm.validate();

    if (this.$refs.ReviewRequestForm.valid) {
      await this.$refs.ReviewRequestForm.denyForm();
      this.showSuccessDialog("deny");
    }
  }
}
</script>
