import axios from "axios";
import { getEnv } from "@/utils/util";
import userInfo from "./stubs/userInfo";

const api = axios.create({
  baseURL: getEnv("VUE_APP_TO_API_URL"),
});

api.defaults.headers.common.Accept = "application/json";

const useStub = getEnv("VUE_APP_STUB_APIS").toLowerCase() === "true";

export default {
  api,
  fetchUserInfo() {
    return useStub ? userInfo.getResponse() : api.get("/userInfo");
  },
};
