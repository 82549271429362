import axios from "axios";

import { getEnv } from "@/utils/util";

const api = axios.create({
  baseURL: getEnv("VUE_APP_ESS_API_URL"),
});

api.defaults.headers.common.Accept = "application/json";

export default {
  api,
  name() {
    return "w4";
  },
  async postW4Data(payload) {
    return api.post("/payroll/employee-w4", payload);
  },
  async fetchW4Data() {
    return api.get("/payroll/employee-w4");
  },
  async fetchDocuments(params) {
    return api.get("/payroll/employee-w4/files", { params });
  },
  async fetchPDFDocument(documentId) {
    return api.get(`/payroll/employee-w4/file/${documentId}`, {
      responseType: "blob",
    });
  },
};
