export default {
  addSettingsFetchError(state, { setting, error }) {
    if (state.settingsFetchErrors[setting]) {
      state.settingsFetchErrors[setting].push(error);
    } else {
      state.settingsFetchErrors[setting] = [error];
    }
  },
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setStates(state, states) {
    state.states = states.map((c) => ({
      ...c,
      code: c.code.trim(),
      description: c.description.trim(),
    }));
  },
};
