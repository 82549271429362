import Mock from "@/apis/stubs/mock";

const getStateTaxes = new Mock(
  {
    data: {
      hireDate: " 9/03/2019",
      electionOption: "",
      percentageElection: "4.2",
      additionalAmount: 3.0,
      electronicConsent: "",
      employmentStatus: "ACTIVE",
      statusMessage: " SINGLE, 1 allowances",
      fullName: "JEFFREY STILWELL",
      firstName: "JEFFREY S",
      lastName: "STILWELL",
      address: "2200 S 75TH AVE",
      cityStateZip: "PEORIA, AZ 85382",
      isDifferent: " ",
      city: "PEORIA",
      state: "AZ",
      zipCode: "85382",
      filingStatus: "SINGLE",
      allowances: 1,
      socialSecurityAreaNumber: "811",
      socialSecurityGroupNumber: "70",
      socialSecuritySerialNumber: "0105",
      ssn: "811-70-0105",
      employerId: "",
      employeeId: "   440861",
      _links: {
        self: {
          href: "http://www.ess-back-end-integration.apps.openshift.swift.com/knight/public/payroll/state-taxes/AZ/",
        },
      },
    },
  },
  {
    data: {
      _links: {
        self: {
          href: "http://www.ess-back-end-integration.apps.openshift.swift.com/knight/public/payroll/state-taxes/AZ",
        },
      },
    },
  },
  {
    data: {
      Arizona: {},
    },
  },
  "Unable to fetch contacts",
);

export default getStateTaxes;
