export default {
  setSearchCriteria(state, data) {
    state.searchCriteria = data;
  },
  setReportsCriteria(state, data) {
    state.reportsCriteria = data;
  },
  setProxyCriteria(state, data) {
    state.proxyCriteria = data;
  },
  setStatusCriteria(state, data) {
    state.statusCriteria = data;
  },
  setListViewOptions(state, data) {
    state.listViewOptions = data;
  },
  setRequestDateRangeCriteria(state, data) {
    state.requestDateRangeCriteria = data;
  },
  setIsFetchingData(state, data) {
    state.isFetchingData = data;
  },
  setTimeOffRequests(state, data) {
    state.timeOffRequests = data;
  },
  setTotalTimeOffRequests(state, data) {
    state.totalTimeOffRequests = data;
  },
};
