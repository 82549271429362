<template>
  <div class="request-queue-table-div">
    <v-text-field
      v-if="statusCode !== statusCodes.incompleteAS400Upload"
      v-model="search"
      outlined
      label="Search by Employee First Name, Last Name, or ID"
      append-icon="mdi-magnify"
      class="request-queue-search-field"
      clearable
      @click:clear="resetSearch"
    ></v-text-field>
    <v-data-table
      class="elevation-1"
      :loading="isLoading"
      loading-text="Loading Requests..."
      no-data-text="No Requests"
      :headers="tableHeaders()"
      :footer-props="tableFooter"
      :items="requests"
      :server-items-length="totalRequests"
      :search="search"
      :options.sync="options"
    >
      <template v-slot:header v-if="isByStatusQueue()">
        <tr class="grey lighten-3 table-filter-row">
          <th v-for="header in tableHeaders()" :key="`${header.text}`">
            <div>
              <v-autocomplete
                v-if="header.text === 'Status'"
                class="table-filter"
                item-value="status"
                item-text="description"
                :items="prepareTimeOffStatuses()"
                :value="statusCriteriaString"
                placeholder="Filter Status"
                v-on:input="statusFilterSelected"
                return-object
                dense
                flat
                clearable
                open-on-clear
              ></v-autocomplete>
            </div>
          </th>
        </tr>
      </template>
      <template v-slot:[`item.id`]="{ item }">
        {{ item.id }}
      </template>
      <template v-slot:[`item.employeeLastName`]="{ item }">
        {{
          formatEmployeeName(
            item.employeeFirstName,
            item.employeeLastName,
            item.employerId,
            item.employeeId
          )
        }}
      </template>
      <template v-slot:[`item.managerLastName`]="{ item }">
        {{
          formatEmployeeName(
            item.managerFirstName,
            item.managerLastName,
            item.managerEmployerId,
            item.managerEmployeeId
          )
        }}
      </template>
      <template v-slot:[`item.statusName`]="{ item }">
        {{ item.statusDescription }}
      </template>
      <template v-slot:[`item.totalHours`]="{ item }">
        {{ item.totalHours !== null ? item.totalHours : "0.00" }}
      </template>
      <template v-slot:[`item.reason`]="{ item }">
        {{ item.reason }}
      </template>
      <template v-slot:[`item.requestTypes`]="{ item }">
        <v-chip
          v-for="(requestType, key) in item.requestTypes"
          :key="key"
          class="ma-1"
          :color="timeOffCategoryBgColor(requestType.code)"
          label
          text-color="white"
          small
        >
          {{ requestType.description }}
        </v-chip>
      </template>
      <template v-slot:[`item.minDate`]="{ item }">
        <div
          :class="{
            highlighted_date: highlightDate(item.minDate),
          }"
        >
          {{ item.minDate !== null ? reFormatISODate(item.minDate) : "-" }}
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          color="primary"
          @click="gotoReview(item.employeeUserId, item.id)"
        >
          Review
          <v-icon class="pl-2">mdi-calendar-clock-outline</v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.createTimestamp`]="{ item }">
        {{ formatDateWithSeconds(item.createTimestamp) }}
      </template>
    </v-data-table>
    <reprocessUploads
      v-if="this.statusCode === this.statusCodes.incompleteAS400Upload"
    ></reprocessUploads>
  </div>
</template>

<script>
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import dates from "@/utils/dates";
import modulePageMixin from "@/utils/mixins";
import timeOffMixin from "@/modules/timeOff/timeOffMixin";
import { reFormatISODate, formatEmployeeName } from "@/utils/util";
import { orderBy } from "lodash";
import ReprocessUploads from "@/modules/timeOff/requestQueues/components/reprocessUploadButton.vue";

const requestQueuesStore = namespace("timeOff/requestQueues");
const requestsSearchStore = namespace("timeOff/requestsSearch");
const timeOffStore = namespace("timeOff");

@Component({
  components: { ReprocessUploads },
  directives: {},
})
export default class requestQueueTable extends Mixins(
  modulePageMixin,
  timeOffMixin
) {
  highlightOlderThanDate = null;

  @requestQueuesStore.Getter("requests") requests;
  @requestQueuesStore.Getter("totalRequests") totalRequests;
  @requestQueuesStore.Getter("requestQueue") requestQueue;
  @requestQueuesStore.Mutation("setTotalRequests") setTotalRequests;
  @requestQueuesStore.Mutation("setRequests") setRequests;
  @requestQueuesStore.Action("fetchRequestQueue") fetchRequestQueue;
  @timeOffStore.Getter("timeOffStatuses") timeOffStatuses;
  @requestsSearchStore.Getter("getStatusCriteria") statusCriteria;
  @requestsSearchStore.Mutation("setStatusCriteria") setStatusCriteria;
  @requestsSearchStore.Action("resetListViewOptions") resetListViewOptions;

  @Prop({
    type: String,
    required: true,
  })
  statusCode;

  data() {
    return {
      reFormatISODate,
      formatEmployeeName,
      tableFooter: {
        "items-per-page-options": [10, 25],
        "show-current-page": true,
        "show-first-last-page": true,
      },
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      search: "",
      isLoading: false,
      statusCriteriaString: null,
      sort: "",
    };
  }

  statusCodes = {
    pending: "P",
    completed: "F",
    denied: "D",
    pendingAS400Upload: "S",
    pendingPayroll: "Y",
    incompleteAS400Upload: "A",
    byStatus: "All",
  };

  possibleTableHeaders = [
    {
      text: "Request ID",
      align: "center",
      value: "id",
      sortable: false,
    },
    {
      text: "Employee",
      align: "center",
      value: "employeeLastName",
      sortable: true,
    },
    {
      text: "Approver Queue",
      align: "center",
      value: "managerLastName",
      sortable: true,
    },
    {
      text: "Status",
      align: "center",
      value: "statusName",
      sortable: false,
    },
    {
      text: "Hours",
      align: "center",
      value: "totalHours",
      sortable: true,
    },
    {
      text: "Reason",
      align: "center",
      value: "reason",
      sortable: false,
    },
    {
      text: "Request Type",
      align: "center",
      value: "requestTypes",
      sortable: false,
    },
    {
      text: "First Day",
      align: "center",
      value: "minDate",
      sortable: true,
    },
    {
      text: "Created Date",
      align: "center",
      value: "createTimestamp",
      sortable: false,
    },
    {
      text: "Actions",
      align: "center",
      value: "actions",
      width: "1px",
      sortable: false,
    },
  ];

  headersToRemove = {
    pending: ["Request ID", "Request Type", "Created Date"],
    completed: ["Request ID", "Reason", "Approver Queue", "Created Date"],
    denied: ["Request ID", "Reason", "Request Type", "Created Date"],
    managerActionRequired: ["Request ID", "Reason", "Created Date"],
    pendingAS400Upload: [
      "Request ID",
      "Approver Queue",
      "Reason",
      "Created Date",
    ],
    pendingPayroll: ["Request ID", "Reason", "Created Date"],
    byStatus: ["Request ID", "Approver Queue", "Reason", "Created Date"],
    incompleteAS400Upload: [
      "Approver Queue",
      "Reason",
      "Hours",
      "Request Type",
      "First Day",
      "Actions",
    ],
  };

  tableHeaders() {
    let tableStyle = "";

    if (this.isManagerActionRequired) {
      tableStyle = "managerActionRequired";
    } else {
      for (const [key, value] of Object.entries(this.statusCodes)) {
        if (value === this.statusCode) {
          tableStyle = key;
        }
      }
    }

    return this.possibleTableHeaders.filter((header) => {
      return !this.headersToRemove[tableStyle].includes(header.text);
    });
  }

  get isManagerActionRequired() {
    return (
      this.statusCode === this.statusCodes.pending &&
      this.$route.meta.managerActionRequired
    );
  }

  statusFilterSelected(status) {
    // reset page
    this.options.page = 1;
    this.setStatusCriteria(status);
  }

  @Watch("statusCriteria")
  statusCriteriaChanged() {
    this.getRequests();
  }

  @Watch("search")
  onSearchChange() {
    if (this.search?.length !== 0 && this.search?.length < 3) {
      return;
    }
    this.options.page = 1;
    this.clearRequests();
    this.searchDebounced();
  }

  @Watch("options", { deep: true })
  onOptionsChange() {
    if (Object.keys(this.options).length !== 0) {
      //set the format for the endpoint like: lastName:desc
      if (this.options.sortBy[0]) {
        this.sort = this.options.sortBy[0];
        if (this.options.sortDesc[0]) this.sort = this.sort + ":desc";
      } else {
        this.sort = "id:desc";
      }
    }

    this.clearRequests();
    this.searchDebounced();
  }

  get path() {
    return this.$route.path;
  }

  @Watch("path")
  onPathChange() {
    this.resetSearch();
    this.resetOptions();
    this.clearRequests();
    this.searchDebounced();
  }

  resetSearch() {
    this.search = "";
  }

  resetOptions() {
    this.options.page = 1;
    this.options.itemsPerPage = 10;
    this.options.sortBy = [];
    this.options.sortDesc = [];
  }

  clearRequests() {
    this.setRequests([]);
    this.setTotalRequests(0);
  }

  searchDebounced() {
    clearTimeout(this._searchTimerId);
    this._searchTimerId = setTimeout(() => {
      this.getRequests();
    }, 500);
  }

  async getRequests() {
    let params = {
      limit: this.options.itemsPerPage,
      page: this.options.page,
      "employeeId||employeeLastName||employeeFirstName": this.search,
      fields:
        "id,employeeFirstName,employeeLastName,employeeUserId,employerId,employeeId,managerFirstName,managerLastName," +
        "managerEmployerId,managerEmployeeId,totalHours,reason,firstDay,minDate,statusDescription,createTimestamp",
      sort: this.sort,
    };

    if (
      this.statusCode === this.statusCodes.completed ||
      this.statusCode === this.statusCodes.denied ||
      this.statusCode === this.statusCodes.pendingAS400Upload ||
      this.statusCode === this.statusCodes.pendingPayroll ||
      this.statusCode === this.statusCodes.byStatus
    ) {
      params.includeEntries = 1;
    }

    if (this.isManagerActionRequired) {
      params.managerActionRequired = 1;
      params.includeEntries = 1;
    }

    this.isLoading = true;
    await this.fetchRequestQueue({
      statusCode:
        this.statusCriteria === null
          ? this.statusCode
          : this.statusCriteria.status,
      queryParams: params,
    });

    if (
      this.statusCode === this.statusCodes.completed ||
      this.isManagerActionRequired ||
      this.statusCode === this.statusCodes.pendingAS400Upload ||
      this.statusCode === this.statusCodes.pendingPayroll ||
      this.statusCode === this.statusCodes.byStatus
    ) {
      this.parseRequestTypes();
    }

    this.isLoading = false;
  }

  prepareTimeOffStatuses() {
    this.timeOffStatuses.push(
      new Object({ status: "All", description: "All" })
    );

    return orderBy(this.timeOffStatuses, "description");
  }

  isByStatusQueue() {
    return this.$route.meta?.statusCode === "All";
  }

  parseRequestTypes() {
    let filteredRequests = this.filterDeletedEntries(this.requests);
    filteredRequests.forEach(function (request) {
      let requestTypesList = [];
      request.entries.forEach(function (requestTypes) {
        if (
          !requestTypesList.some(
            ({ description }) => description === requestTypes.description
          )
        ) {
          requestTypesList.push({
            description: requestTypes.description,
            code: requestTypes.code,
          });
        }
      });
      request.requestTypes = requestTypesList;
    });
  }

  highlightDate(date) {
    return dates.dateIsBefore(date, this.highlightOlderThanDate);
  }

  formatDateWithSeconds(date) {
    return date !== null
      ? dates.dateToStringCustomFormat(date.date, "YYYY-MM-DD HH:mm:ss")
      : "-";
  }

  async created() {
    this.highlightOlderThanDate = dates.dateBeforeToday(
      this.timeOffConfig("highlight_queue_dates_older_than")
    );
  }

  destroyed() {
    this.resetListViewOptions();
  }
}
</script>

<style scoped>
.request-queue-search-field {
  padding-top: 10px;
}

.highlighted_date {
  color: red;
}
</style>
