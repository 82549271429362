export default () => ({
  isSubmitting: false,
  withholdOptions: ["0.5", "1.0", "1.5", "2.0", "2.5", "3.0", "3.5"],
  submitError: {},
  percentageElection: "0.0",
  additionalAmount: "",
  electronicConsent: false,
  isLoading: false,
  error: null,
  currentTaxInfo: {},
  electWithhold: false,
  electAmount: false,
  hasQueued: false,
  queued: {},
});
