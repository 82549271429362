var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-table-div"},[_c('errorAlert',{directives:[{name:"show",rawName:"v-show",value:(_vm.fetchError),expression:"fetchError"}],attrs:{"error":_vm.fetchError}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.isFetchingData,"loading-text":"Loading Users...","no-data-text":"No Users","headers":_vm.tableHeaders,"footer-props":_vm.tableFooter,"items":_vm.adminUsers,"server-items-length":_vm.totalAdminUsers,"options":_vm.options,"item-key":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tr',{staticClass:"grey lighten-3 table-filter-row"},_vm._l((_vm.tableHeaders),function(header){return _c('th',{key:header.text})}),0)]},proxy:true},{key:"item.employeeId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.employerId)+" - "+_vm._s(item.employeeId)+" ")]}},{key:"item.employeeLastName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatName(item))+" ")]}},{key:"item.supervisorName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatName(item))+" ")]}},{key:"item.proxyName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatProxyName(item))+" ")]}},{key:"item.isEnabled",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-switch',{attrs:{"inset":""},on:{"change":function($event){return _vm.updateActiveStatus(item)}},model:{value:(item.isEnabled),callback:function ($$v) {_vm.$set(item, "isEnabled", $$v)},expression:"item.isEnabled"}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.removeUser(item)}}},[_c('v-icon',{staticClass:"pl-2 pa-2"},[_vm._v("mdi-account-remove")]),_vm._v(" Remove ")],1)]}}],null,true)}),_c('deleteDialog',{attrs:{"persistent":"","showDialog":_vm.deleteConfirmation,"message":_vm.deleteMessage},on:{"deleteConfirmed":_vm.removeUserConfirmed,"closeDialog":function($event){_vm.deleteConfirmation = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }