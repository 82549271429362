import _ from "lodash";
import { getEnv } from "@/utils/util";

export default {
  flatSplitCount(state) {
    return state.deposits.filter((deposit) => deposit.depositType === "F")
      .length;
  },
  validFlatSplitLimit(state) {
    return (
      state.deposits.filter((deposit) => deposit.depositType === "F").length
      < getEnv("VUE_APP_MAX_FLAT_SPLITS")
    );
  },
  percentageSplitCount(state) {
    return state.deposits.filter((deposit) => deposit.depositType === "P")
      .length;
  },
  validPercentageSplitLimit(state) {
    return (
      state.deposits.filter((deposit) => deposit.depositType === "P").length
      < getEnv("VUE_APP_MAX_PERCENTAGE_SPLITS")
    );
  },
  fuelCardSplits(state) {
    return state.deposits.filter((deposit) => deposit.accountType === "F");
  },
  hasUnsavedChanges(state) {
    return !_.isEqual(state.deposits, state.initialDeposits);
  },
};
