export default {
  setElectWithhold(state, data) {
    if (typeof data === "boolean") {
      state.electWithhold = data;
    } else state.electWithhold = data <= 0;
  },
  setElectionOption(state, data) {
    state.electionOption = data;
  },
  setElectAmount(state, data) {
    if (typeof data === "boolean") {
      state.electAmount = data;
    } else state.electAmount = data > 0;
  },
  setAdditionalAmount(state, data) {
    state.additionalAmount = data;
  },
  setPercentageElection(state, data) {
    if (data !== undefined) {
      state.percentageElection = parseFloat(data).toFixed(1);
    } else {
      state.percentageElection = "0.0";
    }
  },
  setElectronicConsent(state, data) {
    state.electronicConsent = data;
  },
  setIsSubmitting(state, data) {
    state.isSubmitting = data;
  },
  setSubmitError(state, error) {
    state.submitError = error;
  },
  setIsLoading(state, data) {
    state.isLoading = data;
  },
  setError(state, error) {
    state.error = error;
  },
  setCurrentTaxInfo(state, data) {
    state.currentTaxInfo = data;
  },
  setHasQueued(state, data) {
    state.hasQueued = data.length !== 0;
  },
  setQueued(state, data) {
    state.queued = data;
  },
};
