<template>
  <v-form ref="claimDependents" v-model="valid">
    <v-card class="my-4">
      <v-toolbar class="toolBar" color="primary" dark>
        <v-toolbar-title>
          <i class="mdi mdi-account-child" /> Step 3 - Claim Dependent and Other Credits
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-2">
        <v-row class="dependentsHeaderRow">
          <v-col cols="12" md="9">
            <p class="ma-0">
              If your total income will be $200,000 or less ($400,000 or less if married filing jointly):
            </p>
          </v-col>
        </v-row>
        <v-row class="dependentsRow">
          <v-col cols="12" sm="7" md="9">
            <p>
              Multiply the number of qualifying children under age 17 by $2000<br />
              <span class="hintText">
                * If greater than 20 qualifying children, please message payrolltax@swifttrans.com or call 800-771-6317,
                Option 5
              </span>
            </p>
          </v-col>
          <v-col cols="6" sm="2" md="1">
            <v-select dense v-model="qualifyingChildrenAmount" :items="qualifyingChildrenValues" />
          </v-col>
          <v-col cols="6" sm="3" md="2">
            <v-text-field dense readonly prefix="$" v-model="qualifyingChildrenAmount" />
          </v-col>
        </v-row>
        <v-row class="dependentsRow">
          <v-col cols="12" sm="7" md="9">
            <p>
              Multiply the number of other dependents (older than 17 or qualifying relative) by $500<br />
              <span class="hintText">
                * If greater than 20 other dependents, please message payrolltax@swifttrans.com or call 800-771-6317,
                Option 5
              </span>
            </p>
          </v-col>
          <v-col cols="6" sm="2" md="1">
            <v-select dense v-model="otherDependentAmount" :items="otherDependentValues" />
          </v-col>
          <v-col cols="6" sm="3" md="2">
            <v-text-field dense readonly prefix="$" v-model="otherDependentAmount" />
          </v-col>
        </v-row>
        <v-row class="dependentsRow">
          <v-col cols="12" sm="7" md="9">
            <p>You may add the amount of any other credits</p>
          </v-col>
          <v-spacer />
          <v-col cols="12" sm="3" md="2">
            <v-text-field
              v-model="otherCreditAmount"
              dense
              prefix="$"
              :rules="[money, maxAmount]"
              v-on:blur="checkValue($event, 'otherCreditAmount')"
            />
          </v-col>
        </v-row>
        <v-row class="dependentsRow">
          <v-col cols="12" sm="7" md="9">
            <p>Total for qualifying children, other dependents, and other credits.</p>
          </v-col>
          <v-col cols="2" sm="2" md="1">
            <div><span class="numberBlock">3</span></div>
          </v-col>
          <v-col cols="10" sm="3" md="2">
            <v-text-field v-model="creditsSum" dense prefix="$" readonly />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
/* eslint-disable class-methods-use-this */
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import validators from "@/utils/validators";

const w4store = namespace("payroll/w4");

@Component({
  name: "ClaimDependents",
  components: {},
})
export default class ClaimDependents extends Vue {
  @w4store.State("formData") formData;

  @w4store.Mutation("setFormData") setFormData;

  @w4store.State("formSections") formSections;

  @w4store.Mutation("setFormSections") setFormSections;

  qualifyingChildrenValues = [];

  otherDependentValues = [];

  qualifyingChildrenMultiplier = 2000;

  otherDependentMultiplier = 500;

  dropdownMin = 0;

  dropdownMax = 20;

  @Watch("formData.exemptFlag", { deep: true })
  updateValuesIfExempt(isExempt) {
    if (isExempt) {
      this.setFormData({
        qualifyingChildrenAmount: 0,
        otherDependentAmount: 0,
        otherCreditAmount: 0,
        creditsSum: 0,
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  data() {
    return {
      valid: true,
    };
  }

  get sections() {
    return this.formSections;
  }

  mounted() {
    this.setDropdownValues();
  }

  setDropdownValues() {
    for (let i = this.dropdownMin; i <= this.dropdownMax; i++) {
      this.qualifyingChildrenValues.push({
        text: `${i}`,
        value: i * this.qualifyingChildrenMultiplier,
      });
      this.otherDependentValues.push({
        text: `${i}`,
        value: i * this.otherDependentMultiplier,
      });
    }
  }

  get qualifyingChildrenAmount() {
    return this.formData.qualifyingChildrenAmount;
  }

  set qualifyingChildrenAmount(val) {
    this.setFormData({ qualifyingChildrenAmount: val, employeeSignature: "" });
    this.setFormData({ creditsSum: this.creditsSum });
    this.$emit("validate");
  }

  get otherDependentAmount() {
    return this.formData.otherDependentAmount;
  }

  set otherDependentAmount(val) {
    this.setFormData({ otherDependentAmount: val, employeeSignature: "" });
    this.setFormData({ creditsSum: this.creditsSum });
    this.$emit("validate");
  }

  get otherCreditAmount() {
    const credits = parseFloat(this.formData.otherCreditAmount);
    return !Number.isNaN(credits) ? credits : "";
  }

  set otherCreditAmount(value) {
    this.setFormData({ otherCreditAmount: value, employeeSignature: "" });
    this.setFormData({ creditsSum: this.creditsSum });
    this.$emit("validate");
  }

  get creditsSum() {
    return (
      this.qualifyingChildrenAmount
      + this.otherDependentAmount
      + this.otherCreditAmount
    );
  }

  money(value) {
    return validators.money(value) || "Invalid format for currency";
  }

  maxAmount(value) {
    return (
      validators.maxAmount(value, 49999) || "Amount should lower than $49,999"
    );
  }

  async checkValue(event, field) {
    if (event.target.value === "") {
      this[field] = "0";
    }
    await this.$nextTick();
    this.validate();
  }

  validate() {
    const isValid = this.$refs.claimDependents.validate();

    this.sections.forEach((section) => {
      if (section.component === "claimDependents") {
        section.valid = isValid;
      }
    });
    this.setFormSections(this.sections);
    return isValid;
  }
}
</script>

<style scoped>
.dependentsHeaderRow {
  text-align: right;
}
.dependentsRow {
  text-align: right;
}
.numberBlock {
  padding: 7px;
  font-size: 16px;
  color: black;
  background-color: #a2a2a2;
}
.hintText {
  font-size: 12px;
}
@media only screen and (max-width: 599px) {
  .dependentsRow {
    text-align: left;
  }
}
@media only screen and (max-width: 959px) {
  .dependentsHeaderRow {
    text-align: left;
  }
}
</style>
