import axios from "axios";
import { getEnv } from "@/utils/util";
import { applicationConfig } from "./stubs/applicationConfig";

const api = axios.create({
  baseURL: getEnv("VUE_APP_CONFIG_API_URL"),
});

api.defaults.headers.common.Accept = "application/json";

const useStub = getEnv("VUE_APP_STUB_APIS").toLowerCase() === "true"
  || getEnv("VUE_APP_LOAD_REMOTE_CONFIG").toLowerCase() === "false";

export default {
  api,
  getAppConfiguration() {
    return useStub
      ? applicationConfig.getResponse()
      : api.get("/applicationConfig");
  },
  getModulesFromConfigURL(url) {
    const remoteAPI = axios.create({
      baseURL: url,
    });
    return remoteAPI.get("/applicationConfig");
  },
};
