<template>
  <v-dialog v-model="show" scrollable :max-width="maxWidth" persistent>
    <v-card id="employee-search-card" class="keepMinHeight">
      <v-card-title style="padding: 0">
        <v-col>
          {{ title }}
        </v-col>
        <v-col class="text-right">
          <v-btn icon @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-card-title>
      <v-card-text>
        <!-- if only one search type, just show a title -->
        <v-card-title v-if="showSearchTypeTitle" style="padding: 0">
          {{ searchTypeTitle }}
        </v-card-title>

        <!-- if multiple search types, show the tabs -->
        <v-tabs
          v-if="showSearchSelectorTabs"
          id="search-selector-tabs"
          v-model="searchSelectorTabModel"
          @change="setupCurrentSearchSelectorTab"
          class="headline white--text primary font-weight-light"
          fixed-tabs
          show-arrows
        >
          <v-tab
            v-for="searchTypeTab in searchSelectorTabs"
            :key="searchTypeTab.id"
          >
            {{ searchTypeTab.label }}
          </v-tab>
        </v-tabs>

        <!-- if proxy manager select has one  manager proxy just show it -->
        <v-card-title v-if="showManagerProxyLabel" style="padding: 0">
          {{ managerProxyAssignmentSelects[0].text }}
        </v-card-title>

        <!-- if proxy manager select has 2 manager proxies show list -->
        <v-list v-if="showManagerProxySelectVList">
          <v-list-item-group
            v-model="managerProxySelectedModel"
            color="primary"
          >
            <v-list-item
              v-for="(
                managerProxyAssignment, i
              ) in managerProxyAssignmentSelects"
              :key="i"
            >
              <v-list-item-content>
                <v-list-item-title
                  v-text="managerProxyAssignment.text"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <!-- if proxy manager select has more than 2 manager proxies show dropdown -->
        <v-select
          v-if="showManagerProxySelectDropdown"
          v-model="managerProxySelectedModel"
          :items="managerProxyAssignmentSelects"
          filled
          label="Manager Proxy Assignment"
        ></v-select>

        <v-text-field
          v-if="showSearchInput"
          style="padding-top: 10px"
          v-model="search"
          outlined
          label="Search by First Name, Last Name, or Employee Id"
          append-icon="mdi-magnify"
          autofocus
          clearable
          hide-details
        ></v-text-field>

        <!-- if listing is manager reports, allow selecting direct, indirect -->
        <v-radio-group
          v-if="showManagerReportSelector"
          row
          hide-details
          v-model="managerReportModel"
          @change="managerReportChanged"
          style="padding-top: 0px; padding-bottom: 0px; margin-top: 5px"
        >
          <v-radio label="All" value=""></v-radio>
          <v-radio label="Direct Reports" value="direct"></v-radio>
          <v-radio label="Indirect Reports" value="indirect"></v-radio>
        </v-radio-group>

        <v-data-table
          v-if="showDataTable"
          :headers="tableHeaders"
          :footer-props="footerData"
          :items="entries"
          :options.sync="options"
          :server-items-length="count"
          :loading="isLoading"
          :search="search"
          :no-data-text="noData"
          class="elevation-1"
          loading-text="Loading results..."
        >
          <template v-slot:item="{ item }">
            <tr
              :class="hiLiteSelectedEmployee(item)"
              @click="employeeRowClicked(item)"
            >
              <td>{{ item.employeeId }}</td>
              <td>{{ item.lastName }}</td>
              <td>{{ item.firstName }}</td>
              <td>{{ item.level2 }}</td>
              <td>{{ item.positionTitle }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-text v-if="apiError" class="error--text">
        Employee search error: {{ apiError }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="$emit('close')"> Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import timeOffApi from "@/modules/timeOff/api/timeOff";
import coreApi from "@/apis/core";

export default {
  name: "EmployeeDataTableSelectDialog",

  props: {
    title: { type: String, required: false, default: "Employee Select" },
    maxWidth: { type: String, default: "750" },
    show: { type: Boolean, required: true },
    dataTableItemsPerPage: { type: Number, default: 10 },
    currentSelectedEmployee: { type: String, required: false, default: "" },
    searchType: { type: String, required: false, default: "timeOff" },
    returnObject: { type: Boolean, required: false, default: false },
    hasReports: { type: Boolean, required: false, default: true },
    hasProxyAssignments: { type: Boolean, required: false, default: false },
    hasManagerProxyReports: { type: Boolean, required: false, default: false },
    managerProxyAssignments: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data: () => ({
    loaded: false,
    entries: [],
    pageCount: 0,
    count: 0,
    apiError: null,
    isLoading: false,
    model: null,
    search: null,
    searchTypes: {
      timeOff: {
        api: timeOffApi,
        endpoint: "fetchUserEmployees",
        embeddedKey: "employees",
        managerReportsSearch: true,
      },
      userProxyAssignments: {
        api: timeOffApi,
        endpoint: "fetchUserProxyAssignments",
        embeddedKey: "proxyAssignments",
        managerReportsSearch: false,
      },
      managerProxyEmployees: {
        api: timeOffApi,
        endpoint: "fetchUserEmployeeReports",
        embeddedKey: "employees",
        managerReportsSearch: true,
      },
      infinium: {
        api: coreApi,
        endpoint: "fetchEmployees",
        embeddedKey: "employees",
        managerReportsSearch: false,
      },
    },
    searchSelectorTabTypes: {
      managerReports: {
        id: "managerReports",
        label: "My Reports",
        onSelect: "setupManagerReports",
      },
      proxyAssignments: {
        id: "proxyAssignments",
        label: "My Proxy Assignments",
        onSelect: "setupProxyAssignments",
      },
      managerProxyEmployees: {
        id: "managerProxyEmployees",
        label: "Employees For Manager Proxy",
        onSelect: "setupManagerProxies",
      },
    },
    searchSelectorTabModel: null,
    managerProxySelectedModel: null,
    managerReportModel: "",
    managerReportsSearch: false,
    options: {
      itemsPerPage: 10,
      sortBy: ["completeName"],
      sortDesc: [],
    },
    optionsSynced: false,
    tableHeaders: [
      {
        text: "Employee Id",
        align: "start",
        sortable: true,
        value: "employeeId",
      },
      {
        text: "Last Name",
        align: "start",
        sortable: true,
        value: "lastName",
      },
      {
        text: "First Name",
        value: "firstName",
        sortable: true,
      },
      {
        text: "Position",
        value: "level2",
        sortable: false,
      },
      {
        text: "Position Title",
        value: "positionTitle",
        sortable: false,
      },
    ],
    footerData: {
      "items-per-page-options": [],
      "items-per-page": 0,
      "show-current-page": true,
      "show-first-last-page": true,
    },
    fetchApiFunction: null,
    fetchUsersEmbeddedKey: null,
  }),

  computed: {
    noData: function () {
      return "No employees found";
    },
    searchSelectorTabs: function () {
      if (this.searchType !== "timeOff") {
        return [];
      }

      let tabs = [];
      if (this.hasReports === true) {
        tabs.push(this.searchSelectorTabTypes.managerReports);
      }
      if (this.hasProxyAssignments === true) {
        tabs.push(this.searchSelectorTabTypes.proxyAssignments);
      }
      if (this.hasManagerProxyReports === true) {
        tabs.push(this.searchSelectorTabTypes.managerProxyEmployees);
      }

      return tabs;
    },
    searchSelectorTabsReady: function () {
      if (this.searchSelectorTabs.length === 0) {
        return true;
      }

      return this.searchSelectorTabModel !== null;
    },
    showSearchSelectorTabs: function () {
      return this.searchSelectorTabs.length > 1;
    },
    showSearchTypeTitle: function () {
      return (
        this.searchSelectorTabs.length === 1 &&
        this.searchSelectorTabs[0].id !== "managerReports"
      );
    },
    showSearchInput: function () {
      return this.pageCount > 1 || this.search !== null;
    },
    showManagerReportSelector: function () {
      return this.managerReportsSearch;
    },
    showDataTable: function () {
      return (
        (this.searchSelectorTabsReady && // selectorTabsReady()?
          this.selectedSearchSelectorTabId() !== "managerProxyEmployees") ||
        this.managerProxySelectedModel !== null
      );
    },
    showManagerProxyLabel: function () {
      return (
        this.searchSelectorTabsReady &&
        this.selectedSearchSelectorTabId() === "managerProxyEmployees" &&
        this.managerProxyAssignments.length === 1
      );
    },
    showManagerProxySelectVList: function () {
      return (
        this.searchSelectorTabsReady &&
        this.selectedSearchSelectorTabId() === "managerProxyEmployees" &&
        this.managerProxyAssignments.length === 2
      );
    },
    showManagerProxySelectDropdown: function () {
      return (
        this.searchSelectorTabsReady &&
        this.selectedSearchSelectorTabId() === "managerProxyEmployees" &&
        this.managerProxyAssignments.length > 2
      );
    },
    searchTypeTitle: function () {
      return this.showSearchTypeTitle ? this.searchSelectorTabs[0].label : "";
    },
    managerProxyAssignmentSelects: function () {
      return this.managerProxyAssignments.map((managerProxyAssignment, i) => {
        return {
          text:
            managerProxyAssignment.lastName.trim() +
            ", " +
            managerProxyAssignment.firstName.trim(),
          value: i,
        };
      });
    },
    fields() {
      if (!this.model) return [];

      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || "n/a",
        };
      });
    },
    items() {
      return this.entries.map((entry) => {
        const Description = `${entry.completeName} - ${entry.employeeId}`;

        return Object.assign({}, entry, { Description });
      });
    },
  },

  watch: {
    search(val) {
      if (val?.length > 0 && val?.length < 3) {
        return;
      }
      this.resetEntries();
      this.searchDebounced();
    },
    show(val) {
      if (val === true) {
        this.setupCurrentSearchSelectorTab();
        // reset the search from last session
        this.resetSearch();
        this.getDataFromApi();
      }
    },
    managerProxySelectedModel() {
      this.selectorTabSetupFunction(this.searchSelectorTabModel)();
    },
    options: {
      deep: true,
      handler() {
        if (Object.keys(this.options).length !== 0) {
          //set the format for the endpoint like: lastName:desc
          if (this.options.sortBy[0]) {
            this.sort = this.options.sortBy[0];
            if (this.options.sortDesc[0]) this.sort = this.sort + ":desc";
          } else {
            this.sort = "";
          }
        }
        if (!this.optionsSynced) {
          this.optionsSynced = true;
          return;
        }
        this.searchDebounced();
      },
    },
  },

  methods: {
    setupCurrentSearchSelectorTab() {
      if (this.searchSelectorTabs.length === 0) {
        return;
      }
      if (this.searchSelectorTabModel === null) {
        this.searchSelectorTabModel = 0;
      }
      const setupFunc = this.selectorTabSetupFunction(
        this.searchSelectorTabModel
      );
      setupFunc();
    },
    getSearchSelectorTabType(index) {
      let tabs = this.searchSelectorTabs;
      if (tabs.length === 0) {
        return;
      }
      if (index === null) {
        index = 0;
      }
      let searchSelectorTabType = tabs[index];
      if (!searchSelectorTabType) {
        throw "No selector tab " + index;
      }

      return searchSelectorTabType;
    },
    selectedSearchSelectorTab() {
      return this.getSearchSelectorTabType(this.searchSelectorTabModel);
    },
    selectedSearchSelectorTabId() {
      return this.selectedSearchSelectorTab()?.id ?? null;
    },
    selectorTabSetupFunction(index) {
      let searchSelectorTabType = this.getSearchSelectorTabType(index);
      let setupFunction = searchSelectorTabType.onSelect;

      if (typeof this[setupFunction] !== "function") {
        throw setupFunction + " is not a function";
      }

      return this[setupFunction];
    },
    setupManagerReports() {
      this.resetSearch();
      this.setCurrentFetchApi("timeOff");
      this.options.itemsPerPage =
        this.searchSelectorTabs.length > 1 ? 5 : this.dataTableItemsPerPage;
      this.fetchEntries();
    },
    setupProxyAssignments() {
      this.resetSearch();
      this.setCurrentFetchApi("userProxyAssignments");
      this.options.itemsPerPage =
        this.searchSelectorTabs.length > 1 ? 5 : this.dataTableItemsPerPage;
      this.fetchEntries();
    },
    setupManagerProxies() {
      this.resetSearch();
      if (this.managerProxySelectedModel === null) {
        this.managerProxySelectedModel = 0;
      }

      // get manager proxy user id
      let managerProxyUserId = this.getSelectedManagerProxyUserId(
        this.managerProxySelectedModel
      );

      this.setCurrentFetchApi("managerProxyEmployees");
      this.options.itemsPerPage =
        this.searchSelectorTabs.length > 1 ? 5 : this.dataTableItemsPerPage;

      // override fetchApiFunction here because we need to pass managerProxyUserId
      const api = this.searchTypes["managerProxyEmployees"];
      // - create an anonymous function
      this.fetchApiFunction = (params) => {
        return api.api[api.endpoint](managerProxyUserId, params);
      };

      this.fetchEntries();
    },
    getSelectedManagerProxy(index) {
      let managerProxies = this.managerProxyAssignments;

      if (managerProxies.length === 0) {
        return;
      }
      if (index === null) {
        index = 0;
      }
      let managerProxy = managerProxies[index];
      if (!managerProxy) {
        throw "No manager proxy assignment " + index;
      }

      return managerProxy;
    },
    getSelectedManagerProxyUserId: function (index) {
      return this.getSelectedManagerProxy(index).userId;
    },
    hiLiteSelectedEmployee(item) {
      return this.currentSelectedEmployee &&
        item.userId.trim() === this.currentSelectedEmployee
        ? "orange accent-2"
        : "";
    },
    clearEntries() {
      this.count = 0;
      this.entries = [];
    },
    resetEntries() {
      this.pageCount = 0;
      this.clearEntries();
      this.apiError = null;
      this.options.page = 1;
    },
    resetSearch() {
      this.resetEntries();
      this.managerReportModel = "";
      this.model = null;
      this.search = null;
    },
    async getDataFromApi() {
      await this.fetchEntries();
    },
    searchDebounced() {
      if (!this.show || this.loaded === false) return;

      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.fetchEntries();
      }, 500); /* 500ms throttle */
    },
    managerReportChanged() {
      this.resetEntries();
      this.model = null;
      this.search = null;
      this.searchDebounced();
    },
    async fetchEntries() {
      this.isLoading = true;
      let searchParams = {
        limit: this.options.itemsPerPage,
        page: this.options.page,
        sort: this.sort,
        "level2:neq": "DRV",
        terminationCode: "     ",
        fields: "employeeId,lastName,firstName,level2,positionTitle,userId",
      };

      if (this.managerReportsSearch && this.managerReportModel !== "") {
        searchParams.reports = this.managerReportModel;
      }

      if (this.search !== null) {
        const searchParts = this.search.split(" ", 2);

        if (searchParts.length > 0) {
          searchParams["employeeId||lastName||firstName"] = searchParts[0];

          const secondPart = searchParts[1];
          if (secondPart !== undefined) {
            searchParams["lastName||firstName"] = secondPart;
          }
        }
      }

      try {
        const { data } = await this.fetchApiFunction(searchParams);

        this.entries = data._embedded[this.fetchUsersEmbeddedKey];
        this.count = data._total_items;
        this.pageCount = data._page_count;
      } catch (e) {
        if (e?.message === "Cancelled") {
          // api call was cancelled. just return
          return;
        } else if (e.response) {
          this.apiError = e.response.data.detail || e.response.data.title;
        } else if (e.request) {
          this.apiError = "Could not complete this request";
        } else {
          this.apiError = "Unknown error";
        }
      }

      this.isLoading = false;
      this.loaded = true;
    },

    employeeRowClicked(row) {
      if (this.returnObject) {
        this.$emit("employeeSelected", row);
      } else {
        this.$emit("employeeSelected", row.userId);
      }
    },

    setCurrentFetchApi(searchType) {
      const searchTypeObj = this.searchTypes[searchType];
      // set reference to the api function
      this.fetchApiFunction = searchTypeObj.api[searchTypeObj.endpoint];
      this.fetchUsersEmbeddedKey = searchTypeObj.embeddedKey;
      this.managerReportsSearch = searchTypeObj.managerReportsSearch;
    },
  },
  mounted() {
    if (this.searchSelectorTabs.length === 0) {
      this.setCurrentFetchApi(this.searchType);
    }
    this.options.itemsPerPage = this.dataTableItemsPerPage;
    this.footerData["items-per-page"] = this.dataTableItemsPerPage;
  },
};
</script>

<style scoped>
#employee-search-card.keepMinHeight {
  min-height: 792px !important;
}
</style>
