import _ from "lodash";

export default {
  payload(state) {
    const payload = [...state.workingEducations];

    return payload.map((education) => ({
      ...education,
    }));
  },
  hasEDUnsavedChanges(state) {
    return !_.isEqual(state.initialEducations, state.workingEducations);
  },
};
