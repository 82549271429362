import Mock from "./mock";

export const login = new Mock(
  {
    data: {
      // This should be a jwt token held in the openshift secrets
      TokenId:
      // eslint-disable-next-line max-len,vue/max-len
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NDI3ODI1NjYsIm5iZiI6MTY0Mjc4MjU2NiwidG9rZW4iOiIwMWY0MmE0Zi1jYTlkLTRhYzEtOWQwOS1hZDE1Yzc4YWJmMzAiLCJ1c2VySWQiOiJIRU5ERVJDSCIsImZpcnN0TmFtZSI6IkNocmlzIiwibGFzdE5hbWUiOiJIZW5kZXJzb24iLCJhY3RpdmVHcm91cHMiOlsiS05YLUhSX01TU19TdXBlcl9Vc2VycyIsIkxpY2Vuc2VfTzM2NV9FNSIsIldlYiBUZXJtaW5hdGlvbiBSZXF1ZXN0ZXJzIiwiTUlTIEVtcGxveWVlIFRlcm1pbmF0aW9uIE5vdGlmaWNhdGlvbiIsIkNSTSBFbXBsb3llZXMiLCJTRVFVRUxfVklFV1BPSU5UIiwiQ29uZmlndXJhdGlvbiBNYW5hZ2VyIEFkbWluaXN0cmF0b3JzIiwiQVpfQURNSU4iLCJOdW1hcmFUZWNobmljaWFucyIsIlZQTl9BY2Nlc3MiLCJGcmVlTmFjX0FsZXJ0IiwiQWRtaW4iLCJTQVMiLCJYUmVmQWRtaW4iLCJPZmZpY2UgRW1wbG95ZWVzIiwiTUlTIE5ldyBIaXJlIE5vdGlmaWNhdGlvbiIsIklUIFN0YWZmIiwiUmVjaXBpZW50IE1hbmFnZW1lbnQiLCJLTlgtSFJfTVNTX0FwcHJvdmFsIiwiS05YLUhSX01TU19Ecml2ZXJfVHJhbnNmZXJfQXBwcm92YWwiLCJLTlgtSFJfTVNTX0RyaXZlcl9TZXBhcmF0aW9uX0FwcHJvdmFsIiwiS05YLUhSX01TU19Ecml2ZXJfSGlyZV9BcHByb3ZhbCIsIktOWC1IUl9NU1NfSW5pdGlhdGUiLCJLTlgtSFJfTVNTX0RyaXZlcl9UcmFuc2Zlcl9Jbml0aWF0ZSIsIktOWC1IUl9NU1NfRHJpdmVyX1NlcGFyYXRpb25fSW5pdGlhdGUiLCJLTlgtSFJfTVNTX0RyaXZlcl9IaXJlX0luaXRpYXRlIiwiS05YLUhSX01TU19SZWFkX09ubHkiLCJLTlgtSFJfTVNTX0RyaXZlcl9UcmFuc2Zlcl9SZWFkX09ubHkiLCJLTlgtSFJfTVNTX0RyaXZlcl9TZXBhcmF0aW9uX1JlYWRfT25seSIsIktOWC1IUl9NU1NfRHJpdmVyX0hpcmVfUmVhZF9Pbmx5Il19.rrecw0FEmXivVoPIvMQUOIjLxrsuBQ6vkJ9zcItXaSg",
    },
  },
  null,
  // Invalid Login
  {
    title: "Internal Server Error",
    type: "https://httpstatus.es/500",
    status: 500,
    detail:
      "Invalid login attempt. Please check your User & Password and try again.",
  },
  // Runtime Error
  // {
  //   response: {
  //     status: 500,
  //     data: {
  //       title: "Runtime Error",
  //       type: "https://httpstatus.es/500",
  //       status: 500,
  //       detail: "Some error happened",
  //     },
  //   },
  // }
);

export const logout = new Mock({
  data: {
    message: "You have been logged out.",
  },
});

export const getEmployeeInfo = new Mock({
  data: {
    employerId: "200",
    employeeId: "   00000",
    userId: "TESTU                                  ",
    firstName: "TEST           ",
    middleName: "               ",
    middleInitial: "AN",
    lastName: "EMPLOYEE         ",
    completeName: "EMPLOYEE,TEST AN                      ",
    email:
      "test_employee@swifttrans.com                                               ",
    employeeType: "10100",
    level2: "DRV* ",
    level3: "DV00X",
    positionCode: "92510",
    employeePosition: "AZSDA2",
    location: "PHOE ",
    gender: "M",
    commonName: "TEST                ",
    dateOfHireHyf: 43653,
    dateOfHireText: " 7/08/2019",
    dateOfHireDigits: 7082019,
    dateOfHireMonth: 708,
    dateOfHireYear: 2019,
    statusCode: "FULL ",
    preferredLanguage: "     ",
    jobCode1: "SANP7",
    securityGroupCode: "EXMPT",
    payType: "S",
    positionTitle: "PHOAZ-SOFTWARE DEV/ANALYST II ",
    countryCode: "USA",
    trainingGroup: "IT   ",
    workmanEmplCode: "8810 ",
    laborCategory: "J0854",
    payFrequency: "B ",
    erpLxShopFloorEe: "0",
    _links: {
      self: {
        href: "http://swift.swift.com:10090/CoreAPI/development/knight/public/infinium/employees/stapt",
      },
    },
  },
});

export const getRelease = new Mock({
  data: {
    "3.1.0": {
      date: "2021-12-17",
      Added: ["10 Create Releases API"],
    },
  },
});

export const ssoToken = new Mock({
  data: {
    // eslint-disable-next-line max-len,vue/max-len
    TokenId: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NDI3ODI1NjYsIm5iZiI6MTY0Mjc4MjU2NiwidG9rZW4iOiIwMWY0MmE0Zi1jYTlkLTRhYzEtOWQwOS1hZDE1Yzc4YWJmMzAiLCJ1c2VySWQiOiJIRU5ERVJDSCIsImZpcnN0TmFtZSI6IkNocmlzIiwibGFzdE5hbWUiOiJIZW5kZXJzb24iLCJhY3RpdmVHcm91cHMiOlsiS05YLUhSX01TU19TdXBlcl9Vc2VycyIsIkxpY2Vuc2VfTzM2NV9FNSIsIldlYiBUZXJtaW5hdGlvbiBSZXF1ZXN0ZXJzIiwiTUlTIEVtcGxveWVlIFRlcm1pbmF0aW9uIE5vdGlmaWNhdGlvbiIsIkNSTSBFbXBsb3llZXMiLCJTRVFVRUxfVklFV1BPSU5UIiwiQ29uZmlndXJhdGlvbiBNYW5hZ2VyIEFkbWluaXN0cmF0b3JzIiwiQVpfQURNSU4iLCJOdW1hcmFUZWNobmljaWFucyIsIlZQTl9BY2Nlc3MiLCJGcmVlTmFjX0FsZXJ0IiwiQWRtaW4iLCJTQVMiLCJYUmVmQWRtaW4iLCJPZmZpY2UgRW1wbG95ZWVzIiwiTUlTIE5ldyBIaXJlIE5vdGlmaWNhdGlvbiIsIklUIFN0YWZmIiwiUmVjaXBpZW50IE1hbmFnZW1lbnQiLCJLTlgtSFJfTVNTX0FwcHJvdmFsIiwiS05YLUhSX01TU19Ecml2ZXJfVHJhbnNmZXJfQXBwcm92YWwiLCJLTlgtSFJfTVNTX0RyaXZlcl9TZXBhcmF0aW9uX0FwcHJvdmFsIiwiS05YLUhSX01TU19Ecml2ZXJfSGlyZV9BcHByb3ZhbCIsIktOWC1IUl9NU1NfSW5pdGlhdGUiLCJLTlgtSFJfTVNTX0RyaXZlcl9UcmFuc2Zlcl9Jbml0aWF0ZSIsIktOWC1IUl9NU1NfRHJpdmVyX1NlcGFyYXRpb25fSW5pdGlhdGUiLCJLTlgtSFJfTVNTX0RyaXZlcl9IaXJlX0luaXRpYXRlIiwiS05YLUhSX01TU19SZWFkX09ubHkiLCJLTlgtSFJfTVNTX0RyaXZlcl9UcmFuc2Zlcl9SZWFkX09ubHkiLCJLTlgtSFJfTVNTX0RyaXZlcl9TZXBhcmF0aW9uX1JlYWRfT25seSIsIktOWC1IUl9NU1NfRHJpdmVyX0hpcmVfUmVhZF9Pbmx5Il19.rrecw0FEmXivVoPIvMQUOIjLxrsuBQ6vkJ9zcItXaSg",
  },
});
export const getRequestMultiFactorVerificationCode = new Mock(
  {
    data: {
      sid: "VE34a134ee8831a66e28eb497dcc098c22",
      sentTo: "roaring_elephants@swifttrans.com",
      sentType: "email",
      isFallback: false,
      _links: {
        self: {
          href:
            "https://ess-back-end-integration.apps.dev.openshift.swifttrans.com/knight/public/core/api/multi-factor",
        },
      },
    },
  },
  null,
  // Runtime Error
  {
    response: {
      status: 500,
      data: {
        title: "Runtime Error",
        type: "https://httpstatus.es/500",
        status: 500,
        detail: "Some error happened",
      },
    },
  },
);
export const getSubmitMultiFactorVerificationCode = new Mock(
  {
    valid: true,
    _links: {
      self: {
        href: "https://ess-back-end-integration.apps.dev.openshift.swifttrans.com/knight/public/core/api/multi-factor",
      },
    },
  },
  null,
  // Bad code
  {
    title: "Unauthorized",
    type: "https://httpstatus.es/401",
    status: 401,
    detail: "The code '433653' you sent is invalid",
  },
);
