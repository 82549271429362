var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"request-list-table-div"},[_c('error-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.fetchError),expression:"fetchError"}],attrs:{"error":_vm.fetchError}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.isFetchingData,"loading-text":"Loading Requests...","no-data-text":"No Requests","headers":_vm.tableHeaders,"footer-props":_vm.tableFooter,"items":_vm.timeOffRequests,"server-items-length":_vm.totalTimeOffRequests,"search":_vm.search,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tr',{staticClass:"grey lighten-3 table-filter-row"},_vm._l((_vm.tableHeaders),function(header){return _c('th',{key:header.text},[(header.value === 'minDate')?_c('div',{staticClass:"table-filter"},[_c('dateRangeSelector',{attrs:{"field":"requestDateRange","placeholder":"Filter Dates","datesSelected":_vm.requestDateRangeCriteria},on:{"search":_vm.requestDateRangeSelected}})],1):_vm._e()])}),0)]},proxy:true},{key:"item.createTimestamp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.reformatJsonDateFormalString(item.createTimestamp))+" ")]}},{key:"item.statusDescription",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.statusDescription)+" ")]}},{key:"item.reason",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.reason)+" ")]}},{key:"item.totalHours",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalHours)+" ")]}},{key:"item.timeOffTypes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.timeOffTypesList(item))+" ")]}},{key:"item.minDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.reformatDateFormalString(item.minDate))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.gotoView(item.employeeUserId, item.id)}}},[_c('v-icon',{staticClass:"pl-2"},[_vm._v("mdi-calendar-import")]),_vm._v(" View ")],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }