export default function () {
  return {
    setIsFetchingData: false,
    requestSubmitError: {},
    requestSubmitApiError: {},
    requestFetchError: null,
    isSubmitting: false,
    isFetchingData: false,
    // Calendar
    calendarEntries: [],
    calendarSelectedMonth: "",
  };
}
