import Mock from "./mock";

const userInfo = new Mock({
  data: {
    _links: {
      self: { href: "" },
      first: { href: "" },
      last: { href: "" },
      next: { href: "" },
    },
    _embedded: {
      userInfo: {
        employerId: "200",
        employeeId: "   00000",
        userId: "TESTU                                  ",
        firstName: "TEST           ",
        lastName: "EMPLOYEE         ",
        level2: "DRV* ",
        userRoles: {
          isManager: true,
          isSupervisor: false,
          isPayrollAdmin: false,
          isPayroll: false,
          isPayrollAssistant: false,
          isProxy: false,
          isProxyForManager: false,
        },
        permissions: {
          canChangeEmployees: true,
          canAccessQueues: true,
        },
      },
    },
  },
});

export default userInfo;
