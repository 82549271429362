import axios from "axios";
import { getEnv } from "@/utils/util";
import {
  employeeHoursMock,
  employeeScheduleMock,
  employeeSchedulePatchMock,
  postTimeOffRequestMock,
  patchTimeOffRequestApproveMock,
  patchTimeOffRequestEditMock,
  patchTimeOffRequestDenyMock,
  getTimeOffRequestMock,
  userEmployeesMock,
  requestQueueMock,
  fetchTimeOffRequestsMock,
} from "./stubs/timeOffMock";
import { omitBy, isNil, merge as _merge } from "lodash";
import store from "@/store";

const api = axios.create({
  baseURL: getEnv("VUE_APP_TO_API_URL"),
});

api.defaults.headers.common["Accept"] = "application/json";

const useStub = getEnv("VUE_APP_STUB_APIS").toLowerCase() === "true";

export default {
  api,
  _fetchUserEmployeesCancel: null,
  _fetchUserProxyAssignmentsCancel: null,
  _fetchUserEmployeeReportsCancel: null,
  name() {
    return "timeOff";
  },
  fetchEmployeeHours(employeeIdentifier) {
    return useStub
      ? employeeHoursMock.getResponse()
      : api.get(`/users/${employeeIdentifier}/hours`);
  },

  // Employee Schedule
  apiDataToSchedule(apiData) {
    return {
      Monday: apiData?.mondaySchedule ?? 0,
      Tuesday: apiData?.tuesdaySchedule ?? 0,
      Wednesday: apiData?.wednesdaySchedule ?? 0,
      Thursday: apiData?.thursdaySchedule ?? 0,
      Friday: apiData?.fridaySchedule ?? 0,
      Saturday: apiData?.saturdaySchedule ?? 0,
      Sunday: apiData?.sundaySchedule ?? 0,
    };
  },
  scheduleToApiData(schedule) {
    let apiData = {
      mondaySchedule: schedule?.Monday ?? null,
      tuesdaySchedule: schedule?.Tuesday ?? null,
      wednesdaySchedule: schedule?.Wednesday ?? null,
      thursdaySchedule: schedule?.Thursday ?? null,
      fridaySchedule: schedule?.Friday ?? null,
      saturdaySchedule: schedule?.Saturday ?? null,
      sundaySchedule: schedule?.Sunday ?? null,
    };
    return omitBy(apiData, isNil);
  },
  fetchEmployeeSchedule(employeeIdentifier) {
    return useStub
      ? employeeScheduleMock.getResponse()
      : api.get(`/users/${employeeIdentifier}/schedule`);
  },
  patchEmployeeSchedule(employeeIdentifier, schedule) {
    return useStub
      ? employeeSchedulePatchMock.getResponse()
      : api.patch(
          `/users/${employeeIdentifier}/schedule`,
          this.scheduleToApiData(schedule)
        );
  },
  postTimeOffRequest(employeeIdentifier, requestData) {
    return useStub
      ? postTimeOffRequestMock.getResponse()
      : api.post(`/users/${employeeIdentifier}/timeOffRequests`, requestData);
  },
  patchTimeOffRequestApprove(employeeIdentifier, requestId, requestData) {
    return useStub
      ? patchTimeOffRequestApproveMock.getResponse()
      : api.patch(
          `/users/${employeeIdentifier}/timeOffRequests/${requestId}/approve`,
          requestData
        );
  },
  patchTimeOffRequestEdit(employeeIdentifier, requestId, requestData) {
    return useStub
      ? patchTimeOffRequestEditMock.getResponse()
      : api.patch(
          `/users/${employeeIdentifier}/timeOffRequests/${requestId}`,
          requestData
        );
  },
  patchTimeOffRequestDeny(employeeIdentifier, requestId, requestData) {
    return useStub
      ? patchTimeOffRequestDenyMock.getResponse()
      : api.patch(
          `/users/${employeeIdentifier}/timeOffRequests/${requestId}/deny`,
          requestData
        );
  },
  fetchTimeOffRequest(employeeIdentifier, requestId) {
    return useStub
      ? getTimeOffRequestMock.getResponse()
      : api.get(`/users/${employeeIdentifier}/timeOffRequests/${requestId}`);
  },
  patchTimeOffRequestReattemptUpload() {
    return useStub
      ? getTimeOffRequestMock.getResponse()
      : api.post(`/failedTimeOffRequestUploads/reAttempt`);
  },
  fetchTimeOffRequests(employeeIdentifier, params) {
    const defaultParams = {
      includeEntries: 1,
      fields:
        "id,employerId,employeeId,employeeFirstName,employeeLastName," +
        "reason,status,statusDescription,employeeUserId,totalHours,minDate,maxDate," +
        "createTimestamp",
      sort: "createTimestamp:desc",
    };

    return useStub
      ? fetchTimeOffRequestsMock.getResponse()
      : api.get(`/users/${employeeIdentifier}/timeOffRequests`, {
          params: _merge({}, defaultParams, params),
        });
  },
  fetchUserEmployees(params) {
    // allow api call to be cancelled
    if (this._fetchUserEmployeesCancel?.cancel) {
      this._fetchUserEmployeesCancel?.cancel("Cancelled");
    }
    this._fetchUserEmployeesCancel = axios.CancelToken.source();

    return useStub
      ? userEmployeesMock.getResponse()
      : api.get(`/userEmployees`, {
          cancelToken: this._fetchUserEmployeesCancel.token,
          params: _merge(
            {
              limit: store.getters["timeOff/config"](
                "default_datatable_items_per_page"
              ),
              sort: "completeName",
              fields: "employeeId,lastName,firstName,level2,positionTitle",
            },
            params
          ),
        });
  },
  fetchUserProxyAssignments(params) {
    // allow api call to be cancelled
    if (this._fetchUserProxyAssignmentsCancel?.cancel) {
      this._fetchUserProxyAssignmentsCancel?.cancel("Cancelled");
    }
    this._fetchUserProxyAssignmentsCancel = axios.CancelToken.source();

    return useStub
      ? userEmployeesMock.getResponse()
      : api.get(`/employeeProxyAssignments`, {
          cancelToken: this._fetchUserProxyAssignmentsCancel.token,
          params: _merge(
            {
              limit: store.getters["timeOff/config"](
                "default_datatable_items_per_page"
              ),
              sort: "completeName",
              fields: "employeeId,lastName,firstName,level2,positionTitle",
            },
            params
          ),
        });
  },
  fetchUserEmployeeReports(employeeIdentifier, params) {
    // allow api call to be cancelled
    if (this._fetchUserEmployeeReportsCancel?.cancel) {
      this._fetchUserEmployeeReportsCancel?.cancel("Cancelled");
    }
    this._fetchUserEmployeeReportsCancel = axios.CancelToken.source();

    return useStub
      ? userEmployeesMock.getResponse()
      : api.get(`/users/${employeeIdentifier.trim()}/employeeReports`, {
          cancelToken: this._fetchUserEmployeeReportsCancel.token,
          params: _merge(
            {
              limit: store.getters["timeOff/config"](
                "default_datatable_items_per_page"
              ),
              sort: "completeName",
              fields: "employeeId,lastName,firstName,level2,positionTitle",
            },
            params
          ),
        });
  },
  fetchRequestQueue(params) {
    return useStub
      ? requestQueueMock.getResponse()
      : api.get(`/requestQueues/${params.statusCode}`, {
          params: params.queryParams,
        });
  },
  fetchCalendarTimeOffRequests(params) {
    const defaultParams = {
      limit: -1, // load all without paging
    };

    return useStub
      ? fetchTimeOffRequestsMock.getResponse()
      : api.get(`/manager/timeOffRequests`, {
          params: _merge({}, defaultParams, params),
        });
  },
  fetchDashboardCalendarRequests(employeeIdentifier, params) {
    const defaultParams = {
      includeEntries: 1,
      limit: -1, // load all without paging
    };

    return useStub
      ? fetchTimeOffRequestsMock.getResponse()
      : api.get(`/users/${employeeIdentifier}/timeOffRequests`, {
          params: _merge({}, defaultParams, params),
        });
  },
  fetchManagerListTimeOffRequests(params) {
    const defaultParams = {
      includeEntries: 0,
      fields:
        "id,employerId,employeeId,employeeFirstName,employeeLastName," +
        "managerFirstName,managerLastName,managerEmployerId,managerEmployeeId," +
        "reason,status,statusDescription,employeeUserId,totalHours,minDate,maxDate",
      sort: "id:desc",
    };

    return useStub
      ? fetchTimeOffRequestsMock.getResponse()
      : api.get(`/manager/timeOffRequests`, {
          params: _merge({}, defaultParams, params),
        });
  },
  fetchTimeOffRequestsDownload(params) {
    return api.get("/manager/timeOffRequestsDownload", {
      params: params,
      responseType: "blob",
    });
  },
  fetchUserSettings() {
    return api.get("/userSettings");
  },
  getAllTimeZones() {
    return api.get("/timezones");
  },
  patchUserSettings(data) {
    return api.patch(`/userSettings`, data);
  },
  // Admin User Management APIs
  payrollAdmins(params) {
    return api.get(`/payrollAdmins`, {
      params: params,
    });
  },
  payrollAdminsCreate(params) {
    return api.post(`/payrollAdmins`, params);
  },
  payrollAdminsDelete(employeeIdentifier) {
    return api.delete(`/payrollAdmins/${employeeIdentifier}`);
  },
  payrollAdminsUpdate(employeeIdentifier, payload) {
    return api.patch(`/payrollAdmins/${employeeIdentifier}`, payload);
  },
  payrollAssistants(params) {
    return api.get(`/payrollAssistants`, {
      params: params,
    });
  },
  payrollAssistantsCreate(params) {
    return api.post(`/payrollAssistants`, params);
  },
  payrollAssistantsDelete(employeeId) {
    return api.delete(`/payrollAssistants/${employeeId}`);
  },
  payrollAssistantsUpdate(employeeId, payload) {
    return api.patch(`/payrollAssistants/${employeeId}`, payload);
  },
  addCompanyHoliday(payload) {
    return api.post(`/companyHolidays`, payload);
  },
  deleteCompanyHoliday(date) {
    return api.delete(`/companyHolidays/${date}`);
  },
  supervisorProxies(params) {
    return api.get(`/supervisorProxies`, {
      params: params,
    });
  },
  employeeProxies(params) {
    return api.get(`/employeeProxies`, { params: params });
  },
  employeeProxiesCreate(payload) {
    return api.post(`/employeeProxies`, payload);
  },
  employeeProxiesDelete(payload) {
    return api.delete(`/employeeProxies`, { data: payload });
  },
  employeeProxiesUpdate(payload) {
    return api.patch(`/employeeProxies`, payload);
  },
  fetchScheduleChanges(params) {
    return api.get(`/auditScheduleChanges`, {
      params: params,
    });
  },
  fetchScheduleChangesDownload(params) {
    return api.get("/auditScheduleChangesDownload", {
      params: params,
      responseType: "blob",
    });
  },
};
