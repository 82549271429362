import axios from "axios";
import { getEnv } from "@/utils/util";

const api = axios.create({
  baseURL: getEnv("VUE_APP_ESS_API_URL"),
});

api.defaults.headers.common.Accept = "application/json";

export default {
  api,
  name() {
    return "personalInfo";
  },
  getMaritalCodes() {
    return api.get("/core/infinium/marital-status-codes");
  },
  getInfiniumRelationships() {
    return api.get("core/infinium/relationship-codes");
  },
  getCounties() {
    return api.get("/counties", { params: { limit: 4000 } });
  },
  fetchPersonalInformation() {
    return api.get("/ess/personal-info");
  },
  putPersonalInformation(payload) {
    return api.put("/ess/personal-info", payload);
  },
  getDegreeCodes() {
    return api.get("/core/infinium/degree-codes");
  },
  getEducationCodes() {
    return api.get("core/infinium/education-codes");
  },
  getEducations() {
    return api.get("/ess/education");
  },
  updateEducations(payload) {
    return api.put("/ess/education", payload);
  },
  getEmergencyContacts(employeeId) {
    return api.get(`/ess/emergency-contacts?employeeId=${employeeId}`);
  },
  updateEmergencyContacts(payload) {
    return api.put("/ess/emergency-contacts", payload);
  },
};
