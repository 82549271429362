<template>
  <div class="pto-request-dates-picker">
    <DatePicker
      :attributes="attributes"
      :select-attribute="selectAttribute"
      :disabled-dates="disabledDates"
      :available-dates="availableDates"
      :requestedDates="requestedDates"
      @dayclick="onDayClick"
      @update:to-page="changeCalendarMonth"
      value="null"
      ref="datePicker"
    >
      <template v-slot:day-popover="{ attributes }">
        <div v-for="{ key, popover, customData } in attributes" :key="key">
          <div v-if="customData.title" class="vc-day-popover-header">
            {{ customData.title }}
          </div>
          <div class="popoverLabel">{{ popover.label }}</div>
          <div
            id="notScheduledSlot"
            v-if="customData.type === 'notScheduled' && !isModal"
          >
            <div class="notScheduledContent">
              Would you like to edit your schedule now?
            </div>
            <hr />
            <div class="notScheduledButtons text-right">
              <v-btn
                small
                class="cursor-pointer"
                outlined
                color="green"
                @click="editEmployeeSchedule(customData.day)"
                >Yes</v-btn
              >
              <v-btn small outlined>No</v-btn>
            </div>
          </div>
          <div
            id="companyHolidaySlot"
            v-if="customData.type === 'companyHoliday'"
          >
            <div class="companyHolidayContent">
              Continue?
              <v-btn
                class="companyHolidayButton"
                small
                outlined
                color="green"
                @click="ignoreHoliday(customData.day)"
                >Yes</v-btn
              >
              <v-btn
                class="companyHolidayButton"
                small
                outlined
                @click="closePopovers(customData.day)"
                >No</v-btn
              >
            </div>
          </div>
        </div>
      </template>
    </DatePicker>
  </div>
</template>

<script>
// uses v-calendar for date-picker: https://vcalendar.io/api/v2.0/
import { setupCalendar, DatePicker } from "v-calendar";
import { Component, Mixins, Watch, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import rulesMixin from "@/modules/timeOff/rulesMixin";
import dates from "@/utils/dates";
import { isEmpty } from "lodash";
import timeOffMixin from "@/modules/timeOff/timeOffMixin";

const requestFormStore = namespace("timeOff/requestForm");
const employeeScheduleStore = namespace("timeOff/employeeSchedule");

setupCalendar({
  firstDayOfWeek: 1, // Sunday
});

@Component({
  name: "RequestDatePicker",
  components: { DatePicker },
})
export default class RequestDatesPicker extends Mixins(
  rulesMixin,
  timeOffMixin
) {
  @Prop({
    type: Boolean,
    default: false,
  })
  isModal;

  @Prop({
    type: Object,
    default: function () {
      return {};
    },
  })
  startDate;

  @Prop({
    type: Array,
    default: [],
  })
  requestedDates;

  name = "requestVCalendarDatesPicker.vue";
  selectAttribute = {
    // no need to highlight the currently selected day
    highlight: {
      style: {
        display: "none",
      },
    },
  };
  calendarYear = null;
  calendarMonth = null;
  availableDateList = [];
  popovers = [];
  popoverTimeout = null;
  holidays = [];
  availableDatesHolidayInfo = [];
  requestId = null;
  selectedDates = [];

  @requestFormStore.Getter("selectedCategory") selectedCategory;
  @requestFormStore.Action("toggleRequestEntry") toggleRequestEntry;
  @requestFormStore.Getter("requestEntries") requestEntries;
  @requestFormStore.Getter("getCurrentRequest") getCurrentRequest;

  @employeeScheduleStore.Getter("employeeSchedule") employeeSchedule;

  get attributes() {
    return [
      ...this.highlightToday(),
      ...this.highlightHolidays(),
      ...this.highlightSelected(),
      ...this.popovers,
    ];
  }

  get availableDates() {
    if (this.selectedCategory === null) {
      // if no category, no dates available
      return [];
    }

    return this.availableDateList;
  }

  get disabledDates() {
    if (this.selectedCategory === null) {
      // if no category, need to explicitly disable all dates
      return { weekdays: [1, 2, 3, 4, 5, 6, 7] };
    }
    // if category, no dates disabled - availableDates will take over
    return { start: null, end: null };
  }

  highlightToday() {
    return [
      {
        key: "today",
        order: 5,
        highlight: {
          color: "blue",
          fillMode: "outline",
        },
        dates: new Date(),
      },
    ];
  }

  highlightHolidays() {
    let highlights = [];

    this.holidays.forEach((holiday) => {
      highlights.push({
        key: `${holiday}-holiday`,
        order: 10,
        highlight: {
          contentStyle: {
            color: "var(--white)",
          },
          style: {
            backgroundColor: this.timeOffCategoryBgColor("companyHolidays"),
          },
          fillMode: "solid",
        },
        dates: dates.dateFromString(holiday),
      });
    });

    return highlights;
  }

  highlightSelected() {
    this.setSelectedDates();

    let highlights = [];

    for (const requestedDate of this.selectedDates) {
      Object.keys(requestedDate).forEach((date) => {
        if (requestedDate[date].perc === 1) {
          highlights.push(
            this.fullDateCodeHighlight(date, requestedDate[date])
          );
        } else {
          let filteredEntries = this.selectedDates.filter(
            (entry) => date in entry
          );

          let entry1 = filteredEntries[0][date];

          if (requestedDate[date].isSplit) {
            let entry2 = filteredEntries[1][date];

            highlights.push(
              ...this.splitDateCodeHighlight(date, [entry1, entry2])
            );
          } else {
            if (filteredEntries.length === 1) {
              highlights.push(this.partDateCodeHighlight(date, entry1));
            } else {
              let entry2 = filteredEntries[1][date];

              highlights.push(
                ...this.splitDateCodeHighlight(date, [entry1, entry2])
              );
            }
          }
        }
      });
    }

    return highlights;
  }

  setSelectedDates() {
    this.selectedDates = [];

    for (const [dateStr, entryArr] of Object.entries(this.requestEntries)) {
      entryArr.forEach((entry) => {
        let selectedDate = {};

        selectedDate[dateStr] = {
          code: entry.code,
          error: entry.error,
          hours: parseInt(entry.hours),
          isPrev: false,
          isSplit: entryArr.length === this.config("request_day_max_splits"),
          perc: entry.perc,
          requestId: this.getCurrentRequest.id,
        };

        this.selectedDates.push(selectedDate);
      });
    }

    this.requestedDates.forEach((entry) => {
      if (
        parseInt(entry[Object.keys(entry)[0]].requestId) !==
        this.getCurrentRequest.id
      ) {
        this.selectedDates.push(entry);
      }
    });
  }

  fullDateCodeHighlight(dateStr, codeEntry) {
    return {
      key: `${dateStr}-${codeEntry.code}-fullday`,
      order: 10,
      highlight: {
        contentStyle: {
          color: "var(--white)",
        },
        style: {
          backgroundColor: codeEntry.isPrev
            ? "var(--pto-cal-prev-select-color)"
            : `var(--pto-cat-bg-color-${codeEntry.code})`,
        },
        fillMode: "solid",
      },
      dates: dates.dateFromString(dateStr),
    };
  }

  partDateCodeHighlight(dateStr, codeEntry) {
    // width should be 0.15 minimum
    const perc =
      parseFloat(codeEntry.perc) > 0.15 ? parseFloat(codeEntry.perc) : 0.15;

    const deg = 360 * perc;

    const color = codeEntry.isPrev
      ? "var(--pto-cal-prev-select-color)"
      : `var(--pto-cat-bg-color-${codeEntry.code})`;

    return {
      key: `${dateStr}-${codeEntry.code}-partday`,
      order: 10,
      highlight: {
        style: {
          border: "0px",
          width: "28px",
          height: "28px",
          position: "absolute",
          backgroundImage: `conic-gradient(
            ${color} ${deg}deg,
            var(--pto-cal-bg-color) ${deg}deg)`,
        },
        fillMode: "none",
        contentStyle: {
          color: "var(--pto-cal-split-color)",
        },
      },
      dates: dates.dateFromString(dateStr),
    };
  }

  splitDateCodeHighlight(dateStr, dateEntryArray) {
    const perc1 =
      parseFloat(dateEntryArray[0].perc) > 0.15
        ? parseFloat(dateEntryArray[0].perc)
        : 0.15;

    const deg1 = 360 * perc1;

    const color1 = dateEntryArray[0].isPrev
      ? "var(--pto-cal-prev-select-color)"
      : `var(--pto-cat-bg-color-${dateEntryArray[0].code})`;

    const perc2 =
      parseFloat(dateEntryArray[1].perc) > 0.15
        ? parseFloat(dateEntryArray[1].perc)
        : 0.15;

    const deg2 = 360 * perc2 + deg1;

    let color2;

    switch (true) {
      case dateEntryArray[1].isPrev && !dateEntryArray[0].isPrev:
        color2 = "var(--pto-cal-prev-select-color)";
        break;
      case dateEntryArray[1].isPrev && dateEntryArray[0].isPrev:
        color2 = "var(--pto-cal-prev-select-color-2)";
        break;
      default:
        color2 = `var(--pto-cat-bg-color-${dateEntryArray[1].code})`;
    }

    return [
      {
        key: `${dateStr}-${dateEntryArray[0].code}-split`,
        order: 10,
        highlight: {
          style: {
            border: "0px",
            width: "28px",
            height: "28px",
            position: "absolute",
            backgroundImage: `conic-gradient(
            ${color1} 0deg ${deg1}deg,
            ${color2} ${deg1}deg ${deg2}deg,
            var(--pto-cal-bg-color) ${deg2}deg`,
          },
          fillMode: "none",
          contentStyle: {
            color: "var(--pto-cal-split-color)",
          },
        },
        dates: dates.dateFromString(dateStr),
      },
    ];
  }

  closePopovers(day) {
    day.el.focus();
    day.el.blur();
    window.setTimeout(() => {
      this.popovers = [];
      window.clearTimeout(this.popoverTimeout);
      this.popoverTimeout = null;
    }, 20);
  }

  popoverMessage(day, message, customData) {
    customData = customData || {};

    if (this.popoverTimeout) {
      window.clearTimeout(this.popoverTimeout);
      this.popoverTimeout = null;
    }
    this.popovers = [
      {
        dates: day.date,
        popover: {
          label: message,
          visibility: "focus",
          isInteractive: true, // Defaults to true when using slot
        },
        customData: customData,
      },
    ];

    window.setTimeout(() => {
      day.el.blur();
      day.el.focus();
    }, 20);

    this.popoverTimeout = window.setTimeout(() => {
      this.closePopovers(day);
    }, 5000);
  }

  ignoreHoliday(day) {
    this.closePopovers(day);
    this.availableDatesHolidayInfo[
      dates.dateToString(day.date)
    ].shouldIgnore = true;
    this.onDayClick(day);
    this.setHolidayInfo();
  }

  onDayClick(day) {
    if (!this.selectedCategory) {
      this.popoverMessage(day, "Choose a category first");
      return;
    }

    if (this.isModal) {
      this.requestId = this.getCurrentRequest.id;
    }

    // check that date is available
    let requestDateValid = this.requestDateRule(
      day.date,
      this.selectedCategory.code,
      this.requestedDates,
      this.requestId
    );
    if (requestDateValid !== true) {
      this.popoverMessage(day, requestDateValid);
      return;
    }

    let formattedDay = dates.dateToString(day.date);

    if (
      this.availableDatesHolidayInfo[formattedDay].isHoliday &&
      !this.availableDatesHolidayInfo[formattedDay].shouldIgnore
    ) {
      let customData = {
        title: "Company Holiday Alert",
        type: "companyHoliday",
        day: day,
      };
      this.popoverMessage(day, "", customData);
      return;
    }

    const dateStr = dates.dateString(day.date);

    try {
      const change = {
        dateStr: dateStr,
        code: this.selectedCategory.code,
      };

      this.toggleRequestEntry({
        dateEntry: change,
        selectedDates: this.selectedDates,
      });
      const categoryRules = this.requestCategoryRules(
        this.selectedCategory.code,
        day.date
      );
      if (categoryRules !== true) {
        this.toggleRequestEntry({
          dateEntry: change,
          selectedDates: this.selectedDates,
        });
        this.popoverMessage(day, categoryRules);
        return;
      }

      // give a few cycles for changes to propagate and trigger a change event
      setTimeout(() => {
        this.$emit("datesChanged", change);
      }, 50);
    } catch (e) {
      this.popoverMessage(day, e);
    }
  }

  @Watch("requestEntries")
  setHolidayInfo() {
    this.availableDateList.forEach((date) => {
      let formattedDate = dates.dateToString(date);

      this.availableDatesHolidayInfo[formattedDate] = {
        isHoliday: this.holidays.includes(formattedDate),
        shouldIgnore: this.requestEntries[dates.dateString(date)] !== undefined,
      };
    });
  }

  @Watch("employeeSchedule")
  setAvailableDates() {
    this.availableDateList = this.getAvailableDatesForMonthYear(
      this.calendarYear,
      this.calendarMonth
    );

    this.setHolidayInfo();
  }

  mounted() {
    this.holidays = this.config("companyHolidays") ?? [];

    if (isEmpty(this.startDate)) {
      this.setAvailableDates();
    } else {
      const datePicker = this.$refs.datePicker;
      datePicker.move(this.startDate);
      this.changeCalendarMonth(this.startDate);
    }
  }

  changeCalendarMonth(monthYearObj) {
    this.calendarMonth = monthYearObj.month;
    this.calendarYear = monthYearObj.year;
    this.setAvailableDates();
  }

  editEmployeeSchedule(day) {
    this.closePopovers(day);

    this.$emit("editEmployeeSchedule");
  }
}
</script>

<style>
.pto-request-dates-picker .vc-container {
  width: 100%;
  height: 100%;
}
.pto-request-dates-picker .vc-day-content.is-disabled[role="button"] {
  cursor: default;
}
#notScheduledSlot {
}
#notScheduledSlot .notScheduledContent {
  line-height: 1.1em;
  margin-top: 4px;
}
#notScheduledSlot hr {
  margin-top: 3px;
  margin-bottom: 3px;
}
#notScheduledSlot .notScheduledButtons button {
  margin-left: 10px;
}
#companyHolidaySlot .companyHolidayContent {
  margin-top: 4px;
}
#companyHolidaySlot .companyHolidayButton {
  margin-left: 4px;
}
</style>
