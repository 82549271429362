export default {
  computed: {
    cardHeaderColor() {
      return this.otherEmployeeSelected ?? false
        ? "orange accent-2"
        : "primary";
    },

    cardHeaderClass() {
      return `headline white--text ${this.cardHeaderColor} font-weight-light`;
    },
  },
};
