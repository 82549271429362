import Vue from "vue";
import personalInfo from "./personalInfo";

export default {
  getApi() {
    return personalInfo;
  },
  registerApi() {
    Vue.$apis.addApi(personalInfo);
  },
};
