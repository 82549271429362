<template>
  <div class="request-list-table-div">
    <error-alert :error="fetchError" v-show="fetchError"></error-alert>
    <v-data-table
      class="elevation-1"
      :loading="isFetchingData"
      loading-text="Loading Requests..."
      no-data-text="No Requests"
      :headers="tableHeaders"
      :footer-props="tableFooter"
      :items="timeOffRequests"
      :server-items-length="totalTimeOffRequests"
      :search="search"
      :options.sync="options"
    >
      <template v-slot:header>
        <tr class="grey lighten-3 table-filter-row">
          <th v-for="header in tableHeaders" :key="header.text">
            <div v-if="header.value === 'minDate'" class="table-filter">
              <dateRangeSelector
                field="requestDateRange"
                placeholder="Filter Dates"
                :datesSelected="requestDateRangeCriteria"
                v-on:search="requestDateRangeSelected"
              ></dateRangeSelector>
            </div>
          </th>
        </tr>
      </template>
      <template v-slot:[`item.createTimestamp`]="{ item }">
        {{ reformatJsonDateFormalString(item.createTimestamp) }}
      </template>
      <template v-slot:[`item.statusDescription`]="{ item }">
        {{ item.statusDescription }}
      </template>
      <template v-slot:[`item.reason`]="{ item }">
        {{ item.reason }}
      </template>
      <template v-slot:[`item.totalHours`]="{ item }">
        {{ item.totalHours }}
      </template>
      <template v-slot:[`item.timeOffTypes`]="{ item }">
        {{ timeOffTypesList(item) }}
      </template>
      <template v-slot:[`item.minDate`]="{ item }">
        {{ reformatDateFormalString(item.minDate) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn text @click="gotoView(item.employeeUserId, item.id)">
          <v-icon class="pl-2">mdi-calendar-import</v-icon>
          View
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { Component, Mixins, Watch } from "vue-property-decorator";
import rulesMixin from "@/modules/timeOff/rulesMixin";
import timeOffMixin from "@/modules/timeOff/timeOffMixin";
import { namespace } from "vuex-class";
import { formatEmployeeName } from "@/utils/util";
import dates from "@/utils/dates";
import dateRangeSelector from "@/modules/timeOff/components/fields/dateRangeSelector";
import ErrorAlert from "@/components/errorAlert";

const requestsEmployeeStore = namespace("timeOff/requestsEmployee");

@Component({
  name: "requestsList",
  components: {
    ErrorAlert,
    dateRangeSelector,
  },
})
export default class RequestsCalendar extends Mixins(rulesMixin, timeOffMixin) {
  @requestsEmployeeStore.Action("fetchEmployeeListTimeOffRequests")
  fetchEmployeeListTimeOffRequests;
  @requestsEmployeeStore.Action("resetListViewOptions") resetListViewOptions;
  @requestsEmployeeStore.Getter("getEmployeeIdentifier") getEmployeeIdentifier;
  @requestsEmployeeStore.Getter("getTimeOffRequests") timeOffRequests;
  @requestsEmployeeStore.Getter("getTotalTimeOffRequests") totalTimeOffRequests;
  @requestsEmployeeStore.Getter("getRequestDateRangeCriteria")
  requestDateRangeCriteria;
  @requestsEmployeeStore.Getter("isFetchingData") isFetchingData;
  @requestsEmployeeStore.Getter("fetchError") fetchError;
  @requestsEmployeeStore.Getter("listViewOptions") listViewOptions;
  @requestsEmployeeStore.Mutation("setTimeOffRequests") setTimeOffRequests;
  @requestsEmployeeStore.Mutation("setTotalTimeOffRequests")
  setTotalTimeOffRequests;
  @requestsEmployeeStore.Mutation("setRequestDateRangeCriteria")
  setRequestDateRangeCriteria;
  @requestsEmployeeStore.Mutation("setListViewOptions") setListViewOptions;

  data() {
    return {
      formatEmployeeName,
      tableHeaders: [
        {
          text: "Date Requested",
          align: "center",
          value: "createTimestamp",
          sortable: true,
        },
        {
          text: "Status",
          align: "center",
          value: "statusDescription",
          sortable: true,
        },
        {
          text: "Hours",
          align: "center",
          value: "totalHours",
          sortable: true,
        },
        {
          text: "Reason",
          align: "center",
          value: "reason",
          sortable: false,
        },
        {
          text: "Time Off Type(s)",
          align: "center",
          value: "timeOffTypes",
          sortable: false,
        },
        {
          text: "First Day",
          align: "center",
          value: "minDate",
          sortable: true,
        },
        {
          text: "Actions",
          align: "center",
          value: "actions",
          width: "1px",
          sortable: false,
        },
      ],
      tableFooter: {
        "items-per-page-options": [10, 25],
        "show-current-page": true,
        "show-first-last-page": true,
      },
      search: "",
      sort: "",
      statusCriteriaString: "",
    };
  }

  @Watch("options", { deep: true })
  onOptionsChange() {
    if (Object.keys(this.options).length !== 0) {
      //set the format for the endpoint like: lastName:desc
      if (this.options.sortBy[0]) {
        this.sort = this.options.sortBy[0];
        if (this.options.sortDesc[0]) this.sort = this.sort + ":desc";
      } else {
        this.sort = "createTimestamp:desc";
      }
    }

    this.clearRequests();
    this.loadTimeOffRequests();
  }

  @Watch("requestDateRangeCriteria")
  requestDateRangeCriteriaChanged() {
    this.loadTimeOffRequests();
  }

  get options() {
    return this.listViewOptions;
  }
  set options(data) {
    this.setListViewOptions(data);
  }

  reformatJsonDateFormalString(dateJson) {
    return dates.dateFormalString(dates.dateFromMezzioDateJson(dateJson));
  }

  reformatDateFormalString(dateStr) {
    return dates.dateFormalString(dateStr);
  }

  timeOffTypesList(itemObj) {
    if (!Array.isArray(itemObj?.entries)) {
      return "";
    }

    let typesList = [];
    itemObj.entries.forEach(function (entry) {
      if (!typesList.includes(entry.description)) {
        typesList.push(entry.description);
      }
    });
    return typesList.join(", ");
  }

  requestDateRangeSelected(dateRangeObj) {
    // reset page
    this.options.page = 1;
    this.setRequestDateRangeCriteria(dateRangeObj.value.split("||"));
  }

  clearRequests() {
    this.setTimeOffRequests([]);
    this.setTotalTimeOffRequests(0);
  }

  loadTimeOffRequestsParams() {
    const params = {
      sort: this.sort,
    };

    if (
      this.requestDateRangeCriteria?.length &&
      this.requestDateRangeCriteria.length > 0
    ) {
      params.startDate = this.requestDateRangeCriteria[0];
      params.endDate =
        this.requestDateRangeCriteria.length > 1
          ? this.requestDateRangeCriteria[1]
          : this.requestDateRangeCriteria[0];
    }

    return params;
  }
  async loadTimeOffRequests() {
    let params = this.loadTimeOffRequestsParams();
    params.limit = this.options.itemsPerPage;
    params.page = this.options.page;

    await this.fetchEmployeeListTimeOffRequests({
      employeeIdentifier: this.getEmployeeIdentifier,
      params: params,
    });
  }

  destroyed() {
    this.resetListViewOptions();
  }
}
</script>
