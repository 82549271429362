export default function () {
  return {
    /**
     * Request states:
     */
    selectedCategory: null,
    reasonForRequest: "",
    reviewComments: "",
    /**
     * requestEntries: entries grouped by date, with an additional property `perc`
     * object: {<dateStr>: [{code: <categoryStr>, perc: <percentageOfDayFloat>, hours: <hoursFloat>}]}
     * */
    requestEntries: {},
    currentRequest: {},

    /**
     * Submit states:
     */
    requestSubmitError: {},
    requestFetchError: null,

    /**
     * Form states:
     */
    isSubmitting: false,
    isFormValid: false,
    isApprovalFormValid: false,
    formErrors: "", // no getter
    isCommentRequired: false,
    successMessages: {
      submit: {
        title: "New Time Off Request",
        message: "Your new time off request has been submitted.",
      },
      approve: {
        title: "Approve Time Off Request",
        message: "You have approved this time off request.",
      },
      edit: {
        title: "Edit Time Off Request",
        message: "You have edited this time off request.",
      },
      deny: {
        title: "Deny Time Off Request",
        message: "You have denied this time off request.",
      },
    },
  };
}
