import axios from "axios";
import { getEnv } from "@/utils/util";
import { directDeposits, saveDirectDeposits } from "./stubs/directDeposit";

const api = axios.create({
  baseURL: `${getEnv("VUE_APP_ESS_API_URL")}/payroll`,
});

api.defaults.headers.common.Accept = "application/json";

export default {
  api,
  name() {
    return "directDepositAPI";
  },
  getDirectDeposits(employeeId) {
    return getEnv("VUE_APP_STUB_APIS").toLowerCase() === "true"
      ? directDeposits.getResponse()
      : api.get(`/direct-deposits?employeeId=${employeeId}`);
  },
  saveDirectDeposits(payload) {
    return getEnv("VUE_APP_STUB_APIS").toLowerCase() === "true"
      ? saveDirectDeposits.getResponse()
      : api.put("/direct-deposits", payload);
  },
};
