import { sanitizeStrings } from "@/utils/util";
import personalInfoApi from "../../api/personalInfo";

export default {
  async fetchEducations({ commit }) {
    commit("setIsLoading", true);
    commit("setError", null);

    try {
      const degreeCodes = await personalInfoApi.getDegreeCodes();
      const educationCodes = await personalInfoApi.getEducationCodes();

      const { data } = await personalInfoApi.getEducations();

      sanitizeStrings(data.education);

      commit("setDegreeCodes", degreeCodes.data);
      commit("mergeEducationCodes", educationCodes.data);

      commit("setInitialEducations", data.education ?? []);
      commit("createWorkingEducations");

      commit("setIsLoading", false);
    } catch (e) {
      commit("setError", e);
      commit("setIsLoading", false);
    }
  },
  async saveEducations({ commit, getters }) {
    commit("setSavingError", null);
    commit("setIsSaving", true);
    try {
      const { data } = await personalInfoApi.updateEducations(getters.payload);
      sanitizeStrings(data.education);
      commit("setInitialEducations", data.education ?? []);
      commit("createWorkingEducations");
      commit("setIsSaving", false);
    } catch (e) {
      commit("setSavingError", e);
      commit("setIsSaving", false);
    }
  },
  discardChanges({ commit }) {
    commit("createWorkingEducations");
  },
};
