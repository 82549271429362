<template>
  <div>
    <v-autocomplete
      :item-value="itemValue"
      :item-text="itemText"
      :items="items"
      :label="label"
      :rules="rules"
      :name="name"
      v-on:input="(event) => this.$emit('inputChange', event)"
      :loading="loading"
      :no-data-text="loading ? 'Loading...' : 'No Options'"
      v-model="selectModel"
      return-object
      dense
      filled
      clearable
      open-on-clear
    ></v-autocomplete>
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
// import { MSG_REQUIRED } from "@/utils/validationRules";

/**
 * Usage Example
 *  <DropdownBox
 *    :item-value="value.id"
 *    :items="items"
 *    :label="label"
 *    :name="name"
 *    :modelProperty="selectedValue"
 *    :loading="loading"
 *    v-on:inputChange="setValue"
 *  ></DropdownBox>
 *
 *  In the script
 *  items = [
 *    { id: "1", text: "test" },
 *    { id: "2", text: "selection 2" },
 *  ];
 *  selectedValue = { id: "", text: "" };
 *  label = "Select an option";
 *  name = "select";
 *  value = "";
 *  loading = false;
 *
 *  setValue(event) {
 *    this.value = event;
 *  }
 */

@Component({})
export default class DropdownBox extends Vue {
  selectModel = "";

  @Prop({
    type: Array,
    default: [],
  })
  items;

  @Prop({
    type: String,
    default: "",
  })
  itemValue;

  @Prop({
    type: String,
    default: "",
  })
  itemText;

  @Prop({
    type: String,
    default: "Select an option",
  })
  label;

  @Prop({
    type: String,
    default: "select",
  })
  name;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  loading;

  @Prop({
    required: true,
    default: {},
  })
  modelProperty;

  @Prop({
    required: false,
    default: () => [],
  })
  rules;

  created() {
    this.selectModel = this.modelProperty;
  }
}
</script>
