var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"request-queue-table-div"},[(_vm.statusCode !== _vm.statusCodes.incompleteAS400Upload)?_c('v-text-field',{staticClass:"request-queue-search-field",attrs:{"outlined":"","label":"Search by Employee First Name, Last Name, or ID","append-icon":"mdi-magnify","clearable":""},on:{"click:clear":_vm.resetSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.isLoading,"loading-text":"Loading Requests...","no-data-text":"No Requests","headers":_vm.tableHeaders(),"footer-props":_vm.tableFooter,"items":_vm.requests,"server-items-length":_vm.totalRequests,"search":_vm.search,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([(_vm.isByStatusQueue())?{key:"header",fn:function(){return [_c('tr',{staticClass:"grey lighten-3 table-filter-row"},_vm._l((_vm.tableHeaders()),function(header){return _c('th',{key:("" + (header.text))},[_c('div',[(header.text === 'Status')?_c('v-autocomplete',{staticClass:"table-filter",attrs:{"item-value":"status","item-text":"description","items":_vm.prepareTimeOffStatuses(),"value":_vm.statusCriteriaString,"placeholder":"Filter Status","return-object":"","dense":"","flat":"","clearable":"","open-on-clear":""},on:{"input":_vm.statusFilterSelected}}):_vm._e()],1)])}),0)]},proxy:true}:null,{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.employeeLastName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatEmployeeName( item.employeeFirstName, item.employeeLastName, item.employerId, item.employeeId ))+" ")]}},{key:"item.managerLastName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatEmployeeName( item.managerFirstName, item.managerLastName, item.managerEmployerId, item.managerEmployeeId ))+" ")]}},{key:"item.statusName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.statusDescription)+" ")]}},{key:"item.totalHours",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalHours !== null ? item.totalHours : "0.00")+" ")]}},{key:"item.reason",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.reason)+" ")]}},{key:"item.requestTypes",fn:function(ref){
var item = ref.item;
return _vm._l((item.requestTypes),function(requestType,key){return _c('v-chip',{key:key,staticClass:"ma-1",attrs:{"color":_vm.timeOffCategoryBgColor(requestType.code),"label":"","text-color":"white","small":""}},[_vm._v(" "+_vm._s(requestType.description)+" ")])})}},{key:"item.minDate",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{
          highlighted_date: _vm.highlightDate(item.minDate),
        }},[_vm._v(" "+_vm._s(item.minDate !== null ? _vm.reFormatISODate(item.minDate) : "-")+" ")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.gotoReview(item.employeeUserId, item.id)}}},[_vm._v(" Review "),_c('v-icon',{staticClass:"pl-2"},[_vm._v("mdi-calendar-clock-outline")])],1)]}},{key:"item.createTimestamp",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateWithSeconds(item.createTimestamp))+" ")]}}],null,true)}),(this.statusCode === this.statusCodes.incompleteAS400Upload)?_c('reprocessUploads'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }