<template>
  <v-dialog error v-model="model" width="50%" persistent>
    <v-sheet>
      <v-card>
        <v-card-title>
          <v-icon class="mr-3" color="error"> mdi mdi-alert-circle </v-icon>
          Error: {{ errorTitle }}
        </v-card-title>
        <v-card-text>
          {{ errorDetails }}
          <div class="errors" v-if="errors.length > 0">
            <ul>
              <li v-for="(error, i) in errors" :key="i">
                {{ error }}
              </li>
            </ul>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text color="error" @click="closeDialog"> Close </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-sheet>
  </v-dialog>
</template>

<script>
import errorsMixin from "@/utils/errorsMixin";

export default {
  mixins: [errorsMixin],
  name: "errorDialog",
  props: {
    error: { type: Object, required: false, default: () => {} },
    model: { type: Boolean, required: true, default: false },
  },
};
</script>

<style scoped></style>
