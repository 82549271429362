export default () => ({
  isLoading: false,
  isSubmitting: false,
  error: null,
  submitError: {},
  savingInfo: {},
  maritalCodes: [],
  personalInfo: null, // data from api endpoint
  sameAddress: false, // Same as Resident Address toggle
  wantsMailingAddress: null, // Add Mailing Address toggle
});
