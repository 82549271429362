<template>
  <v-data-table
    :headers="headers"
    :items="requests"
    :items-per-page="5"
    class="elevation-1"
  >
    <template v-slot:[`item.actions`]="{}">
      <v-icon small class="mr-2">mdi-pencil</v-icon>
      <v-icon small>mdi-delete</v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";

@Component
export default class RequestsTable extends Vue {
  // Properties
  disabled = false;
  defaultState = false;

  data() {
    return {
      headers: [
        {
          text: "Date",
          align: "start",
          value: "dateRequested",
        },
        { text: "Category", value: "category" },
        { text: "Hours", value: "hours" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  }

  created() {
    this.requests = [
      {
        dateRequested: "Mon 12/17/00",
        category: "Sick",
        hours: 8,
      },
      {
        dateRequested: "Tue 12/18/00",
        category: "PTO",
        hours: 8,
      },
    ];
  }
}
</script>
