<template>
  <v-container class="my-requests">
    <component :is="employeeRequests" />
  </v-container>
</template>

<script>
import Component from "vue-class-component";
import { Mixins } from "vue-property-decorator";
import modulePageMixin from "@/utils/mixins";
import { namespace } from "vuex-class";
import { authorizationMixin } from "@/modules/timeOff/authorizationMixin";

const requestsEmployeeStore = namespace("timeOff/requestsEmployee");

@Component({
  name: "myRequests",
  components: {},
})
export default class MyRequests extends Mixins(
  modulePageMixin,
  authorizationMixin
) {
  isLoading = false;

  @requestsEmployeeStore.Mutation("setEmployeeIdentifier")
  setEmployeeIdentifier;

  get employeeRequests() {
    return this.loadComponent("employeeRequests", "timeOff/requests");
  }

  mounted() {
    this.setEmployeeIdentifier(this.userId);
  }
}
</script>

<style scoped></style>
