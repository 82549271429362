import { merge as _merge } from "lodash";
import defaultConfig from "../config/config";

const getCompanyConfig = (companyName) => {
  const path = `../config/${companyName}/config`;

  let companyConfig;

  try {
    // load the company config
    // eslint-disable-next-line global-require,import/no-dynamic-require
    const configRequire = require(`@/modules/${path}`);
    companyConfig = configRequire.default ?? {};
  } catch (e) {
    // default to empty
    companyConfig = {};
  }

  return companyConfig;
};

export default {
  setStates(state, states) {
    state.states = states.map((c) => ({
      ...c,
      code: c.code.trim(),
      description: c.description.trim(),
    }));
  },
  setConfig(state, { moduleConfig, companyName }) {
    const companyConfig = getCompanyConfig(companyName);
    state.config = _merge({}, defaultConfig, companyConfig, moduleConfig);
  },
};
