import state from "@/store/websocket/state";
import mutations from "@/store/websocket/mutations";
import actions from "@/store/websocket/actions";
import getters from "@/store/websocket/getters";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
