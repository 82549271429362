export default () => ({
  company: {
    company: "",
    companyTitle: "",
  },
  application: {
    title: "Employee Self Service",
    subTitle: "",
    loadDynamicConfig: true,
    requireMultiFactorLogin: false,
    acceptSSOToken: true,
  },
  modules: null,
  navigation: null,
  routes: [],
  release: null,
  error: null,
});
