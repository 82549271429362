import _ from "lodash";

export default {
  primaryContact(state) {
    return (
      state.workingContacts.find((contact) => contact.primaryContact) ?? {}
    );
  },
  payload(state) {
    const payload = [...state.workingContacts];

    state.initialContacts.forEach((iContact) => {
      if (
        !payload.find(
          (wContact) => wContact.sequenceNumber === iContact.sequenceNumber,
        )
      ) {
        payload.push({
          ...iContact,
          deleteCode: true,
        });
      }
    });

    return payload.map((contact) => {
      if (contact.emergencyAddressZipCode.trim().length === 6) {
        contact.emergencyAddressZipCode = contact.emergencyAddressZipCode
          .trim()
          .slice(0, 5);
      }

      const code = contact.emergencyAddressStateCode.code !== undefined
        ? contact.emergencyAddressStateCode.code
        : contact.emergencyAddressStateCode;

      return {
        ...contact,
        emergencyAddressStateCode: code,
        emergencyPhoneNumber:
          contact?.emergencyPhoneNumber?.replace(/[^+\d]+/g, "") ?? "",
      };
    });
  },
  hasECUnsavedChanges(state) {
    return !_.isEqual(state.initialContacts, state.workingContacts);
  },
};
