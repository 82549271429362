export default {
  setEmployeeIdentifier(state, data) {
    state.employeeIdentifier = data.trim();
  },
  setListViewOptions(state, data) {
    state.listViewOptions = data;
  },
  setRequestDateRangeCriteria(state, data) {
    state.requestDateRangeCriteria = data;
  },
  setIsFetchingData(state, data) {
    state.isFetchingData = data;
  },
  setFetchError(state, data) {
    state.fetchError = data;
  },
  setTimeOffRequests(state, data) {
    state.timeOffRequests = data;
  },
  setTotalTimeOffRequests(state, data) {
    state.totalTimeOffRequests = data;
  },
};
