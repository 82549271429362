<template>
  <v-dialog v-model="show" scrollable :max-width="maxWidth" persistent>
    <v-card>
      <v-card-title style="padding: 0">
        <v-col>
          {{ title }}
        </v-col>
        <v-col class="text-right">
          <v-btn icon @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-card-title>
      <v-card-text style="max-height: 300px">
        <v-autocomplete
          v-model="model"
          :items="items"
          label="Select an Employee"
          :loading="isLoading"
          :search-input.sync="search"
          placeholder="Start typing to Search"
          :no-data-text="noData"
          item-text="Description"
          item-value="employeeId"
          hide-selected
          prepend-icon="mdi-account-search"
          autofocus
          @change="employeeSelected"
        ></v-autocomplete>
      </v-card-text>
      <v-card-text v-if="apiError" class="error--text">
        Employee search error: {{ apiError }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="$emit('close')"> Ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import timeOffApi from "@/modules/timeOff/api/timeOff";

export default {
  name: "EmployeeAutoSelectDialog",
  props: {
    title: { type: String, required: false, default: "Success" },
    maxWidth: { type: String, default: "550" },
    show: { type: Boolean, required: true },
    selectItemsLimit: { type: Number, default: 25 },
  },

  data: () => ({
    entries: [],
    apiError: null,
    isLoading: false,
    model: null,
    search: null,
    count: 0,
    searchStarted: false,
  }),

  computed: {
    noData: function () {
      return this.search === null
        ? "Search by First Name, Last Name, or Employee Id"
        : "No employees found";
    },
    fields() {
      if (!this.model) return [];

      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || "n/a",
        };
      });
    },
    items() {
      return this.entries.map((entry) => {
        const Description = `${entry.completeName} - ${entry.employeeId}`;

        return Object.assign({}, entry, { Description });
      });
    },
  },

  watch: {
    search(val) {
      this.clearEntries();

      if (this.searchStarted === false && (val === null || val.length < 3)) {
        return;
      }

      this.searchStarted = true;
      this.isLoading = true;
      this.apiError = null;
      this.fetchEntriesDebounced();
    },
    show(val) {
      if (val === true) {
        this.clearEntries();
        this.model = null;
        this.search = null;
        this.searchStarted = false;
        this.apiError = null;
      }
    },
  },

  methods: {
    clearEntries() {
      this.count = 0;
      this.entries = [];
    },
    fetchEntriesDebounced() {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.fetchEntries();
      }, 500); /* 500ms throttle */
    },
    async fetchEntries() {
      try {
        const { data } = await timeOffApi.fetchUserEmployees({
          limit: this.selectItemsLimit,
          fields:
            "employeeId,lastName,firstName,level2,positionTitle,userId,completeName",
          "employeeId||firstName||lastName": this.search,
        });

        this.entries = data._embedded.employees;
      } catch (e) {
        if (e.response) {
          this.apiError = e.response.data.detail || e.response.data.title;
        } else if (e.request) {
          this.apiError = "Could not complete this request";
        } else {
          this.apiError = "Unknown error";
        }
      }

      this.isLoading = false;
    },

    employeeSelected() {
      this.$emit("employeeSelected", this.model);
    },
  },
};
</script>
