<template>
  <v-card :disabled="isSaving">
    <v-fade-transition>
      <DeleteOverlay
        v-if="deleting"
        @cancel="deleting = false"
        @delete="confirmDelete(true)"
      />
    </v-fade-transition>
    <v-toolbar dark flat color="primary">
      <v-toolbar-title> Contact </v-toolbar-title>
      <v-spacer />
      <v-btn @click="confirmDelete(false)" text>
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-toolbar>
    <div class="contact-content">
      <v-form ref="form" v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-switch
                :label="isPrimary ? 'Primary Contact' : 'Primary Contact?'"
                :id="contact.feId.toString()"
                color="blue"
                v-bind:input-value="contact.primaryContact"
                @change="(val) => setPrimaryWorkingContact([val, contact])"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="form-column">
              <v-text-field
                dense
                outlined
                :value="contact.emergencyContactName"
                @input="
                  (val) => setWorkingContactName({
                    feId: contact.feId,
                    sequenceNumber: contact.sequenceNumber,
                    val,
                  })
                "
                label="Name"
                :rules="[requiredRule]"
              />
            </v-col>
            <v-col cols="12" md="6" class="form-column">
              <v-select
                dense
                outlined
                label="Relationship"
                :value="contact.emergencyRelationshipCode"
                :items="relationships"
                item-value="code"
                item-text="description"
                :rules="[requiredRule, relationshipRule]"
                @change="
                  (val) =>
                    setWorkingContactRelationship({
                      feId: contact.feId,
                      sequenceNumber: contact.sequenceNumber,
                      val,
                    })
                "
              >
                <template #item="{ item }">
                  <span class="text-capitalize">
                    {{ item.description.toLowerCase() }}
                  </span>
                </template>
                <template #selection="{ item }">
                  <span class="text-capitalize">
                    {{ item.description.toLowerCase() }}
                  </span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="form-column">
              <v-text-field
                ref="street"
                dense
                outlined
                :value="contact.emergencyAddressLine1"
                @input="
                  (val) =>
                    setWorkingContactStreet({
                      feId: contact.feId,
                      sequenceNumber: contact.sequenceNumber,
                      val,
                    })
                "
                label="Street"
                placeholder=""
                :rules="[maxLengthAddressRule]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="form-column">
              <v-text-field
                dense
                outlined
                :value="contact.emergencyAddressCity"
                @input="
                  (val) =>
                    setWorkingContactCity({
                      feId: contact.feId,
                      sequenceNumber: contact.sequenceNumber,
                      val,
                    })
                "
                label="City"
              />
            </v-col>
            <v-col cols="12" md="6" class="form-column">
              <v-autocomplete
                label="State"
                :items="states"
                :value="contact.emergencyAddressStateCode"
                item-value="code"
                item-text="description"
                return-object
                @change="
                  (val) =>
                    setWorkingContactState({
                      feId: contact.feId,
                      sequenceNumber: contact.sequenceNumber,
                      val,
                    })
                "
                autocomplete="address-level1"
                dense
                outlined
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="form-column">
              <v-text-field
                dense
                outlined
                :value="contact.emergencyAddressZipCode"
                @input="
                  (val) =>
                    setWorkingContactZip({
                      feId: contact.feId,
                      sequenceNumber: contact.sequenceNumber,
                      val,
                    })
                "
                label="Zip Code"
                v-mask="'#####-####'"
              />
            </v-col>
            <v-col cols="12" md="6" class="form-column">
              <v-text-field
                dense
                outlined
                label="Phone Number"
                v-mask="'(###) ###-####'"
                :value="contact.emergencyPhoneNumber"
                @input="
                  (val) =>
                    setWorkingContactPhone({
                      feId: contact.feId,
                      sequenceNumber: contact.sequenceNumber,
                      val,
                    })
                "
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </div>
  </v-card>
</template>

<script>
import DeleteOverlay from "@/components/delete-overlay.vue";
import { mapState, mapMutations, mapActions } from "vuex";
// TODO: replace vue-the-mask with a custom masked input, as there are issues with programmatic updates when using
//  this directive, this isn't a huge issue, but it can cause the input to become unmasked when reset
import { mask } from "vue-the-mask";

export default {
  directives: {
    mask,
  },
  components: {
    DeleteOverlay,
  },
  data() {
    return {
      deleting: false,
      isValid: false,
      autocomplete: null,
      maxLengthAddressRule: (v) => v.length <= 30 || "Max 30 characters",
      requiredRule: (v) => !!v || "This field is required",
      relationshipRule: (v) => (v.code !== "" && v.description !== "") || "This field is required",
      valid: false,
    };
  },
  props: {
    contact: { type: Object, required: true },
  },
  mounted() {
    this.autocomplete = new this.$google.maps.places.Autocomplete(
      this.$refs.street.$el.querySelector("input"),
      { types: ["geocode"] },
    );
    this.autocomplete.setComponentRestrictions({ country: ["us"] });
    this.autocomplete.addListener(
      "place_changed",
      this.googleAutocompleteHandler,
    );
  },
  computed: {
    ...mapState("settings", ["states"]),
    ...mapState("personalInfo/emergencyContacts", [
      "relationships",
      "isSaving",
    ]),
    isPrimary() {
      return !!this.contact.primaryContact;
    },
  },
  methods: {
    ...mapMutations("personalInfo/emergencyContacts", [
      "setWorkingContactPhone",
      "setWorkingContactName",
      "setWorkingContactStreet",
      "setWorkingContactCity",
      "setWorkingContactState",
      "setWorkingContactZip",
      "setWorkingContactRelationship",
      "setPrimaryWorkingContact",
      "deleteContact",
    ]),
    ...mapActions("personalInfo/emergencyContacts", [
      "setWorkingContactAddress",
    ]),
    validate() {
      this.$refs.form.validate();
    },
    confirmDelete(confirmed = false) {
      if (this.contact.sequenceNumber === 0 || confirmed) {
        this.deleteContact(this.contact);
      } else {
        this.deleting = true;
      }
    },
    googleAutocompleteHandler() {
      const place = this.autocomplete.getPlace();
      const ac = place.address_components;

      const street = ac
        .filter(
          (c) => c.types.includes("street_number") || c.types.includes("route"),
        )
        .map((c) => c?.short_name ?? "")
        .join(" ");

      const city = ac.filter((c) => c.types.includes("locality"))[0]?.short_name ?? "";

      const stateCode = ac.filter((c) => c.types.includes("administrative_area_level_1"))[0]?.short_name ?? "";

      const stateName = ac.filter((c) => c.types.includes("administrative_area_level_1"))[0]?.long_name ?? "";

      const zip = ac.filter((c) => c.types.includes("postal_code"))[0]?.short_name ?? "";

      this.setWorkingContactAddress({
        feId: this.contact.feId,
        sequenceNumber: this.contact.sequenceNumber,
        street,
        city,
        state: {
          code: stateCode,
          description: stateName,
        },
        zip,
      });
    },
  },
};
</script>

<style lang="scss">
.form-column {
  padding: 5px !important;
}
.primary-contact > header.v-toolbar {
  border-color: black !important;
  border-width: 2px !important;
  border-style: solid !important;
}
</style>
