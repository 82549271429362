<template>
  <v-sheet class="card-top-margin">
    <v-row class="filter-text-field">
      <v-col>
        <v-text-field
          style="padding-top: 5px"
          v-model="search"
          outlined
          label="Search by First Name, Last Name, or Employee Id"
          append-icon="mdi-magnify"
          autofocus
          clearable
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="filter-dropdowns">
      <v-col cols="6">
        <v-select
          v-model="reports"
          :items="reportsList"
          label="Reports Direct/Indirect"
        ></v-select>
      </v-col>
      <v-col v-if="isProxyForManager">
        <v-select
          clearable
          return-object
          v-model="proxyAssignment"
          :items="managerAssignmentsList"
          label="Proxy Assignments"
        ></v-select>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { Mixins, Prop, Watch } from "vue-property-decorator";
import Component from "vue-class-component";
import modulePageMixin from "@/utils/mixins";
import { namespace } from "vuex-class";
import { authorizationMixin } from "@/modules/timeOff/authorizationMixin";

const requestsSearchStore = namespace("timeOff/requestsSearch");
const authStore = namespace("auth");

@Component({
  name: "requestsSearch",
  components: {},
})
export default class RequestsSearch extends Mixins(
  modulePageMixin,
  authorizationMixin
) {
  @requestsSearchStore.Mutation("setReportsCriteria") setReportsCriteria;
  @requestsSearchStore.Mutation("setSearchCriteria") setSearchCriteria;
  @requestsSearchStore.Mutation("setProxyCriteria") setProxyCriteria;
  @requestsSearchStore.Getter("getSearchCriteria") getSearchCriteria;
  @requestsSearchStore.Getter("getReportsCriteria") getReportsCriteria;
  @requestsSearchStore.Getter("getProxyCriteria") getProxyCriteria;

  @authStore.Getter("managerProxyAssignments") managerProxyAssignments;

  data() {
    return {
      isLoading: false,
      reportsList: ["Direct", "Indirect", "Both"],
    };
  }

  @Prop({
    type: Function,
    required: false,
    default: () => {},
  })
  searchAction;

  @Watch("search")
  onSearchChange() {
    if (
      this.search !== null &&
      this.search.length !== 0 &&
      this.search.length < 3
    ) {
      return;
    }

    clearTimeout(this._searchTimerId);
    this._searchTimerId = setTimeout(() => {
      this.setSearchCriteria(this.search);
      this.$emit("onReloadResults", "");
    }, 500);
  }

  @Watch("reports")
  onReportsChange(event) {
    this.setReportsCriteria(event);
    this.search = "";
    this.$emit("onReloadResults");
  }

  @Watch("proxyAssignment")
  onProxyChange() {
    this.search = "";
    this.$emit("onReloadResults");
  }

  get search() {
    return this.getSearchCriteria;
  }
  set search(criteria) {
    this.setSearchCriteria(criteria);
  }

  get reports() {
    return this.getReportsCriteria;
  }
  set reports(value) {
    this.setReportsCriteria(value);
  }

  get proxyAssignment() {
    return this.getProxyCriteria;
  }
  set proxyAssignment(value) {
    this.setProxyCriteria(value);
  }
  get managerAssignmentsList() {
    let assignmentsList = [
      {
        text: "All",
        employerId: "",
        employeeId: "all",
      },
    ];

    let managerProxyAssignments = Array.isArray(this.managerProxyAssignments)
      ? this.managerProxyAssignments
      : Object.values(this.managerProxyAssignments);

    managerProxyAssignments.forEach((assignment) => {
      assignmentsList.push({
        text: `${assignment.lastName.trim()}, ${assignment.firstName.trim()} (${assignment.employerId.trim()} - ${assignment.employeeId.trim()})`,
        employerId: assignment.employerId,
        employeeId: assignment.employeeId,
      });
    });

    return assignmentsList;
  }

  async mounted() {
    if (this.isProxyForManager && !this.isManager) {
      this.proxyAssignment = {
        text: "All",
        employerId: "",
        employeeId: "all",
      };
    }
  }

  resetSearchOptions() {
    this.search = "";
    this.reports = "Both";
    this.proxyAssignment = null;
  }

  destroyed() {
    this.resetSearchOptions();
  }
}
</script>

<style scoped>
.card-top-margin {
  margin-top: 20px;
}

.filter-criteria {
  display: none;
}
</style>
