<template>
  <div class="unsavedChangesDialog">
    <v-dialog
      v-model="show"
      width="unset"
      @click:outside="$emit('cancelNavigation')"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ title }}
        </v-card-title>
        <v-card-text>
          <div class="text-md-justify mt-5">
            {{ message }}
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="red" text @click="$emit('cancelNavigation')">
            Cancel
          </v-btn>
          <v-btn color="red" text @click="$emit('continueNavigation')">
            Leave
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: { type: Boolean, required: true },
    message: { type: String, default: "Changes you made may not be saved." },
    title: { type: String, default: "Leaving?" },
  },
};
</script>

<style lang="scss" scoped></style>
