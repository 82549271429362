<template>
  <v-container fluid class="education-page">
    <pageManager :isLoading="isLoading" :isEmpty="false" :error="error">
      <template #loading>
        <div class="loader">
          <v-progress-circular indeterminate color="accent" />
        </div>
      </template>
      <v-alert
        dense
        color="blue lighten-2"
        type="info"
        prominent
        v-if="hasEDUnsavedChanges"
      >
        <v-row align="center">
          <v-col class="grow">You currently have unsaved changes</v-col>
          <v-col class="shrink">
            <v-btn color="blue darken-3" @click="save">Save Changes</v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <v-row>
        <v-col
          cols="12"
          md="6"
          v-for="education in educations"
          :key="education.feId ? education.feId : education.sequenceNumber"
        >
          <Education ref="educationCard" :education="education" @save="save" />
        </v-col>
      </v-row>
    </pageManager>
    <FloatingControls
      @save="save"
      @add="addEducationCard"
      @discard="discardChanges"
      :actions="actions"
    />
    <v-overlay v-if="isSaving" color="secondary">
      <v-progress-circular indeterminate color="accent" />
    </v-overlay>
    <unsavedChangesDialog
      :show="navigateDialog"
      @cancelNavigation="cancelNavigation"
      @continueNavigation="continueNavigation"
    />
  </v-container>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
} from "vuex";
import pageManager from "@/components/pageManager.vue";
import FloatingControls from "@/components/floating-controls.vue";
import unsavedChangesDialog from "@/components/unsaved-changes-dialog.vue";
import Education from "./education.vue";

export default {
  name: "EducationPage",
  components: {
    Education,
    FloatingControls,
    unsavedChangesDialog,
    pageManager,
  },
  data() {
    return {
      navigateTo: null,
      navigateDialog: false,
      actions: [
        {
          event: "add",
          color: "success",
          icon: "mdi-plus",
          name: "Add Education",
        },
        {
          event: "save",
          color: "success",
          icon: "mdi-content-save-all",
          name: "Save",
        },
        {
          event: "discard",
          color: "accent",
          icon: "mdi-close",
          name: "Discard Changes",
        },
      ],
    };
  },
  computed: {
    ...mapState("personalInfo/education", {
      educations: "workingEducations",
      initialEducations: "initialEducations",
      isLoading: "isLoading",
      isSaving: "isSaving",
      error: "error",
      submitError: "submitError",
    }),
    ...mapGetters("auth", ["employeeId", "employerId"]),
    ...mapGetters("personalInfo/education", ["hasEDUnsavedChanges"]),
  },
  async mounted() {
    await this.$store.dispatch("personalInfo/education/fetchEducations");
  },
  methods: {
    ...mapMutations("personalInfo/education", ["addEducation"]),
    ...mapActions("personalInfo/education", [
      "saveEducations",
      "discardChanges",
    ]),
    addEducationCard() {
      this.addEducation();
    },
    async save() {
      this.$refs.educationCard.forEach((education) => education.validate());

      let allValid = true;
      this.$refs.educationCard.forEach((education) => {
        if (!education.valid) {
          allValid = false;
        }
      });

      if (!allValid) {
        this.$toasted.global.essError({
          message: "Please fix errors prior to saving.",
        });
      }

      if (allValid) {
        await this.$store.dispatch("personalInfo/education/saveEducations");
        if (this.submitError) {
          this.$toasted.global.essError({
            message:
              typeof this.submitError === "string"
                ? this.submitError
                : this.submitError.errors,
          });
        }

        if (!this.submitError) {
          this.$toasted.success("Your changes have been successfully saved!");
        }
      }
    },
    continueNavigation() {
      this.navigateDialog = false;
      // returns to beforeRouteLeave and executes next function
      this.$router.push(this.navigateTo);
    },
    cancelNavigation() {
      this.navigateDialog = false;
      this.navigateTo = null;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasEDUnsavedChanges) {
      if (this.navigateTo) {
        next();
      } else {
        this.navigateTo = to;
        this.navigateDialog = true;
      }
    } else {
      next();
    }
  },
  page: {
    title: "Education",
    meta: [{ name: "description", content: "The Education page." }],
  },
  watch: {
    hasEDUnsavedChanges(val) {
      if (val) {
        window.onbeforeunload = () => true;
      } else {
        window.onbeforeunload = null;
      }
    },
  },
};
</script>
