<template>
  <div class="w4RightSideNavBar">
    <v-navigation-drawer class="menuPadding" fixed clipped expand-on-hover :mini-variant.sync="isMini" permanent right>
      <v-list id="form-sections">
        <div v-for="item in sections" :key="item.title">
          <v-list-item link v-if="item.enabled">
            <v-list-item-icon>
              <v-icon :color="!item.valid ? 'red' : ''">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content @click="jumpToSection(item)">
              <v-list-item-title v-if="item.valid">{{item.title }}</v-list-item-title>
              <v-list-item-title v-else class="red--text">{{item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
      <template v-slot:append>
        <navButton
          action="submit"
          color="accent"
          icon="mdi-send"
          :isMini="isMini"
          :show="showSubmit"
          :disable="!isFormValid"
          v-on="$listeners"
        >
          Submit
        </navButton>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {
  Component,
  Mixins,
  Prop,
  Watch,
} from "vue-property-decorator";
import navButton from "@/modules/timeOff/components/fields/navButton.vue";
import modulePageMixin from "@/utils/mixins";
import { namespace } from "vuex-class";

const w4store = namespace("payroll/w4");

@Component({
  components: { navButton },
})
export default class w4NavBar extends Mixins(modulePageMixin) {
  isLoading = false;

  isEmpty = false;

  isMini = true;

  scrollOptions = {
    duration: 1000,
    offset: 1,
    easing: "linear",
  };

  @w4store.State("isFormValid") isFormValid;

  @w4store.State("formSections") formSections;

  @w4store.Mutation("setFormSections") setFormSections;

  @w4store.State("formData") formData;

  @Watch("formData.exemptFlag", { deep: true })
  updateNavBarItemsEnabled(isExempt) {
    this.sections.forEach((section) => {
      if (section.hideFromExempt) {
        section.enabled = !isExempt;
      }
    });
    this.setFormSections(this.sections);
  }

  @Prop({ type: Boolean, default: true }) showSubmit;

  get sections() {
    return this.formSections;
  }

  jumpToSection(item) {
    this.$vuetify.goTo(item.link, this.scrollOptions);
  }
}
</script>

<style scoped>
#form-sections {
  margin-top: 70px;
}
</style>
