<template>
  <fieldset>
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          ref="street"
          label="Street"
          :rules="[required]"
          :value="street"
          @change="(val) => fieldChange({ field: 'street', val })"
          autocomplete="address-line1"
          clearable
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Apartment"
          :value="street2"
          @change="(val) => fieldChange({ field: 'street2', val })"
          autocomplete="address-line2"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="City"
          :value="city"
          :rules="[required]"
          @change="(val) => fieldChange({ field: 'city', val })"
          autocomplete="address-level2"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-autocomplete
          label="State"
          :items="states"
          :value="state"
          :rules="[required]"
          item-value="code"
          item-text="description"
          return-object
          @change="(val) => fieldChange({ field: 'state', val })"
          autocomplete="address-level1"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Zip Code"
          :value="zip"
          :rules="[required, isValidZip]"
          @keydown="zipCodeEvent"
          @change="(val) => fieldChange({ field: 'zip', val })"
        />
      </v-col>
      <v-col cols="12" md="4" v-if="phoneNumber !== null">
        <v-text-field
          label="Phone Number"
          v-mask="'(###) ###-####'"
          :value="phoneNumber"
          :rules="[isPhoneNumber, required]"
          @change="(val) => fieldChange({ field: 'phoneNumber', val })"
          validate-on-blur
          ref="phone"
          autocomplete="new-phoneNumber"
        />
      </v-col>
    </v-row>
  </fieldset>
</template>

<script>
import { mapState } from "vuex";
import { mask } from "vue-the-mask";
import validators from "@/utils/validators";

export default {
  directives: {
    mask,
  },
  data() {
    return {
      autocomplete: null,
    };
  },
  props: {
    street: { type: String, required: true },
    street2: { type: String, required: true },
    city: { type: String, required: true },
    state: { type: Object, required: true },
    zip: { type: String, required: true },
    phoneNumber: { type: String, default: null },
    validateFieldSet: { type: Boolean, default: true },
  },
  mounted() {
    if (this.$google === undefined) return;

    this.autocomplete = new this.$google.maps.places.Autocomplete(
      this.$refs.street.$el.querySelector("input"),
      { types: ["geocode"] },
    );
    this.autocomplete.setComponentRestrictions({ country: ["us"] });
    this.autocomplete.addListener(
      "place_changed",
      this.googleAutocompleteHandler,
    );
  },
  computed: {
    ...mapState("settings", ["states"]),
  },
  methods: {
    zipCodeEvent(e) {
      // if the pressed key is not a number or a dash
      if (Number.isNaN(e.key) && e.key !== "-") {
        e.stopPropagation();
        e.preventDefault();
      }

      // Only keeps the first 10 digits
      e.target.value = e.target.value.substring(0, 9);
    },
    required(val) {
      if (!this.validateFieldSet) return true;

      return validators.required(val) || "This field is required";
    },
    isPhoneNumber(val) {
      if (!this.validateFieldSet) return true;

      return val.trim().length === 0
        ? true
        : val.replace(/\D/g, "").length === 10 || "Please provide a valid phone number";
    },
    isValidZip(val) {
      if (!this.validateFieldSet) return true;

      return /^\d{5}(-\d{4})?$/.test(val) || "Invalid Zip Code";
    },
    fieldChange({ field, val }) {
      const newAddress = this.phoneNumber === ""
        ? {
          street: this.street,
          street2: this.street2,
          city: this.city,
          state: this.state,
          zip: this.zip,
        }
        : {
          street: this.street,
          street2: this.street2,
          city: this.city,
          state: this.state,
          zip: this.zip,
          phoneNumber: this.phoneNumber,
        };

      newAddress[field] = val;

      this.$emit("change", newAddress);
    },
    googleAutocompleteHandler() {
      const place = this.autocomplete.getPlace();
      const ac = place.address_components;

      const street = ac
        .filter((c) => c.types.includes("street_number") || c.types.includes("route"))
        .map((c) => c?.short_name ?? "")
        .join(" ");

      const city = ac.filter((c) => c.types.includes("locality"))[0]?.short_name ?? "";

      const stateCode = ac.filter((c) => c.types.includes("administrative_area_level_1"))[0]?.short_name ?? "";

      const stateName = ac.filter((c) => c.types.includes("administrative_area_level_1"))[0]?.long_name ?? "";

      const zip = ac.filter((c) => c.types.includes("postal_code"))[0]?.short_name ?? "";

      this.$emit("change", {
        street,
        street2: this.street2,
        city,
        state: {
          code: stateCode,
          description: stateName,
        },
        stateName,
        zip,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
