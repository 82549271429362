<template>
  <div class="page-manager">
    <slot name="error" v-if="error">
      <error-alert :error="error" />
    </slot>
    <slot name="loading" v-else-if="isLoading">
      <v-progress-circular indeterminate color="primary" />
    </slot>
    <slot name="empty" v-else-if="isEmpty">
      <v-alert dense border="left" type="warning"> No data available. </v-alert>
    </slot>
    <slot v-else />

    <slot name="errorDialog">
      <error-dialog
        :error="dialogError.error"
        :model="dialogError.model"
        @dialogClosed="$emit('dialogClosed')"
      />
    </slot>
  </div>
</template>

<script>
import ErrorDialog from "@/components/errorDialog.vue";
import ErrorAlert from "@/components/errorAlert.vue";

export default {
  name: "pageManager",
  components: { ErrorAlert, ErrorDialog },
  props: {
    isLoading: { required: true, type: Boolean },
    isEmpty: { required: true, type: Boolean },
    error: { required: true },
    dialogError: {
      type: Object,
      default() {
        return { error: null, model: false };
      },
    },
  },
};
</script>
