export default function () {
  return {
    submitError: {},
    fetchError: null,
    isSubmitting: false,
    isFetchingData: false,
    progressMessage: "",
    successMessage: "",
    // admins
    adminUsers: [],
    totalAdminUsers: 0,
    searchCriteria: "",
    listViewOptions: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
    },
    defaultListViewOptions: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
    },
    scheduleChanges: [],
    totalScheduleChanges: 0,
  };
}
