import emailPreferencesAPI from "@/modules/payroll/api/emailPreferences";

export default {
  async fetchEmailPreferences({ commit }) {
    commit("setIsLoading", true);
    commit("setError", null);
    try {
      const { data } = await emailPreferencesAPI.getEmailPreferences();
      commit("setEmailPreferences", data.emailPreferences);
    } catch (error) {
      commit("setError", error);
    }
    commit("setIsLoading", false);
  },
  async submitEmailPreferences({ commit }, emailPreference) {
    commit("setIsSubmitting", true);
    commit("setSavingInfo", {});
    try {
      await emailPreferencesAPI.saveEmailPreferences(emailPreference);
      commit("setIsSubmitting", false);
    } catch (error) {
      commit("setSubmitError", error);
      commit("setIsSubmitting", false);
    }
  },
};
