import timeOffApi from "@/modules/timeOff/api/timeOff";
import { getApiError } from "@/utils/errorUtils";

export default {
  async fetchCalendarRequests({ commit }, params) {
    commit("setIsFetchingData", true);

    try {
      const { data } = await timeOffApi.fetchCalendarTimeOffRequests(params);

      commit("setCalendarEntries", data._embedded?.requests ?? []);
    } catch (error) {
      commit("setRequestFetchError", getApiError(error));
    }
    commit("setIsFetchingData", false);
  },
  async fetchDashboardCalendarRequests(
    { commit },
    { employeeIdentifier, params }
  ) {
    commit("setIsFetchingData", true);

    try {
      const { data } = await timeOffApi.fetchDashboardCalendarRequests(
        employeeIdentifier,
        params
      );

      commit("setCalendarEntries", data._embedded?.requests ?? []);
    } catch (error) {
      commit("setRequestFetchError", getApiError(error));
    }
    commit("setIsFetchingData", false);
  },
};
