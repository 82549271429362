<template>
  <v-container class="new-request">
    <ReviewRequestForm
      :requestId="requestId"
      :employeeUserId="userId"
      ref="ReviewRequestForm"
    ></ReviewRequestForm>

    <successDialog
      :message="successDialog.message"
      :show="successDialog.show"
      @close="closeAlertDialog"
    />
  </v-container>
</template>

<script>
import NewRequest from "./newRequest.vue";
import Component from "vue-class-component";
import ReviewRequestForm from "./components/reviewRequestForm";

@Component({
  name: "ViewRequest",
  components: { ReviewRequestForm },
})
export default class ViewRequest extends NewRequest {
  get userId() {
    return this.$route.query?.user ?? "";
  }
  get requestId() {
    return this.$route.query?.request ?? "";
  }

  closeAlertDialog() {
    this.successDialog.show = false;
    this.$router.go(-1);
  }
}
</script>
