export default {
  isFetchingData(state) {
    return state.isFetchingData;
  },
  isSubmitting(state) {
    return state.isSubmitting;
  },
  progressMessage(state) {
    return state.progressMessage;
  },
  successMessage(state) {
    return state.successMessage;
  },
  submitError(state) {
    return state.submitError;
  },
  fetchError(state) {
    return state.fetchError;
  },
  // Admin getters
  adminUsers(state) {
    return state.adminUsers;
  },
  totalAdminUsers(state) {
    return state.totalAdminUsers;
  },
  listViewOptions(state) {
    return state.listViewOptions;
  },
  scheduleChanges(state) {
    return state.scheduleChanges;
  },
  totalScheduleChanges(state) {
    return state.totalScheduleChanges;
  },
};
