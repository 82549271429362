import dates from "@/utils/dates";
import { stringFormat } from "@/utils/util";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { clone as _clone } from "lodash";

const timeOffStore = namespace("timeOff");
const employeeHoursStore = namespace("timeOff/employeeHours");
const requestFormStore = namespace("timeOff/requestForm");
const employeeScheduleStore = namespace("timeOff/employeeSchedule");

@Component
export default class rulesMixin extends Vue {
  minSelectableDate = null;
  maxSelectableDate = null;
  context = null;

  @timeOffStore.Getter("config") config;
  @employeeScheduleStore.Getter("employeeSchedulePayType")
  employeeSchedulePayType;
  @employeeScheduleStore.Getter("scheduleForDate") scheduleForDate;
  @requestFormStore.Getter("getDaysForCategory") getDaysForCategory;
  @requestFormStore.Getter("getRequestedHoursForCategory")
  getRequestedHoursForCategory;
  @employeeHoursStore.Getter("getRemainingHoursForCategory")
  getRemainingHoursForCategory;

  get requestDatesLowerLimitConfig() {
    return this.config("request_day_limit_days_past");
  }

  get requestDatesUpperLimitConfig() {
    return this.config("request_day_limit_days_future");
  }

  get categoryGroupsRules() {
    return this.config("categories_rules");
  }

  get getMinSelectableDate() {
    if (this.minSelectableDate === null) {
      this.minSelectableDate = dates.dateBeforeToday(
        this.requestDatesLowerLimitConfig
      );
    }

    return this.minSelectableDate;
  }

  get getMaxSelectableDate() {
    if (this.maxSelectableDate === null) {
      this.maxSelectableDate = dates.dateAfterToday(
        this.requestDatesUpperLimitConfig
      );
    }
    return this.maxSelectableDate;
  }

  maxDaysForCategoryCode(code) {
    return this.categoryGroupsRules[code]?.maxDays ?? null;
  }

  configValue(key) {
    return this.config(key);
  }

  dateBetweenMinMax(dateStr) {
    return dates.dateIsBetween(
      dateStr,
      this.getMinSelectableDate,
      this.getMaxSelectableDate
    );
  }

  dateIsScheduledDate(dateStr) {
    return this.scheduleForDate(dateStr) > 0;
  }

  entriesExist(requestEntries) {
    // Ensure entries object is not empty
    return Object.keys(requestEntries).length !== 0;
  }

  requestDateAvailable(dateStr) {
    // reject dates before min date and after max date
    if (!this.dateBetweenMinMax(dateStr)) {
      return false;
    }

    // reject dates that the employee is not scheduled for
    return this.dateIsScheduledDate(dateStr);
  }

  getAvailableDatesForMonthYear(year, month) {
    const daysInMonth = dates.daysInMonth(year, month);
    let availableDates = [];

    for (let day = 1; day <= daysInMonth; day++) {
      let date = new Date(year, month - 1, day);
      if (this.requestDateAvailable(date)) {
        availableDates.push(date);
      }
    }

    return availableDates;
  }

  requestEntryHoursRule(hours, code, date, isSplit) {
    // IDEA: Entry Rules could be refactored to be driven by config
    return this.defaultRequestHoursRule(hours, date, isSplit);
  }

  validateCategoryGroups(entries) {
    let valid = true;
    let catEntries;

    catEntries = entries.map((entry) => {
      const categoryValid = this.validateCategoryRules(entry.code);
      if (categoryValid !== true) {
        entry.error = "";
        valid = false;
        entry.error = categoryValid;
      }

      return _clone(entry);
    });

    return valid !== true ? catEntries : valid;
  }

  validateCategoryRules(code, context) {
    let valid = true;
    this.context = context;

    Object.entries(this.categoryGroupsRules).forEach(([key, value]) => {
      if (key === code) {
        valid = this.processCategoryRules(value, key);
      }
    });

    return valid;
  }

  processCategoryRules(rules, categoryCode) {
    let valid = true;
    let fails = [];

    if (rules.rules) {
      Object.keys(rules.rules).forEach((key) => {
        valid = this[key].apply(null, [rules.rules[key], categoryCode]);
        if (valid !== true) fails.push(valid);
      });
    }

    if (fails.length > 0) {
      // using only the first message in the array provides a cleaner popup message
      // The messages can be concatenated using fails.join(" "), but this will make the popover
      // message too crowded.
      // The order of which message is first is based in the order of the rules in the config
      // file.
      valid = fails[0];
    }

    return valid;
  }

  maxDays(value, categoryCode) {
    value = this.parseValue(value);
    const categoryDays = this.getDaysForCategory(categoryCode);

    if (categoryDays > value && value !== null) {
      const message = this.getMessage(categoryCode, "maxDays", value);
      return message ?? "Exceeded allowed days for category";
    }

    return true;
  }

  maxCivicDutyDays(value, categoryCode) {
    let daysFromSchedule = this.parseValue(value) * 2;
    const hardLimit = this.configValue("civic_duty_days_hard_limit") ?? 10;
    const limit = daysFromSchedule > hardLimit ? hardLimit : daysFromSchedule;
    const categoryDays = this.getDaysForCategory(categoryCode);

    if (categoryDays > limit) {
      const message = this.getMessage(categoryCode, "maxCivicDutyDays", limit);
      return message ?? "Exceeded allowed days for category";
    }

    return true;
  }

  excludeWeekdays(value, categoryCode) {
    if (this.context !== undefined && value) {
      const day = dates.dayOfWeek(this.context);
      if (value.indexOf(day) > -1) {
        const message = this.getMessage(categoryCode, "excludeWeekdays", day);
        return message ?? `Weekends are not available for category.`;
      }
    }

    return true;
  }

  parseValue(value) {
    if (typeof value === "string") {
      switch (value.substring(0, 1)) {
        case "@":
          // get config value
          return this.configValue(value.substring(1));
        case "$":
          // get value from variable
          return this[value.substring(1)] ?? "";
        default:
          break;
      }
    }

    return value;
  }

  getMessage(code, ruleKey, ...value) {
    const messages = this.categoryGroupsRules[code]?.messages ?? null;
    let message = null;

    if (messages !== null && messages[ruleKey] !== undefined) {
      message = stringFormat(messages[ruleKey], value);
    }

    return message ?? null;
  }

  negativeBalance(value, categoryCode) {
    const remainingHours = this.getRemainingHoursForCategory(categoryCode);
    const requestedHours = this.getRequestedHoursForCategory(categoryCode);
    if (remainingHours - requestedHours < 0 !== value) {
      return `Balance for category cannot be negative `;
    }
    return true;
  }

  defaultRequestHoursRule(hours, date, isSplit) {
    hours = parseFloat(hours);

    const maxHours = this.scheduleForDate(date);

    if (
      this.employeeSchedulePayType === this.config("salary_pay_type") &&
      !isSplit
    ) {
      if (hours === parseFloat(maxHours)) return true;

      return `Selected must match your scheduled ${maxHours} hours`;
    }

    if (
      this.employeeSchedulePayType === this.config("hourly_pay_type") ||
      (this.employeeSchedulePayType === this.config("salary_pay_type") &&
        isSplit)
    ) {
      if (
        hours >= this.config("schedule_hourly_min_hours") &&
        hours <= maxHours
      ) {
        return true;
      }
    }

    return `Must be between ${this.config(
      "schedule_hourly_min_label"
    )} and your scheduled ${maxHours} hours`;
  }

  hoursLimits(payType) {
    switch (payType) {
      case this.config("hourly_pay_type"):
        return {
          min: this.config("schedule_hourly_min_hours"),
          max: this.config("schedule_hourly_max_hours"),
        };
      case this.config("salary_pay_type"):
        return {
          min: this.config("schedule_salary_min_hours"),
          max: this.config("schedule_salary_max_hours"),
        };
      default:
        return {
          min: 0,
          max: 0,
        };
    }
  }

  workHoursRule(hours) {
    const hourLimits = this.hoursLimits(this.employeeSchedulePayType);

    if (
      (isNaN(parseFloat(hours)) !== true &&
        hours >= hourLimits.min &&
        hours <= hourLimits.max) ||
      parseFloat(hours) === 0
    ) {
      return true;
    }

    return `Must be between ${hourLimits.min} and ${hourLimits.max} hours`;
  }

  requestDateRule(date, code, requestedDates, requestId) {
    if (dates.dateIsBefore(date, this.getMinSelectableDate)) {
      return "Date is too far in the past";
    }
    if (dates.dateIsAfter(date, this.getMaxSelectableDate)) {
      return "Date is too far in the future";
    }
    if (!this.dateIsScheduledDate(date)) {
      return "You are not scheduled for this date";
    }

    if (date instanceof Object) {
      date = dates.dateString(date);
    }

    if (this.datePreviouslyRequested(date, requestedDates, requestId)) {
      if (!this.dateHasAvailableHours(date, requestedDates)) {
        return "This date has already been selected in another request";
      }
    }

    return true;
  }

  requestCategoryRules(category, context) {
    const categoryRules = this.validateCategoryRules(category, context);
    if (categoryRules !== true) {
      return categoryRules;
    }

    return true;
  }

  requestCodeRule(code) {
    let valid = false;
    this.employeeHours.forEach((hours) => {
      if (hours.code === code) {
        valid = true;
      }
    });

    return valid ? valid : "Time off code does not exist";
  }

  requestSplitRule(dateStr, dateEntries, prevEntry, requestId) {
    let hourSum = dateEntries.reduce((accumulator, dateEntry) => {
      return accumulator + parseFloat(dateEntry.hours);
    }, 0);

    if (prevEntry !== null && prevEntry.requestId !== requestId?.toString()) {
      hourSum += prevEntry.hours;
    }

    return this.requestEntryHoursRule(
      hourSum,
      dateEntries[0].code,
      dateStr,
      false
    );
  }

  calendarRange(startDate, endDate) {
    const past = dates.dateIsMonthsPast(
      startDate.date,
      this.config("calendar_max_past_months")
    );

    const future = dates.dateIsMonthsFuture(
      endDate.date,
      this.config("calendar_max_future_months")
    );

    return { past: past, future: future };
  }

  dashboardCalendarRange(startDate, endDate) {
    const past = dates.dateIsMonthsPast(
      startDate.date,
      this.config("dashboard_calendar_max_past_months")
    );

    const future = dates.dateIsMonthsFuture(
      endDate.date,
      this.config("dashboard_calendar_max_future_months")
    );

    return { past: past, future: future };
  }

  getNextMonthValue(startDate) {
    return dates.dateNextMonth(
      startDate,
      this.config("calendar_max_future_months"),
      this.config("calendar_skip_interval")
    );
  }

  getPrevMonthValue(startDate) {
    return dates.datePrevMonth(
      startDate,
      this.config("calendar_max_past_months"),
      this.config("calendar_skip_interval")
    );
  }

  getDashboardNextMonthValue(startDate) {
    return dates.dateNextMonth(
      startDate,
      this.config("dashboard_calendar_max_future_months"),
      this.config("calendar_skip_interval")
    );
  }

  getDashboardPrevMonthValue(startDate) {
    return dates.datePrevMonth(
      startDate,
      this.config("dashboard_calendar_max_past_months"),
      this.config("calendar_skip_interval")
    );
  }

  dateHasAvailableHours(date, requestedDates) {
    const entriesOnDate = requestedDates.reduce((count, currentEntry) => {
      return currentEntry[date] === undefined ? count : ++count;
    }, 0);

    if (entriesOnDate === this.config("request_day_max_splits")) {
      return false;
    }

    const previousEntry = requestedDates.find((entry) =>
      Object.keys(entry).includes(date)
    );

    return previousEntry[date].perc !== 1;
  }

  datePreviouslyRequested(date, requestedDates, requestId) {
    if (requestId !== null && requestId !== undefined) {
      let isDateInRequest = requestedDates.some(function (entry) {
        return entry[date]?.requestId === requestId.toString();
      });

      if (isDateInRequest) {
        return false;
      }
    }

    return requestedDates.find((entry) => Object.keys(entry).includes(date));
  }
}
