import { underscoreToCamelCase } from "@/utils/util";

export default {
  resetRequest(state) {
    state.reasonForRequest = "";
    state.selectedCategory = null;
    state.requestEntries = {};
    state.requestSubmitError = {};
    state.isSubmitting = false;
  },
  setSelectedCategory(state, data) {
    state.selectedCategory = data;
  },
  setReasonForRequest(state, data) {
    state.reasonForRequest = data !== null ? data : "";
  },
  setReviewComments(state, data) {
    state.reviewComments = data !== null ? data : "";
  },

  setRequestEntries(state, data) {
    state.requestEntries = data;
  },
  resetFormErrors(state) {
    Object.entries(state.requestEntries).forEach(([, value]) => {
      if (value.length > 0) {
        value.forEach((entry) => (entry.error = ""));
      }
    });
  },
  setCurrentRequest(state, data) {
    if (Object.keys(data).length !== 0) {
      data.employeeData = JSON.parse(data.employeeData, function (key, value) {
        if (value && typeof value === "object") {
          for (let k in value) {
            if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
              value[underscoreToCamelCase(k)] = value[k];
              delete value[k];
            }
          }
        }
        return value;
      });
    }

    state.currentRequest = data;
  },
  addEntry(state, requestEntry) {
    state.requests.push(requestEntry); //TODO state.requests?
  },
  setRequestSubmitError(state, error) {
    state.requestSubmitError = error;
  },
  setRequestFetchError(state, data) {
    state.requestFetchError = data;
  },
  setIsSubmitting(state, data) {
    state.isSubmitting = data;
  },
  setIsFormValid(state, data) {
    state.isFormValid = data;
  },
  setIsApprovalFormValid(state, data) {
    state.isApprovalFormValid = data;
  },
  setFormError(state, data) {
    state.formErrors = data;
  },
  setIsCommentRequired(state, data) {
    state.isCommentRequired = data;
  },
};
