<template>
  <div class="infoDialog">
    <v-overlay opacity="0.75">
      <v-fade-transition>
        <v-sheet light class="submit-modal pa-md-5">
          <v-icon size="128" color="info">mdi-information-outline</v-icon>
          <div class="text-left">
            <h4 v-if="propInfo.title">{{ propInfo.title }}</h4>
            <div>{{ propInfo.message }}</div>
          </div>
          <v-btn
            outlined
            @click="$emit('clearInfo')"
            class="ok-button"
          >OK</v-btn
          >
        </v-sheet>
      </v-fade-transition>
    </v-overlay>
  </div>
</template>
<script>
export default {
  props: {
    propInfo: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.ok-button {
  margin-top: 20px;
}
.submit-modal {
  min-width: 200px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
</style>
