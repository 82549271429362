import { filter as _filter, find as _find } from "lodash";

export default {
  resetEmployeeHours() {
    this.commit("timeOff/employeeHours/setEmployeeHoursUserId", null);
    this.commit("timeOff/employeeHours/setEmployeeHours", []);
    this.commit("timeOff/employeeHours/setEmployeeHoursFetchError", null);
  },
  setEmployeeHours(state, data) {
    state.defaultHoursRequired.forEach((defaultRequired) => {
      let reqCodesReturned = _filter(
        data,
        (item) => item.code === defaultRequired.code
      );

      if (reqCodesReturned.length === 0) {
        data.push(defaultRequired);
      }
    });

    state.employeeHours = data;
  },
  setEmployeeHoursUserId(state, data) {
    state.employeeHoursUserId = data?.trim();
  },
  setEmployeeHoursFetchError(state, data) {
    state.employeeHoursFetchError = data;
  },
  updateRemainingHoursForCategory(state, data) {
    const categoryHours = _find(state.employeeHours, { code: data.code });
    categoryHours.remaining = data.hours;
  },
};
