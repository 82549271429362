export default [
  {
    moduleId: "personalInfo",
    moduleName: "Personal Info",
    metaInfo: {
      title: "Mange Personal Information",
      meta: [
        {
          name: "ESS Personal Info",
          content: "Personal Information",
        },
      ],
    },
    forms: [{}],
    workflow: [],
    api: [{}],
  },
  {
    moduleId: "personalInfo:myInformation",
    parent: "personalInfo",
    moduleName: "My Information",
    metaInfo: {
      title: "My Information",
      meta: [
        {
          name: "My Information",
          content: "Personal Information Details",
        },
      ],
    },
    forms: [{}],
    workflow: [],
  },
];
