<template>
  <time-off-dashboard
  />
</template>

<script>
import { Component } from "vue-property-decorator";
import TimeOffDashboard from "@/modules/timeOff/components/timeOffDashboard.vue";

@Component({
  components: { TimeOffDashboard },
})
export default class DashboardSwift {}
</script>
