export default [
  {
    path: "/timeoff",
    name: "timeOff",
    component: "modules/timeOff",
    meta: {
      title: "Time Off",
      hideFromNav: false,
      icon: "mdi-clock",
    },
    children: [
      {
        name: "timeOff:myEmployeeRequests",
        path: "requests/my-employee-requests/",
        component: "modules/timeOff/requests/myEmployeeRequests",
        meta: {
          title: "My Employee Requests",
          hideFromNav: false,
          icon: "mdi-clock-plus",
        },
        permissions: [
          "isSupervisor",
          "isManager",
          "isPayrollAdmin",
          "isPayroll",
          "isPayrollAssistant",
          "isProxy",
          "isProxyForManager",
        ],
      },
      {
        name: "timeOff:myRequests",
        path: "my-requests",
        component: "modules/timeOff/requests/myRequests",
        meta: {
          title: "My Requests",
          hideFromNav: false,
          icon: "mdi-calendar-month-outline",
        },
      },
      {
        name: "timeOff:newRequest",
        path: "my-requests/new-request",
        component: "modules/timeOff/requests/newRequest",
        meta: {
          title: "New Request",
          hideFromNav: false,
          icon: "mdi-clock-plus",
        },
      },
      {
        name: "timeOff:reviewRequest",
        path: "requests/review",
        component: "modules/timeOff/requests/reviewRequest",
        meta: {
          title: "Review TimeOff Request",
          hideFromNav: true,
          icon: "mdi-clock-plus",
        },
      },
      {
        name: "timeOff:viewRequest",
        path: "requests/view",
        component: "modules/timeOff/requests/viewRequest",
        meta: {
          title: "View TimeOff Request",
          hideFromNav: true,
          icon: "mdi-clock-plus",
        },
      },
      {
        name: "timeOff:schedule",
        path: "my-schedule",
        alias: ["my-schedule/edit"],
        component: "modules/timeOff/employeeSchedule",
        meta: {
          title: "Employee Schedule",
          hideFromNav: false,
          icon: "mdi-clock-outline",
        },
      },
      {
        name: "timeOff:settings",
        path: "settings",
        component: "modules/timeOff/settings",
        meta: {
          title: "Settings",
          hideFromNav: false,
          icon: "mdi-cog",
        },
      },
      {
        name: "timeOff:queues",
        path: "queues",
        component: "modules/timeOff/queues",
        meta: {
          title: "Queues",
          hideFromNav: false,
        },
        children: [
          {
            name: "timeOff:requestQueues",
            path: "pending-approval",
            component: "modules/timeOff/requestQueues",
            meta: {
              title: "Pending Approval Queue",
              hideFromNav: false,
              icon: "mdi-account-clock",
              statusCode: "P",
            },
          },
          {
            name: "timeOff:completedPafs",
            path: "completed-pafs",
            component: "modules/timeOff/requestQueues",
            meta: {
              title: "Completed PAF's Queue",
              hideFromNav: false,
              icon: "mdi-checkbox-multiple-marked",
              statusCode: "F",
            },
          },
          {
            name: "timeOff:deniedPafs",
            path: "denied-pafs",
            component: "modules/timeOff/requestQueues",
            meta: {
              title: "Denied PAF's Queue",
              hideFromNav: false,
              icon: "mdi-cancel",
              statusCode: "D",
            },
          },
          {
            name: "timeOff:managerActionRequired",
            path: "manager-action-required",
            component: "modules/timeOff/requestQueues",
            meta: {
              title: "Manager Action Required Queue",
              hideFromNav: false,
              icon: "mdi-account-alert",
              statusCode: "P",
              managerActionRequired: true,
            },
          },
          {
            name: "timeOff:pendingAS400Queue",
            path: "pending-as400-upload",
            component: "modules/timeOff/requestQueues",
            meta: {
              title: "Pending AS400 Upload Queue",
              hideFromNav: false,
              icon: "mdi-upload",
              statusCode: "S",
            },
          },
          {
            name: "timeOff:pendingPayroll",
            path: "pending-payroll-approval",
            component: "modules/timeOff/requestQueues",
            meta: {
              title: "Pending Payroll Approval Queue",
              hideFromNav: false,
              icon: "mdi-account-check",
              statusCode: "Y",
            },
          },
          {
            name: "timeOff:byStatus",
            path: "by-status",
            component: "modules/timeOff/requestQueues",
            meta: {
              title: "By Status Queue",
              hideFromNav: false,
              icon: "mdi-list-status",
              statusCode: "All",
            },
          },
        ],
      },
      {
        name: "timeOff:admin",
        path: "admin",
        component: "modules/timeOff/admin",
        meta: {
          title: "Admin",
          hideFromNav: false,
        },
        children: [
          {
            name: "timeOff:adminUsersManager",
            path: "admin/manage/admins",
            component: "modules/timeOff/admin/adminUsersManager",
            meta: {
              title: "Manage Payroll Admins",
              hideFromNav: false,
              icon: "mdi-account-box-multiple",
            },
          },
          {
            name: "timeOff:assistantsUsersManager",
            path: "admin/manage/assistants",
            component: "modules/timeOff/admin/adminUsersManager",
            meta: {
              title: "Manage Payroll Assistants",
              hideFromNav: false,
              icon: "mdi-account-supervisor",
            },
          },
          {
            name: "timeOff:proxiesUsersManager",
            path: "admin/manage/proxies",
            component: "modules/timeOff/admin/adminUsersManager",
            meta: {
              title: "Manage Supervisor Proxies",
              hideFromNav: false,
              icon: "mdi-account-switch",
            },
          },
          {
            name: "timeOff:companyHolidaysManager",
            path: "admin/manage/companyHolidays",
            component: "modules/timeOff/admin/companyHolidaysManager",
            meta: {
              title: "Manage Company Holidays",
              hideFromNav: false,
              icon: "mdi-calendar-today",
            },
          },
          {
            name: "timeOff:auditScheduleChanges",
            path: "admin/auditScheduleChanges",
            component: "modules/timeOff/admin/auditScheduleChanges",
            meta: {
              title: "Audit Schedule Changes",
              hideFromNav: false,
              icon: "mdi-receipt-text-clock-outline",
            },
          },
          {
            name: "timeOff:incompleteAS400Upload'",
            path: "incomplete-as400-upload",
            component: "modules/timeOff/requestQueues",
            meta: {
              title: "Incomplete AS400 Uploads",
              hideFromNav: false,
              icon: "mdi-upload-off",
              statusCode: "A",
            },
          },
        ],
      },
    ],
  },
];
