import Vue from "vue";
import Vuetify from "vuetify/lib";
import themes from "@/utils/themes";
import { titleCase } from "@/utils/util";
import { hasIn } from "lodash";

Vue.use(Vuetify);

function getCompanyTheme() {
  const company = titleCase(Vue.$appInfo.company);

  if (hasIn(themes, company)) {
    return {
      dark: themes[company],
      light: themes[company],
    };
  }

  return {};
}

export default () => new Vuetify({
  theme: {
    options: { variations: false },
    themes: getCompanyTheme(),
  },
});
