<template>
  <v-dialog v-model="show" scrollable :max-width="maxWidth" persistent>
    <v-card>
      <v-card-title>
        <v-icon class="mr-2">mdi-check-circle-outline</v-icon>{{ title }}
      </v-card-title>
      <v-card-text>
        <p>
          You do not have an email address saved to your self-service account, please edit your personal information to
          add a preferred email address.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="openMyInfo"> Edit Personal Information </v-btn>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="closeDialog"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Component, Mixins, Prop } from "vue-property-decorator";
import modulePageMixin from "@/utils/mixins";

@Component({
  name: "CheckEmailPreference",
})
export default class CheckEmailPreference extends Mixins(modulePageMixin) {
  @Prop({ type: String, required: false, default: "Email Address Check" }) title;

  @Prop({ type: String, default: "550" }) maxWidth;

  @Prop({ default: false }) show;

  @Prop({ type: String, required: false, default: "" }) editUrl;

  openMyInfo() {
    if (this.isKnight) {
      this.$router.push("/personal-info/my-information");
    } else if (this.isSwift) {
      if (this.editUrl !== "") {
        window.open(this.editUrl, "_blank");
        this.closeDialog();
      }
    }
  }

  closeDialog() {
    this.$emit("closeDialog");
    this.$router.push("/");
  }
}
</script>

<style scoped></style>
