import dates from "@/utils/dates";
import { hoursFormat } from "@/utils/util";

export default {
  selectedCategory(state) {
    return state.selectedCategory;
  },
  reasonForRequest(state) {
    return state.reasonForRequest;
  },
  reviewComments(state) {
    return state.reviewComments;
  },

  requestEntries(state) {
    return state.requestEntries;
  },
  getRequestEntryDateCode: (state) => (dateStr, code) => {
    const dateRequestEntries = state.requestEntries[dateStr] ?? [];
    for (let i = 0; i < dateRequestEntries.length; i++) {
      if (dateRequestEntries[i].code === code) {
        return dateRequestEntries[i];
      }
    }
    return null;
  },
  dateEntriesArray(state) {
    // flatten requestEntries to array of {date: dateStr, entry: <entryObjectFromDateEntries>}
    return Object.keys(state.requestEntries).reduce((accumulator, dateStr) => {
      state.requestEntries[dateStr].forEach((dateEntry) => {
        accumulator.push({
          dateStr: dateStr,
          entry: dateEntry,
        });
      });

      return accumulator;
    }, []);
  },
  transformedEntries(state) {
    // flatten requestEntries to array of {date: <dateStr>, code: <code>, hours: <hourFloat>, id: <id>}
    // this is the format the API needs
    return Object.keys(state.requestEntries).reduce((accumulator, dateStr) => {
      state.requestEntries[dateStr].forEach((dateEntry) => {
        let transformedEntry = {
          date: dateStr,
          code: dateEntry.code,
          hours: dateEntry.hours,
        };
        if (dateEntry.id) {
          transformedEntry["id"] = dateEntry.id;
        }
        accumulator.push(transformedEntry);
      });

      return accumulator;
    }, []);
  },
  getDaysForCategory: (state) => (categoryCode) => {
    let days = 0;
    Object.keys(state.requestEntries).forEach((dateStr) => {
      state.requestEntries[dateStr].forEach((dateEntry) => {
        if (dateEntry.code === categoryCode) {
          days++;
        }
      });
    });

    return days;
  },
  getDayHours: (state, getters, rootState, rootGetters) => (dateStr, code) => {
    const categoryRules = rootGetters["timeOff/getRulesForCategoryCode"](code);

    // day's hours from employee schedule
    let scheduledHours = parseFloat(
      rootGetters["timeOff/employeeSchedule/scheduleForDate"](dateStr)
    );

    if (categoryRules?.negativeBalance === false) {
      const remainingHours =
        rootGetters["timeOff/employeeHours/getRemainingHoursForCategory"](code);
      const requestedHours = getters.getRequestedHoursForCategory(code);
      const hoursAvailable = remainingHours - requestedHours;

      if (hoursAvailable < scheduledHours && hoursAvailable >= 0) {
        return parseFloat(hoursAvailable.toPrecision(1));
      }
    }

    return scheduledHours;
  },
  updateSelectedHours:
    (commit, getters, rootState, rootGetters) =>
    (dateEntries, dateEntry, prevEntry) => {
      let existingHours = dateEntries.reduce((accumulator, entry) => {
        accumulator += parseFloat(entry.hours);
        return accumulator;
      }, 0);

      if (prevEntry !== null) {
        existingHours += prevEntry.hours;
      }

      const scheduledHours = parseFloat(
        rootGetters["timeOff/employeeSchedule/scheduleForDate"](
          dateEntry.dateStr
        )
      );

      // day's hours
      const dayHours = getters.getDayHours(dateEntry.dateStr, dateEntry.code);

      const canAddToSplit =
        existingHours > 0 ? dayHours >= existingHours / 2 : false;

      if (
        dayHours === 0 ||
        (rootGetters["timeOff/employeeSchedule/employeeSchedulePayType"] ===
          rootGetters["timeOff/config"]("salary_pay_type") &&
          dayHours < scheduledHours &&
          !canAddToSplit)
      ) {
        throw "There are not enough available hours to complete this request";
      }

      if (scheduledHours - existingHours < 0) {
        throw "You already have too many hours requested for this day.";
      }

      if (existingHours === scheduledHours || existingHours <= 0) {
        // a full day is already requested, add new code and evenly split
        let newDateEntry = { code: dateEntry.code, perc: 0, hours: 0.0 };

        dateEntries.push(newDateEntry);

        let newHours = scheduledHours / dateEntries.length;

        if (dayHours / scheduledHours < 1 && dayHours - newHours <= 0) {
          newHours = dayHours;
        }

        dateEntries.forEach((s) => {
          if (s.code !== dateEntry.code) {
            s.perc = parseFloat(s.hours - newHours) / scheduledHours;
            s.hours = hoursFormat(s.hours - newHours);
          } else {
            s.perc = newHours / scheduledHours;
            s.hours = hoursFormat(newHours);
          }
          s.isSplit = s.perc !== 1;
        });
      } else {
        // a partial day is requested, fill request with remaining hours
        let newDateEntry = {
          code: dateEntry.code,
          perc: 0,
          hours: hoursFormat(dayHours - existingHours),
        };

        dateEntries.push(newDateEntry);

        // recalc percentages
        dateEntries.forEach((s) => {
          s.perc = s.hours / dayHours;
          s.isSplit = s.perc !== 1;
        });
      }

      return dateEntries;
    },
  getFormErrors(state, getters, rootState, rootGetters) {
    let errorMessage = "";
    Object.entries(state.requestEntries).forEach(([key, value]) => {
      if (value.length > 0) {
        value.forEach((entry) => {
          if (entry.error) {
            errorMessage +=
              `<strong>${dates.dateFormalString(key)} ${rootGetters[
                "timeOff/employeeHours/categoryFormat"
              ](entry.code)} - </strong>${entry?.error}<br />` ?? "";
          }
        });
      }
    });
    return errorMessage;
  },
  getRequestedHoursForCategory: (state) => (categoryCode) => {
    let hours = 0;

    Object.values(state.requestEntries).forEach((value) => {
      hours += value.reduce((accumulator, entry) => {
        if (entry.code === categoryCode) {
          accumulator += parseFloat(entry.hours);
        }
        return accumulator;
      }, 0);
    });

    return hours;
  },
  getCurrentRequest(state) {
    return state.currentRequest;
  },

  requestSubmitError(state) {
    return state.requestSubmitError;
  },
  requestFetchError(state) {
    return state.requestFetchError;
  },

  isSubmitting(state) {
    return state.isSubmitting;
  },
  isFormValid(state) {
    return state.isFormValid;
  },
  isApprovalFormValid(state) {
    return state.isApprovalFormValid;
  },
  isCommentRequired(state) {
    return state.isCommentRequired;
  },
  successMessage: (state) => (action) => {
    return state.successMessages[action].message;
  },
};
