<template>
  <v-card class="inner-card">
    <v-card-title>History </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr v-for="item in history" :key="item.name">
                  <td>
                    {{ formatLogDate(item.createTimestamp) }} -
                    {{ item.comment }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import dates from "@/utils/dates";

@Component({
  name: "RequestHistory",
})
export default class RequestHistory extends Vue {
  name = "requestHistory.vue";

  @Prop({
    type: Array,
    required: true,
    default: [],
  })
  history;

  formatLogDate(itemDate) {
    return dates.dateTimeString(dates.dateFromMezzioDateJson(itemDate));
  }
}
</script>
