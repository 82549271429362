import Vue from "vue";
import timeOff from "./timeOff";

export default {
  getApi() {
    return timeOff;
  },
  registerApi() {
    Vue.$apis.addApi(timeOff);
  },
};
